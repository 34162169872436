import isEmpty from "lodash/isEmpty";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  FeeDiscountFormInputs,
  useSubmitCreateFeeDiscount,
} from "@/features/FeeDiscountForm";
import { useConfirmFormCancel } from "@/modules/Form";
import { ConfirmModal } from "@/modules/Modal";
import { BackButton } from "@/modules/Navigation";
import { Box, Button, HStack, Text } from "@chakra-ui/react";

export default function FeeDiscountFormControls() {
  const {
    formState: { errors, isSubmitting, isSubmitSuccessful },
    handleSubmit,
    setError,
    trigger,
  } = useFormContext<FeeDiscountFormInputs>();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { submitCreateFeeDiscount, fetching } = useSubmitCreateFeeDiscount();
  const { confirmCancel, isRoutingBack } = useConfirmFormCancel();

  const handleCreate = async () => {
    const isFormValid = await trigger();
    if (isFormValid && isEmpty(errors)) {
      setOpen(true);
    }

    Object.keys(errors).forEach((key) => {
      setError(key as keyof FeeDiscountFormInputs, {
        message: errors[key as keyof FeeDiscountFormInputs]?.message,
      });
    });
  };

  return (
    <>
      <HStack w="full">
        <BackButton />
        <Box flexGrow={1} />
        <Button
          variant="outline"
          onClick={confirmCancel}
          disabled={isRoutingBack}
        >
          {t(`cancel`)}
        </Button>
        <Button variant="solid" onClick={handleCreate} disabled={isRoutingBack}>
          {t(`create`)}
        </Button>
      </HStack>
      <ConfirmModal
        title={t(`confirm_new_fee_discount`)}
        confirmText={t(`confirm`)}
        body={<Text>{t(`confirm_new_fee_discount_body`)}</Text>}
        isOpen={open}
        onClose={() => setOpen(false)}
        onConfirm={handleSubmit(submitCreateFeeDiscount)}
        disableConfirm={isSubmitting || isSubmitSuccessful || fetching}
      />
    </>
  );
}
