import {
  EntityStatus,
  InstitutionEntityFragment,
  UserEntityFragment,
} from "@/graphql";
import { Table } from "@/modules/Table";
import { Menu, MenuList } from "@chakra-ui/react";
import { useSellerExternalAccountEnabled } from "@/modules/LaunchDarkly";
import ArchiveEntityMenuItem from "./ArchiveEntityMenuItem";
import UnarchiveEntityMenuItem from "./UnarchiveEntityMenuItem";
import GenerateManualLinkMenuItem from "./GenerateManualLinkMenuItem";

type EntityCardActionsProps = {
  entity: UserEntityFragment | InstitutionEntityFragment;
  isInstitution?: boolean;
};

export default function EntityCardActions({
  entity,
  isInstitution = false,
}: EntityCardActionsProps) {
  const isArchived = entity.status === EntityStatus.Archived;
  const isActive = entity.status === EntityStatus.Active;
  const isSEAEnabled = useSellerExternalAccountEnabled();

  return (
    <Menu>
      <Table.ActionButton />
      <MenuList>
        {!isArchived && (
          <>
            {isSEAEnabled && <GenerateManualLinkMenuItem entity={entity} />}
            {!isActive && (
              <ArchiveEntityMenuItem
                entity={entity}
                isInstitution={isInstitution}
              />
            )}
          </>
        )}
        {isArchived && (
          <UnarchiveEntityMenuItem
            entity={entity}
            isInstitution={isInstitution}
          />
        )}
      </MenuList>
    </Menu>
  );
}
