import i18next from "i18next";
import { useContext } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { CompanyManagedMarketContext } from "@/features/Company";
import { useCompanyManagedMarketApplyCompanyEligibilityCriterionMutation } from "@/graphql";
import {
  FormLabelPosition,
  Input,
  useYupValidationResolver,
} from "@/modules/Form";
import { ConfirmModal } from "@/modules/Modal";
import { useCustomToast } from "@/modules/Toast";
import { Text, VStack } from "@chakra-ui/react";

import { CompanyEligibilityCriteriaAlert } from "./CompanyEligibilityCriteriaAlert";

type ApplyCompanyEligibilityCriterionInputs = {
  companyId: string;
  text: string;
};

const ValidationSchema = yup.object().shape({
  text: yup.string().trim().required(i18next.t(`required_field`)),
});

function ApplyCompanyEligibilityCriterionModalBody() {
  const {
    formState: { errors },
    register,
  } = useFormContext<ApplyCompanyEligibilityCriterionInputs>();
  const { t } = useTranslation();

  return (
    <VStack gap={4} alignItems="flex-start" w="full">
      <Text>{t(`apply_seller_eligibility_modal_description`)}</Text>
      <CompanyEligibilityCriteriaAlert />
      <Input.Area
        error={errors.text}
        formLabelPosition={FormLabelPosition.column}
        helperText={t(`apply_seller_eligibility_modal_helper_text`)}
        label={t(`seller_eligibility_criteria`)}
        {...register(`text`)}
      />
    </VStack>
  );
}

interface ApplyCompanyEligibilityCriterionModalProps {
  readonly isOpen: boolean;
  readonly onClose: () => void;
}

export function ApplyCompanyEligibilityCriterionModal({
  isOpen,
  onClose,
}: ApplyCompanyEligibilityCriterionModalProps) {
  const { t } = useTranslation();
  const { managedMarketCompany, refetch } = useContext(
    CompanyManagedMarketContext,
  );
  const resolver = useYupValidationResolver(ValidationSchema);
  const methods = useForm<ApplyCompanyEligibilityCriterionInputs>({ resolver });
  const [, applyCompanyEligibilityCriterion] =
    useCompanyManagedMarketApplyCompanyEligibilityCriterionMutation();
  const { successToast } = useCustomToast();

  if (!managedMarketCompany) return null;

  const { id: companyId } = managedMarketCompany;

  const {
    formState: { isSubmitting },
    handleSubmit,
  } = methods;

  const onConfirm = async (data: ApplyCompanyEligibilityCriterionInputs) => {
    const { data: response } = await applyCompanyEligibilityCriterion({
      input: {
        companyId,
        text: data.text,
      },
    });

    if (response?.addCompanyEligibilityCriterion) {
      successToast(t(`seller_eligibility_applied`));
      refetch();
      onClose();
    }
  };

  return (
    <ConfirmModal
      title={t(`apply_seller_eligibility_criteria`)}
      body={
        <FormProvider {...methods}>
          <ApplyCompanyEligibilityCriterionModalBody />
        </FormProvider>
      }
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleSubmit(onConfirm)}
      disableConfirm={isSubmitting}
      size="xl"
    />
  );
}
