import { VStack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { TransactionsTableProvider } from "@/features/Transactions";
import TransactionsTable, { TransactionsUrlParams } from "./TransactionsTable";

export default function TransactionsPage({
  query,
}: {
  query: TransactionsUrlParams;
}) {
  const { t } = useTranslation();

  return (
    <TransactionsTableProvider query={query}>
      <VStack p={8} gap={0} alignItems="flex-start">
        <Text mb={4} textStyle="colfax-22-medium">
          {t(`transactions`)}
        </Text>
        <TransactionsTable />
      </VStack>
    </TransactionsTableProvider>
  );
}
