import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { ThemingProps } from "@chakra-ui/styled-system";

interface ConfirmModalProps {
  title: string;
  body: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onCancel?: () => void;
  cancelText?: string;
  confirmText?: string;
  loading?: boolean;
  disableConfirm?: boolean;
  cancelButtonVariant?: ThemingProps<"Button">["variant"];
  confirmButtonType?: "button" | "submit" | "reset";
  size?: ThemingProps<"Modal">["size"];
  modalVariant?: ThemingProps<"Modal">["variant"];
  showCancelButton?: boolean;
}

export default function ConfirmModal({
  title,
  body,
  isOpen,
  onClose,
  onConfirm,
  onCancel,
  loading = false,
  cancelText = `Cancel`,
  confirmText = `Confirm`,
  disableConfirm = false,
  cancelButtonVariant = `outline`,
  confirmButtonType = `button`,
  size = `lg`,
  modalVariant = `base`,
  showCancelButton = true,
}: ConfirmModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={size} variant={modalVariant}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{body}</ModalBody>
        <ModalFooter>
          {showCancelButton && (
            <Button variant={cancelButtonVariant} onClick={onCancel || onClose}>
              {cancelText}
            </Button>
          )}
          <Button
            isLoading={loading}
            variant="solid"
            type={confirmButtonType}
            onClick={onConfirm}
            isDisabled={disableConfirm}
          >
            {confirmText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
