import {
  ExecutionIssuerApprovalStatus,
  TransactionPageV2ExecutionTaskFragment,
  useTransactionPageV2CompleteExecutionTaskMutation,
} from "@/graphql";
import { ConfirmModal } from "@/modules/Modal";
import { useCustomToast } from "@/modules/Toast";
import { Text } from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";
import { match } from "ts-pattern";

export type CompleteTaskTypes =
  | "MANUAL"
  | "ISSUER_APPROVED"
  | "ISSUER_ROFR"
  | "ISSUER_DECLINED";

const getStatus = (type: CompleteTaskTypes) =>
  match(type)
    .with(`ISSUER_APPROVED`, () => ExecutionIssuerApprovalStatus.Approved)
    .with(`ISSUER_ROFR`, () => ExecutionIssuerApprovalStatus.Rofr)
    .with(`ISSUER_DECLINED`, () => ExecutionIssuerApprovalStatus.Declined)
    .otherwise(() => undefined);

const getTitleAndKey = (type: CompleteTaskTypes) =>
  match(type)
    .with(`MANUAL`, () => ({
      title: `complete_task`,
      key: `confirm_step_confirmation`,
    }))
    .with(`ISSUER_APPROVED`, () => ({
      title: `mark_issuer_approved`,
      key: `mark_issuer_approved_confirmation`,
    }))
    .with(`ISSUER_ROFR`, () => ({
      title: `mark_issuer_rofr`,
      key: `mark_issuer_rofr_confirmation`,
    }))
    .with(`ISSUER_DECLINED`, () => ({
      title: `mark_issuer_declined`,
      key: `mark_issuer_declined_confirmation`,
    }))
    .run();

const getInput = (type: CompleteTaskTypes) => {
  const status = getStatus(type);
  return status !== undefined
    ? { confirmIssuerApproval: { approvalStatus: status } }
    : null;
};

export function CompleteTaskModal({
  isOpen,
  onClose,
  task,
  stepName,
  type = `MANUAL`,
}: {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly stepName?: string;
  readonly task: TransactionPageV2ExecutionTaskFragment | null;
  readonly type?: CompleteTaskTypes;
}) {
  const { t } = useTranslation();
  const [{ fetching }, completeExecutionTask] =
    useTransactionPageV2CompleteExecutionTaskMutation();
  const { errorToast, successToast } = useCustomToast();

  if (!task) return null;

  const handleConfirm = async () => {
    const input = getInput(type);
    const { data } = await completeExecutionTask(
      { taskId: task.id, ...(input ? { input } : {}) },
      { additionalTypenames: [`ExecutionWorkflow`] },
    );

    if (!data?.completeExecutionTask.task) {
      errorToast();
    } else {
      successToast();
      onClose();
    }
  };

  const { title, key } = getTitleAndKey(type);

  return (
    <ConfirmModal
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore i18nKey is not into dynamic keys
      title={t(title)}
      body={
        <Text>
          <Trans
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore i18nKey is not into dynamic keys
            i18nKey={key}
            values={type === `MANUAL` ? { stepName } : undefined}
            components={{ bold: <strong /> }}
          />
        </Text>
      }
      onConfirm={handleConfirm}
      loading={fetching}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
}
