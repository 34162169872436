import { Status } from "@/modules/Status";
import { Box, Text, Tooltip } from "@chakra-ui/react";

export const DEFAULT_TABLE_TOOLTIP_MAX_WIDTH = { base: 75, xl: 175 };

export default function TableTooltipCell({
  id,
  text,
  tooltip,
  maxW = DEFAULT_TABLE_TOOLTIP_MAX_WIDTH,
  lineHeight = 1,
  wrap,
}: {
  id?: string | null;
  text?: string | null;
  tooltip?: string;
  maxW?: Record<string, number>;
  lineHeight?: number;
  wrap?: boolean;
}) {
  return text ? (
    <Box display="inline-flex" maxW={maxW} lineHeight={lineHeight}>
      <Tooltip
        hasArrow
        label={tooltip || text}
        aria-label={`${id || text} tooltip`}
      >
        <Text isTruncated whiteSpace={wrap ? `pre` : ``}>
          {text}
        </Text>
      </Tooltip>
    </Box>
  ) : (
    <Status.Empty />
  );
}
