import { BankAccountCardBankAccountFragment } from "@/graphql";
import { MenuItem, useDisclosure } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { MicrodepositVerificationModal } from "./MicrodepositVerificationModal";

type MicrodepositVerificationButtonProps = {
  readonly bankAccount: BankAccountCardBankAccountFragment;
};

export default function MicrodepositVerificationButton({
  bankAccount,
}: MicrodepositVerificationButtonProps) {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <MenuItem onClick={onOpen}>{t(`manually_verify`)}</MenuItem>
      <MicrodepositVerificationModal
        bankAccount={bankAccount}
        isOpen={isOpen}
        onClose={() => onClose()}
      />
    </>
  );
}
