import {
  ArchiveEntityInput,
  useArchiveEntityMutation,
  UserEntityFragment,
} from "@/graphql";
import { ConfirmModal } from "@/modules/Modal";
import { useCustomToast } from "@/modules/Toast";
import { MenuItem, Text, useDisclosure } from "@chakra-ui/react";
import { SubmitHandler } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";

type ArchiveEntityMenuItemProps = {
  entity: UserEntityFragment;
  isInstitution?: boolean;
};

export default function ArchiveEntityMenuItem({
  entity,
  isInstitution = false,
}: ArchiveEntityMenuItemProps) {
  const { t } = useTranslation();
  const { successToast, errorToast } = useCustomToast();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [, archiveEntity] = useArchiveEntityMutation();

  const onSubmit: SubmitHandler<ArchiveEntityInput> = async (input) => {
    const data = {
      input,
    };
    const { error } = await archiveEntity(data, {
      additionalTypenames: [isInstitution ? `Institution` : `User`],
    });

    if (error) {
      errorToast(t(`could_not_archive_entity`));
    } else {
      successToast(t(`entity_archived_successfully`));
      onClose();
    }
  };

  return (
    <>
      <MenuItem onClick={() => onOpen()}>
        <Text>{t(`archive_entity`)}</Text>
      </MenuItem>
      <ConfirmModal
        body={
          <Trans
            components={{ bold: <strong /> }}
            values={{
              entity: entity.legalName,
            }}
            i18nKey="confirm_archive_message"
          />
        }
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={() =>
          onSubmit({
            entityId: entity.id,
          })
        }
        title={t(`confirm_archive`)}
      />
    </>
  );
}
