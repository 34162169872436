import {
  SaveExecutionWorkflowMutationVariables,
  WorkflowEdgeFragment,
  WorkflowPageExecutionStepFragment,
  WorkflowTemplatePageStepTemplateFragment,
  WorkflowTemplatePageEdgeTemplateFragment,
  SaveExecutionWorkflowTemplateInput,
  ExecutionStepStatus,
  WorkflowPreviewStepFragment,
} from "@/graphql";
import omit from "lodash/omit";
import cloneDeepWith from "lodash/cloneDeepWith";
import isPlainObject from "lodash/isPlainObject";
import isObject from "lodash/isObject";
import mapValues from "lodash/mapValues";

import {
  WorkflowStepNode,
  WorkflowPreviewStepNode,
  WorkflowStepEdge,
  WorkflowTemplateStepNode,
} from "@/modules/Workflow";
import { Node } from "@xyflow/react";

export function mapWorkflowStepsToStepNodes(
  workflowSteps: WorkflowPageExecutionStepFragment[],
): WorkflowStepNode[] {
  return workflowSteps.map((step) => ({
    id: step.id,
    type: `step`,
    data: step,
    deletable: ![
      ExecutionStepStatus.Completed,
      ExecutionStepStatus.InProgress,
    ].includes(step.status),
    position: { x: step.ui.x, y: step.ui.y },
  }));
}

export function mapWorkflowStepTemplatesToStepNodes(
  workflowSteps: WorkflowTemplatePageStepTemplateFragment[],
): WorkflowTemplateStepNode[] {
  return workflowSteps.map((step) => ({
    id: step.id,
    type: `step`,
    data: step,
    position: { x: step.ui.x, y: step.ui.y },
  }));
}

export function mapWorkflowPreviewStepsToStepNodes(
  workflowSteps: WorkflowPreviewStepFragment[],
): WorkflowPreviewStepNode[] {
  return workflowSteps.map((step) => ({
    id: step.id,
    type: `step`,
    data: step,
    position: { x: step.ui.x, y: step.ui.y },
    status: step.status,
  }));
}

export function mapWorkflowEdgesToStepEdges(
  workflowEdges: WorkflowEdgeFragment[],
  workflowNodes?: WorkflowStepNode[],
): WorkflowStepEdge[] {
  const nodeDeletableStatus = workflowNodes
    ? workflowNodes.reduce(
        (acc, node) => ({
          ...acc,
          [node.id]: node.deletable ?? true,
        }),
        {} as Record<string, boolean>,
      )
    : {};

  return workflowEdges.map((edge) => {
    const sourceDeletable = nodeDeletableStatus[edge.fromId || ``] ?? true;
    const targetDeletable = nodeDeletableStatus[edge.toId || ``] ?? true;

    return {
      data: {
        showDropzone: false,
        activateDropzone: false,
      },
      id: edge.id,
      source: edge.fromId || ``,
      target: edge.toId || ``,
      deletable: sourceDeletable || targetDeletable,
      type: `smart`,
    };
  });
}

export function mapWorkflowTemplateEdgesToStepEdges(
  workflowEdges: WorkflowTemplatePageEdgeTemplateFragment[],
): WorkflowStepEdge[] {
  return workflowEdges.map((edge) => ({
    id: edge.id,
    source: edge.fromStepTemplateId || ``,
    target: edge.toStepTemplateId || ``,
    type: `smart`,
  }));
}

function deepOmitTypename<T>(obj: T) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function customizer(value: any) {
    if (isPlainObject(value)) {
      const cleanedObject = omit(value, `__typename`);
      return mapValues(cleanedObject, (v) =>
        isObject(v) ? cloneDeepWith(v, customizer) : v,
      );
    }
    return undefined;
  }

  return cloneDeepWith(obj, customizer);
}

export function mapStepNodesToWorkflowStepsInput(
  nodes: Node<WorkflowPageExecutionStepFragment>[],
): SaveExecutionWorkflowMutationVariables["input"]["steps"] {
  return nodes.map((node) => {
    const config = {
      [node.data.type.toLowerCase()]: deepOmitTypename(node.data.config),
    };

    const instructions = deepOmitTypename(node.data.instructions);

    return {
      key: node.id,
      id: node.id,
      name: node.data.name,
      removable: node.data.removable,
      type: node.data.type,
      visibility: node.data.visibility,
      status: node.data.status || ExecutionStepStatus.Pending,
      config,
      instructions,
      ui: {
        x: Math.floor(node.position.x),
        y: Math.floor(node.position.y),
        width: 0,
        height: 0,
      },
    };
  });
}

export function mapStepNodesToWorkflowTemplateStepsInput(
  nodes: WorkflowTemplateStepNode[],
): SaveExecutionWorkflowTemplateInput["stepTemplates"] {
  return nodes.map((node) => {
    const config = {
      [node.data.type]: deepOmitTypename(node.data.config),
    };

    const instructions = deepOmitTypename(node.data.instructions);

    return {
      key: node.id,
      name: node.data.name,
      type: node.data.type,
      visibility: node.data.visibility,
      removable: node.data.removable,
      config,
      instructions,
      ui: {
        x: Math.floor(node.position.x),
        y: Math.floor(node.position.y),
        width: 0,
        height: 0,
      },
    };
  });
}
