import i18next from "i18next";
import { UseQueryState } from "urql";

import {
  FeeDiscountApplicationActionType,
  FeeDiscountEntityType,
  FeeDiscountPageApplicationActionFragment,
  FeeDiscountPageApplicationFragment,
  FeeDiscountPageApplicationsTableQuery,
  FeeDiscountPageApplicationsTableQueryVariables,
} from "@/graphql";

type EntityDataType = {
  companyName?: string;
  nameOrId?: string;
  nameSlug?: string;
};

type EntityDataMapperProps = Pick<
  FeeDiscountPageApplicationFragment,
  "company" | "listing" | "transaction" | "user"
>;

const DEFAULT = {
  feeDiscountApplications: [],
  totalCount: 0,
  pageInfo: undefined,
};

const ENTITY_TYPE_COPY = {
  [FeeDiscountEntityType.Company]: i18next.t(`company`),
  [FeeDiscountEntityType.Listing]: i18next.t(`listing`),
  [FeeDiscountEntityType.Transaction]: i18next.t(`transaction`),
  [FeeDiscountEntityType.User]: i18next.t(`user`),
};

const MAP_ENTITY_DATA_BY_TYPE = {
  [FeeDiscountEntityType.Company]: ({
    company,
  }: EntityDataMapperProps): EntityDataType => ({
    companyName: company?.name,
    nameOrId: company?.name,
    nameSlug: company?.nameSlug,
  }),
  [FeeDiscountEntityType.Listing]: ({
    listing,
  }: EntityDataMapperProps): EntityDataType => ({
    companyName: listing?.company.name,
    nameOrId: listing?.displayId || listing?.shortId,
  }),
  [FeeDiscountEntityType.Transaction]: ({
    transaction,
  }: EntityDataMapperProps): EntityDataType => ({
    companyName: transaction?.bid?.company.name,
    nameOrId: transaction?.bid?.displayId,
  }),
  [FeeDiscountEntityType.User]: ({
    company,
    user,
  }: EntityDataMapperProps): EntityDataType => ({
    companyName: company?.name,
    nameOrId: user?.name,
  }),
};

const filterByAction =
  (actionType: FeeDiscountApplicationActionType) =>
  ({ action }: FeeDiscountPageApplicationActionFragment) =>
    action === actionType;

export const mapFeeDiscountApplications = (
  responseData:
    | UseQueryState<
        FeeDiscountPageApplicationsTableQuery,
        FeeDiscountPageApplicationsTableQueryVariables
      >
    | undefined,
) => {
  if (
    !responseData ||
    !responseData.data ||
    !responseData.data.feeDiscountApplications
  ) {
    return {
      ...DEFAULT,
      error: responseData?.error,
      fetching: responseData?.fetching,
    };
  }

  const feeDiscountApplications =
    responseData.data.feeDiscountApplications.edges.map(({ node }) => {
      const {
        company,
        entityId,
        entityType,
        expireAt,
        feeDiscountApplicationActions,
        insertedAt,
        listing,
        transaction,
        user,
        ...rest
      } = node;

      const { companyName, nameOrId, nameSlug } = MAP_ENTITY_DATA_BY_TYPE[
        entityType
      ]({
        company,
        listing,
        transaction,
        user,
      });

      const [assignAction] = feeDiscountApplicationActions.filter(
        filterByAction(FeeDiscountApplicationActionType.Assign),
      );

      const [unassignAction] = feeDiscountApplicationActions.filter(
        filterByAction(FeeDiscountApplicationActionType.Unassign),
      );

      return {
        appliedBy: assignAction?.user?.name,
        companyName: companyName || `-`,
        dateApplied: assignAction?.insertedAt,
        dateRemoved: unassignAction?.insertedAt,
        entityId,
        entityType,
        entityTypeLabel: ENTITY_TYPE_COPY[entityType],
        expireAt,
        insertedAt,
        nameOrId,
        nameSlug,
        removedBy: unassignAction?.user?.name,
        ...rest,
      };
    });

  return {
    error: responseData.error,
    feeDiscountApplications,
    loading: responseData.fetching,
    pageInfo: responseData.data.feeDiscountApplications.pageInfo,
    totalCount: responseData.data.feeDiscountApplications.totalCount,
  };
};
