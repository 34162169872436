import { ExecutionCondition, ExecutionWorkflowCondition } from "@/graphql";
import Dagre from "dagre";
import { StatusBadge } from "@/modules/StatusBadge";
import { CheckCircle, Warning, XCircle } from "@phosphor-icons/react";
import { t } from "i18next";
import { BaseEdge, BaseNode, WorkflowStepEdge } from "@/modules/Workflow";
import { Edge } from "@xyflow/react";
import { WorkflowStepNodeType } from "./types";

export const IssuerApprovalApproved = `ISSUER_APPROVED`;

export const hasIssuerDeclinedWorkflow = (
  conditions: Pick<ExecutionCondition, "condition">[],
) =>
  conditions.some(
    ({ condition }) =>
      condition === ExecutionWorkflowCondition.IssuerApprovalDeclined,
  );

export const hasIssuerROFRedWorkflow = (
  conditions: Pick<ExecutionCondition, "condition">[],
) =>
  conditions.some(
    ({ condition }) => condition === ExecutionWorkflowCondition.Rofr,
  );

export const workflowConditionStatus: Record<
  ExecutionWorkflowCondition | typeof IssuerApprovalApproved,
  JSX.Element
> = {
  [ExecutionWorkflowCondition.IssuerApprovalDeclined]: (
    <StatusBadge
      variant="red"
      title={t(`issuer_declined`)}
      icon={<XCircle size={10} weight="fill" />}
    />
  ),
  [ExecutionWorkflowCondition.Rofr]: (
    <StatusBadge
      variant="yellow"
      title={t(`rofr`)}
      icon={<Warning size={10} weight="fill" />}
    />
  ),
  [IssuerApprovalApproved]: (
    <StatusBadge
      variant="green"
      title={t(`approved`)}
      icon={<CheckCircle size={10} weight="fill" />}
    />
  ),
};

export const pathExists = (
  source: string,
  target: string,
  edges: WorkflowStepEdge[],
) => {
  const visited = new Set();
  let queue = [source];

  while (queue.length > 0) {
    const [current, ...rest] = queue;
    queue = rest;

    if (current === target) return true;
    visited.add(current);

    const newEdges = edges.filter(
      (edge) => edge.source === current && !visited.has(edge.target),
    );
    queue = [...queue, ...newEdges.map((edge) => edge.target)];
  }
  return false;
};

export const getLayoutedElements = <T extends BaseNode, E extends BaseEdge>(
  nodes: T[],
  edges: E[],
) => {
  const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
  g.setGraph({
    rankdir: `TB`,
    nodesep: 50, // vertical spacing between nodes
    ranksep: 50, // horizontal spacing between nodes
  });

  edges.forEach((edge: E) => g.setEdge(edge.source, edge.target));
  nodes.forEach((node: T) =>
    g.setNode(node.id, { ...node, width: 255, height: 50 }),
  );

  Dagre.layout(g);

  return {
    nodes: nodes.map((node) => {
      const { x, y } = g.node(node.id);

      return { ...node, position: { x, y } };
    }),
    edges,
  };
};

export function maybeAutoLayout<T extends BaseNode, E extends BaseEdge>(
  nodes: T[],
  edges: E[],
): {
  nodes: T[];
  edges: E[];
} {
  // todo: remove this once we have better layout system that works like knock

  if (nodes.some((node) => node.position.x === 0 && node.position.y === 0)) {
    return getLayoutedElements(nodes, edges);
  }

  return {
    nodes,
    edges,
  };
}

// this should be unified with src/features/Transactions/components/TransactionPageV2/utils.ts
// leaving for now on account of the different types
export function replaceIntermediaryEdges<StepType extends WorkflowStepNodeType>(
  edges: Edge[],
  steps: StepType[],
  intermediaryTypeName: string,
): Edge[] {
  const intermediaryStepIds = new Set(
    steps
      .filter((step) => step.data.config.__typename === intermediaryTypeName)
      .map((step) => step.id),
  );

  const { edgesToAdd, edgesToRemoveIds } = edges.reduce(
    (acc, edge) => {
      const source = edge.source ?? ``;
      const target = edge.target ?? ``;
      const fromStepIsIntermediary = intermediaryStepIds.has(source);
      const toStepIsIntermediary = intermediaryStepIds.has(target);

      if (fromStepIsIntermediary || toStepIsIntermediary) {
        const updatedEdgesToRemoveIds = new Set(acc.edgesToRemoveIds).add(
          edge.id,
        );
        let updatedEdgesToAdd = acc.edgesToAdd;

        if (!fromStepIsIntermediary && toStepIsIntermediary) {
          const newEdges = edges
            .filter(
              (e) =>
                e.target === target && !intermediaryStepIds.has(e.target ?? ``),
            )
            .map((intermediaryEdge) => ({
              id: `${source}-${intermediaryEdge.target}`,
              source,
              target: intermediaryEdge.target ?? ``,
            }));

          updatedEdgesToAdd = [...acc.edgesToAdd, ...newEdges];
          newEdges.forEach((intermediaryEdge) =>
            updatedEdgesToRemoveIds.add(intermediaryEdge.id),
          );
        }

        if (fromStepIsIntermediary && !toStepIsIntermediary) {
          const newEdges = edges
            .filter(
              (e) =>
                e.target === source && !intermediaryStepIds.has(e.source ?? ``),
            )
            .map((intermediaryEdge) => ({
              id: `${intermediaryEdge.source}-${target}`,
              source: intermediaryEdge.source ?? ``,
              target,
            }));

          updatedEdgesToAdd = [...acc.edgesToAdd, ...newEdges];
          newEdges.forEach((intermediaryEdge) =>
            updatedEdgesToRemoveIds.add(intermediaryEdge.id),
          );
        }

        return {
          edgesToAdd: updatedEdgesToAdd,
          edgesToRemoveIds: updatedEdgesToRemoveIds,
        };
      }

      return acc;
    },
    {
      edgesToAdd: [] as Edge[],
      edgesToRemoveIds: new Set<string>(),
    },
  );

  return [
    ...edges.filter((edge) => !edgesToRemoveIds.has(edge.id)),
    ...edgesToAdd,
  ];
}
