import { useContext } from "react";

import { ErrorWrapper } from "@/modules/Error";
import { DetailPageLayout } from "@/modules/Layout";
import { VStack } from "@chakra-ui/react";

import {
  FeeDiscountPageApplicationsTable,
  FeeDiscountPageOverview,
  FeeDiscountPageRightPanel,
  FeeDiscountPageSkeleton,
} from "./components";
import {
  FeeDiscountDetailsContext,
  FeeDiscountDetailsProvider,
  FeeDiscountPageApplicationsTableProvider,
} from "./providers";

function FeeDiscountPageContent() {
  const { error, feeDiscount } = useContext(FeeDiscountDetailsContext);

  const { archivedAt, archivedBy, id, createdBy, insertedAt } =
    feeDiscount || {};
  const showRightPanel = !!createdBy;

  return (
    <ErrorWrapper error={error?.message}>
      {id ? (
        <DetailPageLayout
          isTwoColumn={showRightPanel}
          mainPanel={
            <VStack alignItems="flex-start" gap={4}>
              <FeeDiscountPageOverview />
              <FeeDiscountPageApplicationsTableProvider>
                <FeeDiscountPageApplicationsTable />
              </FeeDiscountPageApplicationsTableProvider>
            </VStack>
          }
          rightPanel={
            showRightPanel && (
              <FeeDiscountPageRightPanel
                archivedAt={archivedAt}
                archivedBy={archivedBy}
                createdBy={createdBy}
                insertedAt={insertedAt}
              />
            )
          }
        />
      ) : (
        <FeeDiscountPageSkeleton />
      )}
    </ErrorWrapper>
  );
}

export default function FeeDiscountPage({ id }: { id: string }) {
  return (
    <FeeDiscountDetailsProvider id={id}>
      <FeeDiscountPageContent />
    </FeeDiscountDetailsProvider>
  );
}
