import {
  ChangeTransactionTypeSigningProcedure,
  ChangeTransactionTypeTransferMethod,
  DocumentType,
  ReviewTransactionSigningProcedure,
  ReviewTransactionTransferMethod,
  ReviewTransactionTransferMethodV2,
  SigningProcedure,
  TransactionsTableNodeFragment,
  TransactionState,
  TransactionTransferMethod,
  TransitionTransactionInput,
  useSubmitPendingTransactionModificationMutation,
  useTransactionsTableRowActionsChangeTransactionTransferTypeMutation,
  useTransactionsTableRowActionsExtendTransactionExpiryDateMutation,
  useTransactionsTableRowActionsMakeTransactionManualMutation,
  useTransactionsTableRowActionsReviewTransactionMutation,
  useTransactionsTableRowActionsReviewTransactionV2Mutation,
  useTransactionsTableRowActionsTransitionTransactionMutation,
} from "@/graphql";
import { DateTimePicker, Input } from "@/modules/Form";
import { ConfirmModal } from "@/modules/Modal";
import { Table } from "@/modules/Table";
import { useColors } from "@/modules/Theme";
import { useCustomToast } from "@/modules/Toast";
import {
  Checkbox,
  Divider,
  Menu,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { Circle } from "@phosphor-icons/react";
import { DateTime } from "luxon";
import { useRouter } from "next/router";
import { useCallback, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { match } from "ts-pattern";
import * as yup from "yup";
import { SubmitHandler, useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSPVToFund } from "@/modules/LaunchDarkly";
import TransactionCancellationModal from "./TransactionCancellationModal";
import TransactionModificationModal from "./TransactionModificationModal";
import TransactionIssuerApprovalDeclinedModal from "./TransactionIssuerApprovalDeclinedModal";
import MuteNotificationWithReasonSection, {
  MuteNotificationReasonType,
  muteNotificationValidationSchema,
} from "./MuteNotificationWithReasonSection";

interface TransactionsTableRowActionsProps {
  readonly transaction: TransactionsTableNodeFragment;
  readonly showActionButtonTitle?: boolean;
}

const useTransitionTransaction = ({
  onSuccess,
}: {
  readonly onSuccess?: () => void;
}) => {
  const { errorToast, successToast } = useCustomToast();

  const [{ fetching }, transitionTransactionMutation] =
    useTransactionsTableRowActionsTransitionTransactionMutation();

  const transitionTransaction = async (input: TransitionTransactionInput) => {
    const { data } = await transitionTransactionMutation({
      input,
    });

    if (!data?.transitionTransaction.transaction?.id) {
      errorToast();
      return;
    }

    successToast();
    if (!!onSuccess) onSuccess();
  };

  return { loading: fetching, transitionTransaction };
};

function ConvertToManualAction({
  transaction,
}: {
  readonly transaction: TransactionsTableNodeFragment;
}) {
  const { t } = useTranslation();

  const { errorToast, successToast } = useCustomToast();

  const [{ fetching }, makeTransactionManual] =
    useTransactionsTableRowActionsMakeTransactionManualMutation();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleConfirm = async () => {
    const { data } = await makeTransactionManual({
      transactionId: transaction.id,
    });

    if (!data?.makeTransactionManual.transaction?.id) {
      errorToast();
      return;
    }

    successToast();
    onClose();
  };

  return (
    <>
      <MenuItem onClick={onOpen}>{t(`convert_to_manual`)}</MenuItem>
      <ConfirmModal
        title={t(`confirm_convert_to_manual_title`)}
        body={
          <Text>
            <Trans
              i18nKey="confirm_convert_to_manual_body"
              components={{ bold: <strong /> }}
            />
          </Text>
        }
        onConfirm={handleConfirm}
        loading={fetching}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
}

const ChangeTransferTypeValidation = muteNotificationValidationSchema.shape({
  transferMethod: yup
    .string()
    .oneOf([
      ChangeTransactionTypeTransferMethod.Direct,
      ChangeTransactionTypeTransferMethod.ForwardContract,
    ])
    .required(),
  signingProcedure: yup
    .string()
    .oneOf([
      ChangeTransactionTypeSigningProcedure.Manual,
      ChangeTransactionTypeSigningProcedure.Automated,
    ])
    .required(),
});

type ChangeTransferTypeType = MuteNotificationReasonType & {
  readonly transferMethod: ChangeTransactionTypeTransferMethod;
  readonly signingProcedure: ChangeTransactionTypeSigningProcedure;
};

function ChangeTransferTypeAction({
  transaction,
}: {
  readonly transaction: TransactionsTableNodeFragment;
}) {
  const defaultTransferMethod = match(transaction.transferMethod)
    .with(
      TransactionTransferMethod.Direct,
      () => ChangeTransactionTypeTransferMethod.Direct,
    )
    .with(
      TransactionTransferMethod.Unknown,
      () => ChangeTransactionTypeTransferMethod.Direct,
    )
    .otherwise(() => ChangeTransactionTypeTransferMethod.ForwardContract);

  const defaultSigningProcedure = match(transaction.signingProcedure)
    .with(
      SigningProcedure.Manual,
      () => ChangeTransactionTypeSigningProcedure.Manual,
    )
    .otherwise(() => ChangeTransactionTypeSigningProcedure.Automated);

  const [{ fetching }, changeTransactionTransferType] =
    useTransactionsTableRowActionsChangeTransactionTransferTypeMutation();
  const { t } = useTranslation();

  const { errorToast, successToast } = useCustomToast();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    handleSubmit,
    register,
    reset,
    resetField,
    setValue,
    control,
    watch,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ChangeTransferTypeValidation),
    defaultValues: {
      muteNotificationReason: null,
      transferMethod: defaultTransferMethod,
      signingProcedure: defaultSigningProcedure,
    },
  });

  // when the modal is opened, force the form to reset with the latest default values
  // this ensures that when the modal stays mounted in the DOM but is opened with different `transaction` data,
  // the form reflects the new values, as react-hook-form does not automatically update `defaultValues`
  const handleOpen = () => {
    onOpen();
    reset({
      muteNotificationReason: null,
      transferMethod: defaultTransferMethod,
      signingProcedure: defaultSigningProcedure,
    });
  };

  const onCloseResetForm = useCallback(() => {
    reset();
    onClose();
  }, [reset, onClose]);

  const handleConfirm: SubmitHandler<ChangeTransferTypeType> = useCallback(
    async (data) => {
      const response = await changeTransactionTransferType(
        {
          transactionId: transaction.id,
          input: {
            signingProcedure: data.signingProcedure,
            transferMethod: data.transferMethod,
            muteNotificationReason: data.muteNotificationReason,
          },
        },
        { additionalTypenames: [`Transaction`] },
      );

      if (!response.data?.changeTransactionTransferType.transaction?.id) {
        errorToast();
        return;
      }

      successToast();
      onCloseResetForm();
    },
    [
      transaction.id,
      changeTransactionTransferType,
      errorToast,
      successToast,
      onCloseResetForm,
    ],
  );

  const transferMethod = watch(`transferMethod`);

  return (
    <>
      <MenuItem onClick={handleOpen}>{t(`change_transfer_type`)}</MenuItem>
      <ConfirmModal
        title={t(`change_transfer_type_title`)}
        body={
          <VStack spacing={5}>
            <Text>
              <Trans
                i18nKey="change_transfer_type_body"
                components={{ bold: <strong /> }}
              />
            </Text>
            <Controller
              name="transferMethod"
              control={control}
              render={({ field: { onChange, value, name, ...fieldProps } }) => (
                <Input.Select
                  name={name}
                  label={t(`select_transfer_type`)}
                  options={[
                    {
                      value: ChangeTransactionTypeTransferMethod.Direct,
                      label: `Direct`,
                    },
                    {
                      value:
                        ChangeTransactionTypeTransferMethod.ForwardContract,
                      label: `Forward`,
                    },
                  ]}
                  value={value}
                  {...fieldProps}
                  onChange={async (data) => {
                    const transferMethod = data.target.value;
                    onChange(transferMethod);
                    await trigger();

                    if (
                      transferMethod ===
                      ChangeTransactionTypeTransferMethod.ForwardContract
                    ) {
                      setValue(
                        `signingProcedure`,
                        ChangeTransactionTypeSigningProcedure.Manual,
                      );
                    }
                  }}
                />
              )}
            />

            <Controller
              name="signingProcedure"
              control={control}
              render={({ field: { onChange, value, name, ...fieldProps } }) => (
                <Input.Select
                  isDisabled={
                    transferMethod ===
                    ChangeTransactionTypeTransferMethod.ForwardContract
                  }
                  name={name}
                  label={t(`select_signing_procedure`)}
                  options={[
                    {
                      value: ChangeTransactionTypeSigningProcedure.Automated,
                      label: `Automated`,
                    },
                    {
                      value: ChangeTransactionTypeSigningProcedure.Manual,
                      label: `Manual`,
                    },
                  ]}
                  {...fieldProps}
                  value={value}
                  onChange={async (data) => {
                    onChange(data.target.value);
                  }}
                />
              )}
            />

            <Divider borderColor="grey.200" borderWidth="1" />
            <MuteNotificationWithReasonSection
              name="muteNotificationReason"
              setValue={setValue}
              error={errors.muteNotificationReason}
              register={register}
              resetField={resetField}
            />
          </VStack>
        }
        loading={fetching}
        onConfirm={handleSubmit(handleConfirm)}
        isOpen={isOpen}
        onClose={onCloseResetForm}
      />
    </>
  );
}

interface ProposeModificationModalProps {
  transaction: TransactionsTableNodeFragment;
  isOpen: boolean;
  onClose: () => void;
}

function ProposeModificationModal({
  transaction,
  isOpen,
  onClose,
}: ProposeModificationModalProps) {
  const { t } = useTranslation();
  const { push } = useRouter();
  const [, submitTransactionModification] =
    useSubmitPendingTransactionModificationMutation();

  const { successToast, errorToast } = useCustomToast();

  const onConfirm = async ({
    transactionId,
    numOfShares,
    pricePerShare,
    reason,
    muteNotificationReason,
  }: {
    readonly transactionId: string;
    readonly numOfShares: number;
    readonly pricePerShare: number;
    readonly reason?: string | null;
    readonly muteNotificationReason: string | null;
  }) => {
    const response = await submitTransactionModification(
      {
        transactionId,
        numOfShares,
        pricePerShare: Math.round(pricePerShare * 100),
        reason,
        muteNotificationReason,
      },
      { additionalTypenames: [`Transaction`] },
    );

    if (response.error) {
      errorToast();
      return;
    }

    await push(`/transactions/${transactionId}`);

    successToast(t(`transaction_modification_success_title`), {
      description: t(`transaction_modification_success_description`),
    });
  };

  return (
    <TransactionModificationModal
      isOpen={isOpen}
      onClose={onClose}
      transaction={transaction}
      onConfirm={onConfirm}
    />
  );
}

function ProposeModificationAction({
  transaction,
}: {
  readonly transaction: TransactionsTableNodeFragment;
}) {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <MenuItem onClick={onOpen}>{t(`propose_modification`)}</MenuItem>
      <ProposeModificationModal
        transaction={transaction}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
}

function ExtendExpirationDateAction({
  transaction,
}: {
  readonly transaction: TransactionsTableNodeFragment;
}) {
  const { t } = useTranslation();

  const currentExpireAt = DateTime.fromISO(transaction.expireAt);
  const [newExpireAt, setNewExpireAt] = useState<DateTime>(
    DateTime.fromISO(transaction.expireAt),
  );

  const [isDirty, setIsDirty] = useState(false);

  const getError = () => {
    if (newExpireAt <= currentExpireAt) {
      return { message: t(`expiration_date_error`) };
    }
    return undefined;
  };

  const showError = isDirty && !!getError();

  const [{ fetching }, extendExpireAt] =
    useTransactionsTableRowActionsExtendTransactionExpiryDateMutation();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { errorToast, successToast } = useCustomToast();

  const handleModalClose = () => {
    setIsDirty(false);
    setNewExpireAt(currentExpireAt);
    onClose();
  };

  const handleConfirm = async () => {
    setIsDirty(true);
    const error = getError();
    if (error) {
      return;
    }

    const { data } = await extendExpireAt({
      transactionId: transaction.id,
      expireAt:
        newExpireAt.toUTC().toISO()?.replace(`Z`, `000Z`) ||
        transaction.expireAt,
    });

    if (!data?.extendTransactionExpiryDate.transaction?.id) {
      errorToast();
      return;
    }

    successToast();
    handleModalClose();
  };

  return (
    <>
      <MenuItem onClick={onOpen}>{t(`extend_expiration_date`)}</MenuItem>
      <ConfirmModal
        title={t(`extend_expiration_date`)}
        body={
          <VStack spacing={5}>
            <Text>
              <Trans
                i18nKey="extend_expiration_date_body"
                components={{ bold: <strong /> }}
              />
            </Text>
            <DateTimePicker
              datePickerLabel={t(`expiration_date`)}
              timePickerLabel={t(`expiration_time`)}
              dateTime={newExpireAt}
              setDateTime={(newDate) => {
                setNewExpireAt(newDate);
                setIsDirty(true);
              }}
              minDate={currentExpireAt}
              error={showError ? getError() : undefined}
            />
          </VStack>
        }
        onConfirm={handleConfirm}
        loading={fetching}
        disableConfirm={showError ? !!getError() : false}
        isOpen={isOpen}
        onClose={handleModalClose}
      />
    </>
  );
}

function CancelTransactionAction({
  transaction,
}: {
  readonly transaction: TransactionsTableNodeFragment;
}) {
  const { t } = useTranslation();

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <MenuItem onClick={onOpen} color="archived">
        {t(`cancel_transaction`)}
      </MenuItem>
      <TransactionCancellationModal
        transaction={transaction}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
}

function TransitionToIssuerPendingApprovalAction({
  transaction,
}: {
  readonly transaction: TransactionsTableNodeFragment;
}) {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [transitionedAt, setTransitionedAt] = useState<DateTime>(
    DateTime.now(),
  );

  const { loading, transitionTransaction } = useTransitionTransaction({
    onSuccess: onClose,
  });

  const {
    handleSubmit,
    register,
    reset,
    resetField,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(muteNotificationValidationSchema),
    defaultValues: {
      muteNotificationReason: null,
    },
  });

  const onSubmit: SubmitHandler<MuteNotificationReasonType> = useCallback(
    async (data) => {
      await transitionTransaction({
        transactionId: transaction.id,
        state: TransactionState.IssuerPendingApproval,
        transitionedAt: transitionedAt.toISO(),
        muteNotificationReason: data.muteNotificationReason,
      });
    },
    [transitionTransaction, transaction.id, transitionedAt],
  );

  const [sky600] = useColors([`sky.600`]);

  const isAutomated =
    transaction.signingProcedure === SigningProcedure.Automated;

  const onCloseResetForm = useCallback(() => {
    reset();
    onClose();
  }, [reset, onClose]);

  return (
    <>
      <MenuItem
        onClick={onOpen}
        isDisabled={isAutomated}
        icon={<Circle size={7} color={sky600} weight="fill" />}
      >
        {t(`issuer_pending_approval`)}
      </MenuItem>
      <ConfirmModal
        title={t(`confirm_transition`)}
        body={
          <VStack spacing={5}>
            <Text>
              <Trans
                i18nKey="confirm_transition_transaction_body"
                components={{ bold: <strong /> }}
                values={{ state: t(`issuer_pending_approval`) }}
              />
            </Text>
            <DateTimePicker
              datePickerLabel={t(`transition_date`)}
              timePickerLabel={t(`transition_time`)}
              dateTime={transitionedAt}
              setDateTime={setTransitionedAt}
              maxDate={DateTime.now().set({ hour: 23, minute: 59, second: 59 })}
            />
            <Divider borderColor="grey.200" borderWidth="1" />
            <MuteNotificationWithReasonSection
              name="muteNotificationReason"
              setValue={setValue}
              error={errors.muteNotificationReason}
              register={register}
              resetField={resetField}
            />
          </VStack>
        }
        onConfirm={handleSubmit(onSubmit)}
        loading={loading}
        isOpen={isOpen}
        onClose={onCloseResetForm}
      />
    </>
  );
}

// deprecated. use V2
function TransitionToBidAcceptedAction({
  transaction,
}: {
  readonly transaction: TransactionsTableNodeFragment;
}) {
  const [{ fetching }, reviewTransaction] =
    useTransactionsTableRowActionsReviewTransactionMutation();

  const [sky600] = useColors([`sky.600`]);

  const { t } = useTranslation();

  const [transferMethod, setTransferMethod] = useState(
    ReviewTransactionTransferMethod.Direct,
  );

  const [signingProcedure, setSigningProcedure] = useState(
    ReviewTransactionSigningProcedure.Automated,
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { errorToast, successToast } = useCustomToast();

  const handleConfirm = async () => {
    const { data } = await reviewTransaction({
      transactionId: transaction.id,
      input: {
        signingProcedure,
        transferMethod,
      },
    });

    if (!data?.reviewTransaction.transaction?.id) {
      errorToast();
      return;
    }

    successToast();
    onClose();
  };

  return (
    <>
      <MenuItem
        onClick={onOpen}
        icon={<Circle size={7} color={sky600} weight="fill" />}
      >
        {t(`bid_accepted`)}
      </MenuItem>
      <ConfirmModal
        title={t(`review_transaction`)}
        body={
          <VStack spacing={5}>
            <Text>
              <Trans
                i18nKey="review_transaction_body"
                components={{ bold: <strong /> }}
              />
            </Text>
            <Input.Select
              name="transferMethod"
              label={t(`select_transfer_type`)}
              options={[
                {
                  value: ReviewTransactionTransferMethod.Direct,
                  label: t(`direct`),
                },
                {
                  value: ReviewTransactionTransferMethod.ForwardContract,
                  label: t(`forward_contract`),
                },
                {
                  value: ReviewTransactionTransferMethod.SpvLister,
                  label: t(`spv_lister`),
                },
                {
                  value: ReviewTransactionTransferMethod.SpvThirdparty,
                  label: t(`spv_thirdparty`),
                },
              ]}
              value={transferMethod}
              onChange={async (data) => {
                setTransferMethod(data.target.value);
              }}
            />
            <Input.Select
              name="transferMethod"
              label={t(`select_signing_procedure`)}
              options={[
                {
                  value: ReviewTransactionSigningProcedure.Automated,
                  label: t(`automated`),
                },
                {
                  value: ReviewTransactionSigningProcedure.Manual,
                  label: t(`manual`),
                },
              ]}
              value={signingProcedure}
              onChange={async (data) => {
                setSigningProcedure(data.target.value);
              }}
            />
          </VStack>
        }
        onConfirm={handleConfirm}
        loading={fetching}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
}

// Used for true value of marketplace.spv_to_fund flag.
function TransitionToBidAcceptedActionV2({
  transaction,
}: {
  readonly transaction: TransactionsTableNodeFragment;
}) {
  const [{ fetching }, reviewTransactionV2] =
    useTransactionsTableRowActionsReviewTransactionV2Mutation();

  const [sky600] = useColors([`sky.600`]);

  const { t } = useTranslation();

  const [transferMethod, setTransferMethod] = useState(
    ReviewTransactionTransferMethodV2.Direct,
  );

  const [signingProcedure, setSigningProcedure] = useState(
    ReviewTransactionSigningProcedure.Automated,
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { errorToast, successToast } = useCustomToast();

  const handleConfirm = async () => {
    const { data } = await reviewTransactionV2({
      transactionId: transaction.id,
      input: {
        signingProcedure,
        transferMethod,
      },
    });

    if (!data?.reviewTransactionV2.transaction?.id) {
      errorToast();
      return;
    }

    successToast();
    onClose();
  };

  return (
    <>
      <MenuItem
        onClick={onOpen}
        icon={<Circle size={7} color={sky600} weight="fill" />}
      >
        {t(`bid_accepted`)}
      </MenuItem>
      <ConfirmModal
        title={t(`review_transaction`)}
        body={
          <VStack spacing={5}>
            <Text>
              <Trans
                i18nKey="review_transaction_body"
                components={{ bold: <strong /> }}
              />
            </Text>
            <Input.Select
              name="transferMethod"
              label={t(`select_transfer_type`)}
              options={[
                {
                  value: ReviewTransactionTransferMethodV2.Direct,
                  label: t(`direct`),
                },
                {
                  value: ReviewTransactionTransferMethodV2.ForwardContract,
                  label: t(`forward_contract`),
                },
                {
                  value: ReviewTransactionTransferMethodV2.Fund,
                  label: t(`fund`),
                },
                {
                  value: ReviewTransactionTransferMethodV2.HiiveFund,
                  label: t(`hiive_fund`),
                },
              ]}
              value={transferMethod}
              onChange={async (data) => {
                setTransferMethod(data.target.value);
              }}
            />
            <Input.Select
              name="transferMethod"
              label={t(`select_signing_procedure`)}
              options={[
                {
                  value: ReviewTransactionSigningProcedure.Automated,
                  label: t(`automated`),
                },
                {
                  value: ReviewTransactionSigningProcedure.Manual,
                  label: t(`manual`),
                },
              ]}
              value={signingProcedure}
              onChange={async (data) => {
                setSigningProcedure(data.target.value);
              }}
            />
          </VStack>
        }
        onConfirm={handleConfirm}
        loading={fetching}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
}

function TransitionToAwaitingClosingAction({
  transaction,
}: {
  readonly transaction: TransactionsTableNodeFragment;
}) {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [transitionedAt, setTransitionedAt] = useState<DateTime>(
    DateTime.now(),
  );

  const { loading, transitionTransaction } = useTransitionTransaction({
    onSuccess: onClose,
  });

  const {
    handleSubmit,
    register,
    resetField,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(muteNotificationValidationSchema),
    defaultValues: {
      muteNotificationReason: null,
    },
  });

  const onSubmit: SubmitHandler<MuteNotificationReasonType> = useCallback(
    async (data) => {
      await transitionTransaction({
        transactionId: transaction.id,
        state: TransactionState.AwaitingClosing,
        transitionedAt: transitionedAt.toISO(),
        muteNotificationReason: data.muteNotificationReason,
      });
    },
    [transaction.id, transitionedAt, transitionTransaction],
  );

  const [sky600] = useColors([`sky.600`]);

  const isAutomated =
    transaction.signingProcedure === SigningProcedure.Automated;

  const onCloseResetForm = () => {
    onClose();
  };

  return (
    <>
      <MenuItem
        isDisabled={isAutomated}
        onClick={onOpen}
        icon={<Circle size={7} color={sky600} weight="fill" />}
      >
        {t(`awaiting_closing`)}
      </MenuItem>
      <ConfirmModal
        title={t(`confirm_transition`)}
        body={
          <VStack spacing={5}>
            <Text>
              <Trans
                i18nKey="confirm_transition_transaction_body"
                components={{ bold: <strong /> }}
                values={{ state: t(`awaiting_closing`) }}
              />
            </Text>
            <DateTimePicker
              datePickerLabel={t(`transition_date`)}
              timePickerLabel={t(`transition_time`)}
              dateTime={transitionedAt}
              setDateTime={setTransitionedAt}
              maxDate={DateTime.now().set({ hour: 23, minute: 59, second: 59 })}
            />
            <Divider borderColor="grey.200" borderWidth="1" />
            <MuteNotificationWithReasonSection
              name="muteNotificationReason"
              setValue={setValue}
              error={errors.muteNotificationReason}
              register={register}
              resetField={resetField}
            />
          </VStack>
        }
        onConfirm={handleSubmit(onSubmit)}
        loading={loading}
        isOpen={isOpen}
        onClose={onCloseResetForm}
      />
    </>
  );
}

const transitionToIssuerApprovedActionValidation =
  muteNotificationValidationSchema.shape({
    rofr: yup.boolean().required(),
  });

type TransitionToIssuerApprovedActionInputType = MuteNotificationReasonType & {
  readonly rofr: boolean;
};

function TransitionToIssuerApprovedAction({
  transaction,
}: {
  readonly transaction: TransactionsTableNodeFragment;
}) {
  const { t } = useTranslation();
  const [transitionedAt, setTransitionedAt] = useState<DateTime>(
    DateTime.now(),
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    handleSubmit,
    reset,
    register,
    resetField,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(transitionToIssuerApprovedActionValidation),
    defaultValues: {
      muteNotificationReason: null,
      rofr: false,
    },
  });

  const { loading, transitionTransaction } = useTransitionTransaction({
    onSuccess: onClose,
  });

  const onSubmit: SubmitHandler<TransitionToIssuerApprovedActionInputType> =
    useCallback(
      async (data) => {
        await transitionTransaction({
          transactionId: transaction.id,
          state: TransactionState.IssuerApproved,
          transitionedAt: transitionedAt.toISO(),
          rofr: data.rofr,
          muteNotificationReason: data.muteNotificationReason,
        });
      },
      [transitionTransaction, transaction.id, transitionedAt],
    );

  const [sky600] = useColors([`sky.600`]);

  const onCloseResetForm = useCallback(() => {
    reset();
    onClose();
  }, [reset, onClose]);

  return (
    <>
      <MenuItem
        onClick={onOpen}
        icon={<Circle size={7} color={sky600} weight="fill" />}
      >
        {t(`issuer_approved`)}
      </MenuItem>
      <ConfirmModal
        title={t(`confirm_transition`)}
        body={
          <VStack alignItems="flex-start" spacing={5}>
            <Text>
              <Trans
                i18nKey="confirm_transition_transaction_body"
                components={{ bold: <strong /> }}
                values={{ state: t(`issuer_approved`) }}
              />
            </Text>
            <Controller
              name="rofr"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Checkbox isChecked={value} onChange={() => onChange(!value)}>
                  {t(`issuer_has_exercised_rofr`)}
                </Checkbox>
              )}
            />

            <DateTimePicker
              datePickerLabel={t(`transition_date`)}
              timePickerLabel={t(`transition_time`)}
              dateTime={transitionedAt}
              setDateTime={setTransitionedAt}
              maxDate={DateTime.now().set({ hour: 23, minute: 59, second: 59 })}
            />
            <Divider borderColor="grey.200" borderWidth="1" />
            <MuteNotificationWithReasonSection
              name="muteNotificationReason"
              setValue={setValue}
              error={errors.muteNotificationReason}
              register={register}
              resetField={resetField}
            />
          </VStack>
        }
        onConfirm={handleSubmit(onSubmit)}
        loading={loading}
        isOpen={isOpen}
        onClose={onCloseResetForm}
      />
    </>
  );
}

function TransitionToClosedFeePendingAction({
  transaction,
}: {
  readonly transaction: TransactionsTableNodeFragment;
}) {
  const { t } = useTranslation();
  const [transitionedAt, setTransitionedAt] = useState<DateTime>(
    DateTime.now(),
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { loading, transitionTransaction } = useTransitionTransaction({
    onSuccess: onClose,
  });

  const {
    handleSubmit,
    register,
    reset,
    resetField,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(muteNotificationValidationSchema),
    defaultValues: {
      muteNotificationReason: null,
    },
  });

  const onSubmit: SubmitHandler<MuteNotificationReasonType> = useCallback(
    async (data) => {
      await transitionTransaction({
        transactionId: transaction.id,
        state: TransactionState.ClosedFeePending,
        transitionedAt: transitionedAt.toISO(),
        muteNotificationReason: data.muteNotificationReason,
      });
    },
    [transitionedAt, transaction.id, transitionTransaction],
  );

  const onCloseResetForm = useCallback(() => {
    reset();
    onClose();
  }, [reset, onClose]);

  const [sky600] = useColors([`sky.600`]);

  return (
    <>
      <MenuItem
        onClick={onOpen}
        icon={<Circle size={7} color={sky600} weight="fill" />}
      >
        {t(`closed_fee_pending`)}
      </MenuItem>
      <ConfirmModal
        title={t(`confirm_transition_transaction_title`)}
        body={
          <VStack spacing={5} alignItems="flex-start">
            <Text>
              <Trans
                i18nKey="confirm_transition_transaction_body"
                components={{ bold: <strong /> }}
                values={{ state: t(`closed_fee_pending`) }}
              />
            </Text>
            <DateTimePicker
              datePickerLabel={t(`transition_date`)}
              timePickerLabel={t(`transition_time`)}
              dateTime={transitionedAt}
              setDateTime={setTransitionedAt}
              maxDate={DateTime.now().set({ hour: 23, minute: 59, second: 59 })}
            />
            <Divider borderColor="grey.200" borderWidth="1" />
            <MuteNotificationWithReasonSection
              name="muteNotificationReason"
              setValue={setValue}
              error={errors.muteNotificationReason}
              register={register}
              resetField={resetField}
            />
          </VStack>
        }
        onConfirm={handleSubmit(onSubmit)}
        loading={loading}
        isOpen={isOpen}
        onClose={onCloseResetForm}
      />
    </>
  );
}

function TransitionToIssuerApprovalDeclinedAction({
  transaction,
}: {
  readonly transaction: TransactionsTableNodeFragment;
}) {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [archived] = useColors([`archived`]);

  return (
    <>
      <MenuItem
        onClick={onOpen}
        icon={<Circle size={7} color={archived} weight="fill" />}
      >
        {t(`issuer_declined`)}
      </MenuItem>
      <TransactionIssuerApprovalDeclinedModal
        transaction={transaction}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
}

function TransitionToClosedFeePaidAction({
  transaction,
}: {
  readonly transaction: TransactionsTableNodeFragment;
}) {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [transitionedAt, setTransitionedAt] = useState<DateTime>(
    DateTime.now(),
  );

  const { loading, transitionTransaction } = useTransitionTransaction({
    onSuccess: onClose,
  });

  const {
    handleSubmit,
    register,
    reset,
    resetField,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(muteNotificationValidationSchema),
    defaultValues: {
      muteNotificationReason: null,
    },
  });

  const onSubmit: SubmitHandler<MuteNotificationReasonType> = useCallback(
    async (data) => {
      await transitionTransaction({
        transactionId: transaction.id,
        state: TransactionState.ClosedFeePaid,
        transitionedAt: transitionedAt.toISO(),
        muteNotificationReason: data.muteNotificationReason,
      });
    },
    [transitionTransaction, transaction.id, transitionedAt],
  );

  const onCloseResetForm = useCallback(() => {
    reset();
    onClose();
  }, [reset, onClose]);

  const [active] = useColors([`active`]);

  return (
    <>
      <MenuItem
        onClick={onOpen}
        icon={<Circle size={7} color={active} weight="fill" />}
      >
        {t(`closed_fee_paid`)}
      </MenuItem>
      <ConfirmModal
        title={t(`confirm_transition_transaction_title`)}
        body={
          <VStack gap={5} alignItems="flex-start">
            <Text>
              <Trans
                i18nKey="confirm_transition_transaction_body"
                components={{ bold: <strong /> }}
                values={{ state: t(`closed_fee_paid`) }}
              />
            </Text>
            <DateTimePicker
              datePickerLabel={t(`transition_date`)}
              timePickerLabel={t(`transition_time`)}
              dateTime={transitionedAt}
              setDateTime={setTransitionedAt}
              maxDate={DateTime.now().set({ hour: 23, minute: 59, second: 59 })}
            />
            <Divider borderColor="grey.200" borderWidth="1" />
            <MuteNotificationWithReasonSection
              name="muteNotificationReason"
              setValue={setValue}
              error={errors.muteNotificationReason}
              register={register}
              resetField={resetField}
            />
          </VStack>
        }
        onConfirm={handleSubmit(onSubmit)}
        loading={loading}
        isOpen={isOpen}
        onClose={onCloseResetForm}
      />
    </>
  );
}

function InReviewActions({
  transaction,
}: {
  readonly transaction: TransactionsTableNodeFragment;
}) {
  const fundFlagIsEnabled = useSPVToFund();

  return (
    <MenuList>
      <MenuGroup>
        {fundFlagIsEnabled ? (
          <TransitionToBidAcceptedActionV2 transaction={transaction} />
        ) : (
          <TransitionToBidAcceptedAction transaction={transaction} />
        )}
      </MenuGroup>
      <MenuDivider />
      <MenuGroup>
        <CancelTransactionAction transaction={transaction} />
      </MenuGroup>
    </MenuList>
  );
}

function BidAcceptedActions({
  transaction,
}: {
  readonly transaction: TransactionsTableNodeFragment;
}) {
  const isStn = transaction.documentType === DocumentType.Stn;
  const isLoi = transaction.documentType === DocumentType.Loi;

  const isAutomated =
    transaction.signingProcedure === SigningProcedure.Automated;

  return (
    <MenuList>
      <MenuGroup>
        {isStn && (
          <TransitionToIssuerPendingApprovalAction transaction={transaction} />
        )}
        {isLoi && (
          <TransitionToAwaitingClosingAction transaction={transaction} />
        )}
      </MenuGroup>
      <MenuDivider />
      <MenuGroup>
        {!transaction.pendingModification && (
          <ProposeModificationAction transaction={transaction} />
        )}
        {isAutomated && (
          <>
            <ExtendExpirationDateAction transaction={transaction} />
            <ConvertToManualAction transaction={transaction} />
          </>
        )}
        <ChangeTransferTypeAction transaction={transaction} />
        <CancelTransactionAction transaction={transaction} />
      </MenuGroup>
    </MenuList>
  );
}

function IssuerPendingApprovalActions({
  transaction,
}: {
  readonly transaction: TransactionsTableNodeFragment;
}) {
  return (
    <MenuList>
      <MenuGroup>
        <TransitionToIssuerApprovedAction transaction={transaction} />
        <TransitionToIssuerApprovalDeclinedAction transaction={transaction} />
      </MenuGroup>
      <MenuDivider />
      <MenuGroup>
        {!transaction.pendingModification && (
          <ProposeModificationAction transaction={transaction} />
        )}
        <CancelTransactionAction transaction={transaction} />
      </MenuGroup>
    </MenuList>
  );
}

function IssuerApprovedActions({
  transaction,
}: {
  readonly transaction: TransactionsTableNodeFragment;
}) {
  return (
    <MenuList>
      <MenuGroup>
        <TransitionToClosedFeePendingAction transaction={transaction} />
      </MenuGroup>
      <MenuDivider />
      <MenuGroup>
        {!transaction.pendingModification && (
          <ProposeModificationAction transaction={transaction} />
        )}
        <CancelTransactionAction transaction={transaction} />
      </MenuGroup>
    </MenuList>
  );
}

function ClosedFeePendingActions({
  transaction,
}: {
  readonly transaction: TransactionsTableNodeFragment;
}) {
  return (
    <MenuList>
      <MenuGroup>
        {!transaction.pendingModification && (
          <ProposeModificationAction transaction={transaction} />
        )}
        <TransitionToClosedFeePaidAction transaction={transaction} />
      </MenuGroup>
    </MenuList>
  );
}

function AwaitingClosingActions({
  transaction,
}: {
  readonly transaction: TransactionsTableNodeFragment;
}) {
  return (
    <MenuList>
      <MenuGroup>
        <TransitionToClosedFeePendingAction transaction={transaction} />
      </MenuGroup>
      <MenuDivider />
      <MenuGroup>
        {!transaction.pendingModification && (
          <ProposeModificationAction transaction={transaction} />
        )}
        <CancelTransactionAction transaction={transaction} />
      </MenuGroup>
    </MenuList>
  );
}

export default function TransactionsTableRowActions({
  transaction,
  showActionButtonTitle,
}: TransactionsTableRowActionsProps) {
  const { state } = transaction;

  const actions = match(state)
    .with(TransactionState.InReview, () => (
      <InReviewActions transaction={transaction} />
    ))
    .with(TransactionState.BidAccepted, () => (
      <BidAcceptedActions transaction={transaction} />
    ))
    .with(TransactionState.IssuerPendingApproval, () => (
      <IssuerPendingApprovalActions transaction={transaction} />
    ))
    .with(TransactionState.IssuerApproved, () => (
      <IssuerApprovedActions transaction={transaction} />
    ))
    .with(TransactionState.ClosedFeePending, () => (
      <ClosedFeePendingActions transaction={transaction} />
    ))
    .with(TransactionState.AwaitingClosing, () => (
      <AwaitingClosingActions transaction={transaction} />
    ))
    .otherwise(() => null);

  if (!actions) return null;

  return (
    <Menu>
      <Table.ActionButton showTitle={showActionButtonTitle} />
      {actions}
    </Menu>
  );
}
