import { match } from "ts-pattern";

import { InvestorType } from "@/graphql";

import StatusEmpty from "./StatusEmpty";
import StatusWithIcon, { StatusWithIconProps } from "./StatusWithIcon";

interface StatusAccreditationProps extends StatusWithIconProps {
  isAccredited: boolean | null | undefined;
  investorType: InvestorType | null | undefined;
}

export default function StatusAccreditation({
  investorType,
  isAccredited,
  ...restProps
}: StatusAccreditationProps) {
  return match({ isAccredited, investorType })
    .with({ investorType: InvestorType.UnaccreditedSeller }, () => (
      <StatusEmpty {...restProps} />
    ))
    .with({ investorType: InvestorType.Broker }, () => (
      <StatusEmpty {...restProps} />
    ))
    .with({ isAccredited: true }, () => (
      <StatusWithIcon color="#323232" {...restProps} />
    ))
    .with({ isAccredited: false }, () => (
      <StatusWithIcon color="#D5D5D5" {...restProps} />
    ))
    .otherwise(() => <StatusWithIcon color="#D5D5D5" {...restProps} />);
}
