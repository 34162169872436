import { useColors } from "@/modules/Theme";
import { Badge, BadgeProps } from "@chakra-ui/react";
import { WarningCircle } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";
import { EntityStatus } from "@/graphql";

type EntityStatusBadgeProps = {
  status: EntityStatus;
};

function EntityStatusBadge({ status }: EntityStatusBadgeProps) {
  const { t } = useTranslation();
  const defaultProps: BadgeProps = {
    backgroundColor: `grey.25`,
    borderColor: `grey.100`,
    borderRadius: 6,
    borderStyle: `solid`,
    borderWidth: 1,
    color: `grey.900`,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1,
    paddingBottom: `2px`,
    paddingLeft: `4px`,
    paddingRight: `4px`,
    paddingTop: `2px`,
    textTransform: `capitalize`,
  };

  const [red800] = useColors([`red.800`]);

  const statusesMap: { [key in EntityStatus]: React.ReactNode } = {
    [EntityStatus.Active]: (
      <Badge
        {...defaultProps}
        borderColor="green.400"
        color="green.400"
        backgroundColor="green.50"
      >
        {t(`active`)}
      </Badge>
    ),
    [EntityStatus.Archived]: (
      <Badge {...defaultProps} gap={1}>
        <WarningCircle weight="fill" size={12} color={red800} />
        {t(`archived`)}
      </Badge>
    ),
    [EntityStatus.NoTransactions]: (
      <Badge {...defaultProps}>{t(`no_transactions`)}</Badge>
    ),
  };

  return statusesMap[status];
}

export default EntityStatusBadge;
