import {
  AreaInput,
  RowDateTimePicker,
  TerminationReasonsSelect,
} from "@/modules/Form";
import { ConfirmModal } from "@/modules/Modal";
import { Box, Divider, Text } from "@chakra-ui/react";

import { useCallback, useState } from "react";
import {
  TransactionsTableNodeFragment,
  TransactionState,
  useTransactionsTableRowActionsTransitionTransactionMutation,
} from "@/graphql";
import { Trans, useTranslation } from "react-i18next";
import * as yup from "yup";
import { DateTime } from "luxon";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCustomToast } from "@/modules/Toast";

type TransactionIssuerApprovalDeclinedModalProps = {
  readonly transaction: TransactionsTableNodeFragment;
  readonly isOpen: boolean;
  readonly onClose: () => void;
};

type TransactionTerminationReasonInput = {
  terminationReasons?: (string | undefined)[] | undefined;
  terminationReasonExplanation?: string | undefined;
};

const REASON_OTHER_KEY = `Other * Requires Context`;

const TERMINATION_REASONS = [
  `Price`,
  `Blanket Restriction - Common Shares`,
  `Blanket Restriction - All Trades`,
  `Not Currently Permitting Trades`,
  `Seller Qualifications`,
  `Buyer Qualifications`,
  REASON_OTHER_KEY,
];

function TransactionIssuerApprovalDeclinedModal({
  transaction,
  isOpen,
  onClose,
}: TransactionIssuerApprovalDeclinedModalProps) {
  const { t } = useTranslation();

  const [transitionedAt, setTransitionedAt] = useState<DateTime>(
    DateTime.now(),
  );
  const [{ fetching }, transitionTransaction] =
    useTransactionsTableRowActionsTransitionTransactionMutation();

  const { errorToast, successToast } = useCustomToast();

  const validationSchema = yup
    .object()
    .shape({
      terminationReasons: yup
        .array(yup.string().oneOf(TERMINATION_REASONS).required())
        .min(1, t`termination_reasons_min_error`)
        .required(t`termination_reasons_min_error`)
        .nonNullable(),
      terminationReasonExplanation: yup
        .string()
        .trim()
        .when(`terminationReasons`, ([terminationReasons], schema) =>
          terminationReasons?.includes(REASON_OTHER_KEY)
            ? schema.required(t`cancel_explanation_required_error`)
            : schema.optional(),
        ),
    })
    .required();

  const {
    register,
    handleSubmit,
    control,
    trigger,
    reset,
    getValues,
    formState: { errors, dirtyFields, submitCount },
  } = useForm({
    resolver: yupResolver(validationSchema, {
      abortEarly: false,
      strict: false,
    }),
  });

  const onCloseModal = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  const onSubmit: SubmitHandler<TransactionTerminationReasonInput> =
    useCallback(
      async (data) => {
        const res = await transitionTransaction({
          input: {
            transactionId: transaction.id,
            terminationReasons: data?.terminationReasons?.join(`, `) || ``,
            terminationExplanation: data.terminationReasonExplanation,
            state: TransactionState.IssuerApprovalDeclined,
            transitionedAt,
          },
        });

        if (!res?.data?.transitionTransaction.transaction?.id) {
          errorToast();
          return;
        }

        successToast();
        onCloseModal();
      },
      [
        transitionTransaction,
        successToast,
        errorToast,
        onCloseModal,
        transitionedAt,
        transaction.id,
      ],
    );

  const isExplanationRequired =
    getValues(`terminationReasons`)?.includes(REASON_OTHER_KEY);

  // only show errors after user attempted to submit
  const reasonsError =
    dirtyFields?.terminationReasons || submitCount > 0
      ? errors?.terminationReasons
      : undefined;
  const explanationError =
    dirtyFields?.terminationReasonExplanation || submitCount > 0
      ? errors?.terminationReasonExplanation
      : undefined;

  return (
    <ConfirmModal
      title={t(`confirm_transition_transaction_title`)}
      body={
        <>
          <Text mb={6}>
            <Trans
              i18nKey="confirm_transition_transaction_body"
              components={{ bold: <strong /> }}
              values={{ state: t(`issuer_approval_declined`) }}
            />
          </Text>
          <RowDateTimePicker
            datePickerLabel={t(`transition_date`)}
            timePickerLabel={t(`transition_time`)}
            dateTime={transitionedAt}
            setDateTime={setTransitionedAt}
            maxDate={DateTime.now().set({
              hour: 23,
              minute: 59,
              second: 59,
            })}
          />
          <Divider borderColor="grey.200" borderWidth="1" my={6} />
          <Controller
            control={control}
            name="terminationReasons"
            render={({ field: { onChange, name, value, ...fieldProps } }) => (
              <TerminationReasonsSelect
                value={value || []}
                label={t(`reasons_for_approval_declined`)}
                options={TERMINATION_REASONS}
                error={reasonsError}
                name={name}
                onChange={async (vals: string[]) => {
                  onChange(vals);
                  await trigger();
                }}
                {...fieldProps}
              />
            )}
          />
          <Box height={6} />
          <AreaInput
            label={t(`additional_details`)}
            helperText={isExplanationRequired ? t(`required`) : t(`optional`)}
            borderColor="grey.300"
            borderWidth=".5px"
            error={explanationError || undefined}
            isRequired={isExplanationRequired}
            maxLength={255}
            isInvalid={!!explanationError}
            {...register(`terminationReasonExplanation`)}
          />
        </>
      }
      modalVariant="skinny"
      onConfirm={handleSubmit(onSubmit)}
      loading={fetching}
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      disableConfirm={fetching}
    />
  );
}

export default TransactionIssuerApprovalDeclinedModal;
