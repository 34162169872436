import { match } from "ts-pattern";

import {
  SigningProcedure,
  TransactionBidBrokerFragment,
  TransactionListingBrokerFragment,
  TransactionTableUserFragment,
  TransactionsTableDocumentSignerFragment,
} from "@/graphql";
import { useColors } from "@/modules/Theme";
import { getSignedSigner } from "@/features/Transactions";

import { Box, Badge, Tooltip, Text } from "@chakra-ui/react";
import { CheckCircle } from "@phosphor-icons/react";
import { DateTimeFormat, formatDate } from "@/modules/NumeralFormat";
import { StatusWithIconProps } from "./StatusWithIcon";

interface StatusDocumentSigned extends StatusWithIconProps {
  signingProcedure: SigningProcedure;
  signers: TransactionsTableDocumentSignerFragment[];
  broker: TransactionBidBrokerFragment | TransactionListingBrokerFragment;
  user: TransactionTableUserFragment;
  ariaLabel: string;
}

function MultiLineText({ text }: { text?: string }) {
  return (
    <Text as="span" whiteSpace="pre-line">
      {text}
    </Text>
  );
}

function SignatureIcon({ signed }: { signed: boolean }) {
  const [grey900, grey100] = useColors([`grey.900`, `grey.100`]);
  if (signed) {
    return (
      <Box py={1}>
        <CheckCircle weight="fill" size={16} color={grey900} />
      </Box>
    );
  }

  return (
    <Box py={1}>
      <CheckCircle weight="fill" size={16} color={grey100} />
    </Box>
  );
}
export default function StatusDocumentSigned({
  signingProcedure,
  user,
  broker,
  signers,
  text,
  ariaLabel,
  ...restProps
}: StatusDocumentSigned) {
  const signer = getSignedSigner(signers, user, broker);
  const signed = !!signer;
  return match({ signingProcedure, signed })
    .with(
      {
        signingProcedure: SigningProcedure.Automated,
        signed: true,
      },
      () => (
        <Badge
          variant="plain"
          textTransform="lowercase"
          alignItems="flex-start"
          {...restProps}
        >
          <Tooltip
            hasArrow
            label={`Signed ${formatDate(
              signer?.insertedAt,
              DateTimeFormat.shortDateWithTime,
            )}`}
            aria-label={ariaLabel}
          >
            <SignatureIcon signed={signed} />
          </Tooltip>
          <MultiLineText text={text} />
        </Badge>
      ),
    )
    .with(
      {
        signingProcedure: SigningProcedure.Automated,
        signed: false,
      },
      () => (
        <Badge
          variant="plain"
          textTransform="lowercase"
          alignItems="flex-start"
          {...restProps}
        >
          <Tooltip hasArrow label="Awaiting Signature" aria-label={ariaLabel}>
            <SignatureIcon signed={signed} />
          </Tooltip>
          <MultiLineText text={text} />
        </Badge>
      ),
    )
    .otherwise(() => (
      <Badge variant="plain" textTransform="lowercase" {...restProps}>
        <Badge variant="plain">
          <Box w={2} />
        </Badge>
        <MultiLineText text={text} />
      </Badge>
    ));
}
