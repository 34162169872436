import { Maybe, TransactionState } from "@/graphql";
import { Status, StatusWithIndicatorProps } from "@/modules/Status";
import i18next from "i18next";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

type TransactionStatusProps = Omit<StatusWithIndicatorProps, "text"> & {
  state: TransactionState;
  onHold?: boolean;
  rofr?: Maybe<boolean>;
};

const transactionStateNames: Record<TransactionState, string> = {
  [TransactionState.AwaitingClosing]: i18next.t(`awaiting_closing`),
  [TransactionState.BidAccepted]: i18next.t(`bid_accepted`),
  [TransactionState.Cancelled]: i18next.t(`cancelled`),
  [TransactionState.ClosedFeePaid]: i18next.t(`close_fee_paid`),
  [TransactionState.ClosedFeePending]: i18next.t(`close_fee_pending`),
  [TransactionState.Expired]: i18next.t(`expired`),
  [TransactionState.InReview]: i18next.t(`in_review`),
  [TransactionState.IssuerApprovalDeclined]: i18next.t(
    `issuer_approval_declined`,
  ),
  [TransactionState.IssuerApproved]: i18next.t(`issuer_approved`),
  [TransactionState.IssuerPendingApproval]: i18next.t(
    `issuer_pending_approval`,
  ),
  [TransactionState.Pending]: i18next.t(`pending`),
};

const transactionStatusColors = {
  [TransactionState.AwaitingClosing]: `inactive`,
  [TransactionState.BidAccepted]: `inactive`,
  [TransactionState.Cancelled]: `archived`,
  [TransactionState.ClosedFeePaid]: `active`,
  [TransactionState.ClosedFeePending]: `inactive`,
  [TransactionState.Expired]: `disabled`,
  [TransactionState.InReview]: `inactive`,
  [TransactionState.IssuerApprovalDeclined]: `archived`,
  [TransactionState.IssuerApproved]: `inactive`,
  [TransactionState.IssuerPendingApproval]: `inactive`,
  [TransactionState.Pending]: `inactive`,
};

export function TransactionStatus({
  state,
  onHold,
  rofr,
  ...restProps
}: TransactionStatusProps) {
  const { t } = useTranslation();

  const text = useMemo(() => {
    const rofrText = rofr ? ` ${t(`rofr`)}` : ``;
    const onHoldText = onHold ? ` (${t(`transaction_on_hold_status`)})` : ``;
    const stateText = transactionStateNames[state];

    return `${stateText}${rofrText}${onHoldText}`;
  }, [onHold, rofr, state, t]);

  return (
    <Status.Indicator
      text={text}
      indicatorProps={{
        ...(onHold
          ? {
              bg: `white`,
              border: `dotted 2px`,
              borderColor: `grey.300`,
            }
          : { bg: transactionStatusColors[state] }),
        ...restProps.indicatorProps,
      }}
      {...restProps}
    />
  );
}
