import { useTranslation } from "react-i18next";

import { useFormContext } from "react-hook-form";
import {
  LinkEntityInput,
  EntityStatus,
  TransactionEntityFragment,
} from "@/graphql";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  MenuButton,
  MenuItem,
  Text,
} from "@chakra-ui/react";
import { CaretDown } from "@phosphor-icons/react";
import { Select } from "@/modules/Form";
import TransactingEntitiesOption from "./TransactingEntitiesOption";

type TransactingEntitiesSelectProps = {
  currentEntity: TransactionEntityFragment;
  entities: TransactionEntityFragment[];
  onChange: (entity: TransactionEntityFragment) => void;
};

function TransactingEntitiesSelect({
  currentEntity,
  entities,
  onChange,
}: TransactingEntitiesSelectProps) {
  const { t } = useTranslation();
  const {
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<Pick<LinkEntityInput, `entityId`>>();
  const newEntityId = watch(`entityId`);
  const entity = entities.find((entity) => entity.id === newEntityId);
  const error = errors.entityId;

  return (
    <Select.Wrapper name="entityId" helperText="" label={t(`new_entity`)}>
      <Select.Container closeOnSelect>
        <FormControl isInvalid={!!error}>
          <MenuButton
            as={Button}
            h={12}
            w="full"
            variant="ghost"
            borderWidth={error ? 2 : 1}
            borderColor={error ? `red.300` : `grey.200`}
            _expanded={{ borderColor: `grey.200` }}
          >
            <Flex alignItems="center" gap={2} justifyContent="space-between">
              <Box flex="1">
                {entity ? (
                  <TransactingEntitiesOption option={entity} />
                ) : (
                  <Text
                    fontWeight={400}
                    fontSize={16}
                    textTransform="capitalize"
                    textAlign="left"
                  >
                    {t(`select_another_entity`)}
                  </Text>
                )}
              </Box>
              <CaretDown height={16} width={16} />
            </Flex>
          </MenuButton>
          <Select.ItemsContainer>
            {entities
              .filter(
                (entity) =>
                  ![currentEntity.id, newEntityId].includes(entity.id),
              )
              .filter((entity) => entity.status !== EntityStatus.Archived)
              .map((entity) => (
                <MenuItem
                  key={entity.id}
                  onClick={() => {
                    setValue(`entityId`, entity.id);
                    onChange(entity);
                  }}
                >
                  <Box w="full">
                    <TransactingEntitiesOption option={entity} />
                  </Box>
                </MenuItem>
              ))}
          </Select.ItemsContainer>
          {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
        </FormControl>
      </Select.Container>
    </Select.Wrapper>
  );
}

export default TransactingEntitiesSelect;
