import { TransactionDetailFragment } from "@/graphql";
import { useTranslation } from "react-i18next";
import { CurrencyFormat, formatCurrency } from "@/modules/NumeralFormat";

import { Card, CardHeader, Text, CardBody } from "@chakra-ui/react";
import { CardTable } from "@/modules/CardTable";
import { getShareSeriesLongString } from "@/features/Transactions";

export function TransactionInfo({
  transaction,
}: {
  readonly transaction?: TransactionDetailFragment;
}) {
  const { t } = useTranslation();

  if (!transaction) {
    return null;
  }
  const { numShares, pricePerShare } = transaction;
  const pricePerShareFloat = (pricePerShare || 0) / 100;
  const shareTotal = formatCurrency(pricePerShareFloat * (numShares ?? 0));
  const formattedPricePerShare = formatCurrency(
    pricePerShareFloat,
    CurrencyFormat.precision2,
  );

  const formattedNumShares = formatCurrency(
    numShares,
    CurrencyFormat.noSymbolPrecision0,
  );
  const shareSeriesMakeup = transaction.bid.listing?.shareSeriesMakeup;
  const formattedShareSeries = getShareSeriesLongString(shareSeriesMakeup);

  return (
    <CardTable w="full" columns={3}>
      <Card variant="table">
        <CardHeader>{t(`shares`)}</CardHeader>
        <CardBody>
          <Text textStyle="colfax-16-medium">{formattedNumShares}</Text>
          <Text textStyle="colfax-14-regular" color="grey.600">
            {formattedShareSeries}
          </Text>
        </CardBody>
      </Card>
      <Card variant="table">
        <CardHeader>{t(`price`)}</CardHeader>
        <CardBody>
          <Text textStyle="colfax-16-medium">{formattedPricePerShare}</Text>
        </CardBody>
      </Card>
      <Card variant="table">
        <CardHeader>{t(`total`)}</CardHeader>
        <CardBody>
          <Text textStyle="colfax-16-medium">{shareTotal}</Text>
        </CardBody>
      </Card>
    </CardTable>
  );
}
