import { WorkflowTemplatesPageWorkflowTemplateNodeFragment } from "@/graphql";
import { SimpleTable, SimpleTableQueryVariables } from "@/modules/SimpleTable";
import { TableColumns } from "@/modules/Table";
import { Trans, useTranslation } from "react-i18next";

import { useCallback, useContext } from "react";

import { useRouter } from "next/router";
import {
  CompanyTemplatesContext,
  DEFAULT_COMPANY_TEMPLATES_VARIABLES,
} from "@/features/Company";
import {
  Box,
  Button,
  Card,
  CardBody,
  HStack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  CreateCompanyTemplateModal,
  LastSavedCell,
  StatusCell,
  TemplatesRowActions,
} from "@/features/WorkflowTemplates";
import { formatAndCapitalize } from "@/modules/StringFormat";
import { Row } from "@tanstack/react-table";

type CompanyTemplatesTableColumn = {
  readonly transferType?: string;
  readonly status?: string;
  readonly workflowsCount?: number;
  readonly lastSavedAt?: string;
};

type CompanyTemplatesTableData = Pick<
  WorkflowTemplatesPageWorkflowTemplateNodeFragment,
  "id" | "lastSavedAt" | "status" | "transferType" | "workflowsCount"
>;

const columns: TableColumns<
  CompanyTemplatesTableColumn,
  CompanyTemplatesTableData
> = {
  transferType: {
    header: () => (
      <Box maxW={45}>
        <Trans i18nKey="company_template" />
      </Box>
    ),
    cell: ({ cell }) => {
      const { transferType } = cell.getContext().row.original;

      return <Text>{formatAndCapitalize(transferType ?? ``)}</Text>;
    },
    enableSorting: false,
  },
  status: {
    header: () => <Trans i18nKey="status" />,
    cell: (props) => <StatusCell {...props} />,
    enableSorting: false,
  },
  workflowsCount: {
    header: () => <Trans i18nKey="active_transactions" />,
    cell: (props) => {
      const { workflowsCount } = props.row.original;

      return <Text>{workflowsCount}</Text>;
    },
    enableSorting: false,
  },
  lastSavedAt: {
    header: () => <Trans i18nKey="last_updated" />,
    cell: (props) => <LastSavedCell {...props} />,
    enableSorting: false,
  },
};

function CompanyTemplatesTable() {
  const { t } = useTranslation();
  const { push } = useRouter();

  const {
    isOpen: createCompanyTemplateIsOpen,
    onOpen: onOpenCreateCompanyTemplate,
    onClose: onCloseCreateCompanyTemplate,
  } = useDisclosure();

  const { error, fetching, data, refetch, company } = useContext(
    CompanyTemplatesContext,
  );

  const onRowClick = useCallback(
    (row: Row<Record<string, unknown>>) => {
      const { id } = row.original;
      push(`/workflow-templates/${id}`);
    },
    [push],
  );

  if (!data || !company) return null;

  if (data.edges.length === 0) {
    return (
      <>
        <Card>
          <CardBody>
            <HStack w="full" justifyContent="space-between">
              <Text>{t`no_company_template`}</Text>
              <Button onClick={onOpenCreateCompanyTemplate}>
                {t(`create_template`)}
              </Button>
            </HStack>
          </CardBody>
        </Card>
        <CreateCompanyTemplateModal
          createCompanyTemplateIsOpen={createCompanyTemplateIsOpen}
          onCloseCreateCompanyTemplate={onCloseCreateCompanyTemplate}
          refetch={refetch}
          company={company}
        />
      </>
    );
  }

  const tableData = data.edges.map((edge) => ({
    ...edge.node,
  }));

  const renderRowActions = (row: Row<Record<string, unknown>>) => (
    <TemplatesRowActions
      workflowTemplate={
        row.original as WorkflowTemplatesPageWorkflowTemplateNodeFragment
      }
    />
  );

  return (
    <SimpleTable<
      SimpleTableQueryVariables,
      CompanyTemplatesTableColumn[],
      CompanyTemplatesTableData[]
    >
      tableStyleProps={{ border: `solid 1px #D5D5D5`, borderTop: `unset` }}
      page={1}
      perPage={100}
      sort={DEFAULT_COMPANY_TEMPLATES_VARIABLES.sortBy}
      setSort={() => {}}
      columns={columns}
      setVariables={() => {}}
      tableData={tableData}
      pageInfo={data.pageInfo}
      totalCount={data.totalCount}
      renderRowActions={renderRowActions}
      error={error}
      loading={fetching}
      onRowClick={onRowClick}
      hidePagination
    />
  );
}

export default CompanyTemplatesTable;
