import { useEffect, useMemo } from "react";

import {
  Background,
  Panel,
  ReactFlow,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "@xyflow/react";
import { Button, ButtonGroup } from "@chakra-ui/react";
import { Minus, Plus } from "@phosphor-icons/react";

import {
  ExecutionStepType,
  TransactionPageV2ExecutionStepFragment,
  WorkflowEdgeFragment,
} from "@/graphql";
import {
  WorkflowPreviewStepEdge,
  WorkflowPreviewStepNode,
} from "@/modules/Workflow";
import { match } from "ts-pattern";
import { SmartStepEdge } from "./SmartStepEdge";
import { PreviewStepNode } from "./PreviewStepNode";
import { maybeAutoLayout } from "./utils";
import { PRO_OPTIONS } from "./constants";
import { PreviewMilestoneStepNode } from "./PreviewMilestoneStepNode";
import {
  mapWorkflowPreviewStepsToStepNodes,
  mapWorkflowEdgesToStepEdges,
} from "./mapper";
import { WorkflowPreviewStepNodeProps } from "./types";
import "@xyflow/react/dist/style.css";

export function WorkflowPreview({
  steps,
  stepEdges,
}: {
  steps: TransactionPageV2ExecutionStepFragment[];
  stepEdges: WorkflowEdgeFragment[];
}) {
  const [nodes, setNodes] = useNodesState<WorkflowPreviewStepNode>([]);
  const [edges, setEdges] = useEdgesState<WorkflowPreviewStepEdge>([]);
  const { zoomOut, zoomIn } = useReactFlow();

  useEffect(() => {
    const nodes = mapWorkflowPreviewStepsToStepNodes(steps);
    const edges = mapWorkflowEdgesToStepEdges(stepEdges);

    const { nodes: layoutedNodes, edges: layoutedEdges } = maybeAutoLayout(
      nodes,
      edges,
    );

    setNodes(layoutedNodes);
    setEdges(layoutedEdges as WorkflowPreviewStepEdge[]); // need to fix the typing here
  }, [setEdges, setNodes, steps, stepEdges]);

  const nodeTypes = useMemo(
    () => ({
      step: (props: WorkflowPreviewStepNodeProps) =>
        match(props.data.type)
          .with(ExecutionStepType.Milestone, () => (
            <PreviewMilestoneStepNode {...props} />
          ))
          .otherwise(() => <PreviewStepNode {...props} />),
    }),
    [],
  );

  return (
    <ReactFlow
      snapToGrid
      snapGrid={[10, 10]}
      fitView
      nodes={nodes}
      edges={edges}
      edgesReconnectable={false}
      nodeTypes={nodeTypes}
      edgeTypes={{
        smart: SmartStepEdge,
      }}
      preventScrolling={false}
      edgesFocusable={false}
      nodesDraggable={false}
      nodesConnectable={false}
      nodesFocusable={false}
      elementsSelectable={false}
      proOptions={PRO_OPTIONS}
      style={{ background: `white` }}
    >
      <Panel position="bottom-left">
        <ButtonGroup
          variant="outline"
          size="xs"
          orientation="vertical"
          isAttached
        >
          <Button
            py={3}
            onClick={() => {
              zoomIn();
            }}
          >
            <Plus />
          </Button>
          <Button
            py={3}
            mt="-1px"
            onClick={() => {
              zoomOut();
            }}
          >
            <Minus />
          </Button>
        </ButtonGroup>
      </Panel>
      <Background />
    </ReactFlow>
  );
}
