import { Link } from "@/modules/Navigation";
import { Status } from "@/modules/Status";
import { useColors } from "@/modules/Theme";
import { useCustomToast } from "@/modules/Toast";
import { HStack, IconButton, Text } from "@chakra-ui/react";
import { CopySimple } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

export type CopyToClipboardItem = {
  label?: string;
  value?: string;
  link?: {
    href: string;
    label: string;
  } | null;
};

function ClipboardItem({ value, link }: Omit<CopyToClipboardItem, "label">) {
  const { t } = useTranslation();
  const { infoToastCondensed } = useCustomToast();
  const [skyBlue600, grey200, grey600] = useColors([
    `skyBlue.600`,
    `grey.200`,
    `grey.600`,
  ]);

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    infoToastCondensed(t(`copied_to_clipboard`), {
      icon: <CopySimple size={24} weight="fill" color={skyBlue600} />,
    });
  };
  return (
    <>
      {link ? (
        <Link
          href={link.href}
          textStyle="colfax-14-regular"
          color={grey600}
          textDecoration="underline"
        >
          {link.label}
        </Link>
      ) : (
        <Text textStyle="colfax-14-regular" color={grey600}>
          {value}
        </Text>
      )}
      {value && (
        <IconButton
          aria-label="Copy id"
          variant="outline"
          minW={0}
          minH={0}
          w={6}
          h={6}
          onClick={() => handleCopy(value)}
          icon={<CopySimple size={12} color={grey200} />}
        />
      )}
    </>
  );
}

export default function CopyToClipboard({
  label,
  value,
  link,
}: CopyToClipboardItem) {
  return (
    <HStack>
      {label && <Text textStyle="colfax-14-medium-uncased">{label}:</Text>}
      {!value && <Status.Empty />}
      {value && <ClipboardItem value={value} link={link} />}
    </HStack>
  );
}
