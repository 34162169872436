import {
  Card,
  CardBody,
  CardHeader,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Skeleton } from "@/modules/Skeleton";
import { useTranslation } from "react-i18next";

export function TransactionRightPanelSkeleton() {
  const { t } = useTranslation();
  return (
    <VStack gap={4}>
      <Card w="full">
        <CardHeader w="full" as={HStack} justifyContent="space-between">
          <Text textStyle="colfax-22-medium">{t(`transaction_status`)}</Text>
        </CardHeader>
        <CardBody>
          <VStack gap={6}>
            <Skeleton h={4} w="full" />
            <Skeleton h={4} w="full" />
          </VStack>
        </CardBody>
      </Card>

      <Card w="full">
        <CardHeader w="full" as={HStack} justifyContent="space-between">
          <Text textStyle="colfax-22-medium">{t(`transaction_documents`)}</Text>
        </CardHeader>
        <CardBody>
          <VStack gap={6}>
            <Skeleton h={4} w="full" />
            <Skeleton h={4} w="full" />
          </VStack>
        </CardBody>
      </Card>

      <Card w="full">
        <CardHeader w="full" as={HStack} justifyContent="space-between">
          <Text textStyle="colfax-22-medium">{t(`fee_discounts`)}</Text>
        </CardHeader>
        <CardBody>
          <VStack gap={6}>
            <Skeleton h={4} w="full" />
            <Skeleton h={4} w="full" />
          </VStack>
        </CardBody>
      </Card>
    </VStack>
  );
}
