import {
  BankAccountCardBankAccountFragment,
  BankAccountVerificationStatus,
} from "@/graphql";
import { Table } from "@/modules/Table";
import { Menu, MenuItem, MenuList } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import CancelVerificationButton from "./CancelVerificationButton";
import ManualVerificationButton from "./ManualVerificationButton";
import MicrodepositVerificationButton from "./MicrodepositVerificationButton";

type BankAccountActionsProps = {
  bankAccount: BankAccountCardBankAccountFragment;
};

export default function BankAccountActions({
  bankAccount,
}: BankAccountActionsProps) {
  const { t } = useTranslation();

  const showManualVerification = [
    BankAccountVerificationStatus.Failed,
    BankAccountVerificationStatus.Manual,
  ].includes(bankAccount.verificationStatus);
  const showPendingVerification = [
    BankAccountVerificationStatus.PendingVerification,
  ].includes(bankAccount.verificationStatus);
  const showCancelVerificationButton = [
    BankAccountVerificationStatus.Manual,
    BankAccountVerificationStatus.PendingVerification,
  ].includes(bankAccount.verificationStatus);

  return (
    <Menu>
      <Table.ActionButton />
      <MenuList>
        {showManualVerification && (
          <ManualVerificationButton
            bankAccount={bankAccount}
            renderButton={(onOpen) => (
              <MenuItem onClick={onOpen}>{t(`manually_verify`)}</MenuItem>
            )}
          />
        )}
        {showPendingVerification && (
          <MicrodepositVerificationButton bankAccount={bankAccount} />
        )}
        {showCancelVerificationButton && (
          <CancelVerificationButton
            bankAccount={bankAccount}
            renderButton={(onOpen) => (
              <MenuItem onClick={onOpen} color="red.800">
                {t(`cancel_verification`)}
              </MenuItem>
            )}
          />
        )}
      </MenuList>
    </Menu>
  );
}
