import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";

import {
  Background,
  Panel,
  useReactFlow,
  Connection,
  addEdge,
  OnNodesChange,
  OnEdgesChange,
  ReactFlow,
  Edge,
} from "@xyflow/react";
import { v4 as uuidv4 } from "uuid";
import { Button, Card, HStack, Text } from "@chakra-ui/react";
import { Minus, Plus } from "@phosphor-icons/react";
import { ExecutionStepConfig, ExecutionStepType } from "@/graphql";
import { Trans, useTranslation } from "react-i18next";
import { match } from "ts-pattern";
import { sentenceCase } from "change-case";
import { useColors } from "@/modules/Theme";
import { Checkbox } from "@/modules/Form";

import {
  WorkflowReactFlowInstance,
  WorkflowStepEdge,
  WorkflowStepNodeProps,
  WorkflowStepNodeType,
} from "@/modules/Workflow";
import { StepNode } from "./StepNode";
import { SmartStepEdge } from "./SmartStepEdge";
import { pathExists } from "./utils";
import { PRO_OPTIONS } from "./constants";
import { MilestoneStepNode } from "./MilestoneStepNode";
import "@xyflow/react/dist/style.css";

type InsertStepData<T> = {
  addNodes: (nodes: T[]) => void;
  nodeData: string;
  stepKey: string;
  x?: number;
  y?: number;
};

interface StepNodeData {
  config: ExecutionStepConfig;
  name: string;
}

function getStepLabel(stepNodeData: StepNodeData, stepKey: string): string {
  return match(stepNodeData.config)
    .with({ __typename: `ExecutionMilestoneConfig` }, (config) =>
      sentenceCase(config.milestone),
    )
    .with({ __typename: `ExecutionAnvilConfig` }, () => stepNodeData.name)
    .otherwise(() => `New ${sentenceCase(stepKey)} Step`);
}

function insertStep<StepType>({
  addNodes,
  nodeData,
  stepKey,
  x,
  y,
}: InsertStepData<StepType>) {
  const id = uuidv4();
  const stepNodeData = JSON.parse(nodeData);
  const name = getStepLabel(stepNodeData, stepKey);

  const step = {
    id,
    type: `step`,
    data: {
      id,
      name,
      visibility: stepNodeData.visibility,
      removable: true,
      type: stepKey,
      config: stepNodeData.config,
      ui: {
        x,
        y,
        width: 0,
        height: 0,
      },
    },
    position: { x, y },
  } as StepType;

  const nodes = [step];

  addNodes(nodes);

  return nodes;
}

const createNewEdgeData = ({
  source,
  target,
}: Pick<WorkflowStepEdge, "source" | "target">): WorkflowStepEdge => ({
  data: {
    showDropzone: false,
    activateDropzone: false,
  },
  id: uuidv4(),
  source,
  target,
  type: `smart`,
});

export function Workflow<StepType extends WorkflowStepNodeType>({
  nodes,
  edges,
  onNodesChange,
  onEdgesChange,
  setNodes,
  setEdges,
  getLayoutedElements,
}: {
  readonly nodes: StepType[];
  readonly edges: WorkflowStepEdge[];
  readonly onNodesChange: OnNodesChange<StepType>;
  readonly onEdgesChange: OnEdgesChange<WorkflowStepEdge>;
  readonly setNodes: Dispatch<SetStateAction<StepType[]>>;
  readonly setEdges: Dispatch<SetStateAction<WorkflowStepEdge[]>>;
  readonly getLayoutedElements: (
    nodes: StepType[],
    edges: WorkflowStepEdge[],
  ) => { nodes: StepType[]; edges: WorkflowStepEdge[] };
}) {
  const { addEdges, deleteElements, fitView, getEdge, zoomOut, zoomIn } =
    useReactFlow<StepType, WorkflowStepEdge>();
  const [menuOpen, setMenuOpen] = useState<string | null>(null);
  const [activeDropzoneEdgeId, setActiveDropZoneEdgeId] = useState<
    string | null
  >(null);
  const [dropzonesEnabled, setDropzonesEnabled] = useState(false);

  const [grey50, grey15] = useColors([`grey.50`, `grey.15`]);

  const { t } = useTranslation();

  const onMenuOpen = useCallback((id: string) => {
    setMenuOpen(id);
  }, []);

  const onMenuClose = useCallback(() => {
    setMenuOpen(null);
  }, []);

  const [reactFlowInstance, setReactFlowInstance] =
    useState<WorkflowReactFlowInstance<StepType>>();

  const onLayout = useCallback(() => {
    const layouted = getLayoutedElements(nodes, edges);

    setNodes([...layouted.nodes]);
    setEdges([...layouted.edges]);

    window.requestAnimationFrame(() => {
      fitView();
    });
  }, [nodes, edges, setNodes, setEdges, fitView, getLayoutedElements]);
  const selectedNode = nodes.find((node) => node.selected);

  const nodeTypes = useMemo(
    () => ({
      step: (props: WorkflowStepNodeProps) =>
        match(props.data.type)
          .with(ExecutionStepType.Milestone, () => (
            <MilestoneStepNode {...props} />
          ))
          .otherwise(() => (
            <StepNode
              {...props}
              deletable={props.deletable}
              isMenuOpen={menuOpen === props.data.id}
              onMenuOpen={onMenuOpen}
              onMenuClose={onMenuClose}
            />
          )),
    }),
    [menuOpen, onMenuClose, onMenuOpen],
  );

  const onConnect = useCallback(
    (params: WorkflowStepEdge | Connection) =>
      setEdges((eds) =>
        addEdge<WorkflowStepEdge>(
          {
            ...params,
            type: `smart`,
          },
          eds,
        ),
      ),
    [setEdges],
  );

  const addNodes = useCallback(
    (newNodes: StepType[]) => {
      setNodes((nodes: StepType[]) => [...nodes, ...newNodes]);
    },
    [setNodes],
  );

  const onNodesDelete = useCallback(
    (deletedNodes: StepType[]) => {
      setEdges(() => {
        const remainingEdges = edges.filter(
          (edge) =>
            !deletedNodes.some(
              (node) => node.id === edge.source || node.id === edge.target,
            ),
        );

        const newEdges = deletedNodes.flatMap((node) => {
          const incomingEdges = edges.filter((edge) => edge.target === node.id);
          const outgoingEdges = edges.filter((edge) => edge.source === node.id);

          return incomingEdges.flatMap(
            (inEdge) =>
              outgoingEdges
                .map((outEdge) => {
                  const newEdge = createNewEdgeData({
                    source: inEdge.source,
                    target: outEdge.target,
                  });

                  return pathExists(
                    newEdge.source,
                    newEdge.target,
                    remainingEdges,
                  )
                    ? null
                    : newEdge;
                })
                .filter(Boolean) as WorkflowStepEdge[],
          );
        });

        return [...remainingEdges, ...newEdges];
      });
    },
    [edges, setEdges],
  );

  const isValidConnection = useCallback(
    (connection: Edge | Connection) => {
      const { source, target } = connection;
      if (!source || !target) {
        return false;
      }

      return !pathExists(source, target, edges);
    },
    [edges],
  );

  const hideAllDropzones = useCallback(() => {
    setEdges(() =>
      edges.map((edge) => ({
        ...edge,
        data: {
          ...edge.data,
          showDropzone: false,
          activateDropzone: false,
        },
      })),
    );

    setActiveDropZoneEdgeId(null);
  }, [edges, setEdges, setActiveDropZoneEdgeId]);

  const showAllDropzones = useCallback(() => {
    setEdges(() =>
      edges.map((edge) => ({
        ...edge,
        data: {
          ...edge.data,
          showDropzone: true,
          activateDropzone: false,
        },
      })),
    );
  }, [edges, setEdges]);

  const isDroppableOnDropzone =
    activeDropzoneEdgeId && activeDropzoneEdgeId?.length > 0;

  const onDrop = useCallback(
    async (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      const type = event.dataTransfer.getData(`stepType`);
      const nodeData = event.dataTransfer.getData(`nodeData`);
      if (!type || !reactFlowInstance) {
        return;
      }

      const activeDropzoneEdge = activeDropzoneEdgeId
        ? getEdge(activeDropzoneEdgeId)
        : undefined;

      if (dropzonesEnabled && isDroppableOnDropzone && activeDropzoneEdge) {
        const {
          data,
          source: sourceStepId,
          target: targetStepId,
        } = activeDropzoneEdge;

        deleteElements({
          edges: [{ id: activeDropzoneEdgeId }],
        });

        const [insertedStep] = insertStep<StepType>({
          addNodes,
          nodeData,
          stepKey: type,
          x: Number(data!.x),
          y: Number(data!.y),
        });

        addEdges([
          createNewEdgeData({
            source: sourceStepId,
            target: insertedStep.id,
          }),
          createNewEdgeData({
            source: insertedStep.id,
            target: targetStepId,
          }),
        ]);
      } else {
        const reactFlow = (
          reactFlowInstance as WorkflowReactFlowInstance<StepType>
        )?.screenToFlowPosition({
          x: event.clientX,
          y: event.clientY,
        });

        insertStep({
          addNodes,
          nodeData,
          stepKey: type,
          x: reactFlow.x,
          y: reactFlow.y,
        });
      }

      hideAllDropzones();
    },
    [
      activeDropzoneEdgeId,
      addEdges,
      addNodes,
      deleteElements,
      dropzonesEnabled,
      getEdge,
      isDroppableOnDropzone,
      hideAllDropzones,
      reactFlowInstance,
    ],
  );

  const onDragOver = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      if (dropzonesEnabled) {
        showAllDropzones();
      }
    },
    [dropzonesEnabled, showAllDropzones],
  );

  const onDragLeave = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      hideAllDropzones();
    },
    [hideAllDropzones],
  );

  const handleOnDropzoneChange = (edge: WorkflowStepEdge) => {
    const { id, data } = edge;

    if (data) {
      const { activateDropzone, showDropzone } = data;

      if (showDropzone && activateDropzone) {
        setActiveDropZoneEdgeId(id);
      }
    }
  };

  return (
    <ReactFlow<StepType, WorkflowStepEdge>
      snapToGrid
      snapGrid={[10, 10]}
      fitView
      nodesDraggable
      nodes={nodes}
      edges={edges}
      onDrop={onDrop}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onMouseUp={onDragLeave}
      nodeTypes={nodeTypes}
      edgeTypes={{
        smart: SmartStepEdge,
      }}
      onNodesChange={onNodesChange}
      onEdgesChange={(edges) => {
        onEdgesChange(edges);

        if (dropzonesEnabled) {
          const [edge] = edges;

          if (!!edge && `item` in edge) {
            handleOnDropzoneChange(edge.item);
          }
        }
      }}
      onNodesDelete={onNodesDelete}
      onConnect={onConnect}
      isValidConnection={isValidConnection}
      onInit={setReactFlowInstance}
      onPaneClick={onMenuClose}
      onNodeClick={onMenuClose}
      onEdgeClick={onMenuClose}
      proOptions={PRO_OPTIONS}
      style={{ background: selectedNode ? grey50 : grey15 }}
    >
      <Panel position="bottom-left">
        <HStack>
          <Card variant="stepNode" p={1}>
            <HStack>
              <Button
                variant="ghost"
                size="xs"
                onClick={() => {
                  zoomIn();
                }}
              >
                <Plus />
              </Button>
              <Text textStyle="colfax-14-medium">100%</Text>
              <Button
                variant="ghost"
                size="xs"
                onClick={() => {
                  zoomOut();
                }}
              >
                <Minus />
              </Button>
            </HStack>
          </Card>
          <Card variant="stepNode" p={1}>
            <Button
              variant="ghost"
              size="xs"
              onClick={() => {
                onLayout();
              }}
            >
              <Trans i18nKey="tidy_up" />
            </Button>
          </Card>
        </HStack>
      </Panel>
      <Panel position="bottom-right">
        <HStack>
          <Card variant="stepNode" p={1}>
            <Checkbox
              isChecked={dropzonesEnabled}
              label={t(`enable_dropzones`)}
              onChange={() => {
                const value = !dropzonesEnabled;

                setDropzonesEnabled(value);

                return Promise.resolve(value);
              }}
              onBlur={() => {
                const value = !dropzonesEnabled;

                return Promise.resolve(value);
              }}
              name="enable_dropzones"
            />
          </Card>
        </HStack>
      </Panel>
      <Background />
    </ReactFlow>
  );
}
