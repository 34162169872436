import { ConfirmModal } from "@/modules/Modal";
import { ModalProps, Stack, Text, chakra } from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useState } from "react";
import {
  AddEntityInput,
  TransactionDetailFragment,
  TransactionEntityFragment,
  useAddEntityMutation,
} from "@/graphql";
import { ConfirmEntityChanges } from "@/features/Transactions";
import { useCustomToast } from "@/modules/Toast";
import { EntityFormFields } from "./EntityFormFields";

type CreateNewEntityModalProps = Omit<ModalProps, "children"> & {
  currentEntity: TransactionEntityFragment;
  transaction: TransactionDetailFragment;
  isBuyer: boolean;
  isInstitutionalUser: boolean;
};

function CreateNewEntityModal({
  currentEntity,
  onClose,
  transaction,
  isBuyer,
  isInstitutionalUser,
  ...others
}: CreateNewEntityModalProps) {
  const { t } = useTranslation();
  const transactionId = transaction.id;
  const methods = useForm<AddEntityInput>();
  const { handleSubmit, formState, reset, getValues, trigger } = methods;
  const { isSubmitting, isValid } = formState;
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [newEntityValues, setNewEntityValues] = useState<AddEntityInput>();
  const [, addEntity] = useAddEntityMutation();
  const { successToast, errorToast } = useCustomToast();

  const getUserId = () =>
    isBuyer ? transaction?.buyerId : transaction?.sellerId;

  const getInstitutionId = () =>
    isBuyer
      ? transaction?.bid.buyer?.institutionId
      : transaction?.bid.counterparty?.institutionId;

  const resetModal = () => {
    setShowConfirmation(false);
    reset();
  };

  const onSubmit: SubmitHandler<AddEntityInput> = async (input) => {
    const data = {
      input: {
        ...input,
        transactionId,
        institutionId: isInstitutionalUser ? getInstitutionId() : undefined,
        userId: !isInstitutionalUser ? getUserId() : undefined,
      },
    };

    const { error } = await addEntity(data, {
      additionalTypenames: [`Transaction`],
    });

    if (error) {
      errorToast(t(`could_not_create_entity`));
    } else {
      successToast(t(`entity_created_successfully`));
      resetModal();
      onClose();
    }
  };

  return (
    <ConfirmModal
      title={
        showConfirmation ? t(`confirm_entity_changes`) : t(`create_new_entity`)
      }
      onConfirm={() => {
        trigger();

        if (!isValid) {
          return;
        }

        const values = getValues();
        setNewEntityValues(values);
        setShowConfirmation(true);

        if (showConfirmation) {
          handleSubmit(onSubmit)();
        }
      }}
      loading={isSubmitting}
      onClose={() => {
        resetModal();
        onClose();
      }}
      body={
        <Stack as={chakra.form} gap={4}>
          {!showConfirmation && (
            <>
              <Text>
                <Trans
                  i18nKey="create_new_entity_modal_description"
                  values={{
                    type: t(isBuyer ? `buyer` : `seller`).toLocaleLowerCase(),
                  }}
                  components={{ bold: <strong /> }}
                />
              </Text>
              <FormProvider {...methods}>
                <EntityFormFields />
              </FormProvider>
            </>
          )}
          {showConfirmation && newEntityValues && (
            <ConfirmEntityChanges
              currentEntity={currentEntity}
              newEntity={newEntityValues}
            />
          )}
        </Stack>
      }
      {...others}
    />
  );
}

export default CreateNewEntityModal;
