import { v4 as uuidv4 } from "uuid";
import { FeeDiscountApplicationState, FeeDiscountType } from "@/graphql";
import { formatCurrencyCents, formatShares } from "@/modules/NumeralFormat";
import { GridItem, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

enum VariantType {
  grey = `grey`,
  teal = `teal`,
}

type VariantConfig = {
  borderColor?: string;
  labelColor?: string;
  amount: Record<string, string>;
  hiiveFees?: Record<string, string>;
  netProceeds?: Record<string, string>;
};

interface GridItemNetProceedsProps {
  label: string;
  pricePerShare: number;
  amount: number;
  styles?: VariantConfig;
}

interface GridItemGrossProceedsProps {
  label: string;
  numShares: number;
  pricePerShare: number;
  amount: number;
  styles?: VariantConfig;
}

const VARIANT_CONFIG: Record<VariantType, VariantConfig> = {
  [VariantType.teal]: {
    amount: {
      textStyle: `colfax-16-regular`,
    },
    netProceeds: {
      borderColor: `teal.500`,
      bg: `teal.25`,
    },
  },
  [VariantType.grey]: {
    borderColor: `grey.200`,
    labelColor: `grey.400`,
    amount: {
      textStyle: `colfax-16-regular`,
    },
    hiiveFees: {
      bg: `grey.50`,
    },
  },
};

const BOX_STYLES = {
  alignItems: `baseline`,
  gridTemplateColumns: `1fr 1fr`,
  borderColor: VARIANT_CONFIG.grey.borderColor,
  borderWidth: 0.5,
  borderBottomWidth: 0,
  px: 4,
  py: 2,
  overflow: `hidden`,
};
const GRID_STYLES = {
  label: {
    color: VARIANT_CONFIG.grey.labelColor,
    colSpan: 2,
  },
  container: {
    alignItems: `stretch`,
    gap: 0,
    textStyle: `colfax-14-medium-uppercased`,
    width: `full`,
  },
  gridItemAmount: {
    justifySelf: `end`,
  },
  grossProceedsSection: {
    ...BOX_STYLES,
    borderTopRadius: `md`,
    borderWidth: BOX_STYLES.borderWidth,
  },
  feesSection: {
    ...BOX_STYLES,
    ...VARIANT_CONFIG.grey.hiiveFees,
  },
  netFeesSection: {
    ...BOX_STYLES,
    ...VARIANT_CONFIG.grey.hiiveFees,
  },
  netProceedsSection: {
    ...BOX_STYLES,
    borderBottomRadius: `md`,
    borderBottomWidth: BOX_STYLES.borderWidth,
  },
};

interface GridItemRowProps {
  label?: string;
  items: {
    label?: string;
    value: number;
    discount?: boolean;
    lineThrough?: boolean;
  }[];
  styles?: VariantConfig;
}

function GridItemRow({ label, items, styles }: GridItemRowProps) {
  return (
    <>
      {label && (
        <GridItem {...GRID_STYLES.label}>
          <Text>{label}</Text>
        </GridItem>
      )}
      {items.map(({ label, value, discount, lineThrough }) => (
        <Fragment key={`${label}-${value}-${uuidv4()}`}>
          <GridItem>
            <Text>{label}</Text>
          </GridItem>
          <GridItem {...GRID_STYLES.gridItemAmount} {...styles?.amount}>
            {discount ? (
              <Text>({formatCurrencyCents(value)})</Text>
            ) : (
              <Text textDecoration={lineThrough ? `line-through` : `none`}>
                {formatCurrencyCents(value)}
              </Text>
            )}
          </GridItem>
        </Fragment>
      ))}
    </>
  );
}

function GridItemGrossProceeds({
  label,
  numShares,
  pricePerShare,
  amount,
  styles,
}: GridItemGrossProceedsProps) {
  const { t } = useTranslation();
  const proceedsLabel = `${formatShares(numShares)} ${t(
    `shares`,
  )} @ ${formatCurrencyCents(pricePerShare)}`;
  return (
    <GridItemRow
      label={label}
      items={[{ label: proceedsLabel, value: amount }]}
      styles={styles}
    />
  );
}

function GridItemNetProceeds({
  label,
  pricePerShare,
  amount,
  styles,
}: GridItemNetProceedsProps) {
  const { t } = useTranslation();
  return (
    <GridItemRow
      label={label}
      items={[
        { label: t(`net_price_per_share`), value: pricePerShare },
        { label: t(`net_proceeds`), value: amount },
      ]}
      styles={styles}
    />
  );
}

type FeeDiscountApplicationType = {
  state: FeeDiscountApplicationState;
  feeDiscount?: {
    name: string;
    type: FeeDiscountType;
    value?: number | null;
  } | null;
  virtualFeeDiscount?: {
    name: string;
    type: FeeDiscountType;
    value?: number | null;
  } | null;
  feeDiscountApplicationCommission?: {
    amount?: number | null;
  } | null;
};

interface FeeBreakdownProps {
  numSharesActual: number;
  pricePerShare: number;
  commission?: {
    flatFeeAmount: number;
    commissionAmount: number;
    feeDiscountAmount?: number | null;
    netFees: number;
  } | null;
  feeDiscountApplications?: (FeeDiscountApplicationType | null)[] | null;
  variant?: keyof typeof VariantType;
}

export default function FeeBreakdown({
  numSharesActual,
  pricePerShare,
  commission,
  feeDiscountApplications,
  variant = VariantType.grey,
}: FeeBreakdownProps) {
  const { t } = useTranslation();

  const {
    flatFeeAmount = 0,
    commissionAmount = 0,
    netFees = 0,
  } = commission || {};

  const grossProceeds = numSharesActual * pricePerShare;
  const netProceeds = grossProceeds - netFees;
  const netPricePerShare = Math.trunc(netProceeds / numSharesActual);

  const activeFeeDiscountApplications =
    feeDiscountApplications?.filter(
      (item): item is FeeDiscountApplicationType => !!item,
    ) || [];

  const isOverride = activeFeeDiscountApplications.some(
    (fda) =>
      fda.feeDiscount?.type === FeeDiscountType.OverrideCommission ||
      fda.virtualFeeDiscount?.type === FeeDiscountType.OverrideCommission,
  );

  const styles = VARIANT_CONFIG[variant];

  return (
    <VStack {...GRID_STYLES.container}>
      <SimpleGrid {...GRID_STYLES.grossProceedsSection}>
        <GridItemGrossProceeds
          label={t(`gross_sale_amount`)}
          numShares={numSharesActual}
          pricePerShare={pricePerShare}
          amount={grossProceeds}
          styles={styles}
        />
      </SimpleGrid>

      <SimpleGrid {...GRID_STYLES.feesSection}>
        <GridItemRow
          label={t(`hiive_fees`)}
          items={[
            {
              label: t(`hiive_base_fee`),
              value: flatFeeAmount,
              lineThrough: isOverride,
            },
            {
              label: t(`hiive_commission`),
              value: commissionAmount,
              lineThrough: isOverride,
            },
          ]}
          styles={styles}
        />
        {!!activeFeeDiscountApplications.length && (
          <GridItemRow
            items={activeFeeDiscountApplications.map(
              ({
                feeDiscount,
                feeDiscountApplicationCommission,
                virtualFeeDiscount,
              }) => ({
                label: feeDiscount?.name || virtualFeeDiscount?.name,
                value: isOverride
                  ? netFees
                  : feeDiscountApplicationCommission?.amount || 0,
                discount: !isOverride,
              }),
            )}
            styles={styles}
          />
        )}
      </SimpleGrid>

      <SimpleGrid {...GRID_STYLES.netFeesSection}>
        <GridItemRow
          items={[{ label: t(`total_hiive_fees`), value: netFees }]}
          styles={styles}
        />
      </SimpleGrid>

      <SimpleGrid {...GRID_STYLES.netProceedsSection} {...styles.netProceeds}>
        <GridItemNetProceeds
          label={t(`net_amount`)}
          pricePerShare={netPricePerShare}
          amount={netProceeds}
          styles={styles}
        />
      </SimpleGrid>
    </VStack>
  );
}
