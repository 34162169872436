import { TableContext } from "@/modules/SimpleTable";
import { HStack, VStack } from "@chakra-ui/react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { Input } from "@/modules/Form";
import { UseFormRegister } from "react-hook-form";

type WorkflowTemplateFormValues = {
  searchText: string;
};

function WorkflowTemplatesTableSearch({
  register,
}: {
  register: UseFormRegister<WorkflowTemplateFormValues>;
}) {
  const { t } = useTranslation();

  return (
    <HStack>
      <Input.Search
        placeholder={t(`search_by_company_name`)}
        w={430}
        {...register(`searchText`)}
      />
    </HStack>
  );
}

function WorkflowTemplatesFilters() {
  const { register } = useContext(TableContext);

  return (
    <VStack spacing={4}>
      <HStack justifyContent="space-between" w="full">
        <WorkflowTemplatesTableSearch register={register} />
      </HStack>
    </VStack>
  );
}

export default WorkflowTemplatesFilters;
