import { ReactNode } from "react";

import { BackButton } from "@/modules/Navigation";
import { Box, GridItem, SimpleGrid, Spacer } from "@chakra-ui/react";

interface DetailPageLayoutProps {
  isTwoColumn?: boolean;
  mainPanel: ReactNode;
  rightPanel?: ReactNode;
  rightPanelWidth?: string;
  tabMenu?: ReactNode;
}

const DEFAULT_RIGHT_PANEL_WIDTH = `minmax(440px, min-content)`;

export default function DetailPageLayout({
  isTwoColumn,
  mainPanel,
  rightPanel,
  rightPanelWidth = DEFAULT_RIGHT_PANEL_WIDTH,
  tabMenu,
}: DetailPageLayoutProps) {
  const gridTemplateColumns = isTwoColumn
    ? { lg: `1fr ${rightPanelWidth}`, md: `1fr 1fr` }
    : `1fr`;

  return (
    <Box p={4} h="100%" display="flex" flexDirection="column">
      <Box px={4} py={2}>
        <BackButton />
      </Box>
      {tabMenu}
      <SimpleGrid columnGap={0} gridTemplateColumns={gridTemplateColumns}>
        <GridItem p={4}>{mainPanel}</GridItem>
        {isTwoColumn && <GridItem p={4}>{rightPanel}</GridItem>}
      </SimpleGrid>
      {/* this expands to hold the footer down when page doesn't take up entire height */}
      <Spacer />
      <Box px={4} py={2}>
        <BackButton />
      </Box>
    </Box>
  );
}
