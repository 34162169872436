import { ListingDetailsType } from "@/features/Listing";
import {
  ListingPageCompanyFragment,
  ListingPageSellerFragment,
} from "@/graphql";

export function hasUserFeeDiscountApplications(
  listing: ListingDetailsType | undefined,
  seller: ListingPageSellerFragment | undefined,
) {
  const { company: listingCompany } = listing || {};
  const { feeDiscountApplications: sellerFeeDiscountApplications } =
    seller || {};
  const [sellerFirstFeeDiscountApplication] =
    sellerFeeDiscountApplications || [];

  if (!sellerFirstFeeDiscountApplication) return false;

  const { company: sellerCompany } = sellerFirstFeeDiscountApplication;

  return listingCompany?.id === sellerCompany?.id;
}

export function hasCompanyFeeDiscountApplications(
  company: ListingPageCompanyFragment | undefined,
) {
  return !!company?.feeDiscountApplications?.length;
}
