import { BankAccountCardBankAccountFragment } from "@/graphql";
import { Button, chakra } from "@chakra-ui/react";
import { ArrowSquareOut } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

type ViewModernTreasuryAccountButtonProps = {
  readonly bankAccount: BankAccountCardBankAccountFragment;
  renderButton?: (url: string) => React.ReactNode;
};

export default function ViewModernTreasuryAccountButton({
  bankAccount,
}: ViewModernTreasuryAccountButtonProps) {
  const { t } = useTranslation();

  const MODERN_TREASUREY_URL = `https://app.moderntreasury.com/external_accounts/${bankAccount.externalId}`;

  return (
    <Button
      as={chakra.a}
      href={MODERN_TREASUREY_URL}
      target="_blank"
      variant="outline"
      size="sm"
      rightIcon={<ArrowSquareOut height={16} width={16} />}
    >
      {t(`view_account`)}
    </Button>
  );
}
