import { useTranslation } from "react-i18next";
import { EntityCardEntityFragment, EntityStatus } from "@/graphql";
import { useSellerExternalAccountEnabled } from "@/modules/LaunchDarkly";
import {
  Card,
  CardBody,
  CardHeader,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useTranslatedTransactingEntityNames } from "@/features/Transactions";
import { getCountryList } from "@/modules/Country";
import { useColors } from "@/modules/Theme";
import {
  BankAccountCard,
  EntityCardActions,
  NoBankAccountsCard,
  EntityStatusBadge,
} from "@/features/Entities";

type EntityCardProps = {
  entity: EntityCardEntityFragment;
  isInstitution?: boolean;
};

export function NoEntitiesCard() {
  const { t } = useTranslation();

  return (
    <Card width="full">
      <CardBody>
        <Text>{t(`no_entities_added`)}</Text>
      </CardBody>
    </Card>
  );
}

export default function EntityCard({ entity, isInstitution }: EntityCardProps) {
  const { bankAccounts, jurisdictionOfFormation, legalName, status, type } =
    entity;
  const isSEAEnabled = useSellerExternalAccountEnabled();

  const hasBankAccounts = bankAccounts.length > 0;
  const { getFormattedEntityName } = useTranslatedTransactingEntityNames();
  const { getName } = getCountryList();
  const [grey600] = useColors([`grey.600`]);
  const hideActions = status === EntityStatus.Active && !isSEAEnabled;

  return (
    <Card w="full">
      <CardHeader as={HStack} justifyContent="space-between">
        <HStack>
          <Text
            fontSize={18}
            fontWeight={500}
            color="gray.900"
            textTransform="capitalize"
          >
            {legalName}
          </Text>
          <Text
            as={HStack}
            fontSize={14}
            fontWeight={400}
            color="gray.900"
            textTransform="capitalize"
          >
            <Text>{getFormattedEntityName(type)}</Text>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="4"
              height="4"
              viewBox="0 0 4 4"
              fill="none"
            >
              <circle cx="2" cy="2" r="2" fill={grey600} />
            </svg>
            <span>{getName(jurisdictionOfFormation?.name as string)}</span>
          </Text>
        </HStack>
        <HStack>
          {status && <EntityStatusBadge status={status} />}
          {!hideActions && (
            <EntityCardActions entity={entity} isInstitution={isInstitution} />
          )}
        </HStack>
      </CardHeader>
      {isSEAEnabled && (
        <CardBody>
          <VStack w="full" spacing={4}>
            {!hasBankAccounts ? (
              <NoBankAccountsCard />
            ) : (
              bankAccounts.map((bankAccount) => (
                <BankAccountCard
                  key={bankAccount.id}
                  owningEntity={entity}
                  bankAccount={bankAccount}
                />
              ))
            )}
          </VStack>
        </CardBody>
      )}
    </Card>
  );
}
