import { FeeDiscountType } from "@/graphql";
import { formatCurrency } from "@/modules/NumeralFormat";
import i18next from "i18next";

export function formatFeeDiscountType(type?: FeeDiscountType) {
  if (!type) {
    return ``;
  }
  return i18next.t(
    `fee_discount_type_${type.toLowerCase()}` as unknown as TemplateStringsArray,
  ) as string;
}

export function formatFeeDiscountValue(
  value?: number | null,
  type?: FeeDiscountType,
) {
  const hundredsToRealValue = (value || 0) / 100;
  return type === FeeDiscountType.FlatFee
    ? formatCurrency(hundredsToRealValue)
    : `${hundredsToRealValue}%`;
}
