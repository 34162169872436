import TransactionSignerCard, {
  TransactionSignerCardProps,
} from "./TransactionSignerCard";

function SellerBrokerTransactionSignerCard({
  signer,
}: TransactionSignerCardProps) {
  return (
    <TransactionSignerCard.Card>
      <TransactionSignerCard.Pill signer={signer} />
      <TransactionSignerCard.EmailDetails
        email={signer.email}
        name={signer.name}
      />
      <TransactionSignerCard.SignerStatus signedAt={signer.signedAt} />
      <TransactionSignerCard.FullDetails userId={signer.id} />
    </TransactionSignerCard.Card>
  );
}

export default SellerBrokerTransactionSignerCard;
