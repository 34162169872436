import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { CompanyManagedMarketContext } from "@/features/Company";
import { useCompanyManagedMarketRemoveCompanyEligibilityCriterionMutation } from "@/graphql";
import { ConfirmModal } from "@/modules/Modal";
import { useCustomToast } from "@/modules/Toast";
import { Text, VStack } from "@chakra-ui/react";

function RemoveCompanyEligibilityCriterionModalBody() {
  const { t } = useTranslation();

  return (
    <VStack gap={4} alignItems="flex-start" w="full">
      <Text>{t(`remove_seller_eligibility_modal_description`)}</Text>
    </VStack>
  );
}

interface RemoveCompanyEligibilityCriterionModalProps {
  readonly isOpen: boolean;
  readonly onClose: () => void;
}

export function RemoveCompanyEligibilityCriterionModal({
  isOpen,
  onClose,
}: RemoveCompanyEligibilityCriterionModalProps) {
  const { t } = useTranslation();
  const { managedMarketCompany, refetch } = useContext(
    CompanyManagedMarketContext,
  );
  const [, deleteCompanyEligibilityCriterion] =
    useCompanyManagedMarketRemoveCompanyEligibilityCriterionMutation();
  const { successToast } = useCustomToast();

  const { id: companyEligibilityCriterionId } =
    managedMarketCompany?.companyEligibilityCriterion || {};

  if (!companyEligibilityCriterionId) return null;

  const onConfirm = async () => {
    const { data: response } = await deleteCompanyEligibilityCriterion({
      companyEligibilityCriterionId,
    });

    if (response?.deleteCompanyEligibilityCriterion) {
      successToast(t(`seller_eligibility_removed`));
      refetch();
      onClose();
    }
  };

  return (
    <ConfirmModal
      title={t(`remove_seller_eligibility`)}
      body={<RemoveCompanyEligibilityCriterionModalBody />}
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
    />
  );
}
