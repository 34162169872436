import {
  ExecutionWorkflowTemplateTransferType,
  useWorkflowTemplatesPageGlobalTemplatesQuery,
  WorkflowTemplatesTableFragment,
} from "@/graphql";
import { DateTimeFormat, formatDate } from "@/modules/NumeralFormat";
import { useColors } from "@/modules/Theme";
import {
  Card,
  CardBody,
  CardFooter,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Globe } from "@phosphor-icons/react";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import {
  WorkflowTemplatesTableProvider,
  WorkflowTemplatesUrlParams,
} from "@/features/WorkflowTemplates";
import { useRouter } from "next/router";
import WorkflowTemplatesTable from "./WorkflowTemplatesTable";
import { workflowTemplateName } from "./utils";

function GlobalTemplateCard({
  workflowTemplate,
}: {
  readonly workflowTemplate: WorkflowTemplatesTableFragment;
}) {
  const router = useRouter();
  const [sky500] = useColors([`sky.500`]);
  return (
    <Card
      variant="grey-footer"
      w={320}
      cursor="pointer"
      borderRadius={10}
      onClick={() => router.push(`/workflow-templates/${workflowTemplate.id}`)}
    >
      <CardBody p={2}>
        <HStack>
          <Globe size={32} color={sky500} />
          <Text textStyle="colfax-18-medium">
            {workflowTemplateName(workflowTemplate)}
          </Text>
        </HStack>
      </CardBody>
      <CardFooter
        py={2}
        px={4}
        justifyContent="space-between"
        borderBottomRadius={10}
      >
        <Text>
          {t(`updated_time`, {
            time: formatDate(
              workflowTemplate.updatedAt,
              DateTimeFormat.fullDateWithMonthShort,
            ),
          })}
        </Text>
      </CardFooter>
    </Card>
  );
}

export default function WorkflowTemplatesPage({
  query,
}: {
  query: WorkflowTemplatesUrlParams;
}) {
  const { t } = useTranslation();

  const [{ fetching, data }] = useWorkflowTemplatesPageGlobalTemplatesQuery();

  if (fetching || !data) return null;

  const globalTemplates = data.listExecutionWorkflowTemplates?.edges.map(
    (edge) => edge.node,
  );
  const directTemplate = globalTemplates?.find(
    (template) =>
      template.transferType === ExecutionWorkflowTemplateTransferType.Direct,
  );

  const indirectTemplate = globalTemplates?.find(
    (template) =>
      template.transferType === ExecutionWorkflowTemplateTransferType.Indirect,
  );

  return (
    <WorkflowTemplatesTableProvider query={query}>
      <VStack p={8} gap={0} alignItems="flex-start">
        <Text mb={4} textStyle="colfax-22-medium">
          {t(`workflow_templates`)}
        </Text>
        <Text mb={4} textStyle="colfax-18-medium">
          {t(`global`)}
        </Text>
        <HStack spacing={4} pb={4}>
          {directTemplate && (
            <GlobalTemplateCard workflowTemplate={directTemplate} />
          )}
          {indirectTemplate && (
            <GlobalTemplateCard workflowTemplate={indirectTemplate} />
          )}
        </HStack>
        <Text mb={4} textStyle="colfax-18-medium">
          {t(`company`)}
        </Text>
        <WorkflowTemplatesTable />
      </VStack>
    </WorkflowTemplatesTableProvider>
  );
}
