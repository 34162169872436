import { Flex, Text } from "@chakra-ui/react";

import { TransactionEntityFragment } from "@/graphql";
import { useTranslatedTransactingEntityNames } from "@/features/Transactions";

type TransactingEntitiesOptionProps = {
  option: TransactionEntityFragment;
};

function TransactingEntitiesOption({ option }: TransactingEntitiesOptionProps) {
  const { getFormattedEntityName } = useTranslatedTransactingEntityNames();

  return (
    <Flex alignItems="center" justifyContent="space-between" h={6} px={2}>
      <Text
        fontWeight={400}
        fontSize={16}
        maxWidth="50%"
        textTransform="capitalize"
      >
        {option.legalName}
      </Text>
      <Text fontWeight={400} fontSize={14} color="skyBlue.1300">
        {getFormattedEntityName(option.type)}
      </Text>
    </Flex>
  );
}

export default TransactingEntitiesOption;
