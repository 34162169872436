import { useRouter } from "next/router";
import { createContext, ReactNode, useContext } from "react";
import { UseQueryState } from "urql";

import { DEFAULT_FETCH_LIMIT, DEFAULT_PAGE } from "@/constants";
import { FeeDiscountPageApplicationsUrlParams } from "@/features/FeeDiscount";
import {
  FeeDiscountApplicationFilter,
  FeeDiscountApplicationSort,
  FeeDiscountApplicationSortableField,
  FeeDiscountApplicationState,
  FeeDiscountPageApplicationsTableQuery,
  FeeDiscountPageApplicationsTableQueryVariables,
  SortDirection,
  useFeeDiscountPageApplicationsTableQuery,
} from "@/graphql";
import { TableContext, TableProvider } from "@/modules/SimpleTable";

interface FeeDiscountPageApplicationsTableProviderProps {
  children: ReactNode;
}

interface ChildProps {
  children: ReactNode;
}

const Context = createContext<{
  data:
    | UseQueryState<
        FeeDiscountPageApplicationsTableQuery,
        FeeDiscountPageApplicationsTableQueryVariables
      >
    | undefined;
}>({ data: undefined });
const { Provider } = Context;

const DEFAULT_VARIABLES: FeeDiscountPageApplicationsTableQueryVariables & {
  page: number;
} = {
  page: DEFAULT_PAGE,
  first: DEFAULT_FETCH_LIMIT,
  filterBy: {
    state: FeeDiscountApplicationState.Active,
  },
  sortBy: {
    field: FeeDiscountApplicationSortableField.DateApplied,
    direction: SortDirection.Desc,
  },
};

function FeeDiscountPageApplicationsTableProviderContent({
  children,
}: ChildProps) {
  const { variables } = useContext(TableContext);

  const [data] = useFeeDiscountPageApplicationsTableQuery({
    variables: {
      ...variables,
      sortBy: variables.sortBy as FeeDiscountApplicationSort,
      filterBy: variables.filterBy as FeeDiscountApplicationFilter,
    },
    requestPolicy: `cache-and-network`,
  });

  return <Provider value={{ data }}>{children}</Provider>;
}

function FeeDiscountPageApplicationsTableProvider({
  children,
}: FeeDiscountPageApplicationsTableProviderProps) {
  const { query } = useRouter();
  const { feeDiscountId, ...queryParams } = query;

  if (!feeDiscountId) {
    return null;
  }

  const defaultVariables = {
    ...DEFAULT_VARIABLES,
    filterBy: {
      ...DEFAULT_VARIABLES.filterBy,
      feeDiscountId,
    },
  };

  return (
    <TableProvider
      query={queryParams as FeeDiscountPageApplicationsUrlParams}
      defaultVariables={defaultVariables}
      sortFields={Object.values(FeeDiscountApplicationSortableField)}
      skipAppendQuery
    >
      <FeeDiscountPageApplicationsTableProviderContent>
        {children}
      </FeeDiscountPageApplicationsTableProviderContent>
    </TableProvider>
  );
}

export {
  Context as FeeDiscountPageApplicationsTableContext,
  FeeDiscountPageApplicationsTableProvider,
};
