import {
  BankAccountRegion,
  GenerateBankAccountCollectionLinkInput,
} from "@/graphql";
import { Select } from "@/modules/Form";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  MenuButton,
  MenuItem,
  Text,
} from "@chakra-ui/react";
import { CaretDown } from "@phosphor-icons/react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import BankAccountRegionOption from "./BankAccountRegionOption";

export default function BankAccountRegionSelect() {
  const { t } = useTranslation();
  const {
    formState: { errors },
    setValue,
    watch,
    trigger,
  } = useFormContext<Pick<GenerateBankAccountCollectionLinkInput, `region`>>();

  const error = errors.region;
  const options = Object.values(BankAccountRegion);
  const value = watch(`region`);

  return (
    <Select.Wrapper
      name="region"
      helperText=""
      label={t(`region_seller_bank_account`)}
    >
      <Select.Container closeOnSelect>
        <FormControl isInvalid={!!error}>
          <MenuButton
            as={Button}
            h={12}
            w="full"
            variant="ghost"
            borderWidth={error ? 2 : 1}
            borderColor={error ? `red.300` : `grey.200`}
            _expanded={{ borderColor: `grey.200` }}
          >
            <Flex alignItems="center" gap={2} justifyContent="space-between">
              <Box flex="1">
                {value ? (
                  <BankAccountRegionOption option={value} />
                ) : (
                  <Text fontWeight={400} fontSize={16} textAlign="left">
                    {t(`select_region`)}
                  </Text>
                )}
              </Box>
              <CaretDown height={16} width={16} />
            </Flex>
          </MenuButton>
          <Select.ItemsContainer>
            {options.map((option) => (
              <MenuItem
                key={option}
                onClick={() => {
                  setValue(`region`, option);
                  trigger();
                }}
              >
                <Box w="full">
                  <BankAccountRegionOption option={option} />
                </Box>
              </MenuItem>
            ))}
          </Select.ItemsContainer>
          {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
        </FormControl>
      </Select.Container>
    </Select.Wrapper>
  );
}
