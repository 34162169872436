import { Tabs } from "@chakra-ui/react";
import isNil from "lodash/isNil";
import { ReactNode, useCallback } from "react";

interface TableTabsProps {
  children: ReactNode;
  defaultIndex?: number;
  index?: number;
  onChange?: (index: number) => void;
}

function TableTabs({
  children,
  defaultIndex,
  onChange,
  index,
}: TableTabsProps) {
  const handleChange = useCallback(
    (index: number) => {
      if (!isNil(onChange)) onChange(index);
    },
    [onChange],
  );

  return (
    <Tabs
      variant="table"
      display="flex"
      p={4}
      bg="white"
      borderTopRadius={8}
      w="fit-content"
      gap={2}
      onChange={handleChange}
      defaultIndex={defaultIndex}
      index={index}
    >
      {children}
    </Tabs>
  );
}

export default TableTabs;
