import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";

import { Link } from "@/modules/Navigation";
import { Button, HStack } from "@chakra-ui/react";
import { useManagedMarkets, useTEXASEnabled } from "@/modules/LaunchDarkly";

interface CompanyPageNavMenuProps {
  readonly slug: string;
}

export default function CompanyPageNavMenu({ slug }: CompanyPageNavMenuProps) {
  const { t } = useTranslation();
  const router = useRouter();
  const { asPath } = router;

  const managedMarketsEnabled = useManagedMarkets();
  const TEXASEnabled = useTEXASEnabled();

  return (
    <HStack p={4} ml={4} gap={4} bg="white" borderRadius={8} w="fit-content">
      <Link href={`/companies/${slug}`} replace>
        <Button
          variant="ghost"
          size="lg"
          isActive={asPath === `/companies/${slug}`}
        >
          {t(`overview`)}
        </Button>
      </Link>

      {TEXASEnabled && (
        <Link href={`/companies/${slug}/templates`} replace>
          <Button
            variant="ghost"
            size="lg"
            isActive={asPath === `/companies/${slug}/templates`}
          >
            {t(`templates`)}
          </Button>
        </Link>
      )}

      {managedMarketsEnabled && (
        <Link href={`/companies/${slug}/managed-market`} replace>
          <Button
            variant="ghost"
            size="lg"
            isActive={asPath === `/companies/${slug}/managed-market`}
          >
            {t(`managed_market`)}
          </Button>
        </Link>
      )}
    </HStack>
  );
}
