import {
  ExecutionAnvilConfig,
  ExecutionStepStatus,
  ExecutionStepType,
  ExecutionStepVisibility,
} from "@/graphql";
import {
  Box,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  Text,
  VStack,
  SimpleGrid,
  GridItem,
  Tag,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Tab,
  Textarea,
  MenuOptionGroup,
  MenuItemOption,
  Input,
  Button,
} from "@chakra-ui/react";
import { Eye, Lock, X } from "@phosphor-icons/react";

import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Edge } from "@xyflow/react";
import { match } from "ts-pattern";

import {
  TaskWithDependency,
  UserTypeTag,
  getFutureTasks,
  taskUserLabel,
} from "@/modules/Task";
import { OverlappingTags } from "@/modules/OverlappingTags";
import { sentenceCase } from "change-case";

import { AnvilLink } from "@/modules/Step";
import { WorkflowStepNodeType } from "./types";
import { replaceIntermediaryEdges } from "./utils";

function DependenciesList<StepType extends WorkflowStepNodeType>({
  dependencies,
}: {
  readonly dependencies: StepType[];
}) {
  return (
    <OverlappingTags>
      {dependencies.map((step) => (
        <Tag
          borderRadius="full"
          size="md"
          variant="grey"
          key={step.id}
          maxW="100%"
        >
          <Text textStyle="colfax-12-regular" isTruncated>
            {step.data.name}
          </Text>
        </Tag>
      ))}
    </OverlappingTags>
  );
}

function VisibilityEditor<StepType extends WorkflowStepNodeType>({
  node,
  readOnly,
  updateVisibility,
}: {
  readonly node: StepType;
  readonly readOnly: boolean;
  readonly updateVisibility: (
    visibility: ExecutionStepVisibility[],
    currentNode: StepType,
  ) => void;
}) {
  if (readOnly) {
    return (
      <OverlappingTags>
        {node.data.visibility.map((assignee) => (
          <UserTypeTag key={assignee} assignee={assignee} />
        ))}
      </OverlappingTags>
    );
  }
  return (
    <Menu closeOnSelect={false}>
      <MenuButton border="none" borderRadius="full" p={0}>
        <OverlappingTags editable>
          {node.data.visibility.map((assignee) => (
            <UserTypeTag key={assignee} assignee={assignee} />
          ))}
        </OverlappingTags>
      </MenuButton>
      <MenuList minWidth="64px">
        <MenuOptionGroup
          onChange={(values) => {
            updateVisibility(values as ExecutionStepVisibility[], node);
          }}
          width={48}
          type="checkbox"
          value={node.data.visibility}
        >
          {[
            ExecutionStepVisibility.Hiive,
            ExecutionStepVisibility.Buyer,
            ExecutionStepVisibility.Seller,
          ].map((assignee) => (
            <MenuItemOption
              isDisabled={assignee === ExecutionStepVisibility.Hiive}
              key={assignee}
              value={assignee}
            >
              {taskUserLabel(assignee)}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
}

function MessagingEditor<StepType extends WorkflowStepNodeType>({
  node,
  updateInstructions,
  isReadOnly,
}: {
  readonly node: StepType;
  readonly isReadOnly: boolean;
  readonly updateInstructions: (
    currentNode: StepType,
    key: string,
    value: string,
  ) => void;
}) {
  const { t } = useTranslation();

  const sellerCopyEditable = node.data.visibility.includes(
    ExecutionStepVisibility.Seller,
  );

  const buyerCopyEditable = node.data.visibility.includes(
    ExecutionStepVisibility.Buyer,
  );

  const showEmptyState = !sellerCopyEditable && !buyerCopyEditable;

  if (node.data.type === ExecutionStepType.Milestone) {
    return (
      <VStack alignItems="start">
        <Text textStyle="heading-xs">{t(`buyer_and_seller`)}</Text>
        <Text textStyle="text-sm" color="grey.600">
          {t(`milestone_messaging`)}
        </Text>
      </VStack>
    );
  }
  const { instructions } = node.data;
  return (
    <VStack spacing={4}>
      {sellerCopyEditable && (
        <VStack alignItems="start" w="full">
          <Text textStyle="heading-xs">{t(`seller`)}</Text>
          <Textarea
            placeholder={t(`add_instruction_for_seller`)}
            variant="borderless"
            disabled={isReadOnly}
            size="sm"
            w="full"
            value={instructions.seller || ``}
            onChange={(e) => {
              updateInstructions(node, `seller`, e.target.value);
            }}
            color="grey.600"
            height={32}
          />
        </VStack>
      )}
      {buyerCopyEditable && (
        <VStack alignItems="start" w="full">
          <Text textStyle="heading-xs">{t(`buyer`)}</Text>
          <Textarea
            placeholder={t(`add_instruction_for_buyer`)}
            variant="borderless"
            disabled={isReadOnly}
            size="sm"
            w="full"
            value={instructions.buyer || ``}
            onChange={(e) => {
              updateInstructions(node, `buyer`, e.target.value);
            }}
            color="grey.600"
            height={32}
          />
        </VStack>
      )}
      {showEmptyState && (
        <VStack alignItems="start" w="full">
          <Text textStyle="text-sm" color="grey.600">
            {t(`instructions_empty_state`)}
          </Text>
        </VStack>
      )}
    </VStack>
  );
}

function isStepCompleted(step: WorkflowStepNodeType) {
  return match(step)
    .with({ data: { status: ExecutionStepStatus.InProgress } }, () => true)
    .with({ data: { status: ExecutionStepStatus.Completed } }, () => true)
    .otherwise(() => false);
}

export function WorkflowStepEditor<StepType extends WorkflowStepNodeType>({
  currentNode,
  nodes,
  edges,
  setNodes,
}: {
  readonly nodes: StepType[];
  readonly edges: Edge[];
  readonly currentNode: StepType;
  readonly setNodes: (nodes: StepType[]) => void;
}) {
  const { t } = useTranslation();

  // for steps that are milestones, we need to replace those edges with step to step edges
  // for the purpose of showing the correct (ignoring milestones) dependencies in start after/required by
  const updatedEdges =
    currentNode.data.type === ExecutionStepType.Milestone
      ? edges
      : replaceIntermediaryEdges(edges, nodes, `ExecutionMilestoneConfig`);

  const startsAfterSteps = nodes.filter((step) =>
    updatedEdges.some(
      (edge) => edge.target === currentNode.id && edge.source === step.id,
    ),
  );

  const showStartsAfterSteps = startsAfterSteps.length > 0;

  const requiredBySteps = nodes.filter((step) =>
    updatedEdges.some(
      (edge) => edge.source === currentNode.id && edge.target === step.id,
    ),
  );

  const showRequiredBySteps = requiredBySteps.length > 0;

  const isMilestone = currentNode.data.type === ExecutionStepType.Milestone;
  const isAnvil =
    currentNode.data.config.__typename === `ExecutionAnvilConfig` ||
    currentNode.data.config.__typename === `ExecutionAnvilV2Config`;

  const isReadOnly = isMilestone || isStepCompleted(currentNode);
  const showTasks = !isMilestone;

  const milestone =
    currentNode.data.config.__typename === `ExecutionMilestoneConfig`
      ? currentNode.data.config.milestone
      : ``;

  const updateInstructions = (
    currentNode: StepType,
    key: string,
    value: string,
  ) => {
    setNodes(
      nodes.map((node) =>
        node.id === currentNode.id
          ? {
              ...node,
              data: {
                ...node.data,
                instructions: {
                  ...node.data.instructions,
                  [key]: value,
                },
              },
            }
          : node,
      ),
    );
  };

  const updateVisibility = (
    visibility: ExecutionStepVisibility[],
    currentNode: StepType,
  ) => {
    setNodes(
      nodes.map((node) =>
        node.id === currentNode.id
          ? {
              ...node,
              data: {
                ...node.data,
                visibility,
              },
            }
          : node,
      ),
    );
  };

  const title =
    currentNode.data.type === ExecutionStepType.Milestone
      ? sentenceCase(milestone)
      : currentNode.data.name;

  const handleUnselect = () => {
    setNodes(
      nodes.map((node) =>
        node.id === currentNode.id
          ? {
              ...node,
              selected: false,
            }
          : node,
      ),
    );
  };

  const tasks = isMilestone ? [] : getFutureTasks(currentNode.data.config);

  const assignees = [...new Set(tasks.map((task) => task.actorType))];

  const { instructions } = currentNode.data;

  return (
    <VStack h="100%" gap={4}>
      <HStack
        p={4}
        borderBottom="1px solid"
        borderColor="gray.200"
        w="100%"
        justifyContent="space-between"
      >
        <Text textStyle="colfax-16-medium">
          {currentNode.data.type === ExecutionStepType.Milestone
            ? t(`edit_milestone`)
            : t(`edit_step`)}
        </Text>
        <HStack>
          {isReadOnly && !isMilestone && (
            <Tag variant="grey" borderRadius="full" size="sm">
              <HStack>
                <Eye />
                <Text textStyle="colfax-12-regular">{t(`view_only`)}</Text>
              </HStack>
            </Tag>
          )}
          <Button variant="outline" size="xs" p={0} onClick={handleUnselect}>
            <X weight="bold" size={14} />
          </Button>
        </HStack>
      </HStack>
      <VStack alignItems="flex-start" px={4} w="100%" gap={4}>
        {isReadOnly ? (
          <HStack>
            {isMilestone && <Lock weight="fill" size={22} />}
            <Text textStyle="colfax-22-medium">{title}</Text>
          </HStack>
        ) : (
          <Input
            size="xl"
            variant="borderless"
            value={currentNode.data.name}
            onChange={(e) => {
              setNodes(
                nodes.map((node) =>
                  node.id === currentNode.id
                    ? { ...node, data: { ...node.data, name: e.target.value } }
                    : node,
                ),
              );
            }}
          />
        )}
        {assignees.length > 0 && (
          <SimpleGrid columns={4} columnGap={2} w="full">
            <GridItem colSpan={1}>
              <Text textStyle="heading-xs">{t(`assignee`)}</Text>
            </GridItem>
            <GridItem colSpan={3}>
              <OverlappingTags>
                {assignees.map((assignee) => (
                  <UserTypeTag key={assignee} assignee={assignee} />
                ))}
              </OverlappingTags>
            </GridItem>
          </SimpleGrid>
        )}
        {currentNode.data.visibility.length > 0 && (
          <SimpleGrid columns={4} columnGap={2} w="full">
            <GridItem colSpan={1}>
              <HStack>
                {isMilestone && <Lock weight="fill" size={12} />}
                <Text textStyle="heading-xs">{t(`visibility`)}</Text>
              </HStack>
            </GridItem>
            <GridItem colSpan={3}>
              <VisibilityEditor<StepType>
                node={currentNode}
                readOnly={isReadOnly}
                updateVisibility={updateVisibility}
              />
            </GridItem>
          </SimpleGrid>
        )}
        {showStartsAfterSteps && (
          <SimpleGrid columns={4} columnGap={2} w="full">
            <GridItem colSpan={1}>
              <Text textStyle="heading-xs" whiteSpace="nowrap">
                {t(`starts_after`)}
              </Text>
            </GridItem>
            <GridItem colSpan={3}>
              <DependenciesList dependencies={startsAfterSteps} />
            </GridItem>
          </SimpleGrid>
        )}

        {showRequiredBySteps && (
          <SimpleGrid columns={4} columnGap={2} w="full">
            <GridItem colSpan={1}>
              <Text textStyle="heading-xs" whiteSpace="nowrap">
                {t(`dependencies`)}
              </Text>
            </GridItem>
            <GridItem colSpan={3}>
              <DependenciesList dependencies={requiredBySteps} />
            </GridItem>
          </SimpleGrid>
        )}
        {!isReadOnly && (
          <VStack alignItems="flex-start" spacing={2} w="full">
            <Text textStyle="heading-xs">
              <Trans i18nKey="step_overview" />
            </Text>
            <Textarea
              w="full"
              disabled={isReadOnly}
              variant="borderless"
              size="sm"
              placeholder={t(`add_instruction_for_hiive`)}
              value={instructions.hiive || ``}
              onChange={(e) =>
                updateInstructions(currentNode, `hiive`, e.target.value)
              }
              color="grey.600"
              height={32}
            />
          </VStack>
        )}
        {isAnvil && (
          <VStack alignItems="flex-start" spacing={2} w="full">
            <Text textStyle="heading-xs">
              <Trans i18nKey="anvil_workflow" />
            </Text>
            <AnvilLink
              weldEid={
                (currentNode.data.config as ExecutionAnvilConfig).anvilWeldEid
              }
            />
          </VStack>
        )}
      </VStack>
      <Tabs h="full" w="full">
        <TabList>
          {showTasks && <Tab>{t(`tasks`)}</Tab>}
          <Tab>
            {isReadOnly && (
              <Box pr={2}>
                {isMilestone && <Lock weight="fill" size={12} />}
              </Box>
            )}
            {t(`messaging`)}
          </Tab>
        </TabList>
        <TabPanels
          h="full"
          bg="grey.25"
          borderTopWidth="1px"
          borderTopColor="grey.100"
        >
          {showTasks && (
            <TabPanel p={6}>
              <VStack spacing={3}>
                {tasks.map((task, index) => (
                  <TaskWithDependency
                    key={task.id}
                    task={task}
                    index={index}
                    previousTask={tasks[index - 1]}
                    stepName={currentNode.data.name}
                    stepType={currentNode.data.type}
                    preview
                  />
                ))}
              </VStack>
            </TabPanel>
          )}
          <TabPanel p={6}>
            <MessagingEditor
              isReadOnly={isReadOnly}
              node={currentNode}
              updateInstructions={updateInstructions}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  );
}
