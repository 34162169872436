import {
  CopyToClipboard,
  CopyToClipboardItem,
} from "@/modules/CopyToClipboard";
import { HStack, Image, Text, VStack } from "@chakra-ui/react";

export default function CompanyInfoClipboard({
  logoUrl,
  companyName,
  copyToClipboardItems,
}: {
  logoUrl?: string | null;
  companyName: string;
  copyToClipboardItems: CopyToClipboardItem[];
}) {
  return (
    <VStack alignItems="flex-start">
      <HStack alignItems="center" gap={4}>
        {logoUrl && <Image src={logoUrl} w="auto" h={8} minW={8} />}
        <Text textStyle="colfax-22-medium">{companyName}</Text>
      </HStack>
      {copyToClipboardItems.map(({ label, value, link }) => (
        <CopyToClipboard label={label} value={value} link={link} key={label} />
      ))}
    </VStack>
  );
}
