import {
  ExecutionWorkflowTemplateTransferType,
  useSaveExecutionWorkflowTemplateMutation,
  useWorkflowTemplatePageWorkflowTemplateQuery,
  WorkflowTemplatePageWorkflowTemplateFragment,
  WorkflowTemplateStatus,
} from "@/graphql";
import {
  getLayoutedElements,
  mapStepNodesToWorkflowTemplateStepsInput,
  mapWorkflowStepTemplatesToStepNodes,
  mapWorkflowTemplateEdgesToStepEdges,
  maybeAutoLayout,
  Workflow,
  WorkflowBuilder,
  WorkflowStepEdge,
  WorkflowStepEditor,
  WorkflowTemplateStepNode,
} from "@/modules/Workflow";
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Image,
  Text,
  VStack,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ArrowLeft,
  DotsThreeOutlineVertical,
  Globe,
} from "@phosphor-icons/react";
import { useRouter } from "next/router";

import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ReactFlowProvider, useEdgesState, useNodesState } from "@xyflow/react";
import { useColors } from "@/modules/Theme";
import { DateTimeFormat, formatDate } from "@/modules/NumeralFormat";
import { match } from "ts-pattern";
import { StatusBadge } from "@/modules/StatusBadge";
import { useCustomToast } from "@/modules/Toast";

import { ConfirmModal } from "@/modules/Modal";
import { sentenceCase } from "change-case";
import { workflowTemplateName } from "./utils";
import { ConfirmTemplateStatusToggleModal } from "./modals";

function WorkflowStatusTag({
  status,
}: {
  readonly status?: WorkflowTemplateStatus;
}) {
  const { t } = useTranslation();
  return match(status)
    .with(WorkflowTemplateStatus.Active, () => (
      <StatusBadge variant="green" border="none" title={t(`active`)} />
    ))
    .with(WorkflowTemplateStatus.Inactive, () => (
      <StatusBadge variant="grey" border="none" title={t(`inactive`)} />
    ))
    .otherwise(() => {
      throw new Error(`Unknown status in workflows table <StatusCell/>`);
    });
}

function TransferTypeTag({
  workflowTemplate,
}: {
  readonly workflowTemplate: WorkflowTemplatePageWorkflowTemplateFragment;
}) {
  const { t } = useTranslation();
  return match(workflowTemplate.transferType)
    .with(ExecutionWorkflowTemplateTransferType.Direct, () => (
      <StatusBadge
        variant="grey"
        borderRadius="full"
        title={t(`direct_transfer`)}
      />
    ))
    .with(ExecutionWorkflowTemplateTransferType.Indirect, () => (
      <StatusBadge
        variant="grey"
        borderRadius="full"
        title={t(`indirect_transfer`)}
      />
    ))
    .otherwise(() => null);
}

function WorkflowTemplatePage({
  workflowTemplateId,
}: {
  readonly workflowTemplateId: string;
}) {
  const router = useRouter();
  const { t } = useTranslation();
  const [sky500] = useColors([`sky.500`]);
  const { successToast, errorToast } = useCustomToast();

  const {
    isOpen: isPublishModalOpen,
    onOpen: onOpenPublishModal,
    onClose: onClosePublishModal,
  } = useDisclosure();

  const [, saveExecutionWorkflowTemplate] =
    useSaveExecutionWorkflowTemplateMutation();

  const [executionWorkflowTemplatesResponse] =
    useWorkflowTemplatePageWorkflowTemplateQuery({
      variables: {
        id: workflowTemplateId,
      },
    });
  const [nodes, setNodes, onNodesChange] =
    useNodesState<WorkflowTemplateStepNode>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<WorkflowStepEdge>([]);

  const selectedNode = nodes.find((node) => node.selected);

  const handleSaveAndPublish = async () => {
    const response = await saveExecutionWorkflowTemplate({
      id: workflowTemplateId,
      input: {
        status: WorkflowTemplateStatus.Active,
        stepTemplates: mapStepNodesToWorkflowTemplateStepsInput(nodes),
        stepEdgeTemplates: edges.map((edge) => ({
          fromStepTemplateKey: edge.source,
          toStepTemplateKey: edge.target,
        })),
      },
    });

    if (response.error) {
      errorToast();
      return;
    }

    onClosePublishModal();
    successToast(t(`workflow_template_saved_and_published`));
  };

  const handleSave = async () => {
    await saveExecutionWorkflowTemplate({
      id: workflowTemplateId,
      input: {
        status: WorkflowTemplateStatus.Inactive,
        stepTemplates: mapStepNodesToWorkflowTemplateStepsInput(nodes),
        stepEdgeTemplates: edges.map((edge) => ({
          fromStepTemplateKey: edge.source,
          toStepTemplateKey: edge.target,
        })),
      },
    });
  };

  const {
    isOpen: isStatusToggleModalOpen,
    onClose: closeStatusToggleModal,
    onToggle: toggleStatusToggleModal,
  } = useDisclosure();

  const executionWorkflowTemplate =
    executionWorkflowTemplatesResponse.data?.executionWorkflowTemplate;

  useEffect(() => {
    const workflowTemplate = executionWorkflowTemplate?.activeVersion;
    if (!workflowTemplate) return;
    const nodes = mapWorkflowStepTemplatesToStepNodes(
      workflowTemplate.stepTemplates,
    );
    const edges = mapWorkflowTemplateEdgesToStepEdges(
      workflowTemplate.stepEdgeTemplates,
    );

    const { nodes: layoutedNodes, edges: layoutedEdges } = maybeAutoLayout(
      nodes,
      edges,
    );

    setNodes(layoutedNodes);
    setEdges(layoutedEdges);
  }, [executionWorkflowTemplate, setNodes, setEdges]);

  if (!executionWorkflowTemplate) return <Spinner />;

  const showActivationToggle = !!executionWorkflowTemplate.company;
  const isCompanySpecific = !!executionWorkflowTemplate?.company;
  return (
    <Flex direction="column" w="full" h="100vh">
      <HStack
        h="64px"
        bg="white"
        borderBottomColor="grey.100"
        borderBottomWidth="1px"
        px={6}
        justifyContent="space-between"
      >
        <HStack spacing={2}>
          <IconButton
            variant="ghost"
            h="8"
            p={0}
            aria-label={t(`back`)}
            onClick={() => router.back()}
          >
            <ArrowLeft height={16} width={16} weight="bold" />
          </IconButton>
          <HStack gap={4}>
            <Flex
              w="8"
              h="8"
              borderWidth="1px"
              borderColor="grey.100"
              borderRadius="sm"
              overflow="hidden"
              p={1}
              bg="white"
              alignItems="center"
              justify="center"
            >
              {isCompanySpecific ? (
                executionWorkflowTemplate?.company?.logoUrl && (
                  <Image
                    w="full"
                    h="full"
                    objectFit="contain"
                    src={executionWorkflowTemplate?.company?.logoUrl}
                  />
                )
              ) : (
                <Globe size="20px" color={sky500} />
              )}
            </Flex>
            <VStack gap={0} alignItems="flex-start">
              <Text textStyle="colfax-12-medium">
                {executionWorkflowTemplate &&
                  workflowTemplateName(executionWorkflowTemplate)}
              </Text>
              <Text textStyle="colfax-12-regular">{t(`template`)}</Text>
            </VStack>
            {isCompanySpecific && (
              <TransferTypeTag workflowTemplate={executionWorkflowTemplate} />
            )}
          </HStack>
        </HStack>
        <HStack gap={3}>
          <Text textStyle="colfax-12-regular" color="grey.600">
            {t(`saved_at`, {
              time: formatDate(
                executionWorkflowTemplate?.lastSavedAt,
                DateTimeFormat.shortDateWithTime,
              ),
            })}
          </Text>
          <WorkflowStatusTag status={executionWorkflowTemplate?.status} />
          {executionWorkflowTemplate?.status ===
          WorkflowTemplateStatus.Active ? (
            <>
              <Button size="sm" onClick={onOpenPublishModal}>
                {t(`save_and_publish`)}
              </Button>
              {showActivationToggle && (
                <Menu>
                  <MenuButton px={2} as={Button} size="sm" variant="outline">
                    <HStack>
                      <DotsThreeOutlineVertical weight="fill" />
                    </HStack>
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={toggleStatusToggleModal}>
                      {t(`make_status`, {
                        status:
                          executionWorkflowTemplate?.status ===
                          WorkflowTemplateStatus.Active
                            ? t(`inactive`)
                            : t(`active`),
                      })}
                    </MenuItem>
                  </MenuList>
                </Menu>
              )}
            </>
          ) : (
            <>
              <Button variant="outline" size="sm" onClick={handleSave}>
                {t(`save_changes`)}
              </Button>
              <Button
                size="sm"
                onClick={() => {
                  toggleStatusToggleModal();
                }}
              >
                {t(`publish`)}
              </Button>
            </>
          )}
        </HStack>
      </HStack>
      <ConfirmTemplateStatusToggleModal
        workflowTemplate={executionWorkflowTemplate}
        isOpen={isStatusToggleModalOpen}
        onClose={closeStatusToggleModal}
      />
      <Flex flex="1" overflowY="auto">
        <ReactFlowProvider>
          <Box flex="1">
            <Workflow<WorkflowTemplateStepNode>
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              setNodes={setNodes}
              setEdges={setEdges}
              getLayoutedElements={getLayoutedElements}
            />
          </Box>

          <Flex
            overflowY="auto"
            w="120"
            bg="white"
            direction="column"
            borderLeftColor="grey.100"
            borderLeftWidth="1px"
          >
            {selectedNode ? (
              <WorkflowStepEditor
                nodes={nodes}
                edges={edges}
                currentNode={selectedNode}
                setNodes={setNodes}
              />
            ) : (
              <WorkflowBuilder nodes={nodes} />
            )}
          </Flex>
        </ReactFlowProvider>
      </Flex>
      <ConfirmModal
        title={t(
          isCompanySpecific ? `publish_company_template` : `publish_template`,
        )}
        body={
          <Text>
            <Trans
              i18nKey={
                isCompanySpecific
                  ? `publish_company_template_warning`
                  : `publish_template_warning`
              }
              components={{ bold: <strong /> }}
              values={{
                company_name: executionWorkflowTemplate?.company?.name,
                transfer_type: sentenceCase(
                  executionWorkflowTemplate.transferType || ``,
                ),
              }}
            />
          </Text>
        }
        isOpen={isPublishModalOpen}
        onClose={onClosePublishModal}
        onConfirm={handleSaveAndPublish}
      />
    </Flex>
  );
}

export default WorkflowTemplatePage;
