import { Checkbox, Text, HStack } from "@chakra-ui/react";
import { AreaInput } from "@/modules/Form";
import { useState } from "react";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import {
  FieldError,
  FieldValues,
  Path,
  UseFormRegister,
  UseFormResetField,
  UseFormSetValue,
  PathValue,
} from "react-hook-form";

type MuteNotificationWithReason<T extends FieldValues> = {
  readonly name: Path<T>;
  readonly error?: FieldError;
  readonly register: UseFormRegister<T>;
  readonly resetField: UseFormResetField<T>;
  readonly setValue: UseFormSetValue<T>;
};

export type MuteNotificationReasonType = {
  readonly muteNotificationReason?: string | null;
};

export const muteNotificationValidationSchema = yup.object({
  muteNotificationReason: yup
    .string()
    .defined()
    .min(1, `Reason for muting is required.`)
    .nullable(),
});

function MuteNotificationWithReasonSection<T extends FieldValues>({
  name,
  register,
  error,
  resetField,
  setValue,
}: MuteNotificationWithReason<T>) {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const formProps = register(name);
  return (
    <>
      <HStack width="full" justifyContent="flex-start" alignItems="center">
        <Checkbox
          isChecked={isChecked}
          onChange={() => {
            if (!isChecked) {
              setValue(name, `` as PathValue<T, Path<T>>);
            } else resetField(name);

            setIsChecked(!isChecked);
          }}
        >
          <Text>{t(`mute_notification_label`)}</Text>
        </Checkbox>
        <Text textStyle="colfax-12-regular" color="grey.600">
          {t(`mute_notification_reason_required`)}
        </Text>
      </HStack>
      {isChecked && (
        <AreaInput
          label={t(`mute_notification_reason_title`)}
          helperText={t(`required`)}
          borderColor="grey.300"
          borderWidth=".5px"
          isRequired={isChecked}
          maxLength={255}
          error={error}
          {...formProps}
        />
      )}
    </>
  );
}

export default MuteNotificationWithReasonSection;
