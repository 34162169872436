import { BankAccountVerificationStatus } from "@/graphql";
import { StatusBadge } from "@/modules/StatusBadge";
import {
  CheckCircle,
  Circle,
  WarningCircle,
  XCircle,
} from "@phosphor-icons/react";
import React from "react";
import { useTranslation } from "react-i18next";

type BankAccountStatusBadgeProps = {
  readonly status: BankAccountVerificationStatus;
  readonly hasTransactions?: boolean;
};

/**
 * @param hasTransactions pass this in if a bank account might not have transactions (collected via off-platform flow)
 * and benefits from distinguishing between bank accounts in the manual state; see usage in BankAccountCard.tsx
 */
export default function BankAccountStatusBadge({
  status,
  hasTransactions = true,
}: BankAccountStatusBadgeProps) {
  const { t } = useTranslation();

  const statusMap: Record<BankAccountVerificationStatus, React.ReactNode> = {
    [BankAccountVerificationStatus.Manual]: (
      <StatusBadge
        variant="yellow-bordered"
        icon={<WarningCircle weight="fill" width={12} height={12} />}
        title={hasTransactions ? t(`manual`) : t(`not_linked`)}
      />
    ),
    [BankAccountVerificationStatus.PendingVerification]: (
      <StatusBadge
        variant="sky-bordered"
        icon={<Circle width={12} height={12} />}
        title={t(`verifying`)}
      />
    ),
    [BankAccountVerificationStatus.Verified]: (
      <StatusBadge
        variant="green-bordered"
        icon={<CheckCircle weight="fill" width={12} height={12} />}
        title={t(`verified`)}
      />
    ),
    [BankAccountVerificationStatus.Cancelled]: (
      <StatusBadge
        variant="red-bordered"
        icon={<XCircle weight="fill" width={12} height={12} />}
        title={t(`cancelled`)}
      />
    ),
    [BankAccountVerificationStatus.Failed]: (
      <StatusBadge
        variant="red-bordered"
        icon={<XCircle weight="fill" width={12} height={12} />}
        title={t(`failed`)}
      />
    ),
    [BankAccountVerificationStatus.Unverified]: undefined,
  };

  return statusMap[status];
}
