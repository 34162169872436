import { CompanyEdgeFragment, CompanyStatus } from "@/graphql";
import { getCompanyIndicatorStatus } from "@/modules/Company";
import { Status } from "@/modules/Status";
import { Table, TableColumns } from "@/modules/Table";
import { DateTimeFormat, formatDate } from "@/modules/NumeralFormat";
import { Box, Image, Tab } from "@chakra-ui/react";
import { CellContext, Row } from "@tanstack/react-table";
import router from "next/router";
import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  SimpleTable,
  SimpleTableQueryVariables,
  SimpleTableUrlParams,
  TableContext,
} from "@/modules/SimpleTable";
import {
  CompaniesTableContext,
  CompaniesTableType,
  mapToTable,
} from "@/features/Companies";
import CompaniesTableRowActions from "./CompaniesTableRowActions";
import CompaniesFilters from "./CompaniesFilters";

export type CompaniesUrlParams = SimpleTableUrlParams & {
  countryIds?: string;
  statuses?: string;
  page: string;
  before: string;
  after: string;
  first: string;
  last: string;
};

function CompaniesTableTabs() {
  const { t } = useTranslation();
  return (
    <Table.Tabs>
      <Tab>{t(`all_companies`)}</Tab>
    </Table.Tabs>
  );
}

function CompaniesTable() {
  const { push } = router;
  const { page, searchText, sort, setSort, setVariables } =
    useContext(TableContext);
  const { data } = useContext(CompaniesTableContext);

  const columns: TableColumns<CompaniesTableType> = useMemo(
    () => ({
      logoUrl: {
        header: () => `Logo`,
        cell: ({ cell }) => {
          const src = cell.getValue() ?? undefined;
          if (!src) {
            return <Status.Empty />;
          }
          return <Image src={src} w="auto" h={6} />;
        },
        enableSorting: false,
      },
      name: {
        header: () => `Name`,
      },
      domain: {
        header: () => `Domain`,
      },
      country: {
        header: () => `Country`,
      },
      lastRoundDate: {
        header: () => `Last Round Date`,
        cell: ({ cell }) => {
          const { lastRoundDate } = cell.getContext().row.original;

          if (!lastRoundDate) {
            return <Status.Empty />;
          }

          return formatDate(
            lastRoundDate,
            DateTimeFormat.fullDateWithMonthShort,
          );
        },
      },
      ...{
        preqin: {
          // FIXME: There are additional type guards here for the sake of the preqin integration rollout
          header: () => `Preqin`,
          cell: ({
            cell,
          }: CellContext<CompaniesTableType, string | null | undefined>) => {
            const context = cell.getContext().row.original;
            if (`preqin` in context) {
              const { preqin } = context;
              return <Status.Preqin preqin={preqin} />;
            }

            return <Status.Empty />;
          },
          enableSorting: false,
        },
      },
      status: {
        header: () => `Status`,
        cell: (props) => (
          <Status.Indicator
            text={props.cell.getValue() ?? ``}
            textTransform="capitalize"
            indicatorProps={{
              bg: getCompanyIndicatorStatus(
                props.cell.getValue()?.toUpperCase() as CompanyStatus,
              ),
            }}
          />
        ),
        enableSorting: false,
      },
    }),
    [],
  );

  const tableData = useMemo(() => mapToTable(data), [data]);

  const onRowClick = useCallback(
    async (row: Row<Record<string, unknown>>) => {
      await push(`/companies/${row.original.nameSlug}/`);
    },
    [push],
  );

  const renderRowActions = useCallback(
    (row: Row<Record<string, unknown>>) => (
      <CompaniesTableRowActions
        company={row.original as CompanyEdgeFragment["node"]}
      />
    ),
    [],
  );

  if (!data) return null;

  return (
    <>
      <CompaniesTableTabs />
      <Box w="full" bg="white" p={4} borderTopRightRadius={8}>
        <CompaniesFilters />
      </Box>
      <SimpleTable<SimpleTableQueryVariables, CompaniesTableType[]>
        page={page}
        sort={sort}
        setSort={setSort}
        searchText={searchText}
        setVariables={setVariables}
        columns={columns}
        tableData={tableData.companies}
        pageInfo={tableData.pageInfo}
        totalCount={tableData.totalCount}
        renderRowActions={renderRowActions}
        onRowClick={onRowClick}
        error={data.error}
        loading={data.fetching}
      />
    </>
  );
}

export default CompaniesTable;
