import {
  CompanyApprovedBuyerType,
  useRemoveCompanyApprovedBuyerMutation,
} from "@/graphql";
import { ConfirmModal } from "@/modules/Modal";
import { useCustomToast } from "@/modules/Toast";
import { Trans, useTranslation } from "react-i18next";
import { match } from "ts-pattern";
import { useContext } from "react";
import {
  CompanyApprovedBuyersTableData,
  CompanyManagedMarketContext,
} from "@/features/Company";

interface RemoveApprovedBuyerModalProps {
  companyApprovedBuyer: CompanyApprovedBuyersTableData;
  isOpen: boolean;
  onClose: () => void;
}

export function RemoveApprovedBuyerModal({
  companyApprovedBuyer,
  isOpen,
  onClose,
}: RemoveApprovedBuyerModalProps) {
  const { t } = useTranslation();
  const { errorToast, successToast } = useCustomToast();
  const [, removeCompanyApprovedBuyer] =
    useRemoveCompanyApprovedBuyerMutation();

  const { managedMarketCompany } = useContext(CompanyManagedMarketContext);

  if (!managedMarketCompany) {
    return null;
  }

  const { entityName, entityId } = match(companyApprovedBuyer.type)
    .with(CompanyApprovedBuyerType.User, () => ({
      entityName: companyApprovedBuyer.user?.name,
      entityId: companyApprovedBuyer.user?.id,
    }))
    .with(CompanyApprovedBuyerType.Institution, () => ({
      entityName: companyApprovedBuyer.institution?.legalName,
      entityId: companyApprovedBuyer.institution?.id,
    }))
    .exhaustive();

  const onConfirm = async () => {
    const { data: response } = await removeCompanyApprovedBuyer({
      companyId: managedMarketCompany?.id,
      entityId: entityId || ``,
      type: companyApprovedBuyer.type,
    });
    if (response?.removeCompanyApprovedBuyer.companyApprovedBuyer?.id) {
      successToast(t(`approved_buyer_removed`));
    } else {
      errorToast(t(`approved_buyer_removed_error`));
    }
    onClose();
  };

  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={onClose}
      title={t(`remove_approved_buyer?`)}
      body={
        <Trans
          i18nKey="remove_approved_buyer_confirmation"
          components={{ bold: <strong /> }}
          values={{
            entityName,
            companyName: managedMarketCompany.name,
          }}
        />
      }
      onConfirm={onConfirm}
    />
  );
}
