import { Trans, useTranslation } from "react-i18next";

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
} from "@chakra-ui/react";

export function CompanyEligibilityCriteriaAlert() {
  const { t } = useTranslation();

  return (
    <Alert alignItems="flex-start" status="info" variant="grey">
      <AlertIcon />
      <Box>
        <AlertTitle>
          {t(`apply_seller_eligibility_modal_example_title`)}
        </AlertTitle>
        <AlertDescription>
          <Trans i18nKey="apply_seller_eligibility_modal_example_content" />
        </AlertDescription>
      </Box>
    </Alert>
  );
}
