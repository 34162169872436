import {
  BankAccountRegion,
  useGenerateBankAccountCollectionLinkMutation,
} from "@/graphql";
import { ConfirmModal } from "@/modules/Modal";
import { useCustomToast } from "@/modules/Toast";
import { Button, ModalProps, Text, VStack } from "@chakra-ui/react";
import { CheckCircle, CopySimple } from "@phosphor-icons/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { BankAccountRegionSelect } from "./BankAccountRegionSelect";

type GenerateManualLinkModalProps = Omit<ModalProps, "children"> & {
  entityId: string;
};

export default function GenerateManualLinkModal({
  entityId,
  isOpen,
  onClose,
}: GenerateManualLinkModalProps) {
  const { t } = useTranslation();
  const { errorToast, infoToastCondensed } = useCustomToast();

  const [{ fetching }, generateFormLink] =
    useGenerateBankAccountCollectionLinkMutation();

  const [requestLinkSuccess, setRequestLinkSuccess] = useState(false);

  const validationSchema = yup
    .object()
    .shape({
      entityId: yup.string().required(t(`select_an_entity`)),
      region: yup
        .string()
        .oneOf(Object.values(BankAccountRegion))
        .required(t(`select_region`)),
    })
    .required();

  const methods = useForm({
    defaultValues: {
      entityId,
    },
    resolver: yupResolver(validationSchema),
  });
  const {
    formState: { isValid },
    getValues,
    reset,
  } = methods;

  const closeModal = () => {
    setRequestLinkSuccess(false);
    onClose();
    reset();
  };

  const generateFormLinkAndCopy = async () => {
    const { data } = await generateFormLink({
      input: {
        entityId,
        region: getValues(`region`),
      },
    });

    const formLink = data?.generateBankAccountCollectionLink.formLink;

    if (!formLink) {
      errorToast(t(`off_platform_generate_link_error`));
      return;
    }

    await navigator.clipboard.writeText(formLink);

    setRequestLinkSuccess(true);
    infoToastCondensed(t(`copied_to_clipboard`), {
      icon: <CopySimple size={24} color="skyBlue.600" />,
    });
  };

  return (
    <ConfirmModal
      title={t(`generate_manual_link`)}
      isOpen={isOpen}
      onClose={closeModal}
      onConfirm={closeModal}
      disableConfirm={fetching}
      confirmText={t(`done`)}
      showCancelButton={false}
      body={
        <VStack alignItems="flex-start" gap={5}>
          <Text>{t(`generate_manual_link_copy`)}</Text>
          <FormProvider {...methods}>
            <BankAccountRegionSelect />
          </FormProvider>
          <Button
            isDisabled={!isValid}
            isLoading={fetching}
            loadingText={t(`off_platform_generate_link_loading`)}
            onClick={generateFormLinkAndCopy}
            variant="outline"
            leftIcon={
              requestLinkSuccess ? (
                <CheckCircle size={20} />
              ) : (
                <CopySimple size={20} />
              )
            }
          >
            {requestLinkSuccess
              ? t(`off_platform_generate_link_success`)
              : t(`copy_manual_link`)}
          </Button>
        </VStack>
      }
    />
  );
}
