import { mapFeeDiscountDetails } from "@/features/FeeDiscount";
import { useFeeDiscountPageQuery } from "@/graphql";
import { ReactNode, createContext, useCallback, useMemo } from "react";
import { CombinedError } from "urql";

type FeeDiscountDetailsContext = {
  error?: CombinedError;
  fetching: boolean;
  feeDiscount: ReturnType<typeof mapFeeDiscountDetails>;
  refetch: () => void;
};

const DEFAULT_CONTEXT: FeeDiscountDetailsContext = {
  error: undefined,
  fetching: false,
  feeDiscount: null,
  refetch: () => {},
};
const Context = createContext<FeeDiscountDetailsContext>(DEFAULT_CONTEXT);
const { Provider } = Context;

interface FeeDiscountDetailsProviderProps {
  id: string;
  children: ReactNode;
}

function FeeDiscountDetailsProvider({
  id,
  children,
}: FeeDiscountDetailsProviderProps) {
  const [{ fetching, error, data }, executeQuery] = useFeeDiscountPageQuery({
    variables: { id },
    requestPolicy: `cache-and-network`,
  });
  const refetch = useCallback(() => {
    executeQuery({ requestPolicy: `network-only` });
  }, [executeQuery]);

  const feeDiscount = useMemo(() => mapFeeDiscountDetails(data), [data]);

  return (
    <Provider value={{ fetching, error, feeDiscount, refetch }}>
      {children}
    </Provider>
  );
}

export { Context, FeeDiscountDetailsProvider };
