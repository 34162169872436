import { getDefaultTableProviderVariables } from "@/constants";
import { WorkflowTemplatesUrlParams } from "@/features/WorkflowTemplates";
import {
  SortDirection,
  WorkflowTemplateFilter,
  WorkflowTemplateSort,
  WorkflowTemplateSortableField,
  WorkflowTemplatesPageWorkflowTemplatesQuery,
  WorkflowTemplatesPageWorkflowTemplatesQueryVariables,
  useWorkflowTemplatesPageWorkflowTemplatesQuery,
} from "@/graphql";
import { TableContext, TableProvider } from "@/modules/SimpleTable";
import { ReactNode, createContext, useContext } from "react";
import { UseQueryState } from "urql";

interface WorkflowTemplatesTableProviderProps {
  children: ReactNode;
  query: WorkflowTemplatesUrlParams;
}

export const Context = createContext<{
  response:
    | UseQueryState<
        WorkflowTemplatesPageWorkflowTemplatesQuery,
        WorkflowTemplatesPageWorkflowTemplatesQueryVariables
      >
    | undefined;
}>({ response: undefined });

const { Provider } = Context;

function Child({ children }: { readonly children: ReactNode }) {
  const { variables } = useContext(TableContext);

  const [response] = useWorkflowTemplatesPageWorkflowTemplatesQuery({
    variables: {
      ...variables,
      sortBy: variables.sortBy as WorkflowTemplateSort,
      filterBy: variables.filterBy as WorkflowTemplateFilter,
    },
  });

  return <Provider value={{ response }}>{children}</Provider>;
}

const DEFAULT_VARIABLES = getDefaultTableProviderVariables({
  sortBy: {
    field: WorkflowTemplateSortableField.UpdatedAt,
    direction: SortDirection.Desc,
  },
  filterBy: {
    searchText: ``,
  },
});

function WorkflowTemplatesTableProvider({
  children,
  query,
}: WorkflowTemplatesTableProviderProps) {
  return (
    <TableProvider
      query={query}
      defaultVariables={DEFAULT_VARIABLES}
      sortFields={Object.values(WorkflowTemplateSortableField)}
    >
      <Child>{children}</Child>
    </TableProvider>
  );
}

export default WorkflowTemplatesTableProvider;
