import { useCallback, useContext, useMemo } from "react";
import { Tab, Box } from "@chakra-ui/react";
import { Trans } from "react-i18next";
import {
  TransactionState,
  TransactionTransferMethod,
  TransactionsTableNodeFragment,
  TransactionSecuritySpecialistFragment,
  useTransactionsTableSecuritySpecialistQuery,
} from "@/graphql";
import { Row } from "@tanstack/react-table";
import { useRouter } from "next/router";

import {
  SimpleTable,
  SimpleTableQueryVariables,
  SimpleTableUrlParams,
  TableContext,
} from "@/modules/SimpleTable";
import { Table, TableColumns } from "@/modules/Table";
import { RESET_PAGINATION_PARAMS } from "@/constants";
import {
  TransactionsTableContext,
  transformTransactionsQueryToData,
  TransactionsTableColumnType,
  TransactionsTableDataType,
  TransactionsTableRowActions,
} from "@/features/Transactions";

import { match } from "ts-pattern";
import {
  CompanyTableCell,
  DatabaseIDTableCell,
  SharesPPSTableCell,
  BuyerTableCell,
  SellerTableCell,
  SigningProcedureTableCell,
  TransactionStateTableCell,
  UpdatedAtTableCell,
} from "./TransactionTableCells";
import { TransactionsSecuritySpecialistFilterSection } from "./TransactionsSecuritySpecialistFilterSection";

export type TransactionsUrlParams = SimpleTableUrlParams & {
  states?: [TransactionState];
  transfer_methods?: [TransactionTransferMethod];
  page: string;
  before: string;
  after: string;
  first: string;
  last: string;
};

function TransactionsTableTabs({
  variables,
  setVariables,
}: {
  variables: SimpleTableQueryVariables;
  setVariables: (variables: SimpleTableQueryVariables) => void;
}) {
  const getTabIndex = (variables: SimpleTableQueryVariables) =>
    match([variables.filterBy?.states, variables.filterBy?.transfer_methods])
      .with([[TransactionState.InReview], null], () => 1)
      .with(
        [
          [
            TransactionState.BidAccepted,
            TransactionState.AwaitingClosing,
            TransactionState.ClosedFeePending,
          ],
          [
            TransactionTransferMethod.ForwardContract,
            TransactionTransferMethod.SpvLister,
            TransactionTransferMethod.SpvThirdparty,
          ],
        ],
        () => 2,
      )
      .with(
        [[TransactionState.BidAccepted], [TransactionTransferMethod.Direct]],
        () => 3,
      )
      .with(
        [
          [TransactionState.IssuerPendingApproval],
          [TransactionTransferMethod.Direct],
        ],
        () => 4,
      )
      .with(
        [
          [TransactionState.IssuerApproved, TransactionState.ClosedFeePending],
          [TransactionTransferMethod.Direct],
        ],
        () => 5,
      )
      .with(
        [
          [
            TransactionState.Cancelled,
            TransactionState.ClosedFeePaid,
            TransactionState.Expired,
            TransactionState.IssuerApprovalDeclined,
          ],
          null,
        ],
        () => 6,
      )
      .otherwise(() => 0);
  const index = useMemo(() => getTabIndex(variables), [variables]);

  const handleTabChange = useCallback(
    (index: number) => {
      setVariables({
        ...variables,
        ...RESET_PAGINATION_PARAMS,
        filterBy: {
          ...variables.filterBy,
          transfer_methods: match(index)
            .with(2, () => [
              TransactionTransferMethod.ForwardContract,
              TransactionTransferMethod.SpvLister,
              TransactionTransferMethod.SpvThirdparty,
            ])
            .with(3, () => [TransactionTransferMethod.Direct])
            .with(4, () => [TransactionTransferMethod.Direct])
            .with(5, () => [TransactionTransferMethod.Direct])
            .otherwise(() => null),

          states: match(index)
            .with(1, () => [TransactionState.InReview])
            .with(2, () => [
              TransactionState.BidAccepted,
              TransactionState.AwaitingClosing,
              TransactionState.ClosedFeePending,
            ])
            .with(3, () => [TransactionState.BidAccepted])
            .with(4, () => [TransactionState.IssuerPendingApproval])
            .with(5, () => [
              TransactionState.IssuerApproved,
              TransactionState.ClosedFeePending,
            ])
            .with(6, () => [
              TransactionState.Cancelled,
              TransactionState.ClosedFeePaid,
              TransactionState.Expired,
              TransactionState.IssuerApprovalDeclined,
            ])
            .otherwise(() => null),
        },
      });
    },
    [setVariables, variables],
  );

  return (
    <Table.Tabs onChange={handleTabChange} index={index}>
      <Tab>All Transactions</Tab>
      <Tab>In Review</Tab>
      <Tab>Indirect</Tab>
      <Tab>Direct: Bid Accepted</Tab>
      <Tab>Direct: Awaiting Issuer</Tab>
      <Tab>Direct: Back to Us</Tab>
      <Tab>Terminal States</Tab>
    </Table.Tabs>
  );
}

const columns: TableColumns<
  TransactionsTableColumnType,
  TransactionsTableDataType
> = {
  companyName: {
    header: () => <Trans i18nKey="company_name" />,
    cell: ({ cell }) => <CompanyTableCell cell={cell} />,
  },
  updatedAt: {
    header: () => <Trans i18nKey="last_updated" />,
    cell: ({ cell }) => <UpdatedAtTableCell cell={cell} />,
  },
  id: {
    header: () => <Trans i18nKey="database_id" />,
    cell: ({ cell }) => <DatabaseIDTableCell cell={cell} />,
    enableSorting: false,
  },
  signingProcedure: {
    header: () => <Trans i18nKey="signing_procedure" />,
    cell: ({ cell }) => <SigningProcedureTableCell cell={cell} />,
  },
  sharesPPS: {
    header: () => <Trans i18nKey="price_per_share_abbr" />,
    cell: ({ cell }) => <SharesPPSTableCell cell={cell} />,
    enableSorting: false,
  },
  buyerEmail: {
    header: () => <Trans i18nKey="buyer" />,
    cell: ({ cell }) => <BuyerTableCell cell={cell} />,
  },
  sellerEmail: {
    header: () => <Trans i18nKey="seller" />,
    cell: ({ cell }) => <SellerTableCell cell={cell} />,
  },
  state: {
    header: () => <Trans i18nKey="state" />,
    cell: ({ cell }) => <TransactionStateTableCell cell={cell} />,
    enableSorting: false,
  },
};

export default function TransactionsTable() {
  const { data } = useContext(TransactionsTableContext);
  const { page, searchText, sort, variables, setSort, setVariables } =
    useContext(TableContext);
  const { push } = useRouter();
  const tableData = useMemo(
    () => transformTransactionsQueryToData(data),
    [data],
  );

  const [ssData] = useTransactionsTableSecuritySpecialistQuery();
  const securitySpecialists = ssData?.data?.transactionSecuritySpecialists;

  const onRowClick = useCallback(
    (row: Row<Record<string, unknown>>) => {
      const { id } = row.original as TransactionsTableNodeFragment;
      push(`/transactions/${id}`);
    },
    [push],
  );

  const isTerminalStatesSelected = (
    (variables.filterBy?.states as Array<TransactionState>) || []
  ).some((state) =>
    [
      TransactionState.Cancelled,
      TransactionState.ClosedFeePaid,
      TransactionState.Expired,
      TransactionState.IssuerApprovalDeclined,
    ].includes(state),
  );

  const renderRowActions = useCallback(
    (row: Row<Record<string, unknown>>) => (
      <TransactionsTableRowActions
        transaction={row.original as TransactionsTableNodeFragment}
      />
    ),
    [],
  );

  if (!data || !securitySpecialists) return null;

  return (
    <>
      <TransactionsTableTabs
        variables={variables}
        setVariables={setVariables}
      />
      <Box w="full" bg="white" p={4} borderTopRightRadius={8}>
        <TransactionsSecuritySpecialistFilterSection
          securitySpecialists={
            securitySpecialists as TransactionSecuritySpecialistFragment[]
          }
        />
      </Box>
      <SimpleTable<
        SimpleTableQueryVariables,
        TransactionsTableColumnType[],
        TransactionsTableDataType[]
      >
        page={page}
        sort={sort}
        setSort={setSort}
        searchText={searchText}
        setVariables={setVariables}
        columns={columns}
        tableData={tableData.transactions}
        pageInfo={tableData.pageInfo}
        totalCount={tableData.totalCount}
        renderRowActions={
          isTerminalStatesSelected ? undefined : renderRowActions
        }
        onRowClick={onRowClick}
        error={data.error}
        loading={data.fetching}
      />
    </>
  );
}
