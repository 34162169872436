import { Controller, useFormContext, useWatch } from "react-hook-form";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { formatFeeDiscountType } from "@/features/FeeDiscount";
import {
  FeeDiscountFormInputs,
  FeeDiscountIcon,
} from "@/features/FeeDiscountForm";
import {
  FeeDiscountSortableField,
  FeeDiscountType,
  SortDirection,
  useFeeDiscountFormQuery,
} from "@/graphql";
import { useDebounce } from "@/modules/Debounce";
import { Input } from "@/modules/Form";
import { formatStringToNumber } from "@/modules/NumeralFormat";
import {
  Card,
  CardBody,
  CardHeader,
  GridItem,
  HStack,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";

function mapFeeDiscountValue(value: string) {
  const numericValue = formatStringToNumber(value);
  return Math.round(numericValue * 100);
}

export default function FeeDiscountFormDetailsCard() {
  const {
    clearErrors,
    control,
    formState: { errors },
    register,
    setError,
    setValue,
  } = useFormContext<FeeDiscountFormInputs>();
  const { t } = useTranslation();
  const type = useWatch({
    name: `type`,
    control,
    defaultValue: FeeDiscountType.FlatFee,
  });
  const { debounce } = useDebounce(200);
  const [debouncedValue, setDebouncedValue] = useState<number | undefined>();

  const [{ data }, executeQuery] = useFeeDiscountFormQuery({
    variables: {
      sortBy: {
        field: FeeDiscountSortableField.Type,
        direction: SortDirection.Asc,
      },
      filterBy: {
        active: true,
        feeDiscountTypes: !!debouncedValue ? [type as FeeDiscountType] : [null],
        value: type === FeeDiscountType.FlatFee ? debouncedValue : undefined,
        percentageValue:
          type !== FeeDiscountType.FlatFee ? debouncedValue : undefined,
      },
      first: 500,
    },
    requestPolicy: `cache-and-network`,
  });

  const feeDiscountTypes = Object.values(FeeDiscountType).map((type) => ({
    label: formatFeeDiscountType(type),
    value: type,
  }));

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, onChange: (value: string) => void) => {
      debounce(() => {
        const { value } = e.target;
        const numericValue =
          type === FeeDiscountType.FlatFee
            ? mapFeeDiscountValue(value)
            : formatStringToNumber(value);
        setDebouncedValue(numericValue);
        executeQuery();
        onChange(value);
      });
    },
    [debounce, setDebouncedValue, executeQuery, type],
  );

  useEffect(() => {
    if (data?.feeDiscounts?.edges?.length) {
      setError(`type`, { message: t(`duplicate_fee_discount`) });
      setError(`value`, { message: t(`duplicate_fee_discount`) });
      setError(`percentageValue`, { message: t(`duplicate_fee_discount`) });
    } else {
      clearErrors();
    }
  }, [clearErrors, data, setError, t]);

  const decimalScale = type === FeeDiscountType.FlatFee ? 2 : 9;

  const fieldName =
    type === FeeDiscountType.FlatFee ? `value` : `percentageValue`;
  const typeRegister = register(`type`);
  return (
    <Card w="full">
      <CardHeader>
        <HStack gap={6}>
          <Text textStyle="colfax-18-medium">{t(`fee_discount_values`)}</Text>
        </HStack>
      </CardHeader>
      <CardBody>
        <VStack gap={6} alignItems="flex-start">
          <SimpleGrid
            gridTemplateColumns={{ base: `1fr 200px` }}
            w="full"
            gap={{ base: 6 }}
          >
            <GridItem>
              <HStack gap={{ base: 6 }} alignItems="flex-start">
                <VStack gap={4} alignItems="flex-start" w="50%">
                  <Input.Select
                    error={errors.type}
                    label={t(`fee_discount_type`)}
                    options={feeDiscountTypes}
                    defaultValue={FeeDiscountType.FlatFee}
                    {...typeRegister}
                    onChange={(e) => {
                      setDebouncedValue(undefined);
                      setValue(`value`, `` as unknown as number);
                      setValue(`percentageValue`, `` as unknown as number);
                      return typeRegister.onChange(e);
                    }}
                  />
                </VStack>
                <VStack gap={{ base: 6 }} alignItems="flex-start" w="50%">
                  <Controller
                    name={fieldName}
                    control={control}
                    render={({ field }) => (
                      <Input.DecimalWithSeparator
                        error={errors[fieldName]}
                        name={fieldName}
                        decimalScale={decimalScale}
                        field={{
                          ...field,
                          name: fieldName as "value",
                          onChange: (e) => {
                            handleOnChange(e, field.onChange);
                          },
                        }}
                        iconLeft={
                          <FeeDiscountIcon
                            feeDiscountType={type as FeeDiscountType}
                          />
                        }
                        label="value"
                      />
                    )}
                  />
                </VStack>
              </HStack>
            </GridItem>
          </SimpleGrid>
        </VStack>
      </CardBody>
    </Card>
  );
}
