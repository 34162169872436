import { MenuItem, Text, useDisclosure } from "@chakra-ui/react";
import { InstitutionEntityFragment, UserEntityFragment } from "@/graphql";
import { useTranslation } from "react-i18next";
import { GenerateManualLinkModal } from "./GenerateManualLinkModal";

type GenerateManualLinkMenuItemProps = {
  entity: UserEntityFragment | InstitutionEntityFragment;
};

export default function GenerateManualLinkMenuItem({
  entity,
}: GenerateManualLinkMenuItemProps) {
  const { t } = useTranslation();
  const { onOpen, ...otherModalProps } = useDisclosure();

  return (
    <>
      <MenuItem onClick={() => onOpen()}>
        <Text>{t(`generate_manual_link`)}</Text>
      </MenuItem>
      <GenerateManualLinkModal entityId={entity.id} {...otherModalProps} />
    </>
  );
}
