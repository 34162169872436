import { Input } from "@/modules/Form";
import { Box, HStack, VStack, Wrap } from "@chakra-ui/react";
import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SimpleTableQueryVariables, TableContext } from "@/modules/SimpleTable";
import { UseFormRegister } from "react-hook-form";
import { RESET_PAGINATION_PARAMS } from "@/constants";
import { FilterTag } from "@/modules/FilterTag";
import {
  useCompaniesCountriesFilterButtonAllCountriesQuery,
  Country,
  CompanyStatus,
} from "@/graphql";
import { getCountryList } from "@/modules/Country";
import ClearFiltersButton from "./ClearFiltersButton";
import CompaniesCountriesFilterButton from "./CompaniesCountriesFilterButton";
import CompaniesStatusFilterButton from "./CompaniesStatusFilterButton";

const PREFERRED_COUNTRIES = [`CA`, `US`];

function CompaniesTableSearch({
  register,
}: {
  register: UseFormRegister<{ searchText: string }>;
}) {
  const { t } = useTranslation();

  return (
    <Input.Search
      placeholder={t(`search_by_company_name`)}
      w={430}
      {...register(`searchText`)}
    />
  );
}

function CompaniesFilters() {
  const { register, variables, setVariables } = useContext(TableContext);

  const [data] = useCompaniesCountriesFilterButtonAllCountriesQuery();

  const countryList = getCountryList();

  const allCountries: Country[] = useMemo(() => {
    if (!data.data) {
      return [];
    }
    return data.data.allCompanyCountries;
  }, [data]);

  const countries = useMemo(
    () => [
      ...allCountries
        .filter(
          ({ name }) =>
            PREFERRED_COUNTRIES.includes(name) &&
            countryList.getName(name)?.toLowerCase(),
        )
        .sort((a, b) => (a.name < b.name ? -1 : 1)),
      ...allCountries
        .filter(
          ({ name }) =>
            !PREFERRED_COUNTRIES.includes(name) &&
            countryList.getName(name)?.toLowerCase(),
        )
        .sort((a, b) => (a.name < b.name ? -1 : 1)),
    ],
    [allCountries, countryList],
  );

  const setFilters = useCallback(
    (variables: SimpleTableQueryVariables) => {
      setVariables((prevVariables) => ({
        ...prevVariables,
        filterBy: {
          ...prevVariables.filterBy,
          ...variables.filterBy,
        },
        ...RESET_PAGINATION_PARAMS,
      }));
    },
    [setVariables],
  );

  const resetFilters = useCallback(() => {
    setVariables((prevVariables) => ({
      ...prevVariables,
      filterBy: {
        searchText: prevVariables.filterBy?.searchText,
      },
      ...RESET_PAGINATION_PARAMS,
    }));
  }, [setVariables]);

  const { filterBy } = variables;

  const selectedCountries = useMemo(
    () => allCountries.filter(({ id }) => filterBy?.countryIds?.includes(id)),
    [allCountries, filterBy?.countryIds],
  );

  const selectedStatuses = useMemo(
    () =>
      Object.values(CompanyStatus).filter(
        (status) => filterBy?.statuses?.includes(status as CompanyStatus),
      ),
    [filterBy?.statuses],
  );

  const removeStatus = useCallback(
    (status: CompanyStatus) => {
      const updatedStatuses =
        filterBy?.statuses?.filter((s) => s !== status) || [];
      setFilters({
        filterBy: {
          ...{
            statuses: updatedStatuses.length > 0 ? updatedStatuses : undefined,
          },
        },
      });
    },
    [filterBy, setFilters],
  );

  const removeCountry = useCallback(
    (id: string) => {
      setFilters({
        filterBy: {
          countryIds: filterBy?.countryIds?.filter(
            (countryId) => countryId !== id,
          ),
        },
      });
    },
    [filterBy?.countryIds, setFilters],
  );

  const hasFilters =
    selectedCountries.length > 0 || selectedStatuses.length > 0;

  return (
    <VStack spacing={4}>
      <HStack justifyContent="space-between" w="full">
        <CompaniesTableSearch register={register} />
        <Box flexGrow={1} />
        <CompaniesCountriesFilterButton
          variables={variables}
          setFilters={setFilters}
          countries={countries}
          countryList={countryList}
          preferredCountries={PREFERRED_COUNTRIES}
        />
        <CompaniesStatusFilterButton
          variables={variables}
          setFilters={setFilters}
        />
      </HStack>
      {hasFilters && (
        <HStack w="full">
          <Wrap>
            {selectedCountries.map(({ name, id }) => (
              <FilterTag
                key={name}
                name={countryList.getName(name) || name}
                onClick={() => removeCountry(id)}
              />
            ))}
            {selectedStatuses.map((status) => (
              <FilterTag
                key={status}
                name={status.toLowerCase()}
                textTransform="capitalize"
                onClick={() => removeStatus(status)}
              />
            ))}
            <ClearFiltersButton resetFilters={resetFilters} />
          </Wrap>
        </HStack>
      )}
    </VStack>
  );
}

export default CompaniesFilters;
