import { DateTime } from "luxon";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  ActivityLogActorType,
  TransactionActivityLogFragment,
  TransactionDiscussionMessageActivityLogFragment,
  useTransactionActivityQuery,
} from "@/graphql";
import { match } from "ts-pattern";
import { StatusBadge } from "@/modules/StatusBadge";
import { CardTable } from "@/modules/CardTable";
import { ErrorWrapper } from "@/modules/Error";
import { formatToLocalTimezone } from "@/modules/NumeralFormat";
import { formatAndCapitalize } from "@/modules/StringFormat";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Grid,
  HStack,
  SimpleGrid,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Minus, Plus, WarningCircle } from "@phosphor-icons/react";

import { TransactionActivitySkeleton } from "./TransactionActivitySkeleton";

interface TransactionActivityProps {
  transactionId: string;
}

interface TransactionActivityCellProps {
  value?: string | null;
  isNotificationMuted?: boolean | null;
}

interface TransactionNotesCellProps {
  value?: string | null;
  muteNotificationReason?: string | null;
}

interface TransactionActivityUserCellProps {
  value: {
    entryId: string;
    firstName?: string;
    lastName?: string;
    actorTypes: ActivityLogActorType[];
  };
  showName?: boolean;
}

const COLLAPSED_MAX_ROWS = 5;

function DateCell({ value }: TransactionActivityCellProps) {
  return (
    <CardBody p={4} borderRight="1px" borderRightColor="grey.50">
      <Box display="flex" alignItems="center" h="full" w="full">
        <Text textStyle="colfax-14-regular" color="grey.600">
          {formatToLocalTimezone(value)}
        </Text>
      </Box>
    </CardBody>
  );
}

function UserCell({
  value,
  showName = true,
}: TransactionActivityUserCellProps) {
  return (
    <CardBody
      as={VStack}
      alignItems="flex-start"
      justifyContent="center"
      p={4}
      borderRight="1px"
      borderRightColor="grey.50"
    >
      <HStack>
        {value.actorTypes.map((actorType) => {
          const pillBackground = match(actorType)
            .with(ActivityLogActorType.Hiive, () => `sky.200`)
            .with(ActivityLogActorType.Buyer, () => `plum.50`)
            .with(ActivityLogActorType.Seller, () => `olive.100`)
            .otherwise(() => `grey.100`);

          return (
            <Text
              px={2}
              py={0.5}
              borderRadius="lg"
              textStyle="colfax-12-regular"
              bg={pillBackground}
              color="grey.900"
              key={value.entryId}
            >
              {formatAndCapitalize(actorType)}
            </Text>
          );
        })}
      </HStack>
      {showName && (
        <Text textStyle="colfax-12-regular">{`${value.firstName} ${value.lastName}`}</Text>
      )}
    </CardBody>
  );
}

function ActivityCell({
  value,
  isNotificationMuted,
}: TransactionActivityCellProps) {
  const { t } = useTranslation();

  return (
    <CardBody p={4} borderRight="1px" borderRightColor="grey.50">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        h="full"
        w="full"
      >
        {isNotificationMuted && (
          <VStack alignItems="flex-start">
            <StatusBadge
              variant="yellow-bordered"
              icon={<WarningCircle weight="fill" width={12} height={12} />}
              title={t(`notification_muted`)}
              mb={1}
            />
          </VStack>
        )}
        <Text textStyle="colfax-14-medium">{value}</Text>
      </Box>
    </CardBody>
  );
}

function ActivityDetailCell({ value }: TransactionActivityCellProps) {
  const isDate = DateTime.fromISO(value || ``).isValid;
  return (
    <CardBody p={4} borderRight="1px" borderRightColor="grey.50">
      <Box display="flex" alignItems="center" h="full" w="full">
        <Text textStyle="colfax-14-medium" textTransform="none">
          {isDate ? formatToLocalTimezone(value) : value}
        </Text>
      </Box>
    </CardBody>
  );
}

function NotesCell({
  value,
  muteNotificationReason,
}: TransactionNotesCellProps) {
  const showNotesLabels = !!muteNotificationReason;

  return (
    <CardBody p={4}>
      <Stack>
        {showNotesLabels && (
          <Text textStyle="colfax-14-regular" color="grey.600">
            <span>
              <b>Muted: </b>
            </span>
            {muteNotificationReason}
          </Text>
        )}
        <Text textStyle="colfax-14-regular" color="grey.600">
          {showNotesLabels && !!value && (
            <span>
              <b>Notes: </b>
            </span>
          )}
          {value}
        </Text>
      </Stack>
    </CardBody>
  );
}

function TransactionActivityTableRow({
  row,
}: {
  readonly row: TransactionActivityLogFragment;
}) {
  const actor = {
    entryId: row.id,
    firstName: row.actor?.firstName || ``,
    lastName: row.actor?.lastName || ``,
    actorTypes: row.actorTypes,
  };

  return (
    <>
      <DateCell value={row.insertedAt} />
      <UserCell value={actor} />
      <ActivityCell
        value={row.change}
        isNotificationMuted={!!row.muteNotificationReason}
      />
      <ActivityDetailCell value={row.changeDetail} />
      <NotesCell
        value={row.notes}
        muteNotificationReason={row.muteNotificationReason}
      />
    </>
  );
}

function TransactionActivityTableRows({
  rows,
}: {
  readonly rows: TransactionActivityLogFragment[];
}) {
  return (
    <SimpleGrid gridTemplateColumns="1fr 1fr 2fr 2.5fr 2.5fr">
      {rows.map((r) => (
        <TransactionActivityTableRow row={r} key={r.id} />
      ))}
    </SimpleGrid>
  );
}

function TransactionActivityTableHeaders() {
  const { t } = useTranslation();

  return (
    <Grid templateColumns="1fr 1fr 2fr 2.5fr 2.5fr">
      <CardHeader>{t`date`}</CardHeader>
      <CardHeader>{t`user`}</CardHeader>
      <CardHeader>{t`activity`}</CardHeader>
      <CardHeader>{t`activity_detail`}</CardHeader>
      <CardHeader>{t`notes`}</CardHeader>
    </Grid>
  );
}

function TransactionActivityTable({
  rows,
}: {
  readonly rows: TransactionActivityLogFragment[];
}) {
  return (
    <CardTable w="full" columns={1} borderBottom="unset">
      <Card variant="table">
        <TransactionActivityTableHeaders />
        <TransactionActivityTableRows rows={rows} />
      </Card>
    </CardTable>
  );
}

function TransactionDiscussionActivityTableRow({
  row,
}: {
  readonly row: TransactionDiscussionMessageActivityLogFragment;
}) {
  return (
    <>
      <DateCell value={row.insertedAt} />
      <UserCell
        value={{
          entryId: row.id,
          actorTypes: row.senderTypes.map((type) => {
            if (type === `buyer`) return ActivityLogActorType.Buyer;
            if (type === `broker`) return ActivityLogActorType.Broker;
            if (type === `hiive`) return ActivityLogActorType.Hiive;

            return ActivityLogActorType.Seller;
          }),
        }}
        showName={false}
      />
      <NotesCell value={row.messageBody} />
    </>
  );
}

function TransactionDiscussionActivityTableRows({
  rows,
  isExpanded,
}: {
  readonly rows: TransactionDiscussionMessageActivityLogFragment[];
  readonly isExpanded: boolean;
}) {
  const { t } = useTranslation();

  if (rows.length === 0) {
    return (
      <HStack p={4} justifyContent="center">
        <Text
          color="grey.300"
          textStyle="colfax-14-regular"
        >{t`no_messages`}</Text>
      </HStack>
    );
  }
  return (
    <SimpleGrid gridTemplateColumns="1fr 1fr 5fr">
      {rows
        .filter((_, i) => (isExpanded ? true : i < COLLAPSED_MAX_ROWS))
        .map((r) => (
          <TransactionDiscussionActivityTableRow row={r} key={r.id} />
        ))}
    </SimpleGrid>
  );
}

function TransactionDiscussionActivityTableHeaders() {
  const { t } = useTranslation();

  return (
    <Grid templateColumns="1fr 1fr 5fr">
      <CardHeader>{t`date`}</CardHeader>
      <CardHeader>{t`user`}</CardHeader>
      <CardHeader>{t`message`}</CardHeader>
    </Grid>
  );
}

function TransactionDiscussionActivityTable({
  rows,
}: {
  readonly rows: TransactionDiscussionMessageActivityLogFragment[];
}) {
  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState(false);
  const showExpandButtonRow = rows.length > COLLAPSED_MAX_ROWS;
  return (
    <CardTable w="full" columns={1} borderBottom="unset">
      <Card variant="table">
        <TransactionDiscussionActivityTableHeaders />
        <TransactionDiscussionActivityTableRows
          rows={rows}
          isExpanded={isExpanded}
        />
        {showExpandButtonRow && (
          <CardHeader w="full">
            <HStack color="grey.900" w="full" justifyContent="flex-end">
              <Button
                aria-label="view-more-button"
                variant="ghost"
                mt={1}
                size="xs"
                textStyle="colfax-16-medium"
                textTransform="uppercase"
                rightIcon={isExpanded ? <Minus /> : <Plus />}
                onClick={() => setIsExpanded(!isExpanded)}
              >
                <Text>{t(isExpanded ? `view_less` : `view_more`)}</Text>
              </Button>
            </HStack>
          </CardHeader>
        )}
      </Card>
    </CardTable>
  );
}

function NewBadge() {
  const { t } = useTranslation();

  return (
    <Text
      px={2}
      py={0.5}
      borderRadius="lg"
      textStyle="colfax-12-regular"
      bg="archived"
      color="white"
    >{t`new`}</Text>
  );
}

interface TransactionActivityContentProps {
  activityLog: TransactionActivityLogFragment[];
  discussionMessages: TransactionDiscussionMessageActivityLogFragment[];
}

function TransactionActivityContent({
  activityLog,
  discussionMessages,
}: TransactionActivityContentProps) {
  const { t } = useTranslation();

  const showNewMessageBadge = !!discussionMessages.find(
    (message) =>
      DateTime.fromISO(message.insertedAt).diffNow(`days`).days >= -1,
  );

  return (
    <VStack gap={4}>
      <Card w="full">
        <CardHeader as={HStack} gap={4} alignItems="center">
          <Text textStyle="colfax-22-medium">
            {t(`transaction_activity_log`)}
          </Text>
        </CardHeader>
        <CardBody>
          <TransactionActivityTable rows={activityLog} />
        </CardBody>
      </Card>
      <Card w="full">
        <CardHeader
          as={HStack}
          gap={2}
          alignItems="center"
          justifyContent="flex-start"
        >
          <Text textStyle="colfax-22-medium">{t(`messaging`)}</Text>
          {showNewMessageBadge && <NewBadge />}
        </CardHeader>
        <CardBody>
          <TransactionDiscussionActivityTable rows={discussionMessages} />
        </CardBody>
      </Card>
    </VStack>
  );
}

export default function TransactionActivity({
  transactionId,
}: TransactionActivityProps) {
  const [{ data, error, fetching }] = useTransactionActivityQuery({
    variables: { transactionId },
    requestPolicy: `cache-and-network`,
  });

  if (fetching) {
    return <TransactionActivitySkeleton />;
  }

  const { transaction } = data || {};

  if (!transaction) {
    return null;
  }

  const { activityLog, discussionMessages } = transaction;

  return (
    <ErrorWrapper error={error?.message}>
      {data?.transaction ? (
        <TransactionActivityContent
          activityLog={activityLog}
          discussionMessages={discussionMessages}
        />
      ) : null}
    </ErrorWrapper>
  );
}
