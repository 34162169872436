import {
  TransactionPageV2ExecutionStepFragment,
  ExecutionStepType,
  ExecutionStepStatus,
  WorkflowEdgeFragment,
} from "@/graphql";

export function updateInProgressMilestone(
  steps: TransactionPageV2ExecutionStepFragment[],
) {
  const milestones = steps.filter(
    (step) => step.type === ExecutionStepType.Milestone,
  );

  const inProgressMilestone = milestones.find((milestone, index) => {
    const previous = milestones[index - 1];
    return (
      previous &&
      previous.status === ExecutionStepStatus.Completed &&
      milestone.status === ExecutionStepStatus.Pending
    );
  });

  return steps.map((step) => {
    if (step.id === inProgressMilestone?.id) {
      return {
        ...step,
        status: ExecutionStepStatus.InProgress,
      };
    }
    return step;
  });
}

export function replaceIntermediaryEdges(
  edges: WorkflowEdgeFragment[],
  steps: TransactionPageV2ExecutionStepFragment[],
  intermediaryTypeName: string,
): WorkflowEdgeFragment[] {
  const intermediaryStepIds = new Set(
    steps
      .filter((step) => step.config.__typename === intermediaryTypeName)
      .map((step) => step.id),
  );

  const { edgesToAdd, edgesToRemoveIds } = edges.reduce(
    (acc, edge) => {
      const fromId = edge.fromId ?? ``;
      const toId = edge.toId ?? ``;
      const fromStepIsIntermediary = intermediaryStepIds.has(fromId);
      const toStepIsIntermediary = intermediaryStepIds.has(toId);

      if (fromStepIsIntermediary || toStepIsIntermediary) {
        const updatedEdgesToRemoveIds = new Set(acc.edgesToRemoveIds).add(
          edge.id,
        );
        let updatedEdgesToAdd = acc.edgesToAdd;

        if (!fromStepIsIntermediary && toStepIsIntermediary) {
          const newEdges = edges
            .filter(
              (e) =>
                e.fromId === toId && !intermediaryStepIds.has(e.toId ?? ``),
            )
            .map((intermediaryEdge) => ({
              id: `${fromId}-${intermediaryEdge.toId}`,
              fromId,
              toId: intermediaryEdge.toId ?? ``,
            }));

          updatedEdgesToAdd = [...acc.edgesToAdd, ...newEdges];
          newEdges.forEach((intermediaryEdge) =>
            updatedEdgesToRemoveIds.add(intermediaryEdge.id),
          );
        }

        if (fromStepIsIntermediary && !toStepIsIntermediary) {
          const newEdges = edges
            .filter(
              (e) =>
                e.toId === fromId && !intermediaryStepIds.has(e.fromId ?? ``),
            )
            .map((intermediaryEdge) => ({
              id: `${intermediaryEdge.fromId}-${toId}`,
              fromId: intermediaryEdge.fromId ?? ``,
              toId,
            }));

          updatedEdgesToAdd = [...acc.edgesToAdd, ...newEdges];
          newEdges.forEach((intermediaryEdge) =>
            updatedEdgesToRemoveIds.add(intermediaryEdge.id),
          );
        }

        return {
          edgesToAdd: updatedEdgesToAdd,
          edgesToRemoveIds: updatedEdgesToRemoveIds,
        };
      }

      return acc;
    },
    {
      edgesToAdd: [] as WorkflowEdgeFragment[],
      edgesToRemoveIds: new Set<string>(),
    },
  );

  return [
    ...edges.filter((edge) => !edgesToRemoveIds.has(edge.id)),
    ...edgesToAdd,
  ];
}
