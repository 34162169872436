import {
  Menu,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
  VStack,
  Divider,
} from "@chakra-ui/react";
import { useCallback } from "react";
import { Table } from "@/modules/Table";
import { Trans, useTranslation } from "react-i18next";
import {
  BankAccountRequestStatus,
  BankAccountVerificationStatus,
  TransactionsStatusActionsTransactionFragment,
  TransactionState,
  useCancelBankAccountVerificationMutation,
  useManuallyApproveBankAccountVerificationMutation,
  useUpdateBankAccountRequestStatusMutation,
  useUpdateTransactionInPipelineMutation,
} from "@/graphql";
import { ConfirmModal } from "@/modules/Modal";
import { useSellerExternalAccountEnabled } from "@/modules/LaunchDarkly";
import { useCustomToast } from "@/modules/Toast";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import MuteNotificationWithReasonSection, {
  muteNotificationValidationSchema,
  MuteNotificationReasonType,
} from "./MuteNotificationWithReasonSection";

function TransactionInPipelineAction({
  transaction,
}: {
  readonly transaction: TransactionsStatusActionsTransactionFragment;
}) {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [{ fetching }, updateTransactionInPipelineMutation] =
    useUpdateTransactionInPipelineMutation();
  const { inPipeline } = transaction;
  const updateTransactionInPipeline = async () => {
    await updateTransactionInPipelineMutation({
      transactionId: transaction.id,
      input: {
        inPipeline: !inPipeline,
      },
    });

    onClose();
  };

  return (
    <MenuGroup>
      <MenuItem onClick={onOpen}>
        {t(
          inPipeline
            ? `mark_transaction_as_on_hold`
            : `mark_transaction_as_active`,
        )}
      </MenuItem>
      <ConfirmModal
        title={t(`confirm_pipeline_status`)}
        body={
          <VStack spacing={5} alignItems="flex-start">
            <Text>
              <Trans
                i18nKey={
                  inPipeline
                    ? `confirm_pipeline_on_hold_status_modal_body`
                    : `confirm_pipeline_in_pipeline_status_modal_body`
                }
                components={{ bold: <strong /> }}
              />
            </Text>
          </VStack>
        }
        onConfirm={updateTransactionInPipeline}
        loading={fetching}
        isOpen={isOpen}
        onClose={onClose}
      />
    </MenuGroup>
  );
}

function CancelSellerBankAccountVerificationAction({
  bankAccountId,
}: {
  readonly bankAccountId: string;
}) {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [{ fetching }, cancelBankAccountVerificationMutation] =
    useCancelBankAccountVerificationMutation();
  const { errorToast, successToast } = useCustomToast();

  const onCancelAccountVerification = async () => {
    const { data } = await cancelBankAccountVerificationMutation({
      bankAccountId,
    });
    onClose();

    const errors = data?.cancelBankAccountVerification.errors;

    if (!!errors && errors.length > 0) {
      errorToast();
      return;
    }

    successToast();
    onClose();
  };

  return (
    <MenuGroup>
      <MenuItem color="archived" onClick={onOpen}>
        {t(`cancel_account_verification`)}
      </MenuItem>
      <ConfirmModal
        title={t(`cancel_account_verification_modal_title`)}
        body={
          <VStack spacing={5} alignItems="flex-start">
            <Text>{t`cancel_account_verification_modal_body`}</Text>
          </VStack>
        }
        onConfirm={onCancelAccountVerification}
        loading={fetching}
        isOpen={isOpen}
        onClose={onClose}
      />
    </MenuGroup>
  );
}

function ApproveManualBankAccountVerificationAction({
  bankAccountId,
}: {
  readonly bankAccountId: string;
}) {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [{ fetching }, approveManualBankAccountVerification] =
    useManuallyApproveBankAccountVerificationMutation();
  const { errorToast, successToast } = useCustomToast();

  const {
    handleSubmit,
    register,
    resetField,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(muteNotificationValidationSchema),
    defaultValues: {
      muteNotificationReason: null,
    },
  });

  const onManuallyApproveAccountVerification: SubmitHandler<MuteNotificationReasonType> =
    useCallback(
      async (data) => {
        const response = await approveManualBankAccountVerification({
          bankAccountId,
          muteNotificationReason: data.muteNotificationReason,
        });

        const resErrors = response.data?.manuallyApproveBankAccount.errors;

        if (!!resErrors && resErrors.length > 0) {
          errorToast();
          return;
        }

        successToast();
        onClose();
      },
      [
        approveManualBankAccountVerification,
        bankAccountId,
        errorToast,
        successToast,
        onClose,
      ],
    );

  return (
    <MenuGroup>
      <MenuItem onClick={onOpen}>{t(`approve_account_verification`)}</MenuItem>
      <ConfirmModal
        title={t(`approve_account_verification_modal_title`)}
        body={
          <VStack spacing={5} alignItems="flex-start">
            <Text>{t`approve_account_verification_modal_body`}</Text>
            <Divider borderColor="grey.200" borderWidth="1" />
            <MuteNotificationWithReasonSection
              name="muteNotificationReason"
              setValue={setValue}
              error={errors.muteNotificationReason}
              register={register}
              resetField={resetField}
            />
          </VStack>
        }
        onConfirm={handleSubmit(onManuallyApproveAccountVerification)}
        loading={fetching}
        isOpen={isOpen}
        onClose={onClose}
      />
    </MenuGroup>
  );
}

function RequestBankAccountCollectionAction({
  transaction,
}: {
  readonly transaction: TransactionsStatusActionsTransactionFragment;
}) {
  const { t } = useTranslation();
  const [_, updateTransactionBankAccountRequestStatusMutation] =
    useUpdateBankAccountRequestStatusMutation();
  const { errorToast, successToast } = useCustomToast();

  const onRequestBankAccountCollection = async () => {
    const { data } = await updateTransactionBankAccountRequestStatusMutation({
      transactionId: transaction.id,
      requestStatus: BankAccountRequestStatus.Requested,
    });

    const errors = data?.updateBankAccountRequestStatus.errors;

    if (!!errors) {
      errorToast();
      return;
    }

    successToast();
  };

  return (
    <MenuGroup>
      <MenuItem
        onClick={onRequestBankAccountCollection}
      >{t`request_bank_account_collection`}</MenuItem>
    </MenuGroup>
  );
}

function CancelBankAccountCollectionAction({
  transaction,
}: {
  readonly transaction: TransactionsStatusActionsTransactionFragment;
}) {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { errorToast, successToast } = useCustomToast();

  const [{ fetching }, updateBankAccountRequestStatusMutation] =
    useUpdateBankAccountRequestStatusMutation();

  const {
    handleSubmit,
    register,
    reset,
    resetField,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(muteNotificationValidationSchema),
    defaultValues: {
      muteNotificationReason: null,
    },
  });

  const onCancelBankAccountCollection: SubmitHandler<MuteNotificationReasonType> =
    useCallback(
      async (data) => {
        const response = await updateBankAccountRequestStatusMutation({
          transactionId: transaction.id,
          requestStatus: BankAccountRequestStatus.Cancelled,
          muteNotificationReason: data.muteNotificationReason,
        });

        const resErrors = response.data?.updateBankAccountRequestStatus.errors;

        if (!!resErrors) {
          errorToast();
          return;
        }

        successToast();
        onClose();
      },
      [
        transaction.id,
        updateBankAccountRequestStatusMutation,
        errorToast,
        successToast,
        onClose,
      ],
    );

  const onCloseResetForm = () => {
    reset();
    onClose();
  };

  return (
    <MenuGroup>
      <MenuItem
        color="archived"
        onClick={onOpen}
      >{t`cancel_bank_account_collection`}</MenuItem>
      <ConfirmModal
        title={t(`cancel_bank_account_collection`)}
        body={
          <VStack spacing={5} alignItems="flex-start">
            <Text>{t`cancel_bank_account_collection_modal_body`}</Text>
            <Divider borderColor="grey.200" borderWidth="1" />
            <MuteNotificationWithReasonSection
              name="muteNotificationReason"
              setValue={setValue}
              error={errors.muteNotificationReason}
              register={register}
              resetField={resetField}
            />
          </VStack>
        }
        onConfirm={handleSubmit(onCancelBankAccountCollection)}
        loading={fetching}
        isOpen={isOpen}
        onClose={onCloseResetForm}
      />
    </MenuGroup>
  );
}

function TransactionStatusActions({
  transaction,
}: {
  transaction: TransactionsStatusActionsTransactionFragment;
}) {
  const isSEAEnabled = useSellerExternalAccountEnabled();

  const shouldShowCancelBankAccountVerification =
    transaction.sellerBankAccount?.id &&
    [
      BankAccountVerificationStatus.PendingVerification,
      BankAccountVerificationStatus.Manual,
    ].includes(transaction.sellerBankAccount?.verificationStatus);

  const shouldShowApproveManualBankAccountVerification =
    transaction.sellerBankAccount?.id &&
    [BankAccountVerificationStatus.Manual].includes(
      transaction.sellerBankAccount?.verificationStatus,
    );

  const requestableStates = [
    TransactionState.InReview,
    TransactionState.BidAccepted,
    TransactionState.IssuerPendingApproval,
    TransactionState.AwaitingClosing,
    TransactionState.IssuerApproved,
    TransactionState.ClosedFeePending,
    TransactionState.ClosedFeePaid,
  ];

  const isNotRequested =
    transaction.sellerBankAccountRequestStatus ===
    BankAccountRequestStatus.NotRequested;

  const sellerHasEntity = !!transaction.sellerEntity?.id;

  const shouldShowRequestBankAccountCollection =
    sellerHasEntity &&
    isNotRequested &&
    requestableStates.includes(transaction.state) &&
    !transaction.representedSellerId &&
    !transaction.sellerBrokerId;

  const shouldShowCancelBankAccountCollection =
    !transaction.sellerBankAccount &&
    transaction.sellerBankAccountRequestStatus ===
      BankAccountRequestStatus.Requested;

  return (
    <Menu>
      <Table.ActionButton showTitle />
      <MenuList>
        <TransactionInPipelineAction transaction={transaction} />
        {isSEAEnabled && shouldShowRequestBankAccountCollection && (
          <RequestBankAccountCollectionAction transaction={transaction} />
        )}
        {isSEAEnabled && shouldShowCancelBankAccountCollection && (
          <CancelBankAccountCollectionAction transaction={transaction} />
        )}
        {isSEAEnabled && !!shouldShowApproveManualBankAccountVerification && (
          <ApproveManualBankAccountVerificationAction
            bankAccountId={transaction!.sellerBankAccount!.id}
          />
        )}
        {isSEAEnabled && !!shouldShowCancelBankAccountVerification && (
          <CancelSellerBankAccountVerificationAction
            bankAccountId={transaction!.sellerBankAccount!.id}
          />
        )}
      </MenuList>
    </Menu>
  );
}

export default TransactionStatusActions;
