import { CardTable } from "@/modules/CardTable";
import { useColors } from "@/modules/Theme";
import {
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Skeleton } from "@/modules/Skeleton";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

function GreyCard({ children }: { readonly children: ReactNode }) {
  const [grey50] = useColors([`grey.50`]);
  return (
    <Card bg="grey.25" border={`solid 1px ${grey50}`} minH={188}>
      <CardBody p={0} py={4} px={4}>
        <VStack alignItems="flex-start" h="full">
          {children}
        </VStack>
      </CardBody>
    </Card>
  );
}

export function TransactionOverviewSkeleton() {
  const { t } = useTranslation();

  return (
    <VStack gap={4}>
      <Card w="full">
        <CardHeader as={HStack} gap={4} alignItems="center">
          <Text textStyle="colfax-22-medium">{t(`transaction`)}</Text>
          <Skeleton h={4} w={100} />
          <Box flexGrow={1} />
          <HStack spacing={4}>
            <HStack>
              <Skeleton h={4} w={100} />
              <Text color="grey.300" mx={0.5}>
                |
              </Text>
              <Skeleton h={4} w={100} />
              <Skeleton h={10} w={100} />
            </HStack>
          </HStack>
        </CardHeader>
        <CardBody>
          <VStack alignItems="flex-start" gap={6}>
            <VStack alignItems="flex-start">
              <HStack alignItems="center" gap={4}>
                <Skeleton h={7} w={7} />
                <Skeleton h={4} w={219} />
              </HStack>
              <Skeleton h={3} w={400} my={2} />
            </VStack>

            <CardTable w="full" columns={3}>
              <Card variant="table">
                <CardHeader>{t(`shares`)}</CardHeader>
                <CardBody>
                  <VStack spacing={2} align="flex-start">
                    <Skeleton h={4} w="full" />
                    <Skeleton h={2} w={10} />
                  </VStack>
                </CardBody>
              </Card>
              <Card variant="table">
                <CardHeader>{t(`price`)}</CardHeader>
                <CardBody>
                  <Skeleton h={4} w="full" />
                </CardBody>
              </Card>
              <Card variant="table">
                <CardHeader>{t(`total`)}</CardHeader>
                <CardBody>
                  <Skeleton h={4} w="full" />
                </CardBody>
              </Card>
            </CardTable>

            <CardTable w="full" columns={2}>
              <Card variant="table" bg="white" justifyContent="flex-start">
                <CardHeader>{t(`buyer`)}</CardHeader>

                <Card m={10} gap={6}>
                  <GreyCard>
                    <Skeleton h={4} w={40} />
                    <Skeleton h={4} w={60} />
                    <Skeleton h={4} w={270} />
                    <Skeleton h={4} w={310} />
                  </GreyCard>

                  <GreyCard>
                    <Skeleton h={4} w={40} />
                    <Skeleton h={4} w={60} />
                    <Skeleton h={4} w={270} />
                    <Skeleton h={4} w={310} />
                  </GreyCard>
                </Card>
              </Card>

              <Card variant="table" bg="white" justifyContent="flex-start">
                <CardHeader>{t(`seller`)}</CardHeader>
                <Card m={10} gap={6}>
                  <GreyCard>
                    <Skeleton h={4} w={40} />
                    <Skeleton h={4} w={60} />
                    <Skeleton h={4} w={270} />
                    <Skeleton h={4} w={310} />
                  </GreyCard>
                </Card>
              </Card>
            </CardTable>
          </VStack>
        </CardBody>
      </Card>

      <Card w="full">
        <CardHeader as={HStack} gap={4} alignItems="center">
          <Text textStyle="colfax-22-medium">
            {`${t(`proceeds_for_transaction`)} `}
          </Text>
        </CardHeader>
        <Card padding={6}>
          <CardTable w="full" columns={1}>
            <Card variant="table">
              <CardHeader>{t(`proceeds`)}</CardHeader>
              <CardBody>
                <VStack alignItems="flex-start" gap={6} w="full">
                  <HStack justifyContent="space-between" w="full">
                    <Skeleton h={4} w={100} />
                    <Skeleton h={4} w={100} />
                  </HStack>
                  <HStack justifyContent="space-between" w="full">
                    <Skeleton h={4} w={100} />
                    <Skeleton h={4} w={100} />
                  </HStack>
                  <HStack justifyContent="space-between" w="full">
                    <Skeleton h={4} w={100} />
                    <Skeleton h={4} w={100} />
                  </HStack>
                </VStack>
              </CardBody>
              <CardFooter>
                <HStack justifyContent="space-between" w="full">
                  <Text textStyle="colfax-14-medium-uncased">
                    {t(`net_amount`)}
                  </Text>
                </HStack>
              </CardFooter>
            </Card>
          </CardTable>
        </Card>
      </Card>
    </VStack>
  );
}
