import React from "react";
import { Box, Card, CardHeader, HStack, Text } from "@chakra-ui/react";
import { ExecutionWorkflowCondition } from "@/graphql";
import { match } from "ts-pattern";
import { t } from "i18next";
import WorkflowConditionStatusIcon from "./WorkflowConditionStatusIcon";

export default function WorkflowConditionCard({
  condition,
}: {
  condition: ExecutionWorkflowCondition;
}) {
  const cardDetails = match(condition)
    .with(ExecutionWorkflowCondition.IssuerApprovalDeclined, () => ({
      borderColor: `red.700`,
      bgColor: `salmon.50`,
      text: t(`issuer_approval_declined`),
    }))
    .with(ExecutionWorkflowCondition.Rofr, () => ({
      borderColor: `pending`,
      bgColor: `yellow.100`,
      text: t(`issuer_exercised_rofr`),
    }))
    .otherwise(() => ({
      borderColor: `blue.50`,
      bgColor: `blue.50`,
      text: t(`unknown`),
    }));

  return (
    <Card w="full" border="1px solid" borderColor={cardDetails.borderColor}>
      <CardHeader
        px={2}
        py={2}
        borderBottom="none"
        bgColor={cardDetails.bgColor}
        borderRadius="md"
      >
        <HStack>
          <Box w={6} h={6} position="relative">
            <WorkflowConditionStatusIcon
              condition={condition}
              background={cardDetails.bgColor}
            />
          </Box>
          <Text textStyle="heading-3xs">{cardDetails.text}</Text>
        </HStack>
      </CardHeader>
    </Card>
  );
}
