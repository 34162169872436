import i18next from "i18next";
import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { RESET_PAGINATION_PARAMS } from "@/constants";
import {
  FeeDiscountPageApplicationsTableContext,
  mapFeeDiscountApplications,
  useRowClick,
} from "@/features/FeeDiscount";
import {
  FeeDiscountApplicationSortableField,
  FeeDiscountApplicationState,
  FeeDiscountPageApplicationFragment,
  SortDirection,
} from "@/graphql";
import { formatDate } from "@/modules/NumeralFormat";
import {
  SimpleTable,
  SimpleTableQueryVariables,
  SimpleTableUrlParams,
  TableContext,
} from "@/modules/SimpleTable";
import { Table, TableColumns } from "@/modules/Table";
import { Tab } from "@chakra-ui/react";

type FeeDiscountPageApplicationsColumnType = {
  appliedBy: string;
  companyName: string;
  dateApplied: string;
  dateRemoved: string;
  entityTypeLabel: string;
  expireAt: string;
  nameOrId?: string;
  removedBy: string;
};

export type FeeDiscountPageApplicationsTableDataType =
  FeeDiscountPageApplicationsColumnType &
    Pick<
      FeeDiscountPageApplicationFragment,
      "entityId" | "entityType" | "id" | "insertedAt" | "state"
    > & { nameSlug?: string };

export type FeeDiscountPageApplicationsUrlParams = SimpleTableUrlParams & {
  feeDiscountId: string;
  page: string;
  before: string;
  after: string;
  first: string;
  last: string;
};

const getColumns = (
  state: FeeDiscountApplicationState | unknown,
): TableColumns<
  FeeDiscountPageApplicationsColumnType,
  FeeDiscountPageApplicationsTableDataType
> => ({
  nameOrId: {
    header: () => i18next.t(`name_id`),
    enableSorting: false,
  },
  companyName: {
    header: () => i18next.t(`company_name`),
    enableSorting: false,
  },
  entityTypeLabel: {
    header: () => i18next.t(`applied_type`),
    enableSorting: false,
  },
  appliedBy: {
    header: () => i18next.t(`applied_by`),
    enableSorting: false,
  },
  dateApplied: {
    header: () => i18next.t(`date_applied`),
    cell: ({ cell }) => formatDate(cell.row.original.dateApplied),
    enableSorting: true,
  },
  removedBy: {
    header: () => i18next.t(`removed_by`),
    enableSorting: false,
    isVisible: state === FeeDiscountApplicationState.Inactive,
  },
  dateRemoved: {
    header: () => i18next.t(`date_removed`),
    cell: ({ cell }) => formatDate(cell.row.original.dateRemoved),
    enableSorting: true,
    isVisible: state === FeeDiscountApplicationState.Inactive,
  },
  expireAt: {
    header: () => i18next.t(`expires`),
    cell: ({ cell }) => formatDate(cell.row.original.expireAt),
    enableSorting: true,
  },
});

interface FeeDiscountPageApplicationsTableTabsProps {
  variables: SimpleTableQueryVariables;
  setVariables: (variables: SimpleTableQueryVariables) => void;
}

function FeeDiscountPageApplicationsTableTabs({
  variables,
  setVariables,
}: FeeDiscountPageApplicationsTableTabsProps) {
  const { t } = useTranslation();

  const index = useMemo(
    () =>
      variables.filterBy?.state === FeeDiscountApplicationState.Active ? 0 : 1,
    [variables],
  );

  const handleTabChange = useCallback(
    (index: number) => {
      setVariables({
        ...variables,
        ...RESET_PAGINATION_PARAMS,
        sortBy: {
          ...variables.sortBy,
          field:
            index === 0
              ? FeeDiscountApplicationSortableField.DateApplied
              : FeeDiscountApplicationSortableField.DateRemoved,
          direction: SortDirection.Desc,
        },
        filterBy: {
          ...variables.filterBy,
          state:
            index === 0
              ? FeeDiscountApplicationState.Active
              : FeeDiscountApplicationState.Inactive,
        },
      });
    },
    [setVariables, variables],
  );

  return (
    <Table.Tabs index={index} onChange={handleTabChange}>
      <Tab>{t(`active`)}</Tab>
      <Tab>{t(`inactive`)}</Tab>
    </Table.Tabs>
  );
}

export default function FeeDiscountPageApplicationsTable() {
  const { data } = useContext(FeeDiscountPageApplicationsTableContext);
  const { page, setSort, setVariables, sort, variables } =
    useContext(TableContext);

  const tableData = useMemo(() => mapFeeDiscountApplications(data), [data]);

  const { state } = variables.filterBy || {};

  const onRowClick = useRowClick();

  if (!data) {
    return null;
  }

  return (
    <>
      <FeeDiscountPageApplicationsTableTabs
        variables={variables}
        setVariables={setVariables}
      />
      <SimpleTable<
        SimpleTableQueryVariables,
        FeeDiscountPageApplicationsColumnType[],
        FeeDiscountPageApplicationsTableDataType[]
      >
        page={page}
        sort={sort}
        setSort={setSort}
        setVariables={setVariables}
        columns={getColumns(state)}
        tableData={tableData.feeDiscountApplications}
        pageInfo={tableData.pageInfo}
        totalCount={tableData.totalCount}
        onRowClick={onRowClick}
        error={data.error}
        loading={!tableData.pageInfo && data.fetching}
      />
    </>
  );
}
