import { useColors } from "@/modules/Theme";
import {
  Card,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { DotsThreeOutlineVertical } from "@phosphor-icons/react";
import { Handle, Position, useNodes, useReactFlow } from "@xyflow/react";
import { useTranslation } from "react-i18next";

import { MouseEvent } from "react";
import { ExecutionStepStatus } from "@/graphql";
import { match } from "ts-pattern";
import { StepIcon } from "@/modules/Step";
import { WorkflowStepNodeProps } from "./types";

const statusColor = (data: WorkflowStepNodeProps["data"]) =>
  match(data)
    .with({ status: ExecutionStepStatus.Pending }, () => ({
      bg: `white`,
      border: `grey.100`,
      opacity: 1,
    }))
    .with({ status: ExecutionStepStatus.InProgress }, () => ({
      bg: `sky.50`,
      border: `sky.500`,
      opacity: 1,
    }))
    .with({ status: ExecutionStepStatus.Completed }, () => ({
      bg: `grey.50`,
      border: `grey.100`,
      opacity: 1,
    }))
    .with({ status: ExecutionStepStatus.Terminated }, () => ({
      bg: `grey.25`,
      border: `grey.100`,
      opacity: 0.65,
    }))
    .otherwise(() => ({
      bg: `white`,
      border: `grey.100`,
      opacity: 1,
    }));

export function StepNode({
  data,
  deletable,
  isMenuOpen,
  onMenuOpen,
  onMenuClose,
}: WorkflowStepNodeProps & {
  isMenuOpen: boolean;
  onMenuOpen: (id: string) => void;
  onMenuClose: () => void;
}) {
  const [grey100] = useColors([`grey.100`]);
  const handleStyle = {
    background: grey100,
    borderRadius: `6px`,
    width: `8px`,
    height: `8px`,
    border: `1px solid white`,
  };

  const { deleteElements } = useReactFlow();

  const onToggle = (event?: MouseEvent) => {
    event?.preventDefault();
    event?.stopPropagation();

    return isMenuOpen ? onMenuClose() : onMenuOpen(data.id);
  };

  const { t } = useTranslation();

  const nodes = useNodes();
  const otherSelected = nodes.some(
    (node) => node.selected && node.id !== data.id,
  );

  const showMenu = data.removable && deletable;

  return (
    <Card
      variant="stepNode"
      w="250px"
      p={2}
      bg={statusColor(data).bg}
      borderColor={statusColor(data).border}
      cursor="pointer"
      opacity={otherSelected ? 0.5 : statusColor(data).opacity}
    >
      <Handle type="target" position={Position.Top} style={handleStyle} />
      <HStack justifyContent="space-between">
        <HStack>
          <StepIcon stepType={data.type} />
          <Text textStyle="heading-xs">{data.name}</Text>
        </HStack>
        {showMenu && (
          <Menu isOpen={isMenuOpen}>
            <MenuButton
              width="24px"
              height="24px"
              p={0}
              m={0}
              onClick={onToggle}
            >
              <DotsThreeOutlineVertical weight="fill" size={14} width="100%" />
            </MenuButton>
            <MenuList>
              <MenuItem
                color="archived"
                onClick={() => deleteElements({ nodes: [{ id: data.id }] })}
              >
                <Text>{t(`delete_step`)}</Text>
              </MenuItem>
            </MenuList>
          </Menu>
        )}
      </HStack>
      <Handle type="source" position={Position.Bottom} style={handleStyle} />
    </Card>
  );
}
