import {
  TransactionEntityFragment,
  TransactionDetailFragment,
} from "@/graphql";
import { useSellerExternalAccountEnabled } from "@/modules/LaunchDarkly";
import { VStack, HStack, Divider, Box, Text } from "@chakra-ui/react";
import { Bank } from "@phosphor-icons/react";
import { BankAccountStatusBadge } from "@/features/Entities";
import { getCountryName } from "@/modules/Country";
import { useTranslation } from "react-i18next";
import useTranslatedTransactingEntityNames from "./useTranslatedTransactingEntityNames";

export default function EntityInfo({
  entity,
  transaction,
  isBuyer,
}: {
  readonly entity: TransactionEntityFragment;
  readonly transaction: TransactionDetailFragment;
  readonly isBuyer: boolean;
}) {
  const { t } = useTranslation();
  const { getFormattedEntityName } = useTranslatedTransactingEntityNames();
  const country = getCountryName(entity.jurisdictionOfFormation?.name);
  const isSEAEnabled = useSellerExternalAccountEnabled();

  const { legalName, type } = entity;

  return (
    <VStack width="full" alignItems="flex-start">
      <HStack>
        <HStack>
          <Bank size="16px" />
          <Text textStyle="colfax-12-medium">
            {getFormattedEntityName(type)}
          </Text>
        </HStack>
        <HStack as={Text} noOfLines={1}>
          <Text as="span">{legalName} , </Text>
          <Text as="span">{country}</Text>
        </HStack>
      </HStack>
      {isSEAEnabled &&
        (!!transaction.sellerBankAccount && !isBuyer ? (
          <>
            <Divider borderWidth={1} mb={1} color="grey.100" />
            <HStack width="full" justifyContent="space-between">
              <Text textStyle="colfax-12-medium">
                {t(`bank_account_status`)}
              </Text>
              <BankAccountStatusBadge
                status={transaction.sellerBankAccount.verificationStatus}
              />
            </HStack>
          </>
        ) : (
          <Box h={8} />
        ))}
    </VStack>
  );
}
