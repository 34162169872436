import { ErrorBanner } from "@/modules/Error";
import { useTranslation } from "react-i18next";

export default function TableError({ message }: { message?: string }) {
  const { t } = useTranslation();

  return (
    <ErrorBanner
      title={t(`something_went_wrong`)}
      description="The table content could not be loaded... Please contact support or try again later."
      subText={message}
    />
  );
}
