import { useContext } from "react";

import {
  CompanyManagedMarketContext,
  CompanyManagedMarketProvider,
  CompanyPageNavMenu,
} from "@/features/Company";
import { ErrorWrapper } from "@/modules/Error";
import { DetailPageLayout } from "@/modules/Layout";

import {
  CompanyManagedMarketMainPanel,
  CompanyManagedMarketPageSkeleton,
  CompanyManagedMarketRightPanel,
} from ".";

interface CompanyManagedMarketPageContentProps {
  readonly nameSlug: string;
}

function CompanyManagedMarketPageContent({
  nameSlug,
}: CompanyManagedMarketPageContentProps) {
  const { managedMarketCompany, error, fetching } = useContext(
    CompanyManagedMarketContext,
  );

  if (!managedMarketCompany) return null;

  if (fetching) {
    return <CompanyManagedMarketPageSkeleton />;
  }

  return (
    <ErrorWrapper error={error?.message}>
      <DetailPageLayout
        isTwoColumn
        mainPanel={<CompanyManagedMarketMainPanel />}
        rightPanel={<CompanyManagedMarketRightPanel />}
        tabMenu={<CompanyPageNavMenu slug={nameSlug} />}
      />
    </ErrorWrapper>
  );
}

interface CompanyManagedMarketPageProps {
  readonly nameSlug: string;
}

export default function CompanyManagedMarketPage({
  nameSlug,
}: CompanyManagedMarketPageProps) {
  return (
    <CompanyManagedMarketProvider nameSlug={nameSlug}>
      <CompanyManagedMarketPageContent nameSlug={nameSlug} />
    </CompanyManagedMarketProvider>
  );
}
