import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { Table } from "@/modules/Table";
import { Menu, MenuGroup, MenuItem, MenuList } from "@chakra-ui/react";

import { CompanyManagedMarketContext } from "@/features/Company";
import { AddApprovedBuyerModal } from "./CompanyApprovedBuyersModals/AddApprovedBuyerModal";
import { UpdateCompanyApprovedBuyersModal } from "./CompanyApprovedBuyersModals/UpdateCompanyApprovedBuyersModal";

enum ModalType {
  UPDATE = `update`,
  ADD_BUYER = `add_buyer`,
}

const NO_MODAL = {
  component: () => null,
  isOpen: false,
};

const MODAL_MAP = {
  [ModalType.UPDATE]: {
    component: UpdateCompanyApprovedBuyersModal,
    isOpen: true,
  },
  [ModalType.ADD_BUYER]: {
    component: AddApprovedBuyerModal,
    isOpen: true,
  },
};

export default function CompanyManagedMarketApprovedBuyersActions() {
  const [modalType, setModalType] = useState<ModalType | null>(null);
  const { t } = useTranslation();
  const { managedMarketCompany } = useContext(CompanyManagedMarketContext);

  const requiresApprovedBuyers = managedMarketCompany?.requiresApprovedBuyers;

  const handleCloseModal = () => setModalType(null);

  const { component: ApprovedBuyersModal, isOpen } = modalType
    ? MODAL_MAP[modalType]
    : NO_MODAL;

  return (
    <>
      <Menu>
        <Table.ActionButton showTitle />
        <MenuList zIndex={3}>
          <MenuGroup>
            {!requiresApprovedBuyers && (
              <MenuItem onClick={() => setModalType(ModalType.UPDATE)}>
                {t(`activate`)}
              </MenuItem>
            )}
            <MenuItem onClick={() => setModalType(ModalType.ADD_BUYER)}>
              {t(`add_approved_buyer`)}
            </MenuItem>
            {requiresApprovedBuyers && (
              <MenuItem
                onClick={() => setModalType(ModalType.UPDATE)}
                textColor="archived"
              >
                {t(`deactivate`)}
              </MenuItem>
            )}
          </MenuGroup>
        </MenuList>
      </Menu>
      {modalType && (
        <ApprovedBuyersModal isOpen={isOpen} onClose={handleCloseModal} />
      )}
    </>
  );
}
