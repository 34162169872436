import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { CompanyManagedMarketContext } from "@/features/Company";
import {
  CompanyInformationDisclosureStatus,
  useCompanyManagedMarketUpdateCompanyInformationDisclosureStatusMutation,
  useCompanyManagedMarketUpdateCompanyRequiresInformationDisclosureMutation,
} from "@/graphql";
import { Input } from "@/modules/Form";
import { formatToLocalTimezone } from "@/modules/NumeralFormat";
import { useCustomToast } from "@/modules/Toast";
import { Card, CardBody, CardHeader, Text } from "@chakra-ui/react";
import { useCompanyApprovedBuyersEnabled } from "@/modules/LaunchDarkly";

export default function CompanyManagedMarketRightPanel() {
  const { t } = useTranslation();
  const { managedMarketCompany, refetch } = useContext(
    CompanyManagedMarketContext,
  );

  const companyApprovedBuyersEnabled = useCompanyApprovedBuyersEnabled();

  const [, updateCompanyInformationDisclosureStatus] =
    useCompanyManagedMarketUpdateCompanyInformationDisclosureStatusMutation();

  const [, updateCompanyRequiresInformationDisclosure] =
    useCompanyManagedMarketUpdateCompanyRequiresInformationDisclosureMutation();

  const { successToast } = useCustomToast();

  if (!managedMarketCompany) return null;

  const {
    requiresInformationDisclosure,
    companyInformationDisclosure: { companyId, status, updatedBy, updatedAt },
    companyInformationDisclosureAction,
  } = managedMarketCompany;

  const { insertedAt, actor } = companyInformationDisclosureAction || {};

  const name = companyApprovedBuyersEnabled ? actor?.name : updatedBy?.name;
  const dateApplied = companyApprovedBuyersEnabled ? insertedAt : updatedAt;

  const handleOnChange = async () => {
    const { data: response } = await updateCompanyInformationDisclosureStatus({
      input: {
        companyId,
        status:
          status === CompanyInformationDisclosureStatus.Active
            ? CompanyInformationDisclosureStatus.Inactive
            : CompanyInformationDisclosureStatus.Active,
      },
    });

    if (response?.updateCompanyInformationDisclosureStatus) {
      successToast(t(`seller_information_disclosure_applied`));
      refetch();
    }
  };

  const handleOnChangeV2 = async () => {
    const { data: response } = await updateCompanyRequiresInformationDisclosure(
      {
        companyId,
        requiresInformationDisclosure: !requiresInformationDisclosure,
      },
    );

    if (response?.updateCompanyRequiresInformationDisclosure) {
      successToast(t(`seller_information_disclosure_applied`));
      refetch();
    }
  };

  return (
    <Card w="full">
      <CardHeader>
        <Text textStyle="colfax-22-medium">{t(`issuer_disclosure`)}</Text>
      </CardHeader>
      <CardBody>
        {companyApprovedBuyersEnabled ? (
          <Input.Switch
            formControlProps={{ display: `flex` }}
            formLabelProps={{ color: `grey.900`, textTransform: `none` }}
            isChecked={requiresInformationDisclosure}
            label={t(`issuer_disclosure_switch_label`)}
            name="informationDisclosure"
            onChange={handleOnChangeV2}
          />
        ) : (
          <Input.Switch
            formControlProps={{ display: `flex` }}
            formLabelProps={{ color: `grey.900`, textTransform: `none` }}
            isChecked={status === CompanyInformationDisclosureStatus.Active}
            label={t(`issuer_disclosure_switch_label`)}
            name="informationDisclosure"
            onChange={handleOnChange}
          />
        )}

        {name && dateApplied && (
          <Text>
            {t(`applied_by`)}: {actor?.name}
            {` `}
            {formatToLocalTimezone(dateApplied)}
          </Text>
        )}
      </CardBody>
    </Card>
  );
}
