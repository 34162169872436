import { UseQueryState } from "urql";
import merge from "lodash/merge";
import { CompaniesTableQuery, CompaniesTableQueryVariables } from "@/graphql";
import { PreqinStatus } from "@/modules/Status";
import { match } from "ts-pattern";
import { DateTime } from "luxon";
import { CompaniesTableType } from "@/features/Companies";

const DEFAULT = { companies: [], totalCount: 0, pageInfo: undefined };

export const mapToTable = (
  responseData:
    | UseQueryState<CompaniesTableQuery, CompaniesTableQueryVariables>
    | undefined,
) => {
  if (!responseData || !responseData.data || !responseData.data.companies) {
    return {
      ...DEFAULT,
      error: responseData?.error,
      fetching: responseData?.fetching,
    };
  }

  const { edges } = responseData.data.companies;

  const companies: CompaniesTableType[] = edges.map(({ node: company }) => {
    const { status, preqinCompany, usePreqin } = company;

    const preqin = match({
      usePreqin,
      preqinCompany: !!preqinCompany,
    })
      .with({ usePreqin: true }, () => PreqinStatus.Shown)
      .with({ preqinCompany: true }, () => PreqinStatus.Hidden)
      .otherwise(() => PreqinStatus.None);

    const companyToShow =
      preqin === PreqinStatus.Shown && preqinCompany ? preqinCompany : company;

    const { country, lastRoundDate } = companyToShow;

    return merge(
      {
        ...company,
        ...companyToShow,
        id: company.id,
      },
      {
        preqin,
        country: country ? country.name : ``,
        lastRoundDate: DateTime.fromISO(lastRoundDate).toISODate(),
        status: status.toLowerCase(),
      },
    );
  });
  return {
    companies,
    totalCount: responseData.data.companies.totalCount,
    pageInfo: responseData.data.companies.pageInfo,
    error: responseData.error,
    loading: responseData.fetching,
  };
};
