import { useColors } from "@/modules/Theme";
import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import { sentenceCase } from "change-case";
import { Handle, Position } from "@xyflow/react";

import { MilestoneStatusIcon } from "@/modules/Step";
import { WorkflowPreviewStepNodeProps } from "./types";

export function PreviewMilestoneStepNode({
  data,
}: WorkflowPreviewStepNodeProps) {
  const [grey100] = useColors([`grey.100`]);
  const handleStyle = {
    background: grey100,
    borderRadius: `6px`,
    width: `8px`,
    height: `8px`,
    border: `1px solid white`,
  };

  return (
    <Flex w="250px" justifyContent="center" py={0.5}>
      <Handle type="target" position={Position.Top} style={handleStyle} />
      {data.completesMilestone && (
        <HStack
          py={0.5}
          px={2}
          spacing={1}
          borderRadius={9}
          boxShadow="0px 2px 2px rgba(0, 0, 0, 0.15)"
          width="fit-content"
          background="purple.100"
          color="purple.1000"
        >
          <Box width={4} h={4} position="relative">
            <MilestoneStatusIcon status={data.status} />
          </Box>
          <Text textStyle="colfax-12-regular">
            {sentenceCase(data.completesMilestone)}
          </Text>
        </HStack>
      )}
      <Handle type="source" position={Position.Bottom} style={handleStyle} />
    </Flex>
  );
}
