import { AddEntityInput, TransactionEntityFragment } from "@/graphql";
import { Stack, Text } from "@chakra-ui/react";
import capitalize from "lodash/capitalize";
import { useTranslatedTransactingEntityNames } from "@/features/Transactions";

type EntityCardBodyProps = {
  // this card usually accepts an Entity, but is also used to preview an Entity that's to be created
  entity: TransactionEntityFragment | AddEntityInput;
  country?: string | null;
};

function EntityCardBody({ entity, country }: EntityCardBodyProps) {
  const { getFormattedEntityName } = useTranslatedTransactingEntityNames();

  return (
    <Stack gap={0}>
      <Text color="grey.800" fontSize={16} fontWeight={500} lineHeight={6}>
        {getFormattedEntityName(entity.type)}
      </Text>
      <Text fontSize={16} fontWeight={400} lineHeight={6}>
        {capitalize(entity.legalName!)}
        {country && `,  ${country}`}
      </Text>
    </Stack>
  );
}

export default EntityCardBody;
