import { FeeDiscountType } from "@/graphql";
import { formatStringToNumber } from "@/modules/NumeralFormat";
import i18next from "i18next";
import * as yup from "yup";

const maxFeeDiscountValuePerType = {
  [FeeDiscountType.PercentageFee]: 100,
  [FeeDiscountType.OverrideCommission]: 100,
  [FeeDiscountType.FlatFee]: 20_000_000,
} as const;

export const ValidationSchema = yup.object().shape({
  name: yup.string().trim().required(i18next.t(`fee_discount_name_required`)),
  type: yup.string().trim().required(i18next.t(`fee_discount_type_required`)),
  value: yup
    .number()
    .nullable()
    .transform((_, originalValue) => {
      const numericValue = formatStringToNumber(originalValue);
      return Number.isNaN(numericValue) ? undefined : numericValue;
    })
    .min(0)
    .when(`type`, (type, schema) => {
      const [feeDiscountType] = type as [FeeDiscountType];
      return schema.max(maxFeeDiscountValuePerType[feeDiscountType]);
    }),
  percentageValue: yup
    .number()
    .nullable()
    .transform((_, originalValue) => {
      const numericValue = formatStringToNumber(originalValue);
      return Number.isNaN(numericValue) ? undefined : numericValue;
    })
    .min(0)
    .when(`type`, (type, schema) => {
      const [feeDiscountType] = type as [FeeDiscountType];
      return schema.max(maxFeeDiscountValuePerType[feeDiscountType]);
    }),
  description: yup.string().optional(),
});

export const FeeDiscountAutoNamingValidationSchema = yup.object().shape({
  type: yup.string().trim().required(i18next.t(`fee_discount_type_required`)),
  value: yup
    .number()
    .nullable()
    .transform((_, originalValue) => {
      const numericValue = formatStringToNumber(originalValue);
      return Number.isNaN(numericValue) ? undefined : numericValue;
    })
    .moreThan(0)
    .when(`type`, (type, schema) => {
      const [feeDiscountType] = type as [FeeDiscountType];
      if (feeDiscountType === FeeDiscountType.FlatFee) {
        return schema
          .max(maxFeeDiscountValuePerType[feeDiscountType])
          .required(i18next.t(`value_is_required`));
      }
      return schema;
    }),

  percentageValue: yup
    .number()
    .nullable()
    .transform((_, originalValue) => {
      const numericValue = formatStringToNumber(originalValue);
      return Number.isNaN(numericValue) ? undefined : numericValue;
    })
    .moreThan(0, i18next.t(`value_must_be_greater_than_0`))
    .when(`type`, (type, schema) => {
      const [feeDiscountType] = type as [FeeDiscountType];
      if (feeDiscountType !== FeeDiscountType.FlatFee) {
        return schema
          .max(
            maxFeeDiscountValuePerType[feeDiscountType],
            `Value must be less than ${maxFeeDiscountValuePerType[feeDiscountType]}`,
          )
          .required(i18next.t(`value_is_required`));
      }
      return schema;
    }),
  description: yup.string().optional(),
});

export const FeeDiscountPreviewValidationSchema = yup.object().shape({
  name: yup.string().trim().required(i18next.t(`fee_discount_name_required`)),
  type: yup.string().trim().required(i18next.t(`fee_discount_type_required`)),
  value: yup
    .number()
    .nullable()
    .transform((_, originalValue) => {
      const numericValue = formatStringToNumber(originalValue);
      return Number.isNaN(numericValue) ? undefined : numericValue;
    })
    .min(0),
  percentageValue: yup
    .number()
    .transform((_, originalValue) => {
      const numericValue = formatStringToNumber(originalValue);
      return Number.isNaN(numericValue) ? undefined : numericValue;
    })
    .min(0),
  description: yup.string().optional(),
});

export const FeeDiscountPreviewAndAutoNamingValidationSchema = yup
  .object()
  .shape({
    type: yup.string().trim().required(i18next.t(`fee_discount_type_required`)),
    value: yup
      .number()
      .required()
      .transform((_, originalValue) => {
        const numericValue = formatStringToNumber(originalValue);
        return Number.isNaN(numericValue) ? undefined : numericValue;
      })
      .moreThan(0),
    percentageValue: yup
      .number()
      .transform((_, originalValue) => {
        const numericValue = formatStringToNumber(originalValue);
        return Number.isNaN(numericValue) ? undefined : numericValue;
      })
      .moreThan(0),
    description: yup.string().optional(),
  });
