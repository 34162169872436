import { useTranslation } from "react-i18next";

import {
  LowercasedTransactionSigningProcedureValues,
  LowercasedTransactionStateValues,
  TransactionsTableRowActions,
  getTransactionStateIndicatorStyle,
  TerminalTransactionStates,
} from "@/features/Transactions";
import {
  TransactionDetailFragment,
  TransactionPageQuery,
  TransactionState,
} from "@/graphql";
import { Status } from "@/modules/Status";
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";

import { CompanyInfoClipboard } from "@/features/Company";
import { useColors } from "@/modules/Theme";
import { TransactionInfo } from "./TransactionInfo";
import { TransactionsProceedsInfo } from "./TransactionsProceedsInfo";
import TransactionSigners from "./TransactionSigners";

export default function TransactionOverview({
  data,
}: {
  data?: TransactionPageQuery;
}) {
  const { t } = useTranslation();

  const transaction = data?.transaction as TransactionDetailFragment;

  const transactionState = transaction?.state || ``;
  const transactionSigningProcedure = transaction?.signingProcedure || ``;
  const bid = transaction?.bid;
  const feeDiscountApplications =
    data?.transaction?.feeDiscountApplications || [];

  const logoUrl = transaction?.bid?.company?.logoUrl;
  const [grey300] = useColors([`grey.300`]);

  const isTransactionOnHold =
    !transaction.inPipeline &&
    !TerminalTransactionStates.includes(transactionState as TransactionState);

  const indicatorStyle = {
    ...getTransactionStateIndicatorStyle(
      transaction?.state,
      isTransactionOnHold,
    ),
    w: 3,
    h: 3,
  };

  const rofrText =
    transactionState === TransactionState.IssuerApproved && transaction.rofr
      ? ` ${t(`rofr`)}`
      : ``;

  const pipelineStatus: string = isTransactionOnHold
    ? `(${t`transaction_on_hold_status`})`
    : ``;

  return (
    <VStack gap={4}>
      <Card w="full">
        <CardHeader as={HStack} gap={4} alignItems="center">
          <Text textStyle="colfax-22-medium">
            {`${t(`transaction`)} ${transaction?.bid?.displayId}`}
          </Text>
          <Box flexGrow={1} />
          <HStack spacing={4}>
            <HStack>
              <Status.Indicator
                text={t(
                  transactionSigningProcedure.toLowerCase() as LowercasedTransactionSigningProcedureValues,
                )}
                fontWeight={500}
              />
              <Text color="grey.300" mx={0.5}>
                |
              </Text>
              <Status.Indicator
                text={
                  <>
                    {t(
                      transactionState.toLowerCase() as LowercasedTransactionStateValues,
                    )}
                    {rofrText}
                    {pipelineStatus && (
                      <span style={{ color: grey300 }}>{pipelineStatus}</span>
                    )}
                  </>
                }
                fontWeight={500}
                indicatorProps={indicatorStyle}
              />
            </HStack>
            <TransactionsTableRowActions
              showActionButtonTitle
              transaction={transaction}
            />
          </HStack>
        </CardHeader>
        <CardBody>
          <VStack alignItems="flex-start" gap={6}>
            <CompanyInfoClipboard
              logoUrl={logoUrl}
              companyName={transaction?.bid?.company?.name || ``}
              copyToClipboardItems={[
                { label: t(`database_id`), value: transaction?.id },
              ]}
            />
            <TransactionInfo transaction={transaction} />
            <TransactionSigners transaction={transaction} />
          </VStack>
        </CardBody>
      </Card>

      <TransactionsProceedsInfo
        displayId={bid?.displayId}
        feeDiscountApplications={feeDiscountApplications}
        transaction={transaction}
      />
    </VStack>
  );
}
