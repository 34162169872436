import { ExecutionStepType } from "@/graphql";
import { Box } from "@chakra-ui/react";
import { CheckFat, Question } from "@phosphor-icons/react";
import { match } from "ts-pattern";
import { AnvilIcon } from "@/modules/Step";

function StepIconTask({ stepType }: { readonly stepType: ExecutionStepType }) {
  return match(stepType)
    .with(ExecutionStepType.Manual, () => (
      <Box
        border="1px solid"
        borderColor="grey.50"
        padding={1}
        borderRadius={6}
      >
        <CheckFat size={14} />
      </Box>
    ))
    .with(ExecutionStepType.IssuerApproval, () => (
      <Box
        border="1px solid"
        borderColor="grey.50"
        padding={1}
        borderRadius={6}
      >
        <CheckFat size={14} />
      </Box>
    ))
    .with(ExecutionStepType.Anvil, () => (
      <Box
        border="1px solid"
        borderColor="grey.50"
        width={6}
        height={6}
        borderRadius={6}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <AnvilIcon width="14px" />
      </Box>
    ))
    .with(ExecutionStepType.AnvilV2, () => (
      <Box
        border="1px solid"
        borderColor="grey.50"
        width={6}
        height={6}
        borderRadius={6}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <AnvilIcon width="14px" />
      </Box>
    ))
    .otherwise(() => (
      <Box background="red" padding={1} borderRadius={6}>
        <Question size={14} />
      </Box>
    ));
}

export default StepIconTask;
