import { SVGProps } from "react";

function CompletedIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="1.37565"
        y="1.37516"
        width="11.25"
        height="11.25"
        rx="3.95833"
        fill="#6C6C6C"
        stroke="#6C6C6C"
        strokeWidth="2.08333"
      />
      <path
        d="M4.46712 7.18877L4.46713 7.18878L5.95849 8.68043L9.79358 4.84502L9.79359 4.84502C9.88152 4.75708 10.0008 4.70768 10.1251 4.70768C10.2495 4.70768 10.3688 4.75708 10.4567 4.84502C10.5446 4.93295 10.594 5.05222 10.594 5.17658C10.594 5.30094 10.5446 5.4202 10.4567 5.50814L6.29012 9.67472L6.29004 9.6748L6.14272 9.52749C6.11854 9.5517 6.08982 9.57091 6.0582 9.58402C6.02659 9.59712 5.9927 9.60387 5.95848 9.60387C5.92426 9.60387 5.89037 9.59712 5.85875 9.58402C5.82714 9.57091 5.79842 9.5517 5.77423 9.52749L3.95132 7.70457C3.90245 7.65571 3.875 7.58943 3.875 7.52033C3.875 7.45122 3.90245 7.38495 3.95132 7.33608C4.00018 7.28722 4.06646 7.25977 4.13556 7.25977C4.20467 7.25977 4.27094 7.28722 4.31981 7.33608L4.46712 7.18877ZM4.46712 7.18877C4.37919 7.10083 4.25992 7.05143 4.13556 7.05143C4.0112 7.05143 3.89194 7.10083 3.804 7.18877C3.71607 7.2767 3.66667 7.39597 3.66667 7.52033C3.66667 7.64469 3.71607 7.76395 3.804 7.85189L5.62684 9.67472L4.46712 7.18877Z"
        fill="white"
        stroke="white"
        strokeWidth="0.416667"
      />
    </svg>
  );
}
export default CompletedIcon;
