import StatusAccreditation from "./StatusAccreditation";
import StatusEmpty from "./StatusEmpty";
import StatusGeneric from "./StatusGeneric";
import StatusIdentityVerification from "./StatusIdentityVerification";
import StatusMembershipAgreement from "./StatusMembershipAgreement";
import StatusPreqin, { PreqinStatus } from "./StatusPreqin";
import StatusSuitability from "./StatusSuitability";
import StatusWithIcon from "./StatusWithIcon";
import StatusWithIndicator, {
  type StatusWithIndicatorProps,
} from "./StatusWithIndicator";
import StatusDocumentSigned from "./StatusDocumentSigned";

export { PreqinStatus, StatusWithIndicatorProps };

export default {
  Icon: StatusWithIcon,
  Indicator: StatusWithIndicator,
  Accreditation: StatusAccreditation,
  Suitability: StatusSuitability,
  MembershipAgreement: StatusMembershipAgreement,
  IdentityVerification: StatusIdentityVerification,
  Generic: StatusGeneric,
  Preqin: StatusPreqin,
  Empty: StatusEmpty,
  DocumentSigned: StatusDocumentSigned,
};
