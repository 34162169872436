import { CompanyStatus } from "@/graphql";
import { MergedColorsType } from "@/modules/Theme";
import { Paths } from "@/types";

const getCompanyIndicatorStatus = (
  status: CompanyStatus,
): Paths<MergedColorsType> => {
  switch (status) {
    case CompanyStatus.Listed:
      return `active`;
    case CompanyStatus.Draft:
      return `grey.200`;
    default:
      return `pending`;
  }
};

export default getCompanyIndicatorStatus;
