import {
  LinkEntityInput,
  TransactionDetailFragment,
  TransactionEntityFragment,
  useLinkEntityMutation,
} from "@/graphql";
import { ConfirmModal } from "@/modules/Modal";
import { ModalProps, Stack, Text, chakra } from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";
import {
  ConfirmEntityChanges,
  CurrentEntityCard,
} from "@/features/Transactions";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useCustomToast } from "@/modules/Toast";
import { useState } from "react";
import { TransactingEntitiesSelect } from "./TransactingEntitiesSelect";

type SwapEntityModalProps = Omit<ModalProps, "children"> & {
  entities: TransactionEntityFragment[];
  currentEntity: TransactionEntityFragment;
  transaction: TransactionDetailFragment;
  isBuyer: boolean;
};

function SwapEntityModal({
  entities = [],
  currentEntity,
  onClose,
  transaction,
  isBuyer,
  ...others
}: SwapEntityModalProps) {
  const { t } = useTranslation();
  const validationSchema = yup
    .object()
    .shape({
      entityId: yup.string().required(t(`select_an_entity`)),
      transactionId: yup.string().required(),
    })
    .required();
  const methods = useForm<LinkEntityInput>({
    defaultValues: {
      transactionId: transaction.id,
    },
    resolver: yupResolver(validationSchema),
  });
  const {
    handleSubmit,
    formState: { isValid, isSubmitting },
    getValues,
    reset,
    trigger,
  } = methods;

  const [, linkEntity] = useLinkEntityMutation();
  const { successToast, errorToast } = useCustomToast();

  const [showConfirmation, setShowConfirmation] = useState(false);

  const newEntity = entities.find(
    (entity) => entity.id === getValues(`entityId`),
  );

  const resetModal = () => {
    setShowConfirmation(false);
    reset();
  };

  const onSubmit: SubmitHandler<LinkEntityInput> = async (input) => {
    const { error } = await linkEntity(
      {
        input,
      },
      {
        additionalTypenames: [`Transaction`],
      },
    );

    if (error) {
      errorToast(t(`could_not_swap_entity`));
    } else {
      successToast(t(`entity_swapped_successfully`));
      resetModal();
      onClose();
    }
  };

  return (
    <ConfirmModal
      body={
        <Stack as={chakra.form} gap={6}>
          {!showConfirmation && (
            <>
              <Stack gap={4}>
                <CurrentEntityCard entity={currentEntity} />
                <Text>
                  <Trans
                    i18nKey={
                      isBuyer
                        ? `swap_entity_modal_description_buyer`
                        : `swap_entity_modal_description_seller`
                    }
                    components={{ bold: <strong /> }}
                  />
                </Text>
              </Stack>
              <FormProvider {...methods}>
                <TransactingEntitiesSelect
                  currentEntity={currentEntity}
                  entities={entities}
                  onChange={() => methods.trigger()}
                />
              </FormProvider>
            </>
          )}
          {showConfirmation && newEntity && (
            <ConfirmEntityChanges
              currentEntity={currentEntity}
              newEntity={{
                jurisdictionOfFormationId:
                  newEntity.jurisdictionOfFormation?.id ?? ``,
                legalName: newEntity.legalName ?? ``,
                type: newEntity.type,
              }}
            />
          )}
        </Stack>
      }
      loading={isSubmitting}
      onConfirm={() => {
        trigger();

        if (!isValid) {
          return;
        }

        setShowConfirmation(true);

        if (showConfirmation) {
          handleSubmit(onSubmit)();
        }
      }}
      onClose={() => {
        resetModal();
        onClose();
      }}
      title={showConfirmation ? t(`confirm_entity_changes`) : t(`swap_entity`)}
      {...others}
    />
  );
}

export default SwapEntityModal;
