import { CompaniesTableRowActions } from "@/features/Companies";
import { CompanyPageQuery, Country } from "@/graphql";
import { CardTable } from "@/modules/CardTable";
import { getCompanyIndicatorStatus } from "@/modules/Company";
import {
  DateTimeFormat,
  formatCapitalRaised,
  formatDate,
  formatLastRoundValue,
} from "@/modules/NumeralFormat";
import { PreqinStatus, Status } from "@/modules/Status";
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Image,
  ListItem,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";
import {
  IndicatorI18nKeys,
  PriceLineI18nKeys,
  orderedIndicators,
  orderedPriceLines,
} from "@/features/Company";

type CompanyPageQueryType = NonNullable<CompanyPageQuery["company"]>;
type PreqinCompanyPageQueryType = CompanyPageQueryType["preqinCompany"];

const getSubheaderText = ({
  legalName,
  country,
  domain,
}: {
  legalName?: string | null;
  country?: Country | null;
  domain?: string | null;
}) => {
  const legalNameText = legalName ?? ``;
  const countryText = country ? ` | ${country.name}` : ``;
  const domainText = domain ? ` | ${domain}` : ``;

  return `${legalNameText}${countryText}${domainText}`;
};

const getCardDataFormatted = ({
  usingPreqin,
  company,
  preqinCompany,
}: {
  usingPreqin: boolean;
  company: CompanyPageQueryType;
  preqinCompany: PreqinCompanyPageQueryType;
}) => {
  const getPreqinValue = (field: string) =>
    (usingPreqin &&
      preqinCompany?.[field as keyof PreqinCompanyPageQueryType]) ||
    company[field as keyof CompanyPageQueryType];

  return {
    subheader: getSubheaderText({
      legalName: getPreqinValue(`legalName`),
      country: getPreqinValue(`country`),
      domain: getPreqinValue(`domain`),
    }),
    description: getPreqinValue(`description`),
    lastRoundValue: formatLastRoundValue({
      lastRoundPostValuation: getPreqinValue(`lastRoundPostValuation`),
      lastRoundPricePerShare: getPreqinValue(`lastRoundPricePerShare`),
    }),
    lastRoundDate: formatDate(
      getPreqinValue(`lastRoundDate`),
      DateTimeFormat.monthYear,
    ),
    lastRoundSeries: getPreqinValue(`lastRoundSeries`)
      ? `(${getPreqinValue(`lastRoundSeries`)})`
      : ``,
    capitalRaised: formatCapitalRaised({
      lastRoundRaised: getPreqinValue(`lastRoundRaised`),
      totalRaised: getPreqinValue(`totalRaised`),
    }),
  };
};

export default function CompanyCard({
  company,
}: {
  company: CompanyPageQueryType;
}) {
  const {
    name,
    logoUrl,
    status,
    tradeable,
    priceChartConfig,
    hasAskPriceComparisonChart,
    preqinCompany,
    usePreqin,
  } = company;

  const preqinStatus = match({
    usePreqin,
    preqinCompany: !!preqinCompany,
  })
    .with({ usePreqin: true }, () => PreqinStatus.Shown)
    .with({ preqinCompany: true }, () => PreqinStatus.Hidden)
    .otherwise(() => PreqinStatus.None);

  const usingPreqin = preqinStatus === PreqinStatus.Shown;

  const { t } = useTranslation();

  const cardData = getCardDataFormatted({
    usingPreqin,
    company,
    preqinCompany,
  });

  const shouldDisplayIndicators = orderedIndicators.some(
    (indicator) => priceChartConfig.graphIndicators[indicator],
  );

  const shouldDisplayPriceLines = orderedPriceLines.some(
    (priceLine) => priceChartConfig.priceLines[priceLine],
  );

  return (
    <Card w="full">
      <CardHeader as={HStack} gap={4} alignItems="center">
        {logoUrl && <Image src={logoUrl} w="auto" h={7} />}
        <Text textStyle="colfax-22-medium">{name}</Text>
        <Box flexGrow={1} />
        <HStack>
          <Status.Preqin
            preqin={preqinStatus}
            text={t(`preqin`)}
            badgeProps={{
              fontWeight: preqinStatus === PreqinStatus.Shown ? 500 : 400,
              fontStyle: `normal`,
            }}
          />
          <Text color="grey.300" mx={0.5}>
            |
          </Text>

          <Status.Indicator
            text={status.toLocaleLowerCase()}
            fontWeight={500}
            indicatorProps={{
              bg: getCompanyIndicatorStatus(status),
              w: 2.5,
              h: 2.5,
            }}
          />
        </HStack>
        <CompaniesTableRowActions company={company} showActionButtonTitle />
      </CardHeader>
      <CardBody>
        {cardData.subheader ? (
          <Text textStyle="colfax-16-regular">{cardData.subheader}</Text>
        ) : (
          <Status.Empty />
        )}
        <Text textStyle="colfax-16-regular" mt={5} maxW="80%">
          {cardData.description}
        </Text>
        <VStack rowGap={6} my={6} alignItems="baseline">
          <CardTable w="full" columns={3}>
            <Card variant="table">
              <CardHeader>{t(`value`)}</CardHeader>
              <CardBody>
                {cardData.lastRoundValue ? (
                  <Text>{cardData.lastRoundValue}</Text>
                ) : (
                  <Status.Empty />
                )}
              </CardBody>
            </Card>
            <Card variant="table">
              <CardHeader>{t(`last_round_date`)}</CardHeader>
              <CardBody>
                {cardData.lastRoundDate || cardData.lastRoundSeries ? (
                  <Text>{`${cardData.lastRoundDate || `-`} ${
                    cardData.lastRoundSeries
                  }`}</Text>
                ) : (
                  <Status.Empty />
                )}
              </CardBody>
            </Card>
            <Card variant="table">
              <CardHeader>{t(`capital_raised`)}</CardHeader>
              <CardBody>
                {cardData.capitalRaised ? (
                  <Text>{cardData.capitalRaised}</Text>
                ) : (
                  <Status.Empty />
                )}
              </CardBody>
            </Card>
          </CardTable>
          <CardTable w="full" columns={2}>
            <Card variant="table">
              <CardHeader>{t(`price_comparison_tool`)}*</CardHeader>
              <CardBody>
                <Text>{hasAskPriceComparisonChart ? t(`on`) : t(`off`)}</Text>
                <Text textStyle="colfax-12-regular">
                  *{t(`shown_to_unaccr_seller`)}
                </Text>
              </CardBody>
            </Card>

            <Card variant="table">
              <CardHeader>{t(`tradeable`)}</CardHeader>
              <CardBody>
                <Text>{tradeable ? t(`yes`) : t(`no`)}</Text>
              </CardBody>
            </Card>
          </CardTable>
          <CardTable w="full" columns={1}>
            <Card variant="table">
              <CardHeader>{t(`historical_price_graph`)}</CardHeader>
              <CardBody>
                <HStack alignItems="flex-start" spacing="15%">
                  <VStack alignItems="flex-start">
                    <Box>
                      <Text textStyle="colfax-14-medium" color="grey.600">
                        {t(`state`)}
                      </Text>
                      <Text>
                        {priceChartConfig.displayChart ? t(`on`) : t(`off`)}
                      </Text>
                    </Box>
                    {priceChartConfig.displayChart && (
                      <Box>
                        <Text textStyle="colfax-14-medium" color="grey.600">
                          {t(`start_date`)}
                        </Text>
                        <Text>
                          {formatDate(
                            priceChartConfig.graphDataStart,
                            DateTimeFormat.fullDateWithMonthShort,
                          )}
                        </Text>
                      </Box>
                    )}
                  </VStack>
                  {priceChartConfig.displayChart && (
                    <>
                      {shouldDisplayIndicators && (
                        <Box>
                          <Text textStyle="colfax-14-medium" color="grey.600">
                            {t(`indicators`)}
                          </Text>
                          <UnorderedList>
                            {orderedIndicators.map(
                              (indicator) =>
                                priceChartConfig.graphIndicators[indicator] && (
                                  <ListItem key={indicator}>
                                    {t(IndicatorI18nKeys[indicator])}
                                  </ListItem>
                                ),
                            )}
                          </UnorderedList>
                        </Box>
                      )}
                      {shouldDisplayPriceLines && (
                        <Box>
                          <Text textStyle="colfax-14-medium" color="grey.600">
                            {t(`price_types`)}
                          </Text>
                          <UnorderedList>
                            {orderedPriceLines.map(
                              (priceline) =>
                                priceChartConfig.priceLines[priceline] && (
                                  <ListItem key={priceline}>
                                    {t(PriceLineI18nKeys[priceline])}
                                  </ListItem>
                                ),
                            )}
                          </UnorderedList>
                        </Box>
                      )}
                    </>
                  )}
                </HStack>
              </CardBody>
            </Card>
          </CardTable>
        </VStack>
      </CardBody>
    </Card>
  );
}
