import { useContext } from "react";
import {
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  SimpleGrid,
  GridItem,
  VStack,
  useDisclosure,
  HStack,
  Spacer,
  Wrap,
} from "@chakra-ui/react";
import { CaretDown } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";
import { SubmitHandler, UseFormRegister, useWatch } from "react-hook-form";

import { TableContext } from "@/modules/SimpleTable";
import { Checkbox, Input } from "@/modules/Form";
import { TransactionSecuritySpecialistFragment } from "@/graphql";
import { FilterTag } from "@/modules/FilterTag";
import { RESET_PAGINATION_PARAMS } from "@/constants";

type TransactionFormValues = {
  searchText: string;
  securitySpecialistCheckbox?: Record<string, boolean>;
};

function TransactionsTableSearch({
  register,
}: {
  register: UseFormRegister<TransactionFormValues>;
}) {
  const { t } = useTranslation();

  return (
    <HStack alignItems="center">
      <Input.Search
        placeholder={t(`search_email_name`)}
        w={430}
        {...register(`searchText`)}
      />
    </HStack>
  );
}

function TransactionsSecuritySpecialistFilterButton({
  securitySpecialists,
}: {
  readonly securitySpecialists?: TransactionSecuritySpecialistFragment[] | null;
}) {
  const { t } = useTranslation();

  const { isOpen, onClose, onToggle } = useDisclosure();
  const { setVariables, register, handleSubmit, control } =
    useContext(TableContext);
  const formValues = useWatch({ control });

  const onSubmit: SubmitHandler<TransactionFormValues> = (data) => {
    onToggle();

    setVariables((prevVariables) => ({
      ...prevVariables,
      filterBy: {
        ...prevVariables.filterBy,
        securitySpecialistUserIds: Object.keys(
          data.securitySpecialistCheckbox as object,
        ).reduce<string[]>(
          (result, id) =>
            !!data.securitySpecialistCheckbox &&
            data.securitySpecialistCheckbox[id]
              ? [...result, id]
              : result,
          [],
        ),
      },
      ...RESET_PAGINATION_PARAMS,
    }));
  };

  const formatNameString = (
    firstName: string,
    lastName: string,
    email?: string | null,
  ) => `${firstName} ${lastName} (${email})`;

  return (
    <Popover returnFocusOnClose={false} isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button
          variant="outline"
          rightIcon={<CaretDown />}
          w={150}
          onClick={onToggle}
        >
          {t(`security_specialist`)}
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent w="400px">
          <PopoverBody as="form" onSubmit={handleSubmit(onSubmit)}>
            <VStack p={2} gap={2} alignItems="flex-start">
              {securitySpecialists?.map(
                ({ id, email, firstName, lastName }) => (
                  <Checkbox
                    label={formatNameString(firstName, lastName, email)}
                    key={id}
                    isChecked={
                      !!formValues?.securitySpecialistCheckbox
                        ? !!formValues?.securitySpecialistCheckbox[id]
                        : false
                    }
                    {...register(
                      `securitySpecialistCheckbox.${id}` as "securitySpecialistCheckbox.string",
                    )}
                  />
                ),
              )}
            </VStack>
            <SimpleGrid columns={2} columnGap={2} mt={2}>
              <GridItem colSpan={1}>
                <Button variant="outline" size="lg" w="full" onClick={onClose}>
                  {t(`cancel`)}
                </Button>
              </GridItem>
              <GridItem colSpan={1}>
                <Button type="submit" size="lg" w="full">
                  {t(`apply`)}
                </Button>
              </GridItem>
            </SimpleGrid>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

export function TransactionsSecuritySpecialistFilterSection({
  securitySpecialists,
}: {
  readonly securitySpecialists: TransactionSecuritySpecialistFragment[];
}) {
  const { t } = useTranslation();
  const { register, variables, setVariables, resetField } =
    useContext(TableContext);

  const onClearFilters = () => {
    setVariables((prevVariables) => ({
      ...prevVariables,
      filterBy: {
        ...prevVariables.filterBy,
        securitySpecialistUserIds: [],
      },
      ...RESET_PAGINATION_PARAMS,
    }));
    resetField(`securitySpecialistCheckbox`);
  };

  const selectedSecuritySpecialists =
    securitySpecialists?.filter(
      (ss) => variables.filterBy?.securitySpecialistUserIds?.includes(ss.id),
    ) ?? [];

  const onRemoveSecuritySpecialistFilter = (omitSSId: string) => {
    setVariables((prevVariables) => ({
      ...prevVariables,
      filterBy: {
        ...prevVariables.filterBy,
        securitySpecialistUserIds:
          prevVariables.filterBy?.securitySpecialistUserIds?.filter(
            (ssid) => ssid !== omitSSId,
          ),
      },
      ...RESET_PAGINATION_PARAMS,
    }));
    resetField(
      `securitySpecialistCheckbox.${omitSSId}` as "securitySpecialistCheckbox.string",
    );
  };

  return (
    <VStack spacing={4}>
      <HStack justifyContent="spaceBetween" width="full">
        <TransactionsTableSearch register={register} />
        <Spacer />
        <TransactionsSecuritySpecialistFilterButton
          securitySpecialists={securitySpecialists}
        />
      </HStack>
      {selectedSecuritySpecialists?.length > 0 && (
        <HStack w="full">
          <Wrap>
            {selectedSecuritySpecialists?.map((ss) => (
              <FilterTag
                key={ss?.id}
                name={`${ss?.firstName} ${ss?.lastName}`}
                onClick={() => onRemoveSecuritySpecialistFilter(ss.id)}
              />
            ))}
            <Button variant="link" onClick={onClearFilters}>
              {t(`clear_filters`)}
            </Button>
          </Wrap>
        </HStack>
      )}
    </VStack>
  );
}
