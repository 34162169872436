import { match } from "ts-pattern";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { CompanyManagedMarketContext } from "@/features/Company";
import { Text, VStack } from "@chakra-ui/react";
import { DateTimeFormat, formatDate } from "@/modules/NumeralFormat";
import { CompanyApprovedBuyersTable } from "./CompanyApprovedBuyersTable";

function CompanyManagedMarketApprovedBuyersContentActivatedByText() {
  const { managedMarketCompany } = useContext(CompanyManagedMarketContext);
  const { t } = useTranslation();

  if (!managedMarketCompany?.requiresApprovedBuyersAction) {
    return null;
  }

  const {
    requiresApprovedBuyersAction: { actor, insertedAt },
    requiresApprovedBuyers,
  } = managedMarketCompany;

  const name = actor?.name;

  const formattedDate = formatDate(
    insertedAt,
    DateTimeFormat.fullDateTimeWithMonthShort,
  );

  return (
    <Text fontSize={15}>
      {requiresApprovedBuyers ? t(`activated_by`) : t(`deactivated_by`)}: {name}
      {` `}
      <Text as="span" fontWeight={500}>
        {formattedDate}
      </Text>
    </Text>
  );
}

export function CompanyManagedMarketApprovedBuyersContent() {
  const { t } = useTranslation();
  const { managedMarketCompany } = useContext(CompanyManagedMarketContext);

  const approvedBuyers = managedMarketCompany?.companyApprovedBuyers || [];
  const requiresApprovedBuyers = !!managedMarketCompany?.requiresApprovedBuyers;

  return match({
    hasApprovedBuyers: approvedBuyers.length > 0,
    requiresApprovedBuyers,
  })
    .with({ hasApprovedBuyers: false, requiresApprovedBuyers: false }, () => (
      <Text>{t(`approved_buyers_disabled`)}</Text>
    ))
    .with({ hasApprovedBuyers: false, requiresApprovedBuyers: true }, () => (
      <VStack alignItems="flex-start" gap={8}>
        <Text>
          {t(`no_approved_buyers`, { companyName: managedMarketCompany?.name })}
        </Text>
        <CompanyManagedMarketApprovedBuyersContentActivatedByText />
      </VStack>
    ))
    .with({ hasApprovedBuyers: true }, () => (
      <VStack alignItems="flex-start" gap={8}>
        <CompanyApprovedBuyersTable />
        <CompanyManagedMarketApprovedBuyersContentActivatedByText />
      </VStack>
    ))
    .exhaustive();
}
