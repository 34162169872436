import {
  Menu,
  MenuGroup,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import { Table } from "@/modules/Table";
import { useTranslation } from "react-i18next";
import { WorkflowTemplateStatus } from "@/graphql";

import { ConfirmTemplateStatusToggleModal } from "./modals";
import { WorkflowTemplatesTableData } from "./WorkflowTemplatesTable";

function RowActionMenuItem({
  workflowTemplate,
}: {
  workflowTemplate: WorkflowTemplatesTableData;
}) {
  const { t } = useTranslation();
  const {
    isOpen,
    onClose,
    onToggle: toggleStatusToggleModal,
  } = useDisclosure();

  const label =
    workflowTemplate.status === WorkflowTemplateStatus.Active
      ? t(`make_inactive`)
      : t(`make_active`);

  return (
    <>
      <MenuItem
        onClick={() => {
          toggleStatusToggleModal();
        }}
      >
        {label}
      </MenuItem>
      <ConfirmTemplateStatusToggleModal
        workflowTemplate={workflowTemplate}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
}

export default function RowActions({
  workflowTemplate,
}: {
  workflowTemplate: WorkflowTemplatesTableData;
}) {
  return (
    <Menu>
      <Table.ActionButton />
      <MenuList>
        <MenuGroup>
          <RowActionMenuItem workflowTemplate={workflowTemplate} />
        </MenuGroup>
      </MenuList>
    </Menu>
  );
}
