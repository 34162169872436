import { mapManagedMarketCompany } from "@/features/Company";
import { useCompanyManagedMarketPageQuery } from "@/graphql";
import { ReactNode, createContext, useCallback, useMemo } from "react";
import { CombinedError } from "urql";

type CompanyManagedMarketContext = {
  error?: CombinedError;
  fetching: boolean;
  managedMarketCompany: ReturnType<typeof mapManagedMarketCompany>;
  refetch: () => void;
};

const DEFAULT_CONTEXT: CompanyManagedMarketContext = {
  error: undefined,
  fetching: false,
  managedMarketCompany: null,
  refetch: () => {},
};
const Context = createContext<CompanyManagedMarketContext>(DEFAULT_CONTEXT);
const { Provider } = Context;

interface CompanyManagedMarketProviderProps {
  children: ReactNode;
  nameSlug: string;
}

function CompanyManagedMarketProvider({
  children,
  nameSlug,
}: CompanyManagedMarketProviderProps) {
  const [{ fetching, error, data }, executeQuery] =
    useCompanyManagedMarketPageQuery({
      variables: { nameSlug },
      requestPolicy: `cache-and-network`,
    });
  const refetch = useCallback(() => {
    executeQuery({ requestPolicy: `network-only` });
  }, [executeQuery]);

  const managedMarketCompany = useMemo(
    () => mapManagedMarketCompany(data),
    [data],
  );

  return (
    <Provider value={{ fetching, error, managedMarketCompany, refetch }}>
      {children}
    </Provider>
  );
}

export { Context, CompanyManagedMarketProvider };
