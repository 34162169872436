import {
  formatFeeDiscountType,
  formatFeeDiscountValue,
} from "@/features/FeeDiscount";
import { FeeDiscountPageFragment, FeeDiscountPageQuery } from "@/graphql";

type FeeDiscountDetailsType = Omit<
  FeeDiscountPageFragment,
  "value" | "feeDiscountApplications"
> & {
  value: string;
  formatedFeeDiscountType: string;
};

export function mapFeeDiscountDetails(
  data?: FeeDiscountPageQuery,
): FeeDiscountDetailsType | null {
  if (!data?.feeDiscount) {
    return null;
  }
  const { value, type } = data.feeDiscount;

  return {
    ...data.feeDiscount,
    value: formatFeeDiscountValue(value, type),
    formatedFeeDiscountType: formatFeeDiscountType(type),
  };
}
