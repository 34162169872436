import entries from "lodash/entries";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useEffect,
} from "react";
import {
  UseFormRegister,
  UseFormResetField,
  UseFormHandleSubmit,
  Control,
} from "react-hook-form";

import { TableSortState, useAppendToUrl } from "@/modules/Table";

import merge from "lodash/merge";
import { FeeDiscountType, SortDirection } from "@/graphql";
import {
  SimpleTableQueryVariables,
  SimpleTableUrlParams,
  useTable,
} from "@/modules/SimpleTable";

interface TableProviderProps<T extends SimpleTableQueryVariables> {
  children: ReactNode;
  defaultVariables: T;
  query: SimpleTableUrlParams & {
    status?: string;
  };
  skipAppendQuery?: boolean;
  sortFields: string[];
  toUrl?: (vars: T) => SimpleTableUrlParams;
  fromUrl?: (
    vars: SimpleTableUrlParams,
    sortFields: string[],
  ) => SimpleTableQueryVariables;
}

interface TableContextValue<T extends SimpleTableQueryVariables> {
  page: number;
  searchText: string;
  sort: TableSortState | undefined;
  variables: SimpleTableQueryVariables;
  register: UseFormRegister<{
    searchText: string;
    securitySpecialistCheckbox?: Record<string, boolean>;
    feeDiscountCheckbox?: Record<FeeDiscountType, boolean>;
  }>;
  resetField: UseFormResetField<{
    searchText: string;
    securitySpecialistCheckbox?: Record<string, boolean>;
    feeDiscountCheckbox?: Record<FeeDiscountType, boolean>;
  }>;
  setSort: Dispatch<SetStateAction<TableSortState | undefined>>;
  setVariables: Dispatch<SetStateAction<T>>;
  handleSubmit: UseFormHandleSubmit<{
    searchText: string;
    securitySpecialistCheckbox?: Record<string, boolean>;
    feeDiscountCheckbox?: Record<FeeDiscountType, boolean>;
  }>;
  control: Control<{
    searchText: string;
    securitySpecialistCheckbox?: Record<string, boolean>;
    feeDiscountCheckbox?: Record<FeeDiscountType, boolean>;
  }>;
}

const Context = createContext<TableContextValue<SimpleTableQueryVariables>>({
  page: 1,
  searchText: ``,
  sort: undefined,
  variables: {} as SimpleTableQueryVariables,
  register: (() => {}) as unknown as UseFormRegister<{
    searchText: string;
    securitySpecialistCheckbox?: Record<string, boolean>;
    feeDiscountCheckbox?: Record<FeeDiscountType, boolean>;
  }>,
  resetField: (() => {}) as unknown as UseFormResetField<{
    searchText: string;
    securitySpecialistCheckbox?: Record<string, boolean>;
    feeDiscountCheckbox?: Record<FeeDiscountType, boolean>;
  }>,
  setSort: () => {},
  setVariables: () => {},
  handleSubmit: (() => {}) as unknown as UseFormHandleSubmit<{
    searchText: string;
    securitySpecialistCheckbox?: Record<string, boolean>;
    feeDiscountCheckbox?: Record<FeeDiscountType, boolean>;
  }>,
  control: {} as Control<{
    searchText: string;
    securitySpecialistCheckbox?: Record<string, boolean>;
    feeDiscountCheckbox?: Record<FeeDiscountType, boolean>;
  }>,
});
const { Provider } = Context;

const defaultToUrl = (
  queryVariables: SimpleTableQueryVariables,
): SimpleTableUrlParams => {
  const { filterBy, sortBy, page, first, before, after, last } = queryVariables;

  const urlQueryVariables = {
    sort: sortBy?.direction,
    sortBy: sortBy?.field,
    search: filterBy?.searchText,
    status: filterBy?.status,
    feeDiscountId: filterBy?.feeDiscountId,
    feeDiscountTypes: filterBy?.feeDiscountTypes
      ? (filterBy.feeDiscountTypes as string[]).join(`,`)
      : undefined,
    active: filterBy?.active?.toString(),
    states: filterBy?.states
      ? (filterBy.states as string[]).join(`,`)
      : undefined,
    transferMethods: filterBy?.transfer_methods
      ? (filterBy.transfer_methods as string[]).join(`,`)
      : undefined,
    userId: filterBy?.userId,
    sellerId: filterBy?.sellerId,
    countryIds: filterBy?.countryIds
      ? (filterBy.countryIds as string[]).join(`,`)
      : undefined,
    statuses: filterBy?.statuses
      ? (filterBy.statuses as string[]).join(`,`)
      : undefined,
    page,
    first,
    before,
    after,
    last,
  };

  return entries(urlQueryVariables)
    .filter(([, value]) => !!value)
    .reduce(
      (prevQueryVariables, [key, value]) => ({
        ...prevQueryVariables,
        [key]: value as string,
      }),
      {} as SimpleTableUrlParams,
    );
};

const defaultFromUrl = <T extends Record<string, unknown>>(
  urlParams: T,
  sortFields: string[],
) =>
  entries(urlParams)
    .filter(([, value]) => !!value)
    .reduce((prevUrlParams, [key, value]) => {
      switch (key) {
        case `after`:
        case `before`:
          return merge(prevUrlParams, { [key]: value });
        case `page`:
          return merge(prevUrlParams, {
            page: parseInt(value as string, 10),
          });
        case `first`:
          return merge(prevUrlParams, {
            first: parseInt(value as string, 10),
            last: undefined,
          });
        case `last`:
          return merge(prevUrlParams, {
            [key]: parseInt(value as string, 10),
            first: undefined,
          });
        case `sort`:
          if (
            ![SortDirection.Asc, SortDirection.Desc].includes(
              value as SortDirection,
            )
          )
            return prevUrlParams;

          return merge(prevUrlParams, {
            sortBy: {
              direction: value,
            },
          });
        case `sortBy`:
          if (!sortFields.includes(value as string)) return prevUrlParams;

          return merge(prevUrlParams, {
            sortBy: {
              field: value,
            },
          });
        case `search`:
          return merge(prevUrlParams, {
            filterBy: {
              searchText: value,
            },
          });
        case `status`:
          return merge(prevUrlParams, {
            filterBy: {
              status: value,
            },
          });
        case `states`:
          return merge(prevUrlParams, {
            filterBy: {
              states: String(value).split(`,`),
            },
          });
        case `transferMethods`:
          return merge(prevUrlParams, {
            filterBy: {
              transfer_methods: String(value).split(`,`),
            },
          });
        case `userId`:
          return merge(prevUrlParams, {
            filterBy: {
              userId: value,
            },
          });
        case `sellerId`:
          return merge(prevUrlParams, {
            filterBy: {
              sellerId: value,
            },
          });
        case `countryIds`:
          return merge(prevUrlParams, {
            filterBy: {
              countryIds: String(value).split(`,`),
            },
          });
        case `statuses`:
          return merge(prevUrlParams, {
            filterBy: {
              statuses: String(value).split(`,`),
            },
          });
        case `feeDiscountId`:
          return merge(prevUrlParams, {
            filterBy: {
              feeDiscountId: value,
            },
          });
        case `feeDiscountTypes`:
          return merge(prevUrlParams, {
            filterBy: {
              feeDiscountTypes: String(value).split(`,`),
            },
          });
        case `active`:
          return merge(prevUrlParams, {
            filterBy: {
              active: value === `true`,
            },
          });
        default:
          return prevUrlParams;
      }
    }, {});

function TableProvider<T extends SimpleTableQueryVariables>({
  children,
  defaultVariables,
  query,
  skipAppendQuery = false,
  sortFields,
  toUrl = defaultToUrl,
  fromUrl = defaultFromUrl,
}: TableProviderProps<T>) {
  const appendToUrl = useAppendToUrl();
  const {
    page,
    searchText,
    sort,
    variables,
    register,
    resetField,
    setSort,
    setVariables,
    handleSubmit,
    control,
  } = useTable({
    defaultVariables,
    sortFields,
    query,
    fromUrl,
  });

  useEffect(() => {
    if (!skipAppendQuery) appendToUrl(toUrl(variables as T));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variables]);

  return (
    <Provider
      value={{
        page,
        searchText,
        sort,
        variables,
        register,
        resetField,
        setSort,
        setVariables,
        handleSubmit,
        control,
      }}
    >
      {children}
    </Provider>
  );
}

export { Context, TableProvider };
