import { Country } from "@/graphql";
import { getCountryList } from "@/modules/Country";
import { Checkbox } from "@/modules/Form";
import { SimpleTableQueryVariables } from "@/modules/SimpleTable";
import {
  Button,
  Divider,
  GridItem,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  SimpleGrid,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { CaretDown } from "@phosphor-icons/react";
import { useCallback, useEffect, useMemo } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

type Inputs = {
  countries?: string[];
  searchText: string;
};

export default function CompaniesStatusFilterButton({
  variables,
  setFilters,
  countries,
  countryList,
  preferredCountries,
}: {
  variables: SimpleTableQueryVariables;
  setFilters: (variables: SimpleTableQueryVariables) => void;
  countries: Country[];
  countryList: ReturnType<typeof getCountryList>;
  preferredCountries: string[];
}) {
  const { t } = useTranslation();

  const defaultCountries = useMemo(
    () => variables.filterBy?.countryIds ?? [],
    [variables.filterBy?.countryIds],
  );

  const { control, formState, handleSubmit, register, reset } = useForm<Inputs>(
    {
      defaultValues: {
        countries: defaultCountries as string[],
      },
    },
  );
  const formValues = useWatch({ control, name: `countries` });

  useEffect(() => {
    reset({ countries: defaultCountries as string[] });
  }, [defaultCountries, reset]);

  const { isOpen, onClose, onToggle } = useDisclosure();

  const onSubmit: SubmitHandler<Inputs> = useCallback(
    (data) => {
      const countryIds =
        data.countries?.filter(
          (country) => !!country && typeof country === `string`,
        ) || [];

      setFilters({
        filterBy: {
          countryIds,
        },
      });
      onClose();
    },
    [onClose, setFilters],
  );

  useEffect(() => {
    const countryIds = variables.filterBy?.countryIds as string[] | undefined;
    if (formState.isSubmitted && !countryIds?.length) {
      reset();
    }
  }, [formState.isSubmitted, variables.filterBy?.countryIds, reset]);

  return (
    <Popover returnFocusOnClose={false} isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button
          variant="outline"
          rightIcon={<CaretDown />}
          w={118}
          onClick={onToggle}
        >
          {t(`country`)}
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent w={350}>
          <PopoverBody as="form" onSubmit={handleSubmit(onSubmit)}>
            <VStack
              p={2}
              gap={2}
              alignItems="flex-start"
              overflowY="scroll"
              maxH={180}
            >
              {countries.map(({ name, id }, index) => (
                <>
                  {index === preferredCountries.length && (
                    <Divider borderColor="grey.200" />
                  )}
                  <Checkbox
                    label={countryList.getName(name)}
                    value={id}
                    key={id}
                    isChecked={formValues?.includes(id)}
                    {...register(`countries.${index}`)}
                  />
                </>
              ))}
            </VStack>
            <SimpleGrid columns={2} columnGap={2} mt={2}>
              <GridItem colSpan={1}>
                <Button variant="outline" size="lg" w="full" onClick={onClose}>
                  {t(`cancel`)}
                </Button>
              </GridItem>
              <GridItem colSpan={1}>
                <Button type="submit" size="lg" w="full">
                  {t(`apply`)}
                </Button>
              </GridItem>
            </SimpleGrid>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}
