import { Card, CardProps, Stack } from "@chakra-ui/react";

type EntityCardProps = CardProps;

function EntityCard({ children, ...props }: EntityCardProps) {
  return (
    <Card as={Stack} gap={3} rounded={6} padding={3} {...props}>
      {children}
    </Card>
  );
}

export default EntityCard;
