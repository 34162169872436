import { AreaInput, TerminationReasonsSelect } from "@/modules/Form";
import { ConfirmModal } from "@/modules/Modal";
import { Box, Divider, Text } from "@chakra-ui/react";
import { useCallback } from "react";
import { useCustomToast } from "@/modules/Toast";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  TransactionsTableNodeFragment,
  useTransactionsTableRowActionsCancelTransactionMutation,
} from "@/graphql";
import { Trans, useTranslation } from "react-i18next";
import * as yup from "yup";

type TransactionCancellationModalProps = {
  readonly transaction: TransactionsTableNodeFragment;
  readonly isOpen: boolean;
  readonly onClose: () => void;
};

type CancelTransactionInput = {
  terminationReasons: string[] | undefined;
  terminationReasonExplanation?: string | undefined;
};

const REASON_OTHER_KEY = `Other * Requires Context`;

const TERMINATION_REASONS = [
  `Buyer Backed Out`,
  `Seller Backed Out`,
  `Transferability Issues`,
  REASON_OTHER_KEY,
];

function TransactionCancellationModal({
  transaction,
  isOpen,
  onClose,
}: TransactionCancellationModalProps) {
  const { t } = useTranslation();

  const [{ fetching }, cancelTransaction] =
    useTransactionsTableRowActionsCancelTransactionMutation();

  const { errorToast, successToast } = useCustomToast();

  const transactionTerminationReasonsValidationSchema = yup
    .object()
    .shape({
      terminationReasons: yup
        .array(yup.string().oneOf(TERMINATION_REASONS).required())
        .min(1, t`termination_reasons_min_error`)
        .required(t`termination_reasons_min_error`)
        .nonNullable(),
      terminationReasonExplanation: yup
        .string()
        .trim()
        .when(`terminationReasons`, ([terminationReasons], schema) =>
          terminationReasons?.includes(REASON_OTHER_KEY)
            ? schema.required(t`cancel_explanation_required_error`)
            : schema.optional(),
        ),
    })
    .required();

  const {
    register,
    handleSubmit,
    control,
    reset,
    trigger,
    getValues,
    formState: { errors, dirtyFields, submitCount },
  } = useForm({
    defaultValues: { terminationReasons: [] },
    resolver: yupResolver(transactionTerminationReasonsValidationSchema, {
      abortEarly: false,
      strict: false,
    }),
  });

  const onCloseModal = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  const onSubmit: SubmitHandler<CancelTransactionInput> = useCallback(
    async (data) => {
      const res = await cancelTransaction({
        transactionId: transaction.id,
        terminationReasons: data?.terminationReasons?.join(`, `) || ``,
        terminationExplanation: data.terminationReasonExplanation,
      });

      if (!res?.data?.cancelTransaction.transaction?.id) {
        errorToast();
        return;
      }

      successToast();
      onCloseModal();
    },
    [cancelTransaction, successToast, errorToast, onCloseModal, transaction.id],
  );

  const isExplanationRequired =
    getValues(`terminationReasons`)?.includes(REASON_OTHER_KEY);

  // only show errors after user attempted to submit
  const reasonsError =
    dirtyFields?.terminationReasons || submitCount > 0
      ? errors?.terminationReasons
      : undefined;
  const explanationError =
    dirtyFields?.terminationReasonExplanation || submitCount > 0
      ? errors?.terminationReasonExplanation
      : undefined;

  return (
    <ConfirmModal
      title={t(`confirm_cancel_transaction_title`)}
      body={
        <>
          <Text>
            <Trans
              i18nKey="confirm_cancel_transaction_body"
              components={{ bold: <strong /> }}
            />
          </Text>
          <Divider borderColor="grey.200" borderWidth="1" my={6} />
          <Controller
            control={control}
            name="terminationReasons"
            render={({ field: { onChange, name, value, ...fieldProps } }) => (
              <TerminationReasonsSelect
                value={value || []}
                label={t(`reasons_for_cancelling`)}
                options={TERMINATION_REASONS}
                error={reasonsError}
                name={name}
                onChange={async (vals: string[]) => {
                  onChange(vals);
                  await trigger();
                }}
                {...fieldProps}
              />
            )}
          />
          <Box height={6} />
          <AreaInput
            label={t(`additional_details`)}
            helperText={isExplanationRequired ? t(`required`) : t(`optional`)}
            borderColor="grey.300"
            borderWidth=".5px"
            error={explanationError}
            isRequired={isExplanationRequired}
            maxLength={255}
            isInvalid={!!explanationError}
            {...register(`terminationReasonExplanation`)}
          />
        </>
      }
      onConfirm={handleSubmit(onSubmit)}
      disableConfirm={fetching}
      loading={fetching}
      isOpen={isOpen}
      onClose={onCloseModal}
      size="lg"
    />
  );
}

export default TransactionCancellationModal;
