import {
  ExecutionStepStatus,
  ExecutionStepType,
  TransactionPageV2ExecutionStepFragment,
  TransactionPageV2ExecutionTaskFragment,
  TransactionPageV2ExecutionWorkflowFragment,
  WorkflowStepConfigFragment,
} from "@/graphql";
import {
  Box,
  HStack,
  Text,
  VStack,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Tag,
  GridItem,
  SimpleGrid,
  Tabs,
  TabPanels,
  Tab,
  TabList,
  TabPanel,
} from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";
import { AnvilLink, stepStatusIcon } from "@/modules/Step";
import {
  getFutureTasks,
  TaskWithDependency,
  UserTypeTag,
} from "@/modules/Task";
import { OverlappingTags } from "@/modules/OverlappingTags";
import { Fragment } from "react";
import {
  hasIssuerDeclinedWorkflow,
  hasIssuerROFRedWorkflow,
  IssuerApprovalApproved,
  workflowConditionStatus,
} from "@/modules/Workflow";
import { replaceIntermediaryEdges } from "./utils";

function StepOverview({
  step,
}: {
  readonly step: TransactionPageV2ExecutionStepFragment;
}) {
  if (!step.instructions.hiive) return null;
  return (
    <VStack alignItems="flex-start" spacing={2}>
      <Text textStyle="heading-xs">
        <Trans i18nKey="step_overview" />
      </Text>
      <Text textStyle="text-sm" color="grey.600">
        {step.instructions.hiive}
      </Text>
    </VStack>
  );
}

function sortTasksByConfigOrder(
  tasks: TransactionPageV2ExecutionTaskFragment[],
  config: WorkflowStepConfigFragment,
) {
  const isAnvil = config.__typename === `ExecutionAnvilConfig`;

  if (!isAnvil) {
    return tasks;
  }

  const taskOrderMap = new Map(
    config.taskOrder.map((order, index) => [order.actorType, index]),
  );

  return [...tasks].sort(
    (a, b) =>
      (taskOrderMap.get(a.actorType) || 0) -
      (taskOrderMap.get(b.actorType) || 0),
  );
}

function DetailsDrawer({
  selectedStepId,
  workflow,
  onClose,
  isOpen,
}: {
  readonly selectedStepId: string;
  readonly workflow: TransactionPageV2ExecutionWorkflowFragment;
  readonly onClose: () => void;
  readonly isOpen: boolean;
}) {
  const { t } = useTranslation();

  const { steps, edges, conditions } = workflow;

  const selectedStep = steps.find((step) => step.id === selectedStepId);

  // for steps that are milestones, we need to replace those edges with step to step edges
  // for the purpose of showing the correct (ignoring milestones) dependencies in start after/required by
  const updatedEdges = replaceIntermediaryEdges(
    edges,
    steps,
    `ExecutionMilestoneConfig`,
  );

  const startsAfterSteps = selectedStep
    ? steps.filter((step) =>
        updatedEdges.some(
          (edge) => edge.toId === selectedStepId && edge.fromId === step.id,
        ),
      )
    : [];

  const requiredBySteps = selectedStep
    ? steps.filter((step) =>
        updatedEdges.some(
          (edge) => edge.toId === step.id && edge.fromId === selectedStepId,
        ),
      )
    : [];

  const showStartsAfter = startsAfterSteps.length > 0;
  const showRequiredBy = requiredBySteps.length > 0;

  if (!selectedStep) return null;

  const {
    tasks: existingTasks,
    name,
    visibility,
    instructions,
    config,
    status,
    type,
  } = selectedStep;

  const tasks =
    status === ExecutionStepStatus.Pending
      ? getFutureTasks(config)
      : sortTasksByConfigOrder(existingTasks, config);

  const hasMessaging =
    type !== ExecutionStepType.Milestone &&
    !!instructions.seller &&
    !!instructions.buyer;

  const assignees = [...new Set(tasks.map((task) => task.actorType))];
  const isAnvil = config.__typename === `ExecutionAnvilConfig`;

  const hasIssuerDeclined = hasIssuerDeclinedWorkflow(conditions);
  const hasIssuerROFRed = hasIssuerROFRedWorkflow(conditions);

  const showIssuerApproved =
    type === ExecutionStepType.IssuerApproval &&
    status === ExecutionStepStatus.Completed &&
    !hasIssuerDeclined &&
    !hasIssuerROFRed;

  return (
    <Drawer placement="right" size="md" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent bg="white">
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px" borderBottomColor="grey.100">
          <HStack justifyContent="space-between">
            <HStack>
              <Box>{stepStatusIcon[status]}</Box>
              <Text textStyle="heading-2xl">{name}</Text>
            </HStack>
            <Box mr={6}>
              {conditions.map(({ condition, stepId }) => {
                if (stepId === selectedStep.id) {
                  return (
                    <Fragment key={condition}>
                      {workflowConditionStatus[condition]}
                    </Fragment>
                  );
                }
                return null;
              })}
              {showIssuerApproved &&
                workflowConditionStatus[IssuerApprovalApproved]}
            </Box>
          </HStack>
        </DrawerHeader>
        <DrawerBody h="full" p={0} flex="none">
          <Box p={6}>
            <VStack gap={5} alignItems="flex-start">
              {assignees.length > 0 && (
                <SimpleGrid columns={4} columnGap={2} w="full">
                  <GridItem colSpan={1}>
                    <Text textStyle="heading-xs">{t(`assignee`)}</Text>
                  </GridItem>
                  <GridItem colSpan={3}>
                    <OverlappingTags>
                      {assignees.map((assignee) => (
                        <UserTypeTag key={assignee} assignee={assignee} />
                      ))}
                    </OverlappingTags>
                  </GridItem>
                </SimpleGrid>
              )}
              <SimpleGrid columns={4} columnGap={2} w="full">
                <GridItem colSpan={1}>
                  <Text textStyle="heading-xs">{t(`visibility`)}</Text>
                </GridItem>
                <GridItem colSpan={3}>
                  <OverlappingTags>
                    {visibility.map((visibility) => (
                      <UserTypeTag key={visibility} assignee={visibility} />
                    ))}
                  </OverlappingTags>
                </GridItem>
              </SimpleGrid>
              {showStartsAfter && (
                <SimpleGrid columns={4} columnGap={2} w="full">
                  <GridItem colSpan={1}>
                    <Text textStyle="heading-xs" whiteSpace="nowrap">
                      {t(`starts_after`)}
                    </Text>
                  </GridItem>
                  <GridItem colSpan={3}>
                    <OverlappingTags>
                      {startsAfterSteps.map((step) => (
                        <Tag
                          borderRadius="full"
                          size="md"
                          variant="grey"
                          key={step.id}
                          maxW="100%"
                        >
                          <Text textStyle="colfax-12-regular" isTruncated>
                            {step.name}
                          </Text>
                        </Tag>
                      ))}
                    </OverlappingTags>
                  </GridItem>
                </SimpleGrid>
              )}
              {showRequiredBy && (
                <SimpleGrid columns={4} columnGap={2} w="full">
                  <GridItem colSpan={1}>
                    <Text textStyle="heading-xs" whiteSpace="nowrap">
                      {t(`dependencies`)}
                    </Text>
                  </GridItem>
                  <GridItem colSpan={3}>
                    <OverlappingTags>
                      {requiredBySteps.map((step) => (
                        <Tag
                          borderRadius="full"
                          size="md"
                          variant="grey"
                          key={step.id}
                          maxW="100%"
                        >
                          <Text textStyle="colfax-12-regular" isTruncated>
                            {step.name}
                          </Text>
                        </Tag>
                      ))}
                    </OverlappingTags>
                  </GridItem>
                </SimpleGrid>
              )}
              <StepOverview step={selectedStep} />
              {isAnvil && (
                <VStack alignItems="flex-start" spacing={2} w="full">
                  <Text textStyle="heading-xs">
                    <Trans i18nKey="anvil_workflow" />
                  </Text>
                  <AnvilLink weldEid={config.anvilWeldEid} />
                </VStack>
              )}
            </VStack>
          </Box>
          <Tabs h="full">
            <TabList>
              <Tab>{t(`tasks`)}</Tab>
              {hasMessaging && <Tab>{t(`messaging`)}</Tab>}
            </TabList>
            <TabPanels
              bg="grey.25"
              h="full"
              borderTopWidth="1px"
              borderTopColor="grey.100"
            >
              <TabPanel p={6}>
                <VStack spacing={3}>
                  {tasks.map((task, index) => (
                    <TaskWithDependency
                      key={task.id}
                      task={task}
                      index={index}
                      previousTask={tasks[index - 1]}
                      stepName={name}
                      stepType={type}
                    />
                  ))}
                </VStack>
              </TabPanel>
              <TabPanel p={6}>
                <VStack spacing={4}>
                  {!!instructions.seller && (
                    <VStack alignItems="start">
                      <Text textStyle="heading-xs">{t(`seller`)}</Text>
                      <Text textStyle="text-sm" color="grey.600">
                        {instructions.seller}
                      </Text>
                    </VStack>
                  )}
                  {!!instructions.buyer && (
                    <VStack alignItems="start">
                      <Text textStyle="heading-xs">{t(`buyer`)}</Text>
                      <Text textStyle="text-sm" color="grey.600">
                        {instructions.buyer}
                      </Text>
                    </VStack>
                  )}
                </VStack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export default DetailsDrawer;
