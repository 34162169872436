import { CompanyPageFragment, FeeDiscountEntityType } from "@/graphql";
import { FeeDiscountsCard } from "@/modules/FeeDiscountsCard";
import { VStack } from "@chakra-ui/react";
import { UseQueryExecute } from "urql";
import CompanySecuritySpecialistCard from "./CompanySecuritySpecialistCard";

interface CompanyRightPanelProps {
  readonly company: CompanyPageFragment;
  readonly refetch: UseQueryExecute;
}

export default function CompanyRightPanel({
  company,
  refetch,
}: CompanyRightPanelProps) {
  return (
    <VStack gap={4}>
      {company.securitySpecialist?.user.email && (
        <CompanySecuritySpecialistCard
          email={company.securitySpecialist.user.email}
        />
      )}

      <FeeDiscountsCard
        entity={{
          entityId: company.id,
          entityType: FeeDiscountEntityType.Company,
          state: company.status,
        }}
        feeDiscountApplications={company.feeDiscountApplications || []}
        onSuccess={refetch}
      />
    </VStack>
  );
}
