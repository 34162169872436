import {
  muteNotificationValidationSchema,
  MuteNotificationReasonType,
  MuteNotificationWithReasonSection,
} from "@/features/Transactions";
import {
  BankAccountCardBankAccountFragment,
  useBankAccountCardManuallyApproveBankAccountMutation,
} from "@/graphql";
import { ConfirmModal } from "@/modules/Modal";
import { useCustomToast } from "@/modules/Toast";
import { useDisclosure, Button, VStack, Divider, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";

type ManualVerificationButtonProps = {
  readonly bankAccount: BankAccountCardBankAccountFragment;
  renderButton?: (onOpen: () => void) => React.ReactNode;
};
export default function ManualVerificationButton({
  bankAccount,
  renderButton,
}: ManualVerificationButtonProps) {
  const { t } = useTranslation();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { errorToast, successToast } = useCustomToast();

  const [{ fetching }, manuallyApproveBankAccount] =
    useBankAccountCardManuallyApproveBankAccountMutation();

  const {
    handleSubmit,
    register,
    resetField,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(muteNotificationValidationSchema),
    defaultValues: {
      muteNotificationReason: null,
    },
  });

  const onConfirm: SubmitHandler<MuteNotificationReasonType> = useCallback(
    async (data) => {
      const response = await manuallyApproveBankAccount({
        bankAccountId: bankAccount.id,
        muteNotificationReason: data.muteNotificationReason,
      });

      if (!response.data?.manuallyApproveBankAccount.bankAccount?.id) {
        errorToast();
        return;
      }

      successToast();
      onClose();
    },
    [
      manuallyApproveBankAccount,
      bankAccount.id,
      errorToast,
      successToast,
      onClose,
    ],
  );

  return (
    <>
      {renderButton ? (
        renderButton(onOpen)
      ) : (
        <Button onClick={onOpen}>{t(`manually_verify`)}</Button>
      )}
      <ConfirmModal
        loading={fetching}
        title={t(`confirm_manual_verification`)}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handleSubmit(onConfirm)}
        body={
          <VStack>
            <Text>{t(`confirm_manual_verification_body`)}</Text>
            <Divider borderColor="grey.200" borderWidth="1" mt={4} mb={4} />
            <MuteNotificationWithReasonSection
              name="muteNotificationReason"
              setValue={setValue}
              error={errors.muteNotificationReason}
              register={register}
              resetField={resetField}
            />
          </VStack>
        }
      />
    </>
  );
}
