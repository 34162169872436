import { Text, Tag, Divider, TagRightIcon, Link } from "@chakra-ui/react";
import { ArrowSquareOut } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

function AnvilLink({ weldEid }: { readonly weldEid: string }) {
  const { t } = useTranslation();

  return (
    <Tag
      background="grey.50"
      borderWidth="0.5px"
      borderColor="grey.100"
      borderRadius="lg"
      size="lg"
      as={Link}
      href={`https://app.useanvil.com/org/hiive/w/${weldEid}`}
      isExternal
    >
      <Text textStyle="text-sm" lineHeight={1} px={2}>
        {t(`direct_transfer`)}
      </Text>
      <Divider orientation="vertical" borderColor="white" opacity="1" />
      <TagRightIcon ml={1}>
        <ArrowSquareOut size={24} weight="fill" />
      </TagRightIcon>
    </Tag>
  );
}

export default AnvilLink;
