import { capitalCase } from "change-case";
import { useTranslation } from "react-i18next";

import {
  FeeDiscountApplicationActionType,
  FeeDiscountApplicationFragment,
  FeeDiscountEntityType,
} from "@/graphql";
import { FeeDiscountEntity } from "@/modules/FeeDiscountsCard";
import { Link } from "@/modules/Navigation";
import { formatToLocalTimezone } from "@/modules/NumeralFormat";
import { Card, CardBody, HStack, Text, VStack } from "@chakra-ui/react";
import { ArrowSquareOut } from "@phosphor-icons/react";

import FeeDiscountsCardHeader from "./FeeDiscountsCardHeader";

interface FeeDiscountsCardProps {
  entity: FeeDiscountEntity;
  feeDiscountApplications: FeeDiscountApplicationFragment[];
  onSuccess?: () => void;
}

interface FeeDiscountDetailsProps {
  feeDiscountApplication: FeeDiscountApplicationFragment;
}

function FeeDiscountDetails({
  feeDiscountApplication,
}: FeeDiscountDetailsProps) {
  const { t } = useTranslation();

  const {
    company,
    entityType,
    feeDiscount,
    expireAt,
    feeDiscountApplicationActions,
    parentApplication,
  } = feeDiscountApplication;

  if (!feeDiscount) {
    return null;
  }

  const assignAction = feeDiscountApplicationActions.find(
    ({ action }) => action === FeeDiscountApplicationActionType.Assign,
  );

  const appliedTo = parentApplication?.entityType || entityType;

  const { name: companyName } = company || {};
  const showCompanyName =
    entityType === FeeDiscountEntityType.User && !!companyName;

  return (
    <VStack
      alignItems="flex-start"
      whiteSpace="nowrap"
      spacing="1"
      textStyle="colfax-14-regular"
    >
      <Text>
        {t(`applied_to`)}: {capitalCase(appliedTo)}
      </Text>
      <Text>
        {t(`applied_by`)}: {assignAction?.user?.name}
      </Text>
      <Text>{formatToLocalTimezone(assignAction?.insertedAt)}</Text>
      {expireAt && (
        <Text>
          {t(`expires`)}: {formatToLocalTimezone(expireAt)}
        </Text>
      )}
      {showCompanyName && (
        <Text>
          {t(`company`)}: {companyName}
        </Text>
      )}
    </VStack>
  );
}

function FeeDiscountItem({
  feeDiscountApplication,
}: {
  feeDiscountApplication: FeeDiscountApplicationFragment;
}) {
  const { feeDiscount } = feeDiscountApplication;

  if (!feeDiscount) return null;

  return (
    <VStack key={feeDiscount.id} alignItems="flex-start" mb={4}>
      <Link
        href={`/fee-discounts/${feeDiscount.id}`}
        textDecoration="underline"
        textStyle="colfax-14-medium"
      >
        <HStack alignItems="center">
          <Text mt="2px">{feeDiscount.name}</Text>
          <ArrowSquareOut size={18} color="grey.600" />
        </HStack>
      </Link>
      <FeeDiscountDetails feeDiscountApplication={feeDiscountApplication} />
    </VStack>
  );
}

export default function FeeDiscountsCard({
  entity,
  feeDiscountApplications,
  onSuccess,
}: FeeDiscountsCardProps) {
  const { t } = useTranslation();

  return (
    <Card w="full" data-testid={`${t(`fee_discounts`)}-section`}>
      <FeeDiscountsCardHeader
        entity={entity}
        feeDiscountApplications={feeDiscountApplications}
        onSuccess={onSuccess}
      />

      <CardBody>
        {feeDiscountApplications.length > 0 ? (
          feeDiscountApplications.map((feeDiscountApplication) => (
            <FeeDiscountItem
              key={
                feeDiscountApplication.id ||
                feeDiscountApplication.feeDiscount?.id
              }
              feeDiscountApplication={feeDiscountApplication}
            />
          ))
        ) : (
          <Text>{t(`no_fee_discounts_applied`)}</Text>
        )}
      </CardBody>
    </Card>
  );
}
