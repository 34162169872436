import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { useTranslation } from "react-i18next";

import {
  BankAccountRequestStatus,
  DocumentType,
  FeeDiscountEntityType,
  SigningProcedure,
  TransactionDetailFragment,
  TransactionPageQuery,
  TransactionState,
} from "@/graphql";
import { DocumentCard } from "@/modules/DocumentCard";
import { FeeDiscountsCard } from "@/modules/FeeDiscountsCard";
import { useSellerExternalAccountEnabled } from "@/modules/LaunchDarkly";
import { formatToLocalTimezone } from "@/modules/NumeralFormat";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";

import { Status } from "@/modules/Status";
import { ReactNode } from "react";
import { useColors } from "@/modules/Theme";
import {
  getBankAccountStatusByVerificationStatus,
  getTransactionStateIndicatorStyle,
  TerminalTransactionStates,
  transactionBankAccountRequestStatusToi18nKeys,
} from "@/features/Transactions";
import { UseQueryExecute } from "urql";
import ProposedTransactionModificationCard from "./ProposedTransactionModificationCard";

import TransactionStatusActions from "./TransactionStatusActions";
import { TransactionIgnoreInPricingToggle } from "./TransactionIgnoreInPricingToggle";

function documentLinkText(documentType?: DocumentType | null) {
  if (documentType === DocumentType.Stn) {
    return `download_stn`;
  }
  if (documentType === DocumentType.Loi) {
    return `download_loi`;
  }
  return `download_document`;
}

interface TransactionStatusCardProps {
  transaction: TransactionDetailFragment;
}

function TransactionStatusCardBody({
  children,
  ...otherProps
}: {
  children: ReactNode;
}) {
  return (
    <CardBody
      {...otherProps}
      style={{ paddingTop: `1em`, paddingBottom: `1em` }}
    >
      {children}
    </CardBody>
  );
}

function TransactionStatusCard({ transaction }: TransactionStatusCardProps) {
  const { t } = useTranslation();
  const [grey300] = useColors([`grey.300`]);
  const isSEAEnabled = useSellerExternalAccountEnabled();

  const isTransactionInPipeline = transaction.inPipeline;

  const indicatorStyle = {
    ...getTransactionStateIndicatorStyle(
      transaction?.state,
      !isTransactionInPipeline,
    ),
    w: 3,
    h: 3,
  };

  const transactionStateText = t(
    isTransactionInPipeline
      ? `transaction_in_pipeline_status`
      : `transaction_on_hold_status`,
  );

  const showExpiresAt =
    transaction.state === TransactionState.BidAccepted &&
    transaction.signingProcedure === SigningProcedure.Automated;

  const isTransactionInTerminalState = TerminalTransactionStates.includes(
    transaction.state,
  );

  const shouldShowBankAccountCollection = isSEAEnabled;

  const shouldShowBankAccountRequestStatus = [
    BankAccountRequestStatus.NotRequested,
    BankAccountRequestStatus.Requested,
    BankAccountRequestStatus.Cancelled,
  ].includes(transaction.sellerBankAccountRequestStatus);

  return (
    <Card w="full">
      <CardHeader w="full" as={HStack} justifyContent="space-between">
        <Text textStyle="colfax-22-medium">{t(`transaction_status`)}</Text>
        {!isTransactionInTerminalState && (
          <TransactionStatusActions transaction={transaction} />
        )}
      </CardHeader>
      <TransactionStatusCardBody>
        <HStack justifyContent="space-between" w="full" alignItems="flex-start">
          <Text textStyle="colfax-14-medium-uncased">
            {t(`transaction_pipeline_status`)}
          </Text>
          {isTransactionInTerminalState ? (
            <Badge variant="plain" fontWeight="medium">{t`terminal`}</Badge>
          ) : (
            <Status.Indicator
              fontWeight="medium"
              color={!isTransactionInPipeline ? grey300 : `auto`}
              text={transactionStateText}
              indicatorProps={indicatorStyle}
            />
          )}
        </HStack>
      </TransactionStatusCardBody>
      {shouldShowBankAccountCollection && (
        <TransactionStatusCardBody>
          <HStack
            justifyContent="space-between"
            w="full"
            alignItems="flex-start"
          >
            <Text textStyle="colfax-14-medium-uncased">
              {t(`bank_account_collection`)}
            </Text>
            {shouldShowBankAccountRequestStatus ? (
              <Text>
                {t(
                  transactionBankAccountRequestStatusToi18nKeys(
                    transaction.sellerBankAccountRequestStatus,
                  ),
                )}
              </Text>
            ) : (
              getBankAccountStatusByVerificationStatus(
                transaction.sellerBankAccount?.verificationStatus,
              )
            )}
          </HStack>
        </TransactionStatusCardBody>
      )}
      <TransactionStatusCardBody>
        <VStack gap={2}>
          <HStack justifyContent="space-between" w="full">
            <Text textStyle="colfax-14-medium-uncased">
              {t(`latest_transition_date`)}
            </Text>
            <Text>{formatToLocalTimezone(transaction.stateChangedAt)}</Text>
          </HStack>
          {showExpiresAt && (
            <HStack justifyContent="space-between" w="full">
              <Text textStyle="colfax-14-medium-uncased">{t(`expires`)}</Text>
              <Text data-testid="transaction-expiry-date">
                {formatToLocalTimezone(transaction.expireAt)}
              </Text>
            </HStack>
          )}
        </VStack>
      </TransactionStatusCardBody>
      <TransactionStatusCardBody>
        <VStack gap={2}>
          <HStack justifyContent="space-between" w="full">
            <Text textStyle="colfax-14-medium" textTransform="uppercase">
              {t(`show_in_price_graph`)}
            </Text>
            <Text>
              <TransactionIgnoreInPricingToggle
                id={transaction.id}
                displayId={transaction.bid.displayId}
                ignoreInPricing={transaction.ignoreInPricing}
              />
            </Text>
          </HStack>
        </VStack>
      </TransactionStatusCardBody>
    </Card>
  );
}

function TransactionRightPanel({
  data,
  refetch,
}: {
  data?: TransactionPageQuery;
  refetch: UseQueryExecute;
}) {
  const { t } = useTranslation();
  const document = data?.transaction?.document;
  const documentType = data?.transaction?.documentType;

  const hasPendingModification = data?.transaction?.pendingModification;

  const transaction = data?.transaction;
  const feeDiscountApplications =
    data?.transaction?.feeDiscountApplications || [];

  const feeDiscountApplicationModifications =
    data?.transaction?.pendingModification?.feeDiscountApplications || [];

  if (!transaction) {
    return null;
  }

  const { pendingModification } = transaction;

  return (
    <VStack gap={4}>
      {!!pendingModification && (
        <ProposedTransactionModificationCard
          transaction={transaction}
          modification={pendingModification}
          onSuccess={refetch}
        />
      )}
      {transaction.stateChangedAt && (
        <TransactionStatusCard transaction={transaction} />
      )}

      {document?.downloadUrl && (
        <DocumentCard
          url={document.downloadUrl}
          header={t(`transaction_documents`)}
          textLink={t(documentLinkText(documentType))}
        />
      )}

      <FeeDiscountsCard
        entity={{
          entityId: transaction.id,
          entityType: FeeDiscountEntityType.Transaction,
          transaction,
          state: transaction.state,
        }}
        feeDiscountApplications={
          hasPendingModification
            ? feeDiscountApplicationModifications
            : feeDiscountApplications
        }
        onSuccess={refetch}
      />
    </VStack>
  );
}

export default withLDConsumer()(TransactionRightPanel);
