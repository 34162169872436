import { ConfirmModal } from "@/modules/Modal";
import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import { Box, HStack, Text, VStack, Divider } from "@chakra-ui/react";
import { Input, InputWrapper, AreaInput } from "@/modules/Form";
import { formatCurrencyCents, formatShares } from "@/modules/NumeralFormat";
import { TransactionsTableNodeFragment } from "@/graphql";
import { useColors } from "@/modules/Theme";
import * as yup from "yup";
import {
  useForm,
  SubmitHandler,
  Controller,
  UseFormRegister,
  Control,
  FieldErrors,
  UseFormResetField,
  UseFormSetValue,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { WarningCircle } from "@phosphor-icons/react";
import { StatusBadge } from "@/modules/StatusBadge";

import MuteNotificationWithReasonSection, {
  muteNotificationValidationSchema,
} from "./MuteNotificationWithReasonSection";

type TransactionModificationFormValueType = {
  readonly numOfShares: number;
  readonly pricePerShare: number;
  readonly reason?: string | null;
  readonly muteNotificationReason: string | null;
};

type TransactionModificationModalProps = {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly onConfirm: ({
    transactionId,
    numOfShares,
    pricePerShare,
    reason,
    muteNotificationReason,
  }: TransactionModificationFormValueType & {
    readonly transactionId: string;
  }) => void;
  readonly transaction: TransactionsTableNodeFragment;
};

type InitialTransactionValuesType = {
  readonly initialNumShares: number;
  readonly initialPricePerShare: number;
};

type TransactionValuesType = {
  readonly numShares: number;
  readonly pricePerShare: number;
};

function TransactionValues({
  initialNumShares,
  initialPricePerShare,
}: InitialTransactionValuesType) {
  const { t } = useTranslation();
  const [grey50] = useColors([`grey.50`]);

  return (
    <Box
      px={3}
      py={2}
      backgroundColor={grey50}
      flexDirection="column"
      w="full"
      justifyContent="space-between"
      borderRadius="md"
    >
      <Text textStyle="text-xs">{t`current_transaction_values`}</Text>
      <HStack justifyContent="space-between" w="full">
        <Text fontWeight={500}>{`${formatShares(
          initialNumShares,
        )} Shares @ ${formatCurrencyCents(initialPricePerShare)}`}</Text>
        <Text>
          {formatCurrencyCents(initialNumShares * initialPricePerShare)}
        </Text>
      </HStack>
    </Box>
  );
}

function ProposedTransactionValues({
  initialNumShares,
  numShares,
  initialPricePerShare,
  pricePerShare,
}: InitialTransactionValuesType & TransactionValuesType) {
  const { t } = useTranslation();

  const [black, grey200] = useColors([`black`, `grey.200`]);
  return (
    <Box
      px={3}
      py={2}
      border={`solid 1px ${black}`}
      flexDirection="column"
      w="full"
      justifyContent="space-between"
      borderRadius="md"
    >
      <Text textStyle="text-xs">{t`proposed_transaction_values`}</Text>
      <HStack justifyContent="space-between" w="full">
        <Text fontWeight={500}>
          <Text color={grey200} as="del">
            {numShares !== initialNumShares
              ? `${formatShares(initialNumShares)} `
              : null}
          </Text>
          {`${formatShares(numShares)} Shares @`}
          <Text color={grey200} as="del">
            {pricePerShare !== initialPricePerShare / 100
              ? `${formatCurrencyCents(initialPricePerShare)} `
              : null}
          </Text>
          {`${formatCurrencyCents(pricePerShare * 100)}`}
        </Text>
        <Text>{formatCurrencyCents(numShares * pricePerShare * 100)}</Text>
      </HStack>
    </Box>
  );
}

function TransactionModificationSummary({
  initialNumShares,
  numShares,
  initialPricePerShare,
  pricePerShare,
  isNotificationMuted,
}: InitialTransactionValuesType &
  TransactionValuesType & {
    readonly isNotificationMuted: boolean;
  }) {
  const { t } = useTranslation();

  return (
    <>
      {isNotificationMuted && (
        <VStack alignItems="flex-start">
          <StatusBadge
            variant="yellow-bordered"
            icon={<WarningCircle weight="fill" width={12} height={12} />}
            title={t(`notification_muted`)}
            mb={4}
          />
        </VStack>
      )}
      <VStack spacing={4}>
        <Text>{t`transaction_modification_summary`}</Text>
        <TransactionValues
          initialNumShares={initialNumShares}
          initialPricePerShare={initialPricePerShare}
        />
        <ProposedTransactionValues
          numShares={numShares}
          initialPricePerShare={initialPricePerShare}
          pricePerShare={pricePerShare}
          initialNumShares={initialNumShares}
        />
      </VStack>
    </>
  );
}

function TransactionModificationForm({
  initialNumShares,
  initialPricePerShare,
  register,
  control,
  errors,
  resetField,
  setValue,
}: {
  readonly initialNumShares: number;
  readonly initialPricePerShare: number;
  readonly register: UseFormRegister<TransactionModificationFormValueType>;
  readonly control: Control<TransactionModificationFormValueType>;
  readonly errors: FieldErrors<TransactionModificationFormValueType>;
  readonly resetField: UseFormResetField<TransactionModificationFormValueType>;
  readonly setValue: UseFormSetValue<TransactionModificationFormValueType>;
}) {
  const { t } = useTranslation();

  return (
    <VStack spacing={2} alignItems="flex-start" w="full">
      <TransactionValues
        initialNumShares={initialNumShares}
        initialPricePerShare={initialPricePerShare}
      />
      <Text mt={2} mb="5">
        {t(`modify_transaction_modal_description`)}
      </Text>
      <VStack spacing={5} w="full">
        <Controller
          control={control}
          name="numOfShares"
          render={({ field: { onChange, value, name, ...fieldProps } }) => (
            <InputWrapper
              name={name}
              label={t(`number_of_shares`)}
              error={errors.numOfShares}
            >
              <Input.Decimal
                name={name}
                onChange={async (data) => {
                  const rawData = data.target.value.replace(/[^0-9.]/g, ``);
                  const updatedNumOfShares = parseFloat(
                    rawData.length === 0 ? `0` : rawData,
                  );
                  onChange(updatedNumOfShares);
                  const updatedSelectionStart =
                    formatShares(updatedNumOfShares).length -
                    data.target.value.length +
                    data.target.selectionStart;
                  Promise.resolve().then(() => {
                    data.target.selectionStart = updatedSelectionStart; // eslint-disable-line
                    data.target.selectionEnd = updatedSelectionStart; // eslint-disable-line
                  });
                }}
                value={value === 0 ? `` : formatShares(value)}
                {...fieldProps}
                onBlur={async (_ev) => false}
              />
            </InputWrapper>
          )}
        />
        <Controller
          control={control}
          name="pricePerShare"
          render={({ field: { onChange, value, name, ...fieldProps } }) => (
            <InputWrapper
              name={name}
              label={t(`price_per_share`)}
              error={errors.pricePerShare}
            >
              <Input.Money
                name={name}
                value={value?.toFixed(2)}
                onChange={(val) => {
                  onChange(parseFloat(val === `` ? `0` : val));
                }}
                {...fieldProps}
              />
            </InputWrapper>
          )}
        />

        <AreaInput
          label={t(`modification_notes`)}
          helperText={t(`internal_use`)}
          placeholder={t(`transaction_modification_textarea_placeholder`)}
          error={errors.reason}
          {...register(`reason`)}
        />
        <Divider borderColor="grey.200" borderWidth="1" />
        <MuteNotificationWithReasonSection
          name="muteNotificationReason"
          error={errors.muteNotificationReason}
          register={register}
          resetField={resetField}
          setValue={setValue}
        />
      </VStack>
    </VStack>
  );
}

function TransactionModificationModal({
  isOpen,
  onClose,
  transaction,
  onConfirm,
}: TransactionModificationModalProps) {
  const [modalStep, setModalStep] = useState<number>(0);
  const initialNumShares = transaction.numShares;
  const initialPricePerShare = transaction.pricePerShare;
  const { t } = useTranslation();

  const modificationValidation = muteNotificationValidationSchema.shape({
    numOfShares: yup
      .number()
      .min(1, t`transaction_modification_min_num_shares_error`)
      .required(),
    pricePerShare: yup
      .number()
      .min(0.01, t`transaction_modification_min_price_per_share_error`)
      .required(),
    reason: yup.string().nullable(),
  });

  const {
    register,
    handleSubmit,
    control,
    reset,
    resetField,
    watch,
    trigger,
    getFieldState,
    setValue,
    formState: { isValid, errors, isDirty },
  } = useForm({
    resolver: yupResolver(modificationValidation, {
      abortEarly: false,
      strict: false,
    }),
    defaultValues: {
      numOfShares: transaction.numShares,
      pricePerShare: transaction.pricePerShare / 100,
      muteNotificationReason: null,
    },
  });

  const dismissModal = useCallback(() => {
    reset();
    setModalStep(0);
    onClose();
  }, [reset, onClose, setModalStep]);

  const onSubmit: SubmitHandler<TransactionModificationFormValueType> =
    useCallback(
      async (data) => {
        if (modalStep === 0) {
          if (isValid) {
            setModalStep(1);
          } else {
            await trigger();
          }
          return;
        }
        onConfirm({
          transactionId: transaction.id,
          numOfShares: data.numOfShares,
          pricePerShare: data.pricePerShare,
          reason: data.reason,
          muteNotificationReason: data.muteNotificationReason,
        });
        dismissModal();
      },
      [modalStep, isValid, onConfirm, dismissModal, transaction, trigger],
    );

  const isNotificationMuted = !!watch(`muteNotificationReason`);
  const numOfShares = watch(`numOfShares`);
  const pricePerShare = watch(`pricePerShare`);

  const disableConfirm =
    !isDirty ||
    (!getFieldState(`numOfShares`).isDirty &&
      !getFieldState(`pricePerShare`).isDirty);

  return (
    <ConfirmModal
      size="lg"
      isOpen={isOpen}
      title={t(
        modalStep === 0
          ? `propose_modification`
          : `confirm_proposed_modification`,
      )}
      body={
        modalStep === 0 ? (
          <TransactionModificationForm
            initialPricePerShare={initialPricePerShare}
            initialNumShares={initialNumShares}
            resetField={resetField}
            register={register}
            control={control}
            setValue={setValue}
            errors={errors}
          />
        ) : (
          <TransactionModificationSummary
            initialNumShares={initialNumShares}
            initialPricePerShare={initialPricePerShare}
            numShares={numOfShares}
            pricePerShare={pricePerShare}
            isNotificationMuted={isNotificationMuted}
          />
        )
      }
      disableConfirm={disableConfirm}
      onConfirm={handleSubmit(onSubmit)}
      onClose={dismissModal}
      cancelButtonVariant="destructive"
    />
  );
}

export default TransactionModificationModal;
