import { TransactionState } from "@/graphql";
import { match } from "ts-pattern";

function getTransactionStateIndicatorColor(
  transactionState?: TransactionState,
): string {
  return match(transactionState)
    .with(TransactionState.AwaitingClosing, () => `sky.600`)
    .with(TransactionState.BidAccepted, () => `sky.600`)
    .with(TransactionState.Cancelled, () => `archived`)
    .with(TransactionState.ClosedFeePaid, () => `active`)
    .with(TransactionState.ClosedFeePending, () => `sky.600`)
    .with(TransactionState.Expired, () => `disabled`)
    .with(TransactionState.InReview, () => `sky.600`)
    .with(TransactionState.IssuerApprovalDeclined, () => `archived`)
    .with(TransactionState.IssuerApproved, () => `sky.600`)
    .with(TransactionState.IssuerPendingApproval, () => `sky.600`)
    .with(TransactionState.Pending, () => `sky.600`)
    .otherwise(() => `disabled`);
}

export function getTransactionStateIndicatorStyle(
  transactionState: TransactionState,
  isTransactionOnHold: boolean,
) {
  return isTransactionOnHold
    ? {
        bg: `white`,
        border: `dotted 2px`,
        borderColor: `grey.300`,
      }
    : {
        bg: getTransactionStateIndicatorColor(transactionState),
      };
}
