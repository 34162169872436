import { Card, CardBody, CardHeader, HStack } from "@chakra-ui/react";
import { Link, LinkTarget } from "@/modules/Navigation";

import { Link as LinkIcon } from "@phosphor-icons/react";
import { useColors } from "@/modules/Theme";

interface DocumentCardProps {
  url: string;
  header: string;
  textLink: string;
}

export default function DocumentCard({
  url,
  header,
  textLink,
}: DocumentCardProps) {
  const [grey600] = useColors([`grey.600`]);

  return (
    <Card w="full">
      <CardHeader textStyle="colfax-22-medium">{header}</CardHeader>
      <CardBody>
        <HStack>
          <LinkIcon size={18} color={grey600} />
          <Link href={url} target={LinkTarget.BLANK} textDecoration="underline">
            {textLink}
          </Link>
        </HStack>
      </CardBody>
    </Card>
  );
}
