import {
  TransactionPageV2TransactionFragment,
  TransactionPageV2UserFragment,
  TransactionPageV2RepresentedUserFragment,
  InvestorType,
  InvestorStatus,
} from "@/graphql";
import { StatusBadge } from "@/modules/StatusBadge";
import { CardTable } from "@/modules/CardTable";
import { Link } from "@/modules/Navigation";
import { useColors } from "@/modules/Theme";
import { useCustomToast } from "@/modules/Toast";
import {
  Card,
  CardBody,
  CardHeader,
  HStack,
  Text,
  VStack,
  Button,
  IconButton,
} from "@chakra-ui/react";
import { CaretRight, CopySimple } from "@phosphor-icons/react";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

function CounterpartyCard({ children }: { readonly children: ReactNode }) {
  return (
    <Card borderStyle="solid" borderWidth="1px" borderColor="grey.50">
      <CardBody bg="grey.25" p={0} px={3} py={3} borderRadius="md">
        {children}
      </CardBody>
    </Card>
  );
}

function Email({ email }: { readonly email: string }) {
  const { t } = useTranslation();
  const { infoToastCondensed } = useCustomToast();
  const [grey900, grey100, sky600] = useColors([
    `grey.900`,
    `grey.100`,
    `sky.600`,
  ]);

  return (
    <HStack>
      <Text>{email}</Text>
      <IconButton
        // eslint-disable-next-line i18next/no-literal-string
        aria-label="copy-email"
        variant="outline"
        minW={0}
        minH={0}
        w={6}
        h={6}
        borderColor={grey100}
        onClick={() => {
          navigator.clipboard.writeText(email);
          infoToastCondensed(t(`copied_to_clipboard`), {
            icon: <CopySimple size={24} weight="fill" color={sky600} />,
          });
        }}
        icon={<CopySimple size={12} color={grey900} />}
      />
    </HStack>
  );
}

function FullDetailsButton({ userId }: { readonly userId: string }) {
  const { t } = useTranslation();

  return (
    <Link href={`/users/${userId}`}>
      <Button variant="outline" size="md" rightIcon={<CaretRight />}>
        {t(`full_details`)}
      </Button>
    </Link>
  );
}

function RepresentedUserCounterpartyCard({
  representedUser,
}: {
  readonly representedUser: TransactionPageV2RepresentedUserFragment;
}) {
  const { t } = useTranslation();

  return (
    <CounterpartyCard>
      <VStack alignItems="flex-start">
        <Text textStyle="text-sm" color="grey.600">
          {t(`represented_user`)}
        </Text>
        <Text textStyle="heading-md">{`${representedUser.firstName} ${representedUser.lastName}`}</Text>
        {!!representedUser.email && <Email email={representedUser.email} />}
      </VStack>
    </CounterpartyCard>
  );
}

function SellerCounterpartyCard({
  seller,
}: {
  readonly seller: TransactionPageV2UserFragment;
}) {
  const title = match(seller)
    .with(
      {
        investorType: InvestorType.UnaccreditedSeller,
      },
      () => `Unaccredited Seller`,
    )
    .with({ investorType: InvestorType.Broker }, () => `Broker`)
    .with({ investorStatus: InvestorStatus.Institutional }, () => `Institution`)
    .otherwise(() => `Seller`);

  return (
    <CounterpartyCard>
      <VStack alignItems="flex-start">
        <StatusBadge title={title} variant="olive" />
        <Text textStyle="heading-md">{seller.name}</Text>
        {!!seller.email && <Email email={seller.email} />}
        <FullDetailsButton userId={seller.id} />
      </VStack>
    </CounterpartyCard>
  );
}

function BuyerCounterpartyCard({
  buyer,
}: {
  readonly buyer: TransactionPageV2UserFragment;
}) {
  const title = match(buyer)
    .with({ investorType: InvestorType.Broker }, () => `Broker`)
    .with({ investorStatus: InvestorStatus.Institutional }, () => `Institution`)
    .otherwise(() => `Buyer`);

  return (
    <CounterpartyCard>
      <VStack alignItems="flex-start">
        <StatusBadge title={title} variant="plum" />
        <Text textStyle="heading-md">{buyer.name}</Text>
        {!!buyer.email && <Email email={buyer.email} />}
        <FullDetailsButton userId={buyer.id} />
      </VStack>
    </CounterpartyCard>
  );
}

function HiiveCounterpartyCard({
  user,
}: {
  readonly user: TransactionPageV2UserFragment;
}) {
  return (
    <CounterpartyCard>
      <VStack alignItems="flex-start">
        <Text textStyle="heading-md">{user.name}</Text>
        {!!user.email && <Email email={user.email} />}
        <FullDetailsButton userId={user.id} />
      </VStack>
    </CounterpartyCard>
  );
}

function CounterpartiesCard({
  transaction,
}: {
  readonly transaction: TransactionPageV2TransactionFragment;
}) {
  const { t } = useTranslation();

  const {
    buyerBroker,
    representedBuyer,
    representedSeller,
    sellerBroker,
    seller,
    buyer,
  } = transaction;

  const isHiiveSeller = !!representedSeller || !!sellerBroker;
  const isHiiveBuyer = !!representedBuyer || !!buyerBroker;

  return (
    <Card w="full">
      <CardHeader>
        <Text textStyle="heading-2xl">{t(`counterparties`)}</Text>
      </CardHeader>
      <CardBody>
        <CardTable w="full" columns={2}>
          <Card variant="table">
            <CardHeader
              color="grey.900"
              textTransform="none"
              textStyle="heading-xs"
            >
              {t(`buyer`)}
            </CardHeader>
            <CardBody>
              {!!representedSeller && (
                <RepresentedUserCounterpartyCard
                  representedUser={representedSeller}
                />
              )}
              <BuyerCounterpartyCard buyer={buyerBroker || buyer} />
              {isHiiveBuyer && <HiiveCounterpartyCard user={buyer} />}
            </CardBody>
          </Card>
          <Card variant="table">
            <CardHeader
              color="grey.900"
              textTransform="none"
              textStyle="heading-xs"
            >
              {t(`seller`)}
            </CardHeader>
            <CardBody>
              {!!representedBuyer && (
                <RepresentedUserCounterpartyCard
                  representedUser={representedBuyer}
                />
              )}
              <SellerCounterpartyCard seller={sellerBroker || seller} />
              {isHiiveSeller && <HiiveCounterpartyCard user={seller} />}
            </CardBody>
          </Card>
        </CardTable>
      </CardBody>
    </Card>
  );
}

export default CounterpartiesCard;
