import { Skeleton } from "@/modules/Skeleton";

import {
  Table as ChakraTable,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

interface TableSkeletonProps {
  columns?: number;
  rows?: number;
}

export default function TableSkeleton({
  columns = 5,
  rows = 10,
}: TableSkeletonProps) {
  return (
    <TableContainer borderWidth={1} borderColor="grey.100" borderTop="unset">
      <ChakraTable size="sm">
        <Thead>
          <Tr borderColor="grey.100 !important" borderY="1px">
            {Array.from({ length: columns }).map((_, index) => (
              <Th
                // eslint-disable-next-line react/no-array-index-key
                key={`${new Date().toISOString()}-${index}`}
                borderColor="grey.100"
                bg="grey.25"
                py={3}
              >
                <Skeleton h={5} w={20} />
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {Array.from({ length: rows }).map((_, index) => (
            <Tr
              // eslint-disable-next-line react/no-array-index-key
              key={`${new Date().toISOString()}-${index}`}
              bg={index % 2 ? `grey.25` : `white`}
              border="none"
            >
              {Array.from({ length: columns }).map((_, index) => (
                <Td // eslint-disable-next-line react/no-array-index-key
                  key={`${new Date().toISOString()}-${index}`}
                  border="none"
                >
                  <Skeleton h={5} w={20} />
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </ChakraTable>
    </TableContainer>
  );
}
