import {
  ExecutionManualConfig,
  ExecutionMilestone,
  ExecutionMilestoneConfig,
  ExecutionStepType,
  ExecutionTaskActorType,
  ExecutionAnvilConfig,
  ExecutionAnvilV2Config,
} from "@/graphql";
import { OverlappingTags } from "@/modules/OverlappingTags";
import { getFutureTasks, UserTypeTag } from "@/modules/Task";
import { WorkflowStepNodeType } from "@/modules/Workflow";
import { Box, Card, CardBody, HStack, Text, VStack } from "@chakra-ui/react";
import { sentenceCase } from "change-case";
import { useTranslation } from "react-i18next";
import { StepIcon } from "@/modules/Step";
import { ANVIL_STEP, ANVIL_V2_STEP, MANUAL_STEP } from "./constants";

type NodeData = {
  name?: string;
  type?: ExecutionStepType;
  visibility?: ExecutionTaskActorType[];
  config:
    | ExecutionManualConfig
    | ExecutionMilestoneConfig
    | ExecutionAnvilConfig
    | ExecutionAnvilV2Config;
};

function initialMilestoneConfig(milestoneType: ExecutionMilestone): NodeData {
  return {
    visibility: [ExecutionTaskActorType.Hiive],
    config: {
      __typename: `ExecutionMilestoneConfig` as const,
      milestone: milestoneType,
    },
  };
}

function getUnusedMilestones(nodes: WorkflowStepNodeType[]) {
  const usedMilestones = nodes
    .filter(
      (node) => node.data.config.__typename === `ExecutionMilestoneConfig`,
    )
    .map(
      (node) =>
        node.data.config.__typename === `ExecutionMilestoneConfig` &&
        node.data.config.milestone,
    );

  const allMilestones = Object.values(ExecutionMilestone);
  const unusedMilestonesList = allMilestones.filter(
    (milestone) => !usedMilestones.includes(milestone),
  );

  const totalMilestoneNodes = allMilestones.length;
  const unusedMilestoneNodes = totalMilestoneNodes - usedMilestones.length;

  return {
    totalMilestoneNodes,
    unusedMilestoneNodes,
    unusedMilestonesList,
  };
}

function BuilderStepCard({
  stepType,
  nodeData,
  title,
}: {
  readonly stepType: ExecutionStepType;
  readonly nodeData: NodeData;
  readonly title?: string;
}) {
  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    e.dataTransfer.setData(`stepType`, stepType);
    if (nodeData) e.dataTransfer.setData(`nodeData`, JSON.stringify(nodeData));
  };

  const tasks = getFutureTasks(nodeData.config);
  const assignees = [...new Set(tasks.map((task) => task.actorType))];

  return (
    <Card
      borderWidth="1px"
      borderRadius="md"
      boxShadow="0px 2px 2px rgba(0, 0, 0, 0.03)"
      gap={0}
      alignItems="flex-start"
      w="full"
      p={2}
      cursor="grab"
      draggable
      onDragStart={handleDragStart}
    >
      <HStack justifyContent="space-between" w="full">
        <HStack>
          <Box minW="auto" w={6} h={6}>
            <StepIcon stepType={stepType} />
          </Box>
          <Box flex="1" minW="0">
            <Text
              textStyle="colfax-16-medium"
              noOfLines={1}
              textTransform="capitalize"
            >
              {nodeData.name || title}
            </Text>
          </Box>
        </HStack>
        <Box>
          <OverlappingTags skipWrap>
            {assignees?.map((assignee) => (
              <UserTypeTag assignee={assignee} key={assignee} />
            ))}
          </OverlappingTags>
        </Box>
      </HStack>
    </Card>
  );
}

export function WorkflowBuilder({
  nodes,
}: {
  readonly nodes: WorkflowStepNodeType[];
}) {
  const { t } = useTranslation();

  const { totalMilestoneNodes, unusedMilestoneNodes, unusedMilestonesList } =
    getUnusedMilestones(nodes);

  return (
    <>
      <VStack
        gap={1}
        p={4}
        w="full"
        alignItems="flex-start"
        borderBottomWidth="1px"
        borderBottomColor="grey.100"
      >
        <Text textStyle="heading-2xl">{t(`builder`)}</Text>
        <Text textStyle="colfax-14-regular">
          {t(`drag_and_drop_in_steps_and_milestones`)}
        </Text>
      </VStack>
      <VStack h="full" p={4} alignItems="flex-start">
        <VStack w="full" alignItems="flex-start" pt={4} spacing={4}>
          <Text textStyle="colfax-14-medium">{t(`steps`)}</Text>
          <BuilderStepCard stepType={MANUAL_STEP.type} nodeData={MANUAL_STEP} />
          <BuilderStepCard
            stepType={ANVIL_STEP.TRANSACTION_CONFIRMATION.type}
            nodeData={ANVIL_STEP.TRANSACTION_CONFIRMATION}
          />
          <BuilderStepCard
            stepType={ANVIL_STEP.INFORMATION_COLLECTION.type}
            nodeData={ANVIL_STEP.INFORMATION_COLLECTION}
          />
          <BuilderStepCard
            stepType={ANVIL_V2_STEP.SHARE_TRANSFER_AGREEMENT.type}
            nodeData={ANVIL_V2_STEP.SHARE_TRANSFER_AGREEMENT}
          />
        </VStack>
        <VStack w="full" alignItems="flex-start" pt={4} spacing={4}>
          <HStack>
            <Text textStyle="colfax-14-medium">{t(`milestones`)}</Text>
            <Text textStyle="colfax-14-regular">
              ({t(`available`)} {unusedMilestoneNodes}/{totalMilestoneNodes})
            </Text>
          </HStack>
          {unusedMilestonesList.map((milestone) => (
            <BuilderStepCard
              stepType={ExecutionStepType.Milestone}
              title={sentenceCase(milestone)}
              key={milestone}
              nodeData={initialMilestoneConfig(milestone)}
            />
          ))}
          {unusedMilestonesList.length === 0 && (
            <Card variant="flat" width="full">
              <CardBody p={2} px={4}>
                <Text textStyle="heading-md" color="grey.500">
                  {t(`all_milestones_active`)}
                </Text>
              </CardBody>
            </Card>
          )}
        </VStack>
      </VStack>
    </>
  );
}
