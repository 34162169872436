import { Box, Button, HStack, Text, VStack } from "@chakra-ui/react";
import { Plus } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import { CompaniesTableProvider } from "@/features/Companies";
import CompaniesTable, {
  CompaniesUrlParams,
} from "./components/CompaniesTable";

function CompaniesPage({ query }: { query: CompaniesUrlParams }) {
  const { t } = useTranslation();
  const { push } = useRouter();

  return (
    <VStack p={8} gap={0} alignItems="flex-start">
      <HStack w="full" alignItems="center">
        <Text textStyle="colfax-22-medium">{t(`companies`)}</Text>
        <Box flexGrow={1} />
        <Button
          variant="solid"
          size="lg"
          leftIcon={<Plus />}
          onClick={async () => {
            await push(`/companies/create`);
          }}
        >
          {t(`add_company`)}
        </Button>
      </HStack>
      <CompaniesTableProvider query={query}>
        <CompaniesTable />
      </CompaniesTableProvider>
    </VStack>
  );
}

export default CompaniesPage;
