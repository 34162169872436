import { BankAccountRegion } from "@/graphql";
import { Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

type BankAccountRegionOptionOption = {
  option: BankAccountRegion;
};

export default function BankAccountRegionOption({
  option,
}: BankAccountRegionOptionOption) {
  const { t } = useTranslation();
  const labelMap: Record<BankAccountRegion, "string"> = {
    [BankAccountRegion.Europe]: t(`european_account`),
    [BankAccountRegion.Other]: t(`other_account`),
    [BankAccountRegion.Us]: t(`us_account`),
  };

  return (
    <Flex alignItems="center" justifyContent="space-between" h={8} px={2}>
      <Text fontWeight={400} fontSize={16}>
        {labelMap[option]}
      </Text>
    </Flex>
  );
}
