import {
  BankAccountCardBankAccountFragment,
  BankAccountCardTransactionFragment,
  BankAccountVerificationStatus,
  EntityCardEntityFragment,
  ManuallyLinkBankAccountToTransactionInput,
} from "@/graphql";
import { formatCurrencyCents, formatShares } from "@/modules/NumeralFormat";
import {
  Box,
  Button,
  Card,
  CardBody,
  Divider,
  Fade,
  HStack,
  Image,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { ArrowSquareOut, CaretDown, CaretUp } from "@phosphor-icons/react";
import { useRouter } from "next/router";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  BankAccountActions,
  ManuallyLinkToTransactionModal,
  ViewModernTreasuryAccountButton,
} from "./BankAccountActions";
import BankAccountNumber from "./BankAccountNumber";
import BankAccountStatusBadge from "./BankAccountStatusBadge";

function BankAccountTransactionRow({
  transaction,
}: {
  readonly transaction: BankAccountCardTransactionFragment;
}) {
  const { numShares, pricePerShare } = transaction;
  const { push } = useRouter();

  return (
    <HStack
      justifyContent="space-between"
      my={4}
      onClick={() => push(`/transactions/${transaction.id}`)}
    >
      <HStack>
        <Box borderWidth="1px" borderRadius="md" color="grey.50" p="3px">
          {!!transaction.company.logoUrl && (
            <Image h="16px" w="16px" src={transaction.company.logoUrl} />
          )}
        </Box>
        <Text textStyle="colfax-14-medium">{transaction.bid.displayId}</Text>
      </HStack>
      <HStack>
        <Text>{`${formatShares(numShares)} @ ${formatCurrencyCents(
          pricePerShare,
        )}`}</Text>
        <Box w={1} />
        <Button variant="outline" p="0" h="6" border="none">
          <ArrowSquareOut size="16px" weight="bold" />
        </Button>
      </HStack>
    </HStack>
  );
}

type BankAccountCardProps = {
  readonly owningEntity: EntityCardEntityFragment;
  readonly bankAccount: BankAccountCardBankAccountFragment;
};

export default function BankAccountCard({
  bankAccount,
  owningEntity,
}: BankAccountCardProps) {
  const { t } = useTranslation(`users`);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const formMethods = useForm<ManuallyLinkBankAccountToTransactionInput>({
    defaultValues: {
      transactionId: ``,
      bankAccountId: bankAccount.id,
    },
  });
  const [showTransactions, setShowTransactions] = useState(false);

  const { last4, transactions, verificationStatus } = bankAccount;
  const hasTransactions = transactions.length > 0;

  const messageMap: Record<BankAccountVerificationStatus, React.ReactNode> = {
    [BankAccountVerificationStatus.Cancelled]: (
      <Text textStyle="colfax-14-regular">
        {t(`verification_cancelled_explainer`)}
      </Text>
    ),
    [BankAccountVerificationStatus.Failed]: (
      <Text textStyle="colfax-14-regular">
        {t(`verification_failed_explainer`)}
      </Text>
    ),
    [BankAccountVerificationStatus.Manual]: hasTransactions ? (
      <Text textStyle="colfax-14-medium">
        {t(`needs_to_be_manually_verified`)}
      </Text>
    ) : (
      <>
        <Button size="sm" onClick={onOpen}>
          {t(`add_transaction`)}
        </Button>
        <FormProvider {...formMethods}>
          <ManuallyLinkToTransactionModal
            sellerTransactions={owningEntity.sellerTransactions}
            bankAccount={bankAccount}
            isOpen={isOpen}
            onClose={() => onClose()}
          />
        </FormProvider>
      </>
    ),
    [BankAccountVerificationStatus.PendingVerification]: undefined,
    [BankAccountVerificationStatus.Unverified]: undefined,
    [BankAccountVerificationStatus.Verified]: undefined,
  };

  const showMessage = [
    BankAccountVerificationStatus.Cancelled,
    BankAccountVerificationStatus.Failed,
    BankAccountVerificationStatus.Manual,
  ].includes(verificationStatus);

  const showActions =
    [
      BankAccountVerificationStatus.Failed,
      BankAccountVerificationStatus.Manual,
      BankAccountVerificationStatus.PendingVerification,
    ].includes(verificationStatus) && hasTransactions;

  return (
    <Card w="full" variant="grey-footer">
      <CardBody>
        <HStack justifyContent="space-between" w="full">
          <HStack gap={6}>
            <BankAccountStatusBadge
              status={verificationStatus}
              hasTransactions={hasTransactions}
            />
            <VStack gap={0} alignItems="start">
              <HStack>
                <Text
                  fontSize={16}
                  fontWeight={500}
                  color="gray.900"
                  textTransform="capitalize"
                >
                  {t(`account`)}
                </Text>
                <BankAccountNumber bankAccount={{ last4 }} />
              </HStack>
              {hasTransactions ? (
                <Button
                  size="xs"
                  variant="ghost"
                  pl={0}
                  onClick={() => setShowTransactions(!showTransactions)}
                  _hover={{ bg: `none` }}
                  _focus={{ bg: `none` }}
                  _active={{ border: `none` }}
                >
                  <Text textStyle="colfax-14-regular" color="grey.900">
                    {t(`view_transactions`)}
                  </Text>
                  <Box w={1} />
                  {showTransactions ? <CaretUp /> : <CaretDown />}
                </Button>
              ) : (
                <Box pt={1}>
                  <Text textStyle="colfax-14-regular" color="grey.900">
                    {t(`no_transactions`)}
                  </Text>
                </Box>
              )}
            </VStack>
          </HStack>
          <HStack gap={3}>
            {showMessage && messageMap[verificationStatus]}
            <ViewModernTreasuryAccountButton bankAccount={bankAccount} />
            {showActions && <BankAccountActions bankAccount={bankAccount} />}
          </HStack>
        </HStack>
        {hasTransactions && showTransactions && (
          <Fade
            in={hasTransactions && showTransactions}
            transition={{ enter: { duration: 0.2 } }}
          >
            <VStack width="full" mt={4}>
              <CardBody width="full" p={0}>
                {transactions.map((transaction, index) => (
                  <>
                    <BankAccountTransactionRow
                      key={transaction.id}
                      transaction={transaction}
                    />
                    {!!transactions[index + 1] && <Divider color="grey.50" />}
                  </>
                ))}
              </CardBody>
            </VStack>
          </Fade>
        )}
      </CardBody>
    </Card>
  );
}
