import { Avatar } from "@chakra-ui/react";
import { taskUserTypeAvatarColor, taskUserTypeIcon } from "./utils";
import { UserType } from "./types";

interface UserTypeAvatarProps {
  readonly assignee: UserType;
}

function UserTypeAvatar({ assignee }: UserTypeAvatarProps) {
  return (
    <Avatar
      bg={taskUserTypeAvatarColor(assignee)}
      width="16px"
      height="16px"
      icon={taskUserTypeIcon(assignee)}
    />
  );
}

export default UserTypeAvatar;
