import { useFeeDiscountsCardActionsUnapplyFeeDiscountMutation } from "@/graphql";
import { handleGqlError } from "@/modules/Error";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

type UnapplyFeeDiscountProps = {
  data: { feeDiscountApplicationId: string };
  onSuccess: () => void;
  onFailure: (message?: string) => void;
};

export default function useUnapplyFeeDiscount() {
  const { t } = useTranslation();
  const [{ fetching }, unapplyFeeDiscountMutation] =
    useFeeDiscountsCardActionsUnapplyFeeDiscountMutation();

  const unapplyFeeDiscount = useCallback(
    async ({
      data: { feeDiscountApplicationId },
      onFailure,
      onSuccess,
    }: UnapplyFeeDiscountProps) => {
      if (!feeDiscountApplicationId) {
        onFailure(t(`select_invalid_option`));
        return;
      }

      const { data, error } = await unapplyFeeDiscountMutation({
        feeDiscountApplicationId,
      });

      if (error) {
        onFailure(handleGqlError(error));
      }
      if (data?.unapplyFeeDiscount?.feeDiscountApplication) {
        onSuccess();
      }
    },
    [unapplyFeeDiscountMutation, t],
  );

  return { fetching, unapplyFeeDiscount };
}
