import { HStack, IconButton, Text } from "@chakra-ui/react";
import { CaretLeft, CaretRight } from "@phosphor-icons/react";
import { DEFAULT_FETCH_LIMIT } from "@/constants";

interface TablePaginationProps {
  onPreviousPage: () => void;
  onNextPage: () => void;
  page: number;
  showPreviousPage: boolean;
  showNextPage: boolean;
  totalCount: number;
  loading: boolean;
  perPage?: number;
}

function TablePagination({
  onPreviousPage,
  onNextPage,
  page,
  showPreviousPage,
  showNextPage,
  totalCount,
  loading,
  perPage = DEFAULT_FETCH_LIMIT,
}: TablePaginationProps) {
  const renderPaginationText = () => {
    if (totalCount === 0) {
      return `No results`;
    }

    const start = (page - 1) * perPage + 1;
    const end = Math.min(page * perPage, totalCount);

    return `${start}-${end} of ${totalCount}`;
  };

  return (
    <HStack justifyContent="center" p={4}>
      <Text>{renderPaginationText()}</Text>
      <IconButton
        aria-label="Previous Page"
        icon={<CaretLeft />}
        size="xs"
        variant="outline"
        onClick={onPreviousPage}
        isDisabled={loading || !showPreviousPage}
      />
      <IconButton
        aria-label="Next Page"
        icon={<CaretRight />}
        size="xs"
        variant="outline"
        onClick={onNextPage}
        isDisabled={loading || !showNextPage}
      />
    </HStack>
  );
}

export default TablePagination;
