import assign from "lodash/assign";
import TableRoot from "./Table";
import TableActionButton from "./TableActionButton";
import TableError from "./TableError";
import TableLoading from "./TableLoading";
import TablePagination from "./TablePagination";
import TableTabs from "./TableTabs";
import TableSkeleton from "./TableSkeleton";

export {
  DEFAULT_TABLE_TOOLTIP_MAX_WIDTH,
  default as TableTooltipCell,
} from "./TableTooltipCell";

export const Table = assign(TableRoot, {
  Tabs: TableTabs,
  Pagination: TablePagination,
  Loading: TableLoading,
  ActionButton: TableActionButton,
  Error: TableError,
  Skeleton: TableSkeleton,
});

export { type TableColumns, type TableSortState } from "./Table";
