import {
  ExecutionMilestone,
  ExecutionStepStatus,
  ExecutionWorkflowStatus,
  TransactionPageV2TransactionFragment,
  TransactionPageV2ExecutionMilestoneFragment,
  TransactionPageV2ExecutionStepFragment,
  useTransactionPageV2TransactionQuery,
  TransactionPageV2WorkflowConditionFragment,
} from "@/graphql";
import { CopyToClipboard } from "@/modules/CopyToClipboard";
import { FeeBreakdown } from "@/modules/FeeBreakdown";
import { BackButton } from "@/modules/Navigation";
import {
  DateTimeFormat,
  formatCurrencyCents,
  formatDate,
  formatShares,
} from "@/modules/NumeralFormat";
import {
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  GridItem,
  HStack,
  Image,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  chakra,
  Text,
  VStack,
  useDisclosure,
  Button,
  Link,
  Flex,
  Divider,
} from "@chakra-ui/react";
import { PencilSimple } from "@phosphor-icons/react";
import { sentenceCase } from "change-case";
import { Fragment, useLayoutEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { match } from "ts-pattern";
import { ReactFlowProvider } from "@xyflow/react";
import { ErrorWrapper } from "@/modules/Error";
import { MilestoneStatusIcon } from "@/modules/Step";
import { getShareSeriesLongString } from "@/features/Transactions";
import {
  WorkflowPreview,
  WorkflowStatus,
  workflowConditionStatus,
  WorkflowConditionStatusIcon,
  WorkflowConditionCard,
} from "@/modules/Workflow";
// TODO remove this import when TEXAS is done
// eslint-disable-next-line no-restricted-imports
import { TransactionPageV1 } from "../TransactionPage";
import DetailsDrawer from "./DetailsDrawer";
import CounterpartiesCard from "./CounterpartiesCard";
import { StepCardBody } from "./StepCardBody";
import { updateInProgressMilestone } from "./utils";

function MilestoneRow({
  showLine = true,
  isActive = false,
  isCompleted = false,
  completedAt,
  milestone,
  conditions,
}: {
  readonly milestone: string;
  readonly showLine?: boolean;
  readonly isActive?: boolean;
  readonly isTerminated?: boolean;
  readonly isCompleted?: boolean;
  readonly completedAt?: string;
  readonly conditions: TransactionPageV2WorkflowConditionFragment[];
}) {
  const ref = useRef<HTMLDivElement | null>(null);

  const lineRef = useRef<SVGSVGElement | null>(null);

  const resizeObserverRef = useRef<ResizeObserver | null>(null);

  const padding = 8;
  const gap = 8;
  const borderWidth = 1;

  useLayoutEffect(() => {
    if (!ref.current || !lineRef.current) {
      if (!resizeObserverRef.current) return;

      resizeObserverRef.current.disconnect();
      return;
    }
    // eslint-disable-next-line functional/immutable-data
    resizeObserverRef.current = new ResizeObserver((entries) => {
      if (!lineRef.current) return;

      const {
        contentRect: { height },
      } = entries[0];

      const h = height + 2 * padding + 2 * borderWidth + gap;

      // eslint-disable-next-line functional/immutable-data
      lineRef.current.style.height = `${h}px`;
      if (!isCompleted) {
        // eslint-disable-next-line functional/immutable-data
        lineRef.current.style.strokeDasharray = `${3 / h}`;
      }
    });

    resizeObserverRef.current.observe(ref.current);

    // eslint-disable-next-line consistent-return
    return () => {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
    };
  }, [isCompleted]);

  const headingColor = match({ isCompleted, isActive })
    .with(
      {
        isCompleted: true,
      },
      () => `grey.900`,
    )
    .with({ isActive: true }, () => `purple.900`)
    .otherwise(() => `grey.600`);

  const status = match({ isCompleted, isActive })
    .with({ isCompleted: true }, () => ExecutionStepStatus.Completed)
    .with({ isActive: true }, () => ExecutionStepStatus.InProgress)
    .otherwise(() => ExecutionStepStatus.Pending);

  const overrideStatus =
    milestone === ExecutionMilestone.Approved && conditions.length > 0;

  return (
    <Box
      px={6}
      py={2}
      w="full"
      bg={isActive ? `purple.25` : `white`}
      as={chakra.button}
      textAlign="left"
      _hover={{
        bg: isActive ? `purple.25` : `grey.25`,
      }}
      _active={{
        bg: isActive ? `purple.50` : `grey.50`,
      }}
      transition="background-color .1s ease-in-out"
    >
      <HStack spacing={0} gap={3} alignItems="flex-start">
        <Box h="full" position="relative">
          {showLine && (
            <chakra.svg
              ref={lineRef}
              position="absolute"
              top="50%"
              left="50%"
              transform="auto"
              translateX="-50%"
              w="2px"
              stroke={isCompleted ? `purple.1000` : `purple.700`}
              viewBox="0 0 2 1"
              preserveAspectRatio="none"
            >
              <chakra.line y1={0} y2={1} x1={1} x2={1} strokeWidth={1} />
            </chakra.svg>
          )}
          <Box w={4} h={4} position="relative" color="purple.1000">
            {overrideStatus ? (
              conditions.map(({ condition }) => (
                <Fragment key={condition}>
                  <WorkflowConditionStatusIcon condition={condition} />
                </Fragment>
              ))
            ) : (
              <MilestoneStatusIcon status={status} />
            )}
          </Box>
        </Box>
        <VStack alignItems="flex-start" ref={ref} w="full">
          <HStack justifyContent="space-between" w="full">
            <Text
              textStyle="heading-3xs"
              lineHeight="16px"
              color={headingColor}
            >
              {sentenceCase(milestone)}
            </Text>
            <HStack>
              <Text textStyle="text-xs" lineHeight="16px">
                {completedAt &&
                  formatDate(completedAt, DateTimeFormat.shortDateWithTime)}
              </Text>
            </HStack>
          </HStack>
        </VStack>
      </HStack>
    </Box>
  );
}

function TransactionMilestones({
  milestoneOrder,
  milestoneCompletions,
  conditions,
}: {
  readonly milestoneOrder: ExecutionMilestone[];
  readonly milestoneCompletions: TransactionPageV2ExecutionMilestoneFragment[];
  readonly conditions: TransactionPageV2WorkflowConditionFragment[];
}) {
  const lastCompletedMilestone =
    milestoneCompletions[milestoneCompletions.length - 1]?.milestone;

  const activeMilestone =
    milestoneOrder[milestoneOrder.indexOf(lastCompletedMilestone) + 1];

  const getIsCompleted = (milestone: ExecutionMilestone) =>
    milestoneCompletions.map((m) => m.milestone).includes(milestone);

  const getCompletedAt = (milestone: ExecutionMilestone) =>
    milestoneCompletions[
      milestoneCompletions.findIndex((m) => m.milestone === milestone)
    ]?.completedAt || null;

  return (
    <VStack alignItems="start">
      <Text textStyle="heading-xl" px={6} pt={4}>
        <Trans i18nKey="transaction_status" />
      </Text>
      <VStack spacing={0} gap={2} w="full" pb={4}>
        {milestoneOrder.map((milestone, index) => (
          <MilestoneRow
            key={milestone}
            milestone={milestone}
            showLine={index !== milestoneOrder.length - 1}
            isCompleted={getIsCompleted(milestone)}
            isActive={activeMilestone === milestone}
            completedAt={getCompletedAt(milestone)}
            conditions={conditions}
          />
        ))}
      </VStack>
    </VStack>
  );
}

function TransactionOverviewCard({
  transaction,
}: {
  readonly transaction: TransactionPageV2TransactionFragment;
}) {
  const { t } = useTranslation();

  const {
    numShares,
    pricePerShare,
    workflow,
    bid: { listing },
  } = transaction;

  const grossProceeds = pricePerShare * numShares;

  const formattedNumShares = formatShares(numShares);
  const formattedPricePerShare = formatCurrencyCents(pricePerShare);
  const formattedGrossProceeds = formatCurrencyCents(grossProceeds);
  const shareSeriesMakeup = listing?.shareSeriesMakeup;
  const formattedShareSeries = getShareSeriesLongString(shareSeriesMakeup);
  const hasConditions = workflow?.conditions
    ? workflow?.conditions?.length > 0
    : false;

  return (
    <Card w="full">
      <CardHeader>
        <Text textStyle="heading-2xl">{t(`transaction_overview`)}</Text>
      </CardHeader>
      <CardBody p={0} borderBottom="none">
        <VStack alignItems="flex-start" gap={4} px={6} py={4}>
          <HStack w="full" justifyContent="space-between">
            <Text textStyle="colfax-16-medium">{t(`shares`)}</Text>
            <Text>{formattedNumShares}</Text>
          </HStack>
          <HStack w="full" justifyContent="space-between">
            <Text textStyle="colfax-16-medium">{t(`share_type`)}</Text>
            <Text>{formattedShareSeries}</Text>
          </HStack>
          <HStack w="full" justifyContent="space-between">
            <Text textStyle="colfax-16-medium">{t(`price_per_share`)}</Text>
            <Text>{formattedPricePerShare}</Text>
          </HStack>
          <HStack w="full" justifyContent="space-between">
            <Text textStyle="colfax-16-medium">{t(`total`)}</Text>
            <Text>{formattedGrossProceeds}</Text>
          </HStack>
        </VStack>
        {workflow && workflow?.status !== ExecutionWorkflowStatus.Pending && (
          <>
            <Divider borderColor="grey.100" />
            <TransactionMilestones
              milestoneOrder={workflow?.milestoneOrder}
              milestoneCompletions={workflow?.milestoneCompletions}
              conditions={workflow?.conditions}
            />
          </>
        )}
      </CardBody>
      {hasConditions && (
        <CardBody pt={0} p={6}>
          {workflow?.conditions.map(({ condition }) => (
            <WorkflowConditionCard key={condition} condition={condition} />
          ))}
        </CardBody>
      )}
    </Card>
  );
}

function TransactionPageV2({
  transactionId,
}: {
  readonly transactionId: string;
}) {
  const { t } = useTranslation();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [selectedStepId, setSelectedStepId] = useState<string | null>(null);

  const [{ data, error, fetching }] = useTransactionPageV2TransactionQuery({
    variables: { transactionId },
  });

  // TODO spinner
  if (fetching || !data?.transaction) return null;

  const { transaction } = data;

  const {
    numShares,
    pricePerShare,
    commission,
    feeDiscountApplications,
    workflow,
  } = transaction;

  const handleSelectStep = (step: TransactionPageV2ExecutionStepFragment) => {
    setSelectedStepId(step.id);

    onOpen();
  };

  if (!workflow) return <TransactionPageV1 transactionId={transactionId} />;

  const steps = updateInProgressMilestone(workflow.steps);

  const nonTerminatedSteps = steps.filter(
    (step) => step.status !== ExecutionStepStatus.Terminated,
  );

  const withInProgressEdges = [
    { id: `0`, fromId: steps[0].id, toId: steps[1].id },
    ...workflow.edges,
  ];

  const canEdit = workflow.status !== ExecutionWorkflowStatus.Completed;

  return (
    <ErrorWrapper error={error?.message}>
      <Box p={4} position="relative">
        {!!selectedStepId && (
          <DetailsDrawer
            isOpen={isOpen}
            onClose={onClose}
            selectedStepId={selectedStepId}
            workflow={workflow}
          />
        )}
        <Box p={4}>
          <BackButton />
        </Box>
        <Tabs variant="outline-black" isLazy>
          <SimpleGrid columns={12} gap={6}>
            <GridItem colSpan={8}>
              <VStack spacing={4}>
                <Card w="full">
                  <CardHeader>
                    <HStack justifyContent="space-between">
                      <HStack>
                        {!!transaction.company.logoUrl && (
                          <Box
                            w="10"
                            maxH="10"
                            borderRadius="md"
                            p={1.5}
                            borderWidth={1}
                            borderColor="grey.100"
                          >
                            <Image
                              width="100%"
                              height="100%"
                              src={transaction.company.logoUrl}
                            />
                          </Box>
                        )}
                        <Text textStyle="heading-3xl">
                          {transaction.company.name}
                        </Text>
                      </HStack>
                      <HStack>
                        {workflow.conditions.map(({ condition }) => (
                          <Fragment key={condition}>
                            {workflowConditionStatus[condition]}
                          </Fragment>
                        ))}
                        <WorkflowStatus workflow={workflow} />
                      </HStack>
                    </HStack>
                  </CardHeader>
                  <CardBody w="full">
                    <CopyToClipboard
                      label={t(`database_id`)}
                      value={transaction.id}
                    />
                  </CardBody>
                  <CardFooter overflow="hidden" bg="grey.15" py={4}>
                    <TabList h="12">
                      <Tab>{t(`tasks`)}</Tab>
                      <Tab>{t(`details`)}</Tab>
                      <Tab>{t(`workflow`)}</Tab>
                    </TabList>
                  </CardFooter>
                </Card>
                <TabPanels>
                  <TabPanel>
                    <Card
                      w="full"
                      overflow="hidden"
                      sx={{
                        "& > :last-child": {
                          borderBottomRadius: `md`,
                        },
                      }}
                    >
                      <CardHeader>
                        <Text textStyle="heading-2xl">
                          {t(`transaction_steps`)}
                        </Text>
                      </CardHeader>
                      {nonTerminatedSteps.map((step) => (
                        <StepCardBody
                          onClick={handleSelectStep}
                          key={step.id}
                          step={step}
                        >
                          {workflow.conditions.map(({ condition, stepId }) => {
                            if (stepId === step.id) {
                              return (
                                <Fragment key={condition}>
                                  {workflowConditionStatus[condition]}
                                </Fragment>
                              );
                            }
                            return null;
                          })}
                        </StepCardBody>
                      ))}
                    </Card>
                  </TabPanel>
                  <TabPanel>
                    <VStack spacing={4}>
                      <CounterpartiesCard transaction={transaction} />
                      <Card w="full">
                        <CardHeader textStyle="heading-2xl">
                          {t(`fees_&_proceeds`)}
                        </CardHeader>
                        <CardBody>
                          <FeeBreakdown
                            numSharesActual={numShares}
                            pricePerShare={pricePerShare}
                            feeDiscountApplications={feeDiscountApplications}
                            commission={commission}
                          />
                        </CardBody>
                      </Card>
                    </VStack>
                  </TabPanel>
                  <TabPanel>
                    <Card>
                      <CardHeader>
                        <HStack w="full" justifyContent="space-between">
                          <Text textStyle="heading-2xl">{t(`workflow`)}</Text>
                          <HStack spacing={6}>
                            <Text
                              textStyle="colfax-14-regular"
                              color="grey.600"
                            >
                              {t(`updated`)}
                              {` `}
                              {formatDate(
                                workflow.updatedAt,
                                DateTimeFormat.fullDateTime,
                              )}
                            </Text>
                            {canEdit && (
                              <Button
                                as={Link}
                                href={`/workflows/${workflow.id}`}
                                variant="outline"
                                size="sm"
                                leftIcon={<PencilSimple size={16} />}
                              >
                                <Text textStyle="colfax-12-medium">
                                  {t(`edit_workflow`)}
                                </Text>
                              </Button>
                            )}
                          </HStack>
                        </HStack>
                      </CardHeader>
                      <CardBody p={0} pb={2}>
                        <Flex flex="1">
                          <Box flex="1" minH={178}>
                            <ReactFlowProvider>
                              <WorkflowPreview
                                steps={steps}
                                stepEdges={withInProgressEdges}
                              />
                            </ReactFlowProvider>
                          </Box>
                        </Flex>
                      </CardBody>
                    </Card>
                  </TabPanel>
                </TabPanels>
              </VStack>
            </GridItem>
            <GridItem colSpan={4}>
              <TransactionOverviewCard transaction={transaction} />
            </GridItem>
          </SimpleGrid>
        </Tabs>
      </Box>
    </ErrorWrapper>
  );
}

export default TransactionPageV2;
