import { AddEntityInput, TransactionEntityFragment } from "@/graphql";
import { Stack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import CurrentEntityCard from "./CurrentEntityCard";
import NewEntityCard from "./NewEntityCard";

type ConfirmEntityChangesProps = {
  currentEntity: TransactionEntityFragment;
  newEntity: AddEntityInput;
};

function ConfirmEntityChanges({
  currentEntity,
  newEntity,
}: ConfirmEntityChangesProps) {
  const { t } = useTranslation();

  return (
    <Stack gap={4}>
      <Text>{t(`confirm_entity_changes_description`)}</Text>
      <NewEntityCard entity={newEntity} />
      <CurrentEntityCard entity={currentEntity} />
    </Stack>
  );
}

export default ConfirmEntityChanges;
