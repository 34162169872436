import {
  TransactionsTableDataType,
  TerminalTransactionStates,
  TransactionStatus,
} from "@/features/Transactions";
import {
  TransactionState,
  TransactionTableBidBrokerFragment,
  TransactionTableListingBrokerFragment,
} from "@/graphql";
import {
  DateTimeFormat,
  formatCurrencyCents,
  formatDate,
  formatShares,
} from "@/modules/NumeralFormat";
import { Status } from "@/modules/Status";
import { Box, Text } from "@chakra-ui/react";
import { capitalCase } from "change-case";

import {
  DEFAULT_TABLE_TOOLTIP_MAX_WIDTH,
  TableTooltipCell,
} from "@/modules/Table";
import { Cell } from "@tanstack/react-table";

type TableCellType = {
  readonly cell: Cell<TransactionsTableDataType, unknown>;
};

export function CompanyTableCell({ cell }: TableCellType) {
  const {
    bid: { company },
  } = cell.getContext().row.original;

  if (!company?.name) {
    return <Status.Empty />;
  }

  return (
    <Box display="inline-block">
      <Text>{company.name}</Text>
    </Box>
  );
}

export function UpdatedAtTableCell({ cell }: TableCellType) {
  const { updatedAt } = cell.getContext().row.original;

  if (!updatedAt) {
    return <Status.Empty />;
  }

  return (
    <Box display="inline-block" maxW="120px">
      {formatDate(updatedAt, DateTimeFormat.fullDateWithMonthShort)}
    </Box>
  );
}

export function DatabaseIDTableCell({ cell }: TableCellType) {
  const { id: transactionId } = cell.getContext().row.original;
  return (
    <TableTooltipCell
      id={transactionId}
      text={transactionId}
      maxW={{
        ...DEFAULT_TABLE_TOOLTIP_MAX_WIDTH,
        xl: 125,
      }}
    />
  );
}

export function SigningProcedureTableCell({ cell }: TableCellType) {
  const { signingProcedure } = cell.getContext().row.original;

  if (!signingProcedure) {
    return <Status.Empty />;
  }

  return (
    <Box display="inline-flex" maxW={{ base: 75, xl: 125 }}>
      <Text isTruncated>{capitalCase(signingProcedure)}</Text>
    </Box>
  );
}

export function SharesPPSTableCell({ cell }: TableCellType) {
  const { numShares, pricePerShare } = cell.getContext().row.original;
  if (!numShares || !pricePerShare) {
    return <Status.Empty />;
  }

  return (
    <Box display="inline-block" maxW={{ base: 75, xl: 125 }}>
      <Text>{`${formatShares(numShares)} @ ${formatCurrencyCents(
        pricePerShare,
      )}`}</Text>
    </Box>
  );
}

export function BuyerTableCell({ cell }: TableCellType) {
  const {
    signingProcedure,
    representedBuyer,
    bid: { buyer, broker },
    document,
  } = cell.getContext().row.original;
  const { id: signerId, email: signerEmail } = buyer;

  if (!signerEmail) {
    return <Status.Empty />;
  }

  const alternateEmail = representedBuyer?.email || broker?.email;
  const emailText = !!alternateEmail
    ? `${alternateEmail}\n${signerEmail}`
    : signerEmail;

  return (
    <Status.DocumentSigned
      text={emailText}
      ariaLabel={`buyer-${signerId}`}
      signingProcedure={signingProcedure}
      user={buyer}
      broker={broker as TransactionTableBidBrokerFragment}
      signers={document?.signers || []}
    />
  );
}

export function SellerTableCell({ cell }: TableCellType) {
  const {
    signingProcedure,
    representedSeller,
    bid: { counterparty, listing },
    document,
  } = cell.getContext().row.original;
  const { id: signerId, email: signerEmail } = counterparty;
  if (!signerEmail) {
    return <Status.Empty />;
  }

  const broker = listing?.broker;
  const alternateEmail = representedSeller?.email || broker?.email;
  const emailText = !!alternateEmail
    ? `${alternateEmail}\n${signerEmail}`
    : signerEmail;

  return (
    <Status.DocumentSigned
      text={emailText}
      ariaLabel={`counterparty-${signerId}`}
      signingProcedure={signingProcedure}
      user={counterparty}
      broker={broker as TransactionTableListingBrokerFragment}
      signers={document?.signers || []}
    />
  );
}

export function TransactionStateTableCell({ cell }: TableCellType) {
  const { state, inPipeline, rofr } = cell.getContext().row.original;

  if (!state) {
    return <Status.Empty />;
  }

  const onHold =
    !inPipeline &&
    !TerminalTransactionStates.includes(state as TransactionState);

  return (
    <Box display="inline-block">
      <TransactionStatus
        state={state as TransactionState}
        onHold={onHold}
        rofr={rofr}
      />
    </Box>
  );
}
