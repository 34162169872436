import { ReactNode } from "react";

import { Badge, BadgeProps, Tooltip } from "@chakra-ui/react";
import { CheckCircle } from "@phosphor-icons/react";

export interface StatusWithIconProps {
  color?: string;
  icon?: ReactNode;
  text?: string;
  tooltipLabel?: ReactNode;
  badgeProps?: BadgeProps;
}

export default function StatusWithIcon({
  color,
  icon = <CheckCircle weight="fill" size={16} />,
  text,
  tooltipLabel,
  badgeProps,
}: StatusWithIconProps) {
  return (
    <Tooltip hasArrow label={tooltipLabel}>
      <Badge variant="italic" color={color} {...badgeProps}>
        {icon}
        {text}
      </Badge>
    </Tooltip>
  );
}
