import {
  ExecutionStepType,
  ExecutionTaskActorType,
  ExecutionTaskType,
} from "@/graphql";

export const PRO_OPTIONS = { hideAttribution: true };

export const ANVIL_V2_STEP = {
  SHARE_TRANSFER_AGREEMENT: {
    name: `Share Transfer Agreement`,
    visibility: [
      ExecutionTaskActorType.Hiive,
      ExecutionTaskActorType.Buyer,
      ExecutionTaskActorType.Seller,
    ],
    type: ExecutionStepType.AnvilV2,
    config: {
      __typename: `ExecutionAnvilV2Config` as const,
      anvilWeldEid: `EApCOhsvl0Wfv4RHdv1I`,
      requiredSignatures: [
        ExecutionTaskActorType.Seller,
        ExecutionTaskActorType.Buyer,
      ],
      requiredFills: [
        {
          actorType: ExecutionTaskActorType.Seller,
          anvilForgeEid: `FBJxTw7fD3HTEYPJgsuD`,
        },
        {
          actorType: ExecutionTaskActorType.Buyer,
          anvilForgeEid: `MQ7grk4t5DCckeC1oxux`,
        },
      ],
    },
  },
};

export const ANVIL_STEP = {
  TRANSACTION_CONFIRMATION: {
    name: `Confirm Transaction Details`,
    visibility: [
      ExecutionTaskActorType.Hiive,
      ExecutionTaskActorType.Buyer,
      ExecutionTaskActorType.Seller,
    ],
    type: ExecutionStepType.Anvil,
    config: {
      __typename: `ExecutionAnvilConfig` as const,
      anvilWeldEid: `ROSOI44U0KB0zoRQjDpv`,
      taskOrder: [
        {
          actorType: ExecutionTaskActorType.Seller,
          type: ExecutionTaskType.AnvilFillDocument,
          anvilForgeEid: `kEaPjXvOHTH7jpub23rx`,
        },
        {
          actorType: ExecutionTaskActorType.Buyer,
          type: ExecutionTaskType.AnvilSignDocument,
          anvilForgeEid: `lWIAvE0TNLwiVKWHDOYe`,
        },
      ],
    },
  },
  INFORMATION_COLLECTION: {
    name: `Information Collection`,
    visibility: [ExecutionTaskActorType.Hiive, ExecutionTaskActorType.Seller],
    type: ExecutionStepType.Anvil,
    config: {
      __typename: `ExecutionAnvilConfig` as const,
      anvilWeldEid: `g0lCzvd1ee2OngDr2IZW`,
      taskOrder: [
        {
          actorType: ExecutionTaskActorType.Seller,
          type: ExecutionTaskType.AnvilFillDocument,
          anvilForgeEid: `pU16eNCZxoijA1hmOqhz`,
        },
      ],
    },
  },
};

export const MANUAL_STEP = {
  name: `Manual step`,
  type: ExecutionStepType.Manual,
  visibility: [ExecutionTaskActorType.Hiive],
  config: {
    __typename: `ExecutionManualConfig` as const,
    buyerCopy: null,
    sellerCopy: null,
    operationsCopy: null,
  },
};
