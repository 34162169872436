import { match } from "ts-pattern";

import StatusEmpty from "./StatusEmpty";
import StatusWithIcon, { StatusWithIconProps } from "./StatusWithIcon";

export enum PreqinStatus {
  None = `none`,
  Hidden = `hidden`,
  Shown = `shown`,
}

interface StatusPreqinProps extends StatusWithIconProps {
  preqin?: PreqinStatus;
}

export default function StatusPreqin({
  preqin,
  ...restProps
}: StatusPreqinProps) {
  return match({ preqin })
    .with({ preqin: PreqinStatus.Shown }, () => (
      <StatusWithIcon color="#323232" {...restProps} />
    ))
    .with({ preqin: PreqinStatus.Hidden }, () => (
      <StatusWithIcon color="#D5D5D5" {...restProps} />
    ))
    .otherwise(() => <StatusEmpty {...restProps} />);
}
