import {
  ExecutionStepStatus,
  TransactionPageV2ExecutionStepFragment,
  ExecutionStepType,
} from "@/graphql";
import { DateTimeFormat, formatDate } from "@/modules/NumeralFormat";
import { Box, CardBody, HStack, Text, VStack, Button } from "@chakra-ui/react";
import { sentenceCase } from "change-case";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";
import { MilestoneStatusIcon, stepStatusIcon } from "@/modules/Step";
import { OverlappingTags } from "@/modules/OverlappingTags";
import { getFutureTasks, UserTypeTag } from "@/modules/Task";

function MilestoneStepCardBody({
  step,
}: {
  readonly step: TransactionPageV2ExecutionStepFragment;
}) {
  const milestoneBackgroundColor = match(step.status)
    .with(ExecutionStepStatus.Completed, () => `grey.25`)
    .with(ExecutionStepStatus.InProgress, () => `purple.25`)
    .otherwise(() => `white`);

  const textColor =
    step.status === ExecutionStepStatus.Completed ? `grey.600` : `purple.1000`;

  return (
    <CardBody
      key={step.id}
      p={3}
      px={6}
      backgroundColor={milestoneBackgroundColor}
      borderLeft="purple.500"
    >
      <HStack justifyContent="space-between">
        <HStack alignItems="flex-start">
          <Box w={4} h={4} position="relative" color="purple.1000">
            <MilestoneStatusIcon status={step.status} />
          </Box>
          <VStack gap={1} alignItems="flex-start">
            <Text textStyle="heading-xs" lineHeight={4} color={textColor}>
              {step.config.__typename === `ExecutionMilestoneConfig` &&
                sentenceCase(step.config.milestone)}
            </Text>
          </VStack>
        </HStack>
      </HStack>
    </CardBody>
  );
}

function DefaultStepCardBody({
  step,
  onClick,
  children,
}: {
  readonly step: TransactionPageV2ExecutionStepFragment;
  readonly onClick: (step: TransactionPageV2ExecutionStepFragment) => void;
  readonly children: React.ReactNode;
}) {
  const { t } = useTranslation();

  const backgroundColor =
    step.status === ExecutionStepStatus.Completed ? `grey.25` : `white`;

  const textColor =
    step.status === ExecutionStepStatus.Completed ? `grey.600` : `grey.900`;

  const statusTextColor = match(step.status)
    .with(ExecutionStepStatus.Completed, () => `grey.900`)
    .with(ExecutionStepStatus.InProgress, () => `sky.500`)
    .otherwise(() => `grey.500`);

  const statusText = match(step.status)
    .with(ExecutionStepStatus.Completed, () =>
      formatDate(step.completedAt, DateTimeFormat.fullDateWithMonthShort),
    )
    .with(ExecutionStepStatus.InProgress, () => t(`in_progress`))
    .otherwise(() => t(`pending`));

  const tasks =
    step.status === ExecutionStepStatus.Pending
      ? getFutureTasks(step.config)
      : step.tasks;
  const assignees = [...new Set(tasks.map((task) => task.actorType))];

  return (
    <CardBody key={step.id} p={3} px={6} backgroundColor={backgroundColor}>
      <HStack justifyContent="space-between">
        <HStack alignItems="flex-start" pl={6}>
          {stepStatusIcon[step.status]}
          <VStack gap={1} alignItems="flex-start">
            <Text textStyle="heading-xs" lineHeight={4} color={textColor}>
              {step.name}
            </Text>
          </VStack>
        </HStack>
        <HStack>
          {children}
          <Box>
            <OverlappingTags skipWrap>
              {assignees.map((assignee) => (
                <UserTypeTag key={assignee} assignee={assignee} />
              ))}
            </OverlappingTags>
          </Box>
          <Text w={28} color={statusTextColor} textAlign="center">
            {statusText}
          </Text>
          <Button variant="outline" onClick={() => onClick(step)}>
            {t(`view_details`)}
          </Button>
        </HStack>
      </HStack>
    </CardBody>
  );
}

export function StepCardBody({
  step,
  onClick,
  children,
}: {
  readonly step: TransactionPageV2ExecutionStepFragment;
  readonly onClick: (step: TransactionPageV2ExecutionStepFragment) => void;
  readonly children: React.ReactNode;
}) {
  return match(step.type)
    .with(ExecutionStepType.Milestone, () => (
      <MilestoneStepCardBody step={step} />
    ))
    .otherwise(() => (
      <DefaultStepCardBody step={step} onClick={onClick}>
        {children}
      </DefaultStepCardBody>
    ));
}
