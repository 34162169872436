import {
  BankAccountCardBankAccountFragment,
  ManuallyLinkBankAccountToTransactionInput,
  SellerTransactionsTransactionFragment,
  useManuallyLinkBankAccountToTransactionMutation,
} from "@/graphql";
import { ConfirmModal } from "@/modules/Modal";
import { useColors } from "@/modules/Theme";
import { useCustomToast } from "@/modules/Toast";
import { HStack, Image, Text, VStack } from "@chakra-ui/react";
import { MagnifyingGlass } from "@phosphor-icons/react";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Combobox } from "@/modules/Form";
import {
  BankAccountNumber,
  BankAccountTransactionBadge,
} from "@/features/Entities";

type TransactionComboboxProps = {
  transactions: SellerTransactionsTransactionFragment[];
  isLoading?: boolean;
};

function TransactionCombobox({
  transactions,
  isLoading,
}: TransactionComboboxProps) {
  const { t } = useTranslation();

  const [grey200] = useColors([`grey.200`]);

  const filterBySearch = ({ search }: { readonly search: string }) =>
    search.length > 0
      ? transactions.filter((item) => item.id.match(search))
      : transactions;

  const {
    register,
    setValue,
    formState: { errors },
    clearErrors,
  } = useFormContext<ManuallyLinkBankAccountToTransactionInput>();

  return (
    <Combobox
      error={errors.transactionId}
      label={t(`search_transactions`)}
      placeholder={t(`search_by_database_id`)}
      getItemLogo={(transaction: SellerTransactionsTransactionFragment) => (
        <Image
          h={4}
          w={4}
          objectFit="contain"
          src={transaction.company.logoUrl || ``}
        />
      )}
      getItemKey={(transaction: SellerTransactionsTransactionFragment) =>
        transaction.id
      }
      // @ts-expect-error: per design this needs to be a component instead of a string
      getItemLabel={(transaction: SellerTransactionsTransactionFragment) => (
        <HStack>
          <Text>{transaction.bid.displayId}</Text>
          <Text fontWeight={400}>{transaction.id}</Text>
        </HStack>
      )}
      getItems={filterBySearch}
      setValue={(transaction) => {
        setValue(`transactionId`, transaction.id);
        clearErrors();
      }}
      leftElement={<MagnifyingGlass size={20} color={grey200} />}
      isLoading={isLoading}
      {...register(`transactionId`, {
        required: `transactionId is required`,
      })}
    />
  );
}

function ManuallyLinkToTransactionModal({
  sellerTransactions,
  bankAccount,
  isOpen,
  onClose,
}: {
  readonly sellerTransactions: SellerTransactionsTransactionFragment[];
  readonly bankAccount: BankAccountCardBankAccountFragment;
  readonly isOpen: boolean;
  readonly onClose: () => void;
}) {
  const { t } = useTranslation();
  const [, linkBankAccountToTransaction] =
    useManuallyLinkBankAccountToTransactionMutation();
  const { successToast, errorToast } = useCustomToast();

  const { formState, getValues, reset, trigger, handleSubmit } =
    useFormContext<ManuallyLinkBankAccountToTransactionInput>();

  const { isSubmitting, isValid } = formState;

  const [showConfirmation, setShowConfirmation] = useState(false);

  const [invalidError, setInvalidError] = useState(``);

  const resetModal = () => {
    setInvalidError(``);
    setShowConfirmation(false);
    reset();
  };

  const onSubmit = async () => {
    const inputData = { input: getValues() };

    const { data: responseData } =
      await linkBankAccountToTransaction(inputData);
    const responseError =
      responseData?.manuallyLinkBankAccountToTransaction.errors;

    if (!!responseError) {
      errorToast(`Unable to link bank account`);
    } else {
      successToast(`Successfully Linked to Transaction`);
      resetModal();
      onClose();
    }
  };

  const modalTitle = showConfirmation
    ? t(`confirm_transaction_assignment`)
    : t(`assign_transaction_to_bank_account`, { last4: bankAccount.last4 });

  const modalCopy = showConfirmation
    ? t(`confirm_transaction_assignment_copy`)
    : t(`assign_transaction_modal_copy`);

  const selected = getValues();

  const selectedTransaction = sellerTransactions.find(
    ({ id }) => id === selected.transactionId,
  );

  const handleOnCancel = () => {
    if (showConfirmation) {
      resetModal();

      return;
    }

    resetModal();
    onClose();
  };

  const handleOnClose = () => {
    resetModal();
    onClose();
  };

  const handleOnConfirm = () => {
    trigger();

    if (!isValid) {
      return;
    }

    const current = getValues();
    const isInputValid = !!sellerTransactions.find(
      ({ id }) => id === current.transactionId,
    );

    if (!isInputValid) {
      setInvalidError(t(`invalid_transaction_to_link_error`));

      return;
    }

    setShowConfirmation(true);

    if (isInputValid && showConfirmation) {
      setInvalidError(``);
      handleSubmit(onSubmit)();
    }
  };

  return (
    <ConfirmModal
      title={modalTitle}
      isOpen={isOpen}
      onClose={handleOnClose}
      onConfirm={handleOnConfirm}
      onCancel={handleOnCancel}
      loading={isSubmitting}
      confirmText={t(`confirm`)}
      body={
        <VStack alignItems="flex-start" gap={5}>
          <Text>{modalCopy}</Text>
          {showConfirmation ? (
            <HStack
              borderWidth={1}
              width="full"
              borderRadius="6px"
              p={4}
              justifyContent="space-between"
            >
              <HStack>
                <Text fontWeight={500}>{t(`account`)}</Text>
                <BankAccountNumber bankAccount={{ last4: bankAccount.last4 }} />
              </HStack>
              <BankAccountTransactionBadge
                logoUrl={selectedTransaction?.company.logoUrl || ``}
                displayId={selectedTransaction?.bid.displayId || ``}
              />
            </HStack>
          ) : (
            <>
              <TransactionCombobox transactions={sellerTransactions} />
              <Text textStyle="colfax-14-regular" color="red.500">
                {invalidError}
              </Text>
            </>
          )}
        </VStack>
      }
    />
  );
}

export default ManuallyLinkToTransactionModal;
