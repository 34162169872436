import { VStack } from "@chakra-ui/react";

import { useCompanyApprovedBuyersEnabled } from "@/modules/LaunchDarkly";
import CompanyManagedMarketSellerEligibilityCard from "./CompanyManagedMarketSellerEligibilityCard";
import CompanyManagedMarketApprovedBuyersCard from "./CompanyManagedMarketApprovedBuyersCard";

export default function CompanyManagedMarketMainPanel() {
  const companyApprovedBuyersEnabled = useCompanyApprovedBuyersEnabled();

  return (
    <VStack gap={4}>
      <CompanyManagedMarketSellerEligibilityCard />
      {companyApprovedBuyersEnabled && (
        <CompanyManagedMarketApprovedBuyersCard />
      )}
    </VStack>
  );
}
