import { CardTable } from "@/modules/CardTable";
import { DetailPageLayout } from "@/modules/Layout";
import { Skeleton } from "@/modules/Skeleton";
import { Table } from "@/modules/Table";
import {
  Card,
  CardBody,
  CardHeader,
  HStack,
  VStack,
  Box,
  Tab,
} from "@chakra-ui/react";

export default function FeeDiscountPageSkeleton() {
  return (
    <DetailPageLayout
      isTwoColumn
      mainPanel={
        <VStack alignItems="flex-start" gap={4}>
          <Card w="full">
            <CardHeader gap={4} alignItems="center">
              <Skeleton h={8} w={32} />
            </CardHeader>
            <CardBody>
              <VStack alignItems="flex-start" gap={8}>
                <HStack gap={1}>
                  <Skeleton h={3} w={100} />
                  <Skeleton h={3} w={70} />
                </HStack>
                <CardTable w="full" columns={2}>
                  <Card variant="table">
                    <CardHeader>
                      <Skeleton h={3} w={20} />
                    </CardHeader>
                    <CardBody>
                      <Skeleton h={3} w={40} />
                    </CardBody>
                  </Card>
                  <Card variant="table">
                    <CardHeader>
                      <Skeleton h={3} w={20} />
                    </CardHeader>
                    <CardBody>
                      <Skeleton h={3} w={40} />
                    </CardBody>
                  </Card>
                </CardTable>
              </VStack>
            </CardBody>
          </Card>

          <Box w="full">
            <Table.Tabs index={Infinity}>
              <Tab>
                <Skeleton h={3} w={40} />
              </Tab>
              <Tab>
                <Skeleton h={3} w={40} />
              </Tab>
              <Tab>
                <Skeleton h={3} w={40} />
              </Tab>
              <Tab>
                <Skeleton h={3} w={40} />
              </Tab>
            </Table.Tabs>
            <Table.Loading />
          </Box>
        </VStack>
      }
      rightPanel={
        <Card w="full">
          <CardHeader>
            <Skeleton h={8} w={20} />
          </CardHeader>
          <CardBody>
            <VStack alignItems="flex-start">
              <Skeleton h={3} w={80} />
            </VStack>
          </CardBody>
        </Card>
      }
    />
  );
}
