import { ReactNode, createContext, useContext } from "react";
import { UseQueryState } from "urql";

import { getDefaultTableProviderVariables } from "@/constants";
import {
  CompaniesTableQuery,
  CompaniesTableQueryVariables,
  CompanyFilter,
  CompanySort,
  CompanySortField,
  SortDirection,
  useCompaniesTableQuery,
} from "@/graphql";
import {
  SimpleTableUrlParams,
  TableContext,
  TableProvider,
} from "@/modules/SimpleTable";

interface CompaniesTableProviderProps {
  children: ReactNode;
  query: SimpleTableUrlParams & {
    countryIds?: string;
    statuses?: string;
  };
}

interface ChildProps {
  children: ReactNode;
}

const Context = createContext<{
  data:
    | UseQueryState<CompaniesTableQuery, CompaniesTableQueryVariables>
    | undefined;
}>({ data: undefined });
const { Provider } = Context;

const DEFAULT_VARIABLES: CompaniesTableQueryVariables & { page: number } =
  getDefaultTableProviderVariables({
    sortBy: {
      field: CompanySortField.Name,
      direction: SortDirection.Asc,
    },
  });

function Child({ children }: ChildProps) {
  const { variables } = useContext(TableContext);

  const [data] = useCompaniesTableQuery({
    variables: {
      ...variables,
      sortBy: variables.sortBy as CompanySort,
      filterBy: variables.filterBy as CompanyFilter,
    },
    requestPolicy: `cache-and-network`,
  });

  return <Provider value={{ data }}>{children}</Provider>;
}

function CompaniesTableProvider({
  children,
  query,
}: CompaniesTableProviderProps) {
  return (
    <TableProvider
      query={query}
      defaultVariables={DEFAULT_VARIABLES}
      sortFields={Object.values(CompanySortField)}
    >
      <Child>{children}</Child>
    </TableProvider>
  );
}

export { CompaniesTableProvider, Context };
