import { useState } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import {
  FeeDiscountApplicationFragment,
  FeeDiscountEntityType,
} from "@/graphql";
import { FeeDiscountEntity } from "@/modules/FeeDiscountsCard";
import { useFeeDiscountTransactionOneTimeApplication } from "@/modules/LaunchDarkly";
import { Table } from "@/modules/Table";
import { Menu, MenuItem, MenuList } from "@chakra-ui/react";

import { ApplyFeeDiscountModal } from "./ApplyFeeDiscountModal";
import { ApplyOneTimeFeeDiscountModal } from "./ApplyOneTimeFeeDiscountModal";
import { RemoveFeeDiscountModal } from "./RemoveFeeDiscountModal";

const MAX_DISCOUNTS_BY_ENTITY_TYPE = {
  [FeeDiscountEntityType.Transaction]: 2,
  [FeeDiscountEntityType.Listing]: 1,
  [FeeDiscountEntityType.Company]: 1,
  [FeeDiscountEntityType.User]: 1,
};

enum ModalType {
  APPLY = `apply`,
  APPLY_ONE_TIME = `apply_one_time`,
  REMOVE = `remove`,
}

type NullableModalType = ModalType | null;

type FeeDiscountApplicationActionState = {
  canApply: boolean;
  canRemove: boolean;
};

const getActionStates = (
  entity: FeeDiscountEntity,
  feeDiscountApplications: FeeDiscountApplicationFragment[],
): FeeDiscountApplicationActionState => {
  const { entityType } = entity;

  return match(entityType)
    .with(FeeDiscountEntityType.Listing, () => {
      const isUnderMaxLimit =
        feeDiscountApplications.length <
        MAX_DISCOUNTS_BY_ENTITY_TYPE[FeeDiscountEntityType.Listing];

      // NOTE: This is assuming validation needs to be run on one entity, which should currently ALWAYS be the case
      const [feeDiscountApplication] = feeDiscountApplications;

      const isLookup =
        feeDiscountApplication?.entityType !== FeeDiscountEntityType.Listing;

      return {
        canApply: isUnderMaxLimit || isLookup,
        canRemove: feeDiscountApplications.length > 0 && !isLookup,
      };
    })
    .otherwise(() => ({
      canApply:
        feeDiscountApplications.length <
        MAX_DISCOUNTS_BY_ENTITY_TYPE[entityType],
      canRemove: feeDiscountApplications.length > 0,
    }));
};

interface FeeDiscountCardActionsApplyMenuListProps {
  entityType: FeeDiscountEntityType;
  setModalType: (type: NullableModalType) => void;
}

function FeeDiscountCardActionsApplyMenuList({
  entityType,
  setModalType,
}: FeeDiscountCardActionsApplyMenuListProps) {
  const feeDiscountTransactionOneTimeApplicationEnabled =
    useFeeDiscountTransactionOneTimeApplication();
  const { t } = useTranslation();

  const showOneTimeFeeDiscountApplication =
    feeDiscountTransactionOneTimeApplicationEnabled &&
    entityType === FeeDiscountEntityType.Transaction;

  if (showOneTimeFeeDiscountApplication) {
    return (
      <>
        <MenuItem onClick={() => setModalType(ModalType.APPLY)}>
          {t(`apply_existing_fee_discount`)}
        </MenuItem>
        <MenuItem onClick={() => setModalType(ModalType.APPLY_ONE_TIME)}>
          {t(`create_one_time_fee_discount`)}
        </MenuItem>
      </>
    );
  }

  return (
    <MenuItem onClick={() => setModalType(ModalType.APPLY)}>
      {t(`apply_fee_discount`)}
    </MenuItem>
  );
}

const NO_CONFIRMATION_MODAL = {
  component: () => null,
  isOpen: () => false,
};

const CONFIRMATION_MODAL_MAP = {
  [ModalType.APPLY]: { component: ApplyFeeDiscountModal, isOpen: () => true },
  [ModalType.APPLY_ONE_TIME]: {
    component: ApplyOneTimeFeeDiscountModal,
    isOpen: (entityType?: FeeDiscountEntityType) =>
      entityType === FeeDiscountEntityType.Transaction,
  },
  [ModalType.REMOVE]: { component: RemoveFeeDiscountModal, isOpen: () => true },
};

interface FeeDiscountCardActionsProps {
  entity: FeeDiscountEntity;
  feeDiscountApplications: FeeDiscountApplicationFragment[];
  onSuccess?: () => void;
}

export default function FeeDiscountCardActions({
  entity,
  feeDiscountApplications,
  onSuccess,
}: FeeDiscountCardActionsProps) {
  const [modalType, setModalType] = useState<NullableModalType>(null);
  const { t } = useTranslation();

  const { entityType } = entity;
  const { canApply, canRemove } = getActionStates(
    entity,
    feeDiscountApplications,
  );

  const handleCloseModal = () => setModalType(null);

  const { component: ConfirmationModal, isOpen } = modalType
    ? CONFIRMATION_MODAL_MAP[modalType]
    : NO_CONFIRMATION_MODAL;

  return (
    <>
      <Menu>
        <Table.ActionButton showTitle />
        <MenuList>
          {canApply && (
            <FeeDiscountCardActionsApplyMenuList
              entityType={entityType}
              setModalType={setModalType}
            />
          )}
          {canRemove && (
            <MenuItem onClick={() => setModalType(ModalType.REMOVE)}>
              {t(`remove_fee_discount`)}
            </MenuItem>
          )}
        </MenuList>
      </Menu>

      {modalType && (
        <ConfirmationModal
          isOpen={isOpen(entityType)}
          entity={entity}
          feeDiscountApplications={feeDiscountApplications}
          onClose={handleCloseModal}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
}
