import { CardTable } from "@/modules/CardTable";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Grid,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Skeleton } from "@/modules/Skeleton";
import { Plus } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

export function TransactionActivitySkeleton() {
  const { t } = useTranslation();
  return (
    <VStack gap={4}>
      <Card w="full">
        <CardHeader as={HStack} gap={4} alignItems="center">
          <Text textStyle="colfax-22-medium">
            {t(`transaction_activity_log`)}
          </Text>
        </CardHeader>
        <CardBody>
          <CardTable w="full" columns={1} borderBottom="unset">
            <Card variant="table">
              <Grid templateColumns="1fr 1fr 1fr 2fr 2fr">
                <CardHeader>{t`date`}</CardHeader>
                <CardHeader>{t`user`}</CardHeader>
                <CardHeader>{t`activity`}</CardHeader>
                <CardHeader>{t`activity_detail`}</CardHeader>
                <CardHeader>{t`notes`}</CardHeader>
              </Grid>

              <Grid gridTemplateColumns="1fr 1fr 1fr 2fr 2fr">
                {Array.from({ length: 5 })
                  .map((_, i) => i)
                  .flatMap((_) =>
                    Array.from({ length: 5 })
                      .map((_, i) => i)
                      .map((col) => (
                        <CardBody
                          as={VStack}
                          alignItems="flex-start"
                          key={`activity-${_}-${col}`}
                          justifyContent="center"
                          p={4}
                          borderRight="1px"
                          borderRightColor="grey.50"
                        >
                          {col === 1 && <Skeleton h={2} w={20} />}
                          <Skeleton h={4} w="full" />
                        </CardBody>
                      )),
                  )}
              </Grid>
            </Card>
          </CardTable>
        </CardBody>
      </Card>

      <Card w="full">
        <CardHeader as={HStack} gap={4} alignItems="center">
          <Text textStyle="colfax-22-medium">{t(`messaging`)}</Text>
        </CardHeader>
        <CardBody>
          <CardTable w="full" columns={1} borderBottom="unset">
            <Card variant="table">
              <Grid templateColumns="1fr 1fr 5fr">
                <CardHeader>{t`date`}</CardHeader>
                <CardHeader>{t`user`}</CardHeader>
                <CardHeader>{t`message`}</CardHeader>
              </Grid>

              <Grid gridTemplateColumns="1fr 1fr 5fr">
                {Array.from({ length: 5 })
                  .map((_, i) => i)
                  .flatMap((_) =>
                    Array.from({ length: 3 })
                      .map((_, i) => i)
                      .map((col) => (
                        <CardBody
                          as={VStack}
                          alignItems="flex-start"
                          justifyContent="center"
                          key={`messaging-${_}-${col}`}
                          p={4}
                          borderRight="1px"
                          borderRightColor="grey.50"
                        >
                          {col === 1 ? (
                            <Skeleton h={3} w={20} />
                          ) : (
                            <Skeleton h={4} w="full" />
                          )}
                        </CardBody>
                      )),
                  )}
              </Grid>
              <CardHeader w="full">
                <HStack color="grey.900" w="full" justifyContent="flex-end">
                  <Button
                    variant="ghost"
                    mt={1}
                    size="xs"
                    textStyle="colfax-16-medium"
                    textTransform="uppercase"
                    rightIcon={<Plus />}
                  >
                    <Text>{t(`view_more`)}</Text>
                  </Button>
                </HStack>
              </CardHeader>
            </Card>
          </CardTable>
        </CardBody>
      </Card>
    </VStack>
  );
}
