import { HStack, MenuButton, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { DotsThreeOutlineVertical } from "@phosphor-icons/react";

export default function TableActionButton({
  showTitle,
}: {
  showTitle?: boolean;
}) {
  const { t } = useTranslation();
  return showTitle ? (
    <MenuButton>
      <HStack p={1}>
        <DotsThreeOutlineVertical weight="fill" size={14} />
        <Text textStyle="colfax-12-medium">{t(`actions`)}</Text>
      </HStack>
    </MenuButton>
  ) : (
    <MenuButton width="24px" height="24px" p={0} m={0}>
      <DotsThreeOutlineVertical weight="fill" size={14} width="100%" />
    </MenuButton>
  );
}
