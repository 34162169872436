import { Table } from "@/modules/Table";
import { Menu, MenuItem, MenuList, useDisclosure } from "@chakra-ui/react";
import { CreateNewEntityModal, SwapEntityModal } from "@/features/Transactions";
import Link from "next/link";
import { useTranslation } from "react-i18next";
import {
  TransactionDetailFragment,
  EntityStatus,
  TransactionEntityFragment,
} from "@/graphql";

type EntityDetailsActionsButtonProps = {
  entity?: TransactionEntityFragment;
  id: string;
  isBuyer: boolean;
  isInstitutionalUser?: boolean;
  transaction: TransactionDetailFragment;
};

function EntityDetailsActionsButton({
  entity,
  id,
  isBuyer,
  isInstitutionalUser = false,
  transaction,
}: EntityDetailsActionsButtonProps) {
  const { t } = useTranslation();
  const url = isInstitutionalUser
    ? `/institutions/${id}/entities`
    : `/users/${id}/entities`;

  const {
    isOpen: createEntityModalIsOpen,
    onOpen: onOpenCreateEntityModal,
    onClose: onCloseCreateEntityModal,
  } = useDisclosure();
  const {
    isOpen: swapEntityModalIsOpen,
    onOpen: onOpenSwapEntityModal,
    onClose: onCloseSwapEntityModal,
  } = useDisclosure();

  const getEntities = () => {
    if (isInstitutionalUser) {
      return isBuyer
        ? transaction.bid?.buyer?.institution?.entities
        : transaction.bid?.counterparty?.institution?.entities;
    }

    return isBuyer
      ? transaction.bid?.buyer?.entities
      : transaction.bid?.counterparty?.entities;
  };
  const entities = getEntities();
  const nonArchivedEntities = entities?.filter(
    (entity) => entity.status !== EntityStatus.Archived,
  );
  const hasMultiEntities =
    nonArchivedEntities && nonArchivedEntities.length >= 2;

  return (
    <>
      <Menu>
        <Table.ActionButton />
        <MenuList>
          <MenuItem onClick={onOpenCreateEntityModal}>
            {t(`change_to_new_entity`)}
          </MenuItem>
          {entity && hasMultiEntities && (
            <MenuItem onClick={onOpenSwapEntityModal}>
              {t(`swap_entity`)}
            </MenuItem>
          )}
          <MenuItem>
            <Link href={url}>{t(`view_all_entities`)}</Link>
          </MenuItem>
        </MenuList>
      </Menu>
      {entity && (
        <>
          <CreateNewEntityModal
            currentEntity={entity}
            isBuyer={isBuyer}
            isOpen={createEntityModalIsOpen}
            isInstitutionalUser={isInstitutionalUser}
            onClose={onCloseCreateEntityModal}
            transaction={transaction}
          />
          {hasMultiEntities && (
            <SwapEntityModal
              currentEntity={entity}
              entities={nonArchivedEntities}
              isBuyer={isBuyer}
              isOpen={swapEntityModalIsOpen}
              onClose={onCloseSwapEntityModal}
              transaction={transaction}
            />
          )}
        </>
      )}
    </>
  );
}

export default EntityDetailsActionsButton;
