import { useTransactionUpdateTransactionIgnoreInPricingMutation } from "@/graphql";
import { useCustomToast } from "@/modules/Toast";
import { Switch } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

type TransactionIgnoreInPricingToggleProps = {
  id: string;
  displayId: string;
  ignoreInPricing: boolean;
};

export function TransactionIgnoreInPricingToggle({
  id,
  displayId,
  ignoreInPricing,
}: TransactionIgnoreInPricingToggleProps) {
  const { t } = useTranslation();
  const { successToast, errorToast } = useCustomToast();

  const [{ fetching }, executeIgnoreInPricing] =
    useTransactionUpdateTransactionIgnoreInPricingMutation();

  return (
    <Switch
      isDisabled={fetching}
      colorScheme="green"
      isChecked={!ignoreInPricing}
      onChange={async () => {
        try {
          const { data, error } = await executeIgnoreInPricing({
            transactionId: id,
            ignoreInPricing: !ignoreInPricing,
          });

          const ignoreInPricingValue =
            data?.updateTransactionIgnoreInPricing.transaction?.ignoreInPricing;

          if (error) {
            return errorToast(t(`error_toggling_visibility`));
          }

          return successToast(
            t(
              ignoreInPricingValue
                ? `transaction_hidden_from_price_graph`
                : `transaction_shown_on_price_graph`,
              {
                displayId,
              },
            ),
          );
        } catch (_) {
          return errorToast(t(`error_toggling_visibility`));
        }
      }}
    />
  );
}
