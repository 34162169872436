import { DateTime } from "luxon";

const PriceLineI18nKeys = {
  shareTypeCommon: `share_type_common`,
  shareTypePreferred: `share_type_preferred`,
  transferTypeDirect: `transfer_type_direct`,
  transferTypeIndirect: `transfer_type_indirect`,
} as const;
const IndicatorI18nKeys = {
  acceptedBids: `accepted_bids`,
  lastRoundPps: `last_round_pps`,
  postedBidsAsks: `posted_bids_asks`,
} as const;

const minGraphDate = DateTime.fromISO(`2022-05-01`).toISODate()!;
const defaultGraphStartDate = DateTime.fromISO(`2023-01-01`).toISODate()!;

const orderedIndicators = [
  `lastRoundPps`,
  `acceptedBids`,
  `postedBidsAsks`,
] as const;
const orderedPriceLines = [
  `transferTypeDirect`,
  `transferTypeIndirect`,
  `shareTypeCommon`,
  `shareTypePreferred`,
] as const;

export {
  PriceLineI18nKeys,
  IndicatorI18nKeys,
  defaultGraphStartDate,
  minGraphDate,
  orderedIndicators,
  orderedPriceLines,
};
