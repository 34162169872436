import { useState } from "react";
import { useTranslation } from "react-i18next";

import { CompanyManagedMarketPageCompanyEligibilityCriterionFragment } from "@/graphql";
import { Table } from "@/modules/Table";
import { Menu, MenuGroup, MenuItem, MenuList } from "@chakra-ui/react";

import { ApplyCompanyEligibilityCriterionModal } from "./ApplyCompanyEligibilityCriterionModal";
import { EditCompanyEligibilityCriterionModal } from "./EditCompanyEligibilityCriterionModal";
import { RemoveCompanyEligibilityCriterionModal } from "./RemoveCompanyEligibilityCriterionModal";

enum ModalType {
  APPLY = `apply`,
  EDIT = `edit`,
  REMOVE = `remove`,
}

type NullableModalType = ModalType | null;

type CompanyEligibilityCriterionActionState = {
  canApply: boolean;
  canEdit: boolean;
  canRemove: boolean;
};

const getActionStates = (
  companyEligibilityCriterion?: CompanyManagedMarketPageCompanyEligibilityCriterionFragment | null,
): CompanyEligibilityCriterionActionState => {
  if (companyEligibilityCriterion) {
    return {
      canApply: false,
      canEdit: true,
      canRemove: true,
    };
  }

  return {
    canApply: true,
    canEdit: false,
    canRemove: false,
  };
};

const NO_CONFIRMATION_MODAL = {
  component: () => null,
  isOpen: false,
};

const CONFIRMATION_MODAL_MAP = {
  [ModalType.APPLY]: {
    component: ApplyCompanyEligibilityCriterionModal,
    isOpen: true,
  },
  [ModalType.EDIT]: {
    component: EditCompanyEligibilityCriterionModal,
    isOpen: true,
  },
  [ModalType.REMOVE]: {
    component: RemoveCompanyEligibilityCriterionModal,
    isOpen: true,
  },
};

interface CompanyManagedMarketSellerEligibilityActionsProps {
  readonly companyEligibilityCriterion?: CompanyManagedMarketPageCompanyEligibilityCriterionFragment | null;
  readonly showActionButtonTitle?: boolean;
}

export default function CompanyManagedMarketSellerEligibilityActions({
  companyEligibilityCriterion,
  showActionButtonTitle,
}: CompanyManagedMarketSellerEligibilityActionsProps) {
  const [modalType, setModalType] = useState<NullableModalType>(null);
  const { t } = useTranslation();

  const { canApply, canEdit, canRemove } = getActionStates(
    companyEligibilityCriterion,
  );

  const handleCloseModal = () => setModalType(null);

  const { component: ConfirmationModal, isOpen } = modalType
    ? CONFIRMATION_MODAL_MAP[modalType]
    : NO_CONFIRMATION_MODAL;

  return (
    <>
      <Menu>
        <Table.ActionButton showTitle={showActionButtonTitle} />
        <MenuList>
          <MenuGroup>
            {canApply && (
              <MenuItem onClick={() => setModalType(ModalType.APPLY)}>
                {t(`apply_seller_eligibility`)}
              </MenuItem>
            )}
            {canEdit && (
              <MenuItem onClick={() => setModalType(ModalType.EDIT)}>
                {t(`edit_seller_eligibility`)}
              </MenuItem>
            )}
            {canRemove && (
              <MenuItem onClick={() => setModalType(ModalType.REMOVE)}>
                {t(`remove_seller_eligibility`)}
              </MenuItem>
            )}
          </MenuGroup>
        </MenuList>
      </Menu>
      {modalType && (
        <ConfirmationModal isOpen={isOpen} onClose={handleCloseModal} />
      )}
    </>
  );
}
