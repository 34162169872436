import i18next from "i18next";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo } from "react";
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import {
  CommissionInfoValidationError,
  CompanySearchFragment,
  FeeDiscountEntityType,
  FeeDiscountSortableField,
  InvestorType,
  SortDirection,
  UserDetailFragment,
  useFeeDiscountsCardActionsFeeDiscountsQuery,
} from "@/graphql";
import { CompanySearchCombobox } from "@/modules/Company";
import { FeeBreakdownPreview } from "@/modules/FeeBreakdown";
import {
  FeeDiscountEntity,
  useApplyFeeDiscount,
  useApplyFeeDiscountWarning,
} from "@/modules/FeeDiscountsCard";
import { Input, useYupValidationResolver } from "@/modules/Form";
import {
  useFeeDiscountPreview,
  useMultipleHoldings,
} from "@/modules/LaunchDarkly";
import { ConfirmModal } from "@/modules/Modal";
import { useCustomToast } from "@/modules/Toast";
import { FormLabel, Text, VStack } from "@chakra-ui/react";

const MODAL_CONFIG = {
  [FeeDiscountEntityType.Transaction]: {
    note: i18next.t(`apply_fee_discount_description`),
    showPreview: true,
  },
  [FeeDiscountEntityType.Listing]: {
    showExpirationDate: true,
    showPreview: true,
  },
  [FeeDiscountEntityType.Company]: {
    showExpirationDate: true,
  },
  [FeeDiscountEntityType.User]: {
    showExpirationDate: true,
    showSelectCompany: true,
  },
};

type ApplyFeeDiscountInputs = {
  feeDiscountId: string;
  expireAt?: Date | null;
  entityCompanyId?: string;
};

interface FeeDiscountCardActionsCompanySearchFeeDiscountApplication {
  id: string;
  entityType: FeeDiscountEntityType;
}

const getValidationSchema = (entityType: FeeDiscountEntityType) =>
  yup.object().shape({
    feeDiscountId: yup
      .string()
      .required(i18next.t(`fee_discount_select_invalid_option`)),
    expireAt: yup
      .date()
      .nullable()
      .transform((current, original) => (original === `` ? null : current))
      .min(DateTime.now().toISODate(), i18next.t(`select_invalid_date`))
      .optional(),
    entityCompanyId:
      entityType === FeeDiscountEntityType.User
        ? yup.string().required(i18next.t(`company_select_invalid_option`))
        : yup.string().optional(),
  });

interface UnaccreditedSellerCompanySelectProps {
  readonly holdings: UserDetailFragment["holdings"];
  onCompanySelect: (
    feeDiscountApplications: FeeDiscountCardActionsCompanySearchFeeDiscountApplication[],
  ) => void;
}

function UnaccreditedSellerCompanySelect({
  holdings,
  onCompanySelect,
}: UnaccreditedSellerCompanySelectProps) {
  const multipleHoldingsEnabled = useMultipleHoldings();
  const { t } = useTranslation();
  const {
    formState: { errors },
    register,
    watch,
  } = useFormContext<ApplyFeeDiscountInputs>();

  const { entityCompanyId } = watch();

  const companies = useMemo(
    () => holdings?.map((holding) => holding?.company) || [],
    [holdings],
  );

  const [firstCompany] = companies;

  const options = useMemo(
    () =>
      companies.map((company) => ({
        label: company?.name || ``,
        value: company?.id || ``,
      })),
    [companies],
  );

  useEffect(() => {
    const selectedCompany =
      companies.length === 1
        ? firstCompany
        : companies.find((company) => company?.id === entityCompanyId);

    if (selectedCompany) {
      onCompanySelect(selectedCompany?.feeDiscountApplications);
    }
  }, [companies, entityCompanyId, firstCompany, onCompanySelect]);

  if (!companies) return null;

  return multipleHoldingsEnabled && companies.length > 1 ? (
    <Input.Select
      error={errors.entityCompanyId}
      label={t(`select_company`)}
      options={options}
      placeholder={t(`select`)}
      {...register(`entityCompanyId`)}
    />
  ) : (
    <VStack alignItems="flex-start" gap={0}>
      <FormLabel variant="input">{t(`select_company`)}</FormLabel>
      <Text>{firstCompany?.name}</Text>
      <input
        type="hidden"
        value={firstCompany?.id}
        {...register(`entityCompanyId`)}
      />
    </VStack>
  );
}

interface FeeDiscountsCardActionsSelectCompanyProps {
  entity: FeeDiscountEntity;
  onCompanySelect: (
    feeDiscountApplications: FeeDiscountCardActionsCompanySearchFeeDiscountApplication[],
  ) => void;
}

function FeeDiscountsCardActionsSelectCompany({
  entity,
  onCompanySelect,
}: FeeDiscountsCardActionsSelectCompanyProps) {
  const {
    control,
    formState: { errors },
    register,
    setValue,
    trigger,
  } = useFormContext<ApplyFeeDiscountInputs>();
  const { entityCompanyId } = useWatch({ control });

  const { entityType } = entity;

  const isUnaccreditedSeller =
    entityType === FeeDiscountEntityType.User &&
    entity.investorType === InvestorType.UnaccreditedSeller;

  const handleCompanySelect = (value: CompanySearchFragment) => {
    const { feeDiscountApplications } = value || {};

    setValue(`entityCompanyId`, value.id);
    onCompanySelect(
      feeDiscountApplications as FeeDiscountCardActionsCompanySearchFeeDiscountApplication[],
    );
    trigger(`entityCompanyId`);
  };

  return isUnaccreditedSeller ? (
    <UnaccreditedSellerCompanySelect
      holdings={entity.holdings}
      onCompanySelect={onCompanySelect}
    />
  ) : (
    <CompanySearchCombobox
      value={entityCompanyId as unknown as string}
      setValueCallback={handleCompanySelect}
      error={errors.entityCompanyId}
      {...register(`entityCompanyId`)}
    />
  );
}

interface ApplyFeeDiscountModalBodyProps {
  readonly entity: FeeDiscountEntity;
  readonly note?: string;
  readonly showExpirationDate?: boolean;
  readonly showPreview?: boolean;
  readonly showSelectCompany?: boolean;
}

function ApplyFeeDiscountModalBody({
  entity,
  note,
  showExpirationDate,
  showPreview,
  showSelectCompany,
}: ApplyFeeDiscountModalBodyProps) {
  const {
    clearErrors,
    control,
    formState: { errors },
    register,
    setError,
  } = useFormContext<ApplyFeeDiscountInputs>();
  const { t } = useTranslation();
  const feeDiscountPreviewEnabled = useFeeDiscountPreview();
  const { feeDiscountId } = useWatch({ control });

  const { warningMessage, setWarningMessage } =
    useApplyFeeDiscountWarning(entity);

  const [{ data: feeDiscountsData }] =
    useFeeDiscountsCardActionsFeeDiscountsQuery({
      variables: {
        first: 100, // Fetching the first 100 fee discounts for the dropdown. This can be paginated if needed.
        sortBy: {
          field: FeeDiscountSortableField.Type,
          direction: SortDirection.Asc,
        },
        filterBy: {
          active: true,
        },
      },
    });

  const previewEnabled = feeDiscountPreviewEnabled && showPreview;

  const applyOptions = (feeDiscountsData?.feeDiscounts?.edges ?? []).map(
    ({ node: { id, name } }) => ({ label: name, value: id }),
  );

  const handleCompanySelect = (
    feeDiscountApplications: FeeDiscountCardActionsCompanySearchFeeDiscountApplication[],
  ) => {
    const companyHasFeeDiscount = feeDiscountApplications?.some(
      (fda) => fda.entityType === FeeDiscountEntityType.Company,
    );

    if (feeDiscountApplications?.length && companyHasFeeDiscount) {
      setWarningMessage(t(`apply_user_fee_discount_warning_message`));
    } else {
      setWarningMessage(null);
    }
  };

  const handleError = useCallback(
    (error: CommissionInfoValidationError) => {
      const { message } = error;
      setError(`feeDiscountId`, { message });
    },
    [setError],
  );

  useEffect(() => {
    clearErrors();
  }, [clearErrors, feeDiscountId]);

  return (
    <VStack gap={4} alignItems="flex-start" w="full">
      {note && <Text>{note}</Text>}
      {!!warningMessage && <Text color="archived">{warningMessage}</Text>}
      {applyOptions && (
        <Input.Select
          error={errors.feeDiscountId}
          label={t(`select_fee_discount`)}
          options={applyOptions}
          placeholder={t(`select`)}
          {...register(`feeDiscountId`)}
        />
      )}
      {showSelectCompany && (
        <FeeDiscountsCardActionsSelectCompany
          entity={entity}
          onCompanySelect={handleCompanySelect}
        />
      )}
      {showExpirationDate && (
        <Input.Date
          label={`${t(`expiration_date`)} (${t(`optional`)})`}
          error={errors.expireAt}
          minDate={DateTime.now().toISODate() as string}
          {...register(`expireAt`)}
        />
      )}
      {previewEnabled && (
        <FeeBreakdownPreview
          entity={entity}
          feeDiscountId={feeDiscountId}
          onError={handleError}
        />
      )}
    </VStack>
  );
}

interface ApplyFeeDiscountModalProps {
  readonly entity: FeeDiscountEntity;
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly onSuccess?: () => void;
}

export function ApplyFeeDiscountModal({
  entity,
  isOpen,
  onClose,
  onSuccess,
}: ApplyFeeDiscountModalProps) {
  const { entityType } = entity;
  const { t } = useTranslation();
  const ValidationSchema = getValidationSchema(entityType);
  const resolver = useYupValidationResolver(ValidationSchema);

  const methods = useForm<ApplyFeeDiscountInputs>({ resolver });

  const {
    formState: { isSubmitting },
    handleSubmit,
    setError,
  } = methods;

  const { fetching, applyFeeDiscount } = useApplyFeeDiscount();
  const { successToast } = useCustomToast();

  const onConfirm = (data: ApplyFeeDiscountInputs) =>
    applyFeeDiscount({
      data: { ...data, entity },
      onSuccess: () => {
        successToast(t(`fee_discount_applied`));
        onSuccess?.();
        onClose();
      },
      onFailure: (message) => setError(`feeDiscountId`, { message }),
    });

  return (
    <ConfirmModal
      title={t(`apply_fee_discount`)}
      body={
        <FormProvider {...methods}>
          <ApplyFeeDiscountModalBody
            entity={entity}
            {...MODAL_CONFIG[entityType]}
          />
        </FormProvider>
      }
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleSubmit(onConfirm)}
      disableConfirm={isSubmitting || fetching}
    />
  );
}
