/* eslint-disable no-param-reassign */
/* eslint-disable functional/immutable-data */
import React, { useCallback } from "react";
import {
  EdgeProps,
  getSmoothStepPath,
  SmoothStepEdge,
  useReactFlow,
} from "@xyflow/react";
import { Card, Flex } from "@chakra-ui/react";
import { WorkflowStepEdge } from "@/modules/Workflow";
import { useTranslation } from "react-i18next";

const buttonHeight = 35;
const buttonWidth = 250;

type SmartStepEdgeProps = EdgeProps<WorkflowStepEdge>;

export function SmartStepEdge(props: SmartStepEdgeProps) {
  const { t } = useTranslation();
  const { id, data, ...others } = props;

  const [, labelX, labelY] = getSmoothStepPath(others);
  const x = labelX - buttonWidth / 2;
  const y = labelY - buttonHeight / 2;

  const { updateEdgeData } = useReactFlow();

  const onDragLeave = (
    event: React.DragEvent<HTMLDivElement | SVGForeignObjectElement>,
  ) => {
    event.preventDefault();

    updateEdgeData(id, {
      activateDropzone: false,
    });
  };

  const onDragOver = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      updateEdgeData(id, {
        activateDropzone: true,
        x,
        y,
      });
    },
    [id, x, y, updateEdgeData],
  );

  return (
    <>
      <SmoothStepEdge {...props} />
      <foreignObject
        width={buttonWidth}
        height={buttonHeight}
        x={x}
        y={y}
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        {data?.showDropzone && (
          <Card
            as="div"
            borderWidth="1px"
            borderRadius="md"
            boxShadow="0px 2px 2px rgba(0, 0, 0, 0.15)"
            h={buttonHeight}
            w={buttonWidth}
            p={0}
            backgroundColor="white"
            border="1px dashed"
            borderColor={data?.activateDropzone ? `red` : `grey`}
            cursor="pointer"
            justifyContent="center"
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
          >
            <Flex
              color={data?.activateDropzone ? `red` : ``}
              alignItems="center"
              w="full"
              justifyContent="center"
            >
              {t(`drop_step_here`)}
            </Flex>
          </Card>
        )}
      </foreignObject>
    </>
  );
}
