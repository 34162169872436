import React from "react";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import { Button, HStack } from "@chakra-ui/react";
import { Link } from "@/modules/Navigation";

interface TransactionPageNavMenuProps {
  transactionId: string;
}

export default function TransactionPageNavMenu({
  transactionId,
}: TransactionPageNavMenuProps) {
  const { t } = useTranslation();
  const router = useRouter();
  const { asPath } = router;

  return (
    <HStack p={4} ml={4} gap={4} bg="white" borderRadius={8} w="fit-content">
      <Link href={`/transactions/${transactionId}`} replace>
        <Button
          variant="ghost"
          size="lg"
          isActive={asPath === `/transactions/${transactionId}`}
        >
          {t(`overview`)}
        </Button>
      </Link>
      <Link href={`/transactions/${transactionId}/activity`} replace>
        <Button
          variant="ghost"
          size="lg"
          isActive={asPath === `/transactions/${transactionId}/activity`}
        >
          {t(`transaction_activity`)}
        </Button>
      </Link>
    </HStack>
  );
}
