import { useLayoutEffect, RefObject, useCallback } from "react";
import useResizeObserver from "@react-hook/resize-observer";

const TABLE_PADDING_OFFSET = 50;

export default function useTableResizeObserver(
  target: RefObject<HTMLDivElement>,
  destination: RefObject<HTMLDivElement>,
  stickyColumnWidth: number,
) {
  const maybeApplyDropShadowToStickyColumn = useCallback(
    (tableClientWidth: number) => {
      const tableScrollWidth = target?.current?.scrollWidth || 0;
      const tableBodyScroll = target.current?.scrollLeft || 0;
      const maxTableBodyScroll = tableScrollWidth - tableClientWidth;
      const windowWidth =
        window.innerWidth - stickyColumnWidth - TABLE_PADDING_OFFSET;
      if (!destination.current) return;
      if (
        tableScrollWidth > tableClientWidth &&
        windowWidth < tableScrollWidth &&
        Math.ceil(tableBodyScroll) < maxTableBodyScroll
      ) {
        // eslint-disable-next-line no-param-reassign,functional/immutable-data
        destination.current.style.boxShadow = `-5px 0 5px 0 rgba(0, 0, 0, 0.05)`;
      } else {
        // eslint-disable-next-line no-param-reassign,functional/immutable-data
        destination.current.style.boxShadow = `unset`;
      }
    },
    [destination, target, stickyColumnWidth],
  );

  const onTableScroll = useCallback(() => {
    const width = target.current?.getBoundingClientRect().width;
    if (width) maybeApplyDropShadowToStickyColumn(width);
  }, [maybeApplyDropShadowToStickyColumn, target]);

  useLayoutEffect(() => {
    onTableScroll();
  }, [target, onTableScroll]);

  useResizeObserver(target, (entry) => {
    maybeApplyDropShadowToStickyColumn(entry.contentRect.width);
  });
  return { onTableScroll };
}
