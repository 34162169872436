import { FormProvider, useForm } from "react-hook-form";

import {
  FeeDiscountFormInputs,
  FeeDiscountAutoNamingValidationSchema,
  ValidationSchema,
} from "@/features/FeeDiscountForm";
import { useYupValidationResolver } from "@/modules/Form";
import { useFeeDiscountAutoNaming } from "@/modules/LaunchDarkly";
import { Grid, GridItem, VStack } from "@chakra-ui/react";

import FeeDiscountFormControls from "./FeeDiscountFormControls";
import FeeDiscountFormDetailsCard from "./FeeDiscountFormDetailsCard";
import FeeDiscountFormValuesCard from "./FeeDiscountFormValuesCard";

interface FeeDiscountFormProps {
  feeDiscount?: FeeDiscountFormInputs;
}

function FeeDiscountFormContent() {
  const feeDiscountAutoNamingEnabled = useFeeDiscountAutoNaming();

  return (
    <VStack as="form" gap={4} my={6} alignItems="flex-start">
      <FeeDiscountFormControls />

      <Grid templateColumns="repeat(2, 1fr)" gap={4} width="100%">
        <GridItem colSpan={2} order={feeDiscountAutoNamingEnabled ? `2` : `1`}>
          <FeeDiscountFormDetailsCard />
        </GridItem>
        <GridItem colSpan={2} order={feeDiscountAutoNamingEnabled ? `1` : `2`}>
          <FeeDiscountFormValuesCard />
        </GridItem>
      </Grid>

      <FeeDiscountFormControls />
    </VStack>
  );
}

export default function FeeDiscountForm({ feeDiscount }: FeeDiscountFormProps) {
  const feeDiscountAutoNamingEnabled = useFeeDiscountAutoNaming();
  const resolver = useYupValidationResolver(
    feeDiscountAutoNamingEnabled
      ? FeeDiscountAutoNamingValidationSchema
      : ValidationSchema,
  );
  const methods = useForm<FeeDiscountFormInputs>({
    resolver,
    defaultValues: {
      ...(feeDiscount || {}),
    },
    mode: `onChange`,
  });

  return (
    <FormProvider {...methods}>
      <FeeDiscountFormContent />
    </FormProvider>
  );
}
