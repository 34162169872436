import {
  WorkflowTemplatePageWorkflowTemplateFragment,
  WorkflowTemplatesTableFragment,
} from "@/graphql";
import { sentenceCase } from "change-case";

export function workflowTemplateName(
  template:
    | WorkflowTemplatePageWorkflowTemplateFragment
    | WorkflowTemplatesTableFragment,
) {
  if (!template.transferType) {
    return ``;
  }
  if (!template.company) {
    return sentenceCase(template.transferType);
  }
  return template.company.name;
}
