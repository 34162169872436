import { SimpleTableQueryVariables } from "@/modules/SimpleTable";

// This utilty was created because for some reason, values from lodash's merge
// are cached in nextjs... or we think that's the culprit. This could be a larger underlying issue
// with the nextjs framework or lodash. This was initially observed by @AydanGaite @highestblue

// TODO:: look into
const mergeSimpleTableVariables = <T extends SimpleTableQueryVariables>(
  defaultVariables: T,
  urlVariables: SimpleTableQueryVariables,
): T => ({
  ...defaultVariables,
  ...urlVariables,
  filterBy: {
    ...defaultVariables.filterBy,
    ...urlVariables.filterBy,
  },
  sortBy: {
    ...defaultVariables.sortBy,
    ...urlVariables.sortBy,
  },
});

export default mergeSimpleTableVariables;
