import { StatusBadge } from "@/modules/StatusBadge";
import { match } from "ts-pattern";
import {
  ExecutionWorkflow,
  ExecutionWorkflowCondition,
  ExecutionWorkflowStatus,
} from "@/graphql";
import { CompletedIcon, InProgressIcon, PendingIcon } from "@/modules/Step";
import { useColors } from "@/modules/Theme";
import { hasIssuerDeclinedWorkflow } from "@/modules/Workflow";
import { useTranslation } from "react-i18next";

type WorkflowStatus = {
  conditions: { condition: ExecutionWorkflowCondition }[];
  status: ExecutionWorkflow["status"];
};

export function WorkflowStatus({ workflow }: { workflow: WorkflowStatus }) {
  const { t } = useTranslation();
  const [pending] = useColors([`pending`]);

  if (!workflow) {
    return (
      <StatusBadge
        height={5}
        variant="yellow"
        title={t(`pending`)}
        icon={<PendingIcon width={8} fill={pending} />}
      />
    );
  }

  const issuerDeclined = hasIssuerDeclinedWorkflow(workflow.conditions);

  return match({ issuerDeclined, status: workflow.status })
    .with({ issuerDeclined: true }, () => null)
    .with({ status: ExecutionWorkflowStatus.Pending }, () => (
      <StatusBadge
        height={5}
        variant="yellow"
        title={t(`pending`)}
        icon={<PendingIcon width={8} fill={pending} />}
      />
    ))
    .with({ status: ExecutionWorkflowStatus.InProgress }, () => (
      <StatusBadge
        height={5}
        variant="sky"
        title={t(`in_progress`)}
        icon={<InProgressIcon width={8} />}
      />
    ))
    .with({ status: ExecutionWorkflowStatus.Completed }, () => (
      <StatusBadge
        height={5}
        variant="grey"
        title={t(`completed`)}
        icon={<CompletedIcon width={8} />}
      />
    ))
    .otherwise(() => {
      throw new Error(`Unknown status in workflows table <StatusCell/>`);
    });
}
