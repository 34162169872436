import { useTranslation } from "react-i18next";

import { Skeleton } from "@/modules/Skeleton";
import { GridItem, SimpleGrid, Text, VStack } from "@chakra-ui/react";

enum VariantType {
  grey = `grey`,
  teal = `teal`,
}

type VariantConfig = {
  borderColor?: string;
  labelColor?: string;
  amount: Record<string, string>;
  hiiveFees?: Record<string, string>;
  netProceeds?: Record<string, string>;
};

const VARIANT_CONFIG: Record<VariantType, VariantConfig> = {
  [VariantType.teal]: {
    amount: {
      textStyle: `colfax-16-regular`,
    },
    netProceeds: {
      borderColor: `teal.500`,
      bg: `teal.25`,
    },
  },
  [VariantType.grey]: {
    borderColor: `grey.200`,
    labelColor: `grey.400`,
    amount: {
      textStyle: `colfax-16-regular`,
    },
    hiiveFees: {
      bg: `grey.50`,
    },
  },
};

const BOX_STYLES = {
  alignItems: `baseline`,
  gridTemplateColumns: `1fr 1fr`,
  borderColor: VARIANT_CONFIG.grey.borderColor,
  borderWidth: 0.5,
  borderBottomWidth: 0,
  px: 4,
  py: 2,
  overflow: `hidden`,
};
const GRID_STYLES = {
  label: {
    color: VARIANT_CONFIG.grey.labelColor,
    colSpan: 2,
  },
  container: {
    alignItems: `stretch`,
    gap: 0,
    textStyle: `colfax-14-medium-uppercased`,
    width: `full`,
  },
  gridItemAmount: {
    justifySelf: `end`,
  },
  grossProceedsSection: {
    ...BOX_STYLES,
    borderTopRadius: `md`,
    borderWidth: BOX_STYLES.borderWidth,
  },
  feesSection: {
    ...BOX_STYLES,
    ...VARIANT_CONFIG.grey.hiiveFees,
  },
  netFeesSection: {
    ...BOX_STYLES,
    ...VARIANT_CONFIG.grey.hiiveFees,
  },
  netProceedsSection: {
    ...BOX_STYLES,
    borderBottomRadius: `md`,
    borderBottomWidth: BOX_STYLES.borderWidth,
  },
};

export default function FeeBreakdownSkeleton() {
  const { t } = useTranslation();

  return (
    <VStack {...GRID_STYLES.container}>
      <SimpleGrid {...GRID_STYLES.grossProceedsSection}>
        <GridItem {...GRID_STYLES.label}>
          <Text>{t(`gross_sale_amount`)}</Text>
        </GridItem>
        <GridItem>
          <Skeleton h={4} w={40} />
        </GridItem>
        <GridItem {...GRID_STYLES.gridItemAmount}>
          <Skeleton h={4} w={40} />
        </GridItem>
      </SimpleGrid>

      <SimpleGrid {...GRID_STYLES.feesSection}>
        <GridItem {...GRID_STYLES.label}>
          <Text>{t(`hiive_fees`)}</Text>
        </GridItem>
        <GridItem>
          <Text>{t(`hiive_base_fee`)}</Text>
        </GridItem>
        <GridItem {...GRID_STYLES.gridItemAmount}>
          <Skeleton h={4} w={40} />
        </GridItem>
        <GridItem>
          <Text>{t(`hiive_commission`)}</Text>
        </GridItem>
        <GridItem {...GRID_STYLES.gridItemAmount}>
          <Skeleton h={4} w={40} />
        </GridItem>
      </SimpleGrid>

      <SimpleGrid {...GRID_STYLES.netFeesSection}>
        <GridItem>
          <Text>{t(`total_hiive_fees`)}</Text>
        </GridItem>
        <GridItem {...GRID_STYLES.gridItemAmount}>
          <Skeleton h={4} w={40} />
        </GridItem>
      </SimpleGrid>

      <SimpleGrid {...GRID_STYLES.netProceedsSection}>
        <GridItem {...GRID_STYLES.label}>
          <Text>{t(`net_amount`)}</Text>
        </GridItem>
        <GridItem>
          <Text>{t(`net_price_per_share`)}</Text>
        </GridItem>
        <GridItem {...GRID_STYLES.gridItemAmount}>
          <Skeleton h={4} w={40} />
        </GridItem>
        <GridItem>
          <Text>{t(`net_proceeds`)}</Text>
        </GridItem>
        <GridItem {...GRID_STYLES.gridItemAmount}>
          <Skeleton h={4} w={40} />
        </GridItem>
      </SimpleGrid>
    </VStack>
  );
}
