import { CardTable } from "@/modules/CardTable";
import { Card, CardHeader, Stack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import {
  InvestorType,
  SigningProcedure,
  TransactionDetailFragment,
} from "@/graphql";
import { getSignedSigner } from "@/features/Transactions";
import { useMultiEntityExperienceEnabled } from "@/modules/LaunchDarkly";
import {
  BuyerBrokerTransactionSignerCard,
  BuyerTransactionSignerCard,
  RepresentedUserTransactionSignerCard,
  SellerBrokerTransactionSignerCard,
  SellerTransactionSignerCard,
} from ".";
import { EntityDetailsCard } from "./EntityDetailsCard";

type TransactionSignersProps = {
  readonly transaction: TransactionDetailFragment;
};

function TransactionSigners({ transaction }: TransactionSignersProps) {
  const { t } = useTranslation();

  const buyer = transaction?.bid?.buyer;
  const buyerBroker = transaction?.bid?.broker;
  const sellerBroker = transaction?.bid?.listing?.broker;
  const seller = transaction?.bid.counterparty;
  const signers = transaction?.document?.signers || [];
  const isTransactionAutomated =
    transaction?.signingProcedure === SigningProcedure.Automated;

  const sellerPartySigner = getSignedSigner(signers, seller, sellerBroker!);

  const buyerPartySigner = getSignedSigner(signers, buyer, buyerBroker!);

  const sellerRepresentedUser = transaction?.representedSeller;
  const buyerRepresentedUser = transaction?.representedBuyer;

  const multiEntityExperienceEnabled = useMultiEntityExperienceEnabled();

  return (
    <CardTable w="full" columns={2}>
      <Card variant="table" bg="white" justifyContent="flex-start">
        <CardHeader>{t(`buyer`)}</CardHeader>
        <Card m={10} gap={6}>
          <Stack gap={4}>
            {multiEntityExperienceEnabled && (
              <EntityDetailsCard
                entity={transaction.buyerEntity}
                id={buyer.institutionId ?? buyer.id}
                isInstitutionalUser={!!buyer.institutionId}
                isBuyer
                transaction={transaction}
              />
            )}
            {!!buyerRepresentedUser?.id && (
              <RepresentedUserTransactionSignerCard
                signer={{
                  id: buyerRepresentedUser.id,
                  email: buyerRepresentedUser.email!,
                  name: `${buyerRepresentedUser.firstName} ${buyerRepresentedUser.lastName}`,
                }}
              />
            )}
            {!!buyerBroker?.id && (
              <BuyerBrokerTransactionSignerCard
                signer={{
                  ...buyerBroker,
                  investorType: InvestorType.Broker,
                  name: buyerBroker.name!,
                  email: buyerBroker.email!,
                  isSigner: isTransactionAutomated,
                  signedAt: buyerPartySigner?.insertedAt,
                }}
              />
            )}
            {buyer && (
              <BuyerTransactionSignerCard
                signer={{
                  ...buyer,
                  name: buyer.name!,
                  email: buyer.email!,
                  isSigner:
                    isTransactionAutomated &&
                    (!!buyerRepresentedUser?.id || !buyerBroker?.id),
                  signedAt: buyerPartySigner?.insertedAt,
                  isHiiveUser: !!buyerBroker?.id || !!buyerRepresentedUser?.id,
                }}
              />
            )}
          </Stack>
        </Card>
      </Card>
      <Card variant="table" bg="white">
        <CardHeader>{t(`seller`)}</CardHeader>
        <Card m={10} gap={6}>
          <Stack gap={4}>
            {multiEntityExperienceEnabled && (
              <EntityDetailsCard
                entity={transaction.sellerEntity}
                id={seller.institutionId ?? seller.id}
                isInstitutionalUser={!!seller.institutionId}
                isBuyer={false}
                transaction={transaction}
              />
            )}
            {!!sellerRepresentedUser?.id && (
              <RepresentedUserTransactionSignerCard
                signer={{
                  id: sellerRepresentedUser.id,
                  email: sellerRepresentedUser.email!,
                  name: `${sellerRepresentedUser.firstName} ${sellerRepresentedUser.lastName}`,
                }}
              />
            )}
            {!!sellerBroker?.id && (
              <SellerBrokerTransactionSignerCard
                signer={{
                  ...sellerBroker,
                  investorType: InvestorType.Broker,
                  name: sellerBroker.name!,
                  email: sellerBroker.email!,
                  isSigner: isTransactionAutomated,
                  signedAt: sellerPartySigner?.insertedAt,
                }}
              />
            )}
            {seller && (
              <SellerTransactionSignerCard
                signer={{
                  ...seller,
                  name: seller.name!,
                  email: seller.email!,
                  isSigner:
                    isTransactionAutomated &&
                    (!!sellerRepresentedUser?.id || !sellerBroker?.id),
                  signedAt: sellerPartySigner?.insertedAt,
                  isHiiveUser:
                    !!sellerBroker?.id || !!sellerRepresentedUser?.id,
                }}
              />
            )}
          </Stack>
        </Card>
      </Card>
    </CardTable>
  );
}

export default TransactionSigners;
