import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Box,
  HStack,
  IconButton,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { ThemingProps } from "@chakra-ui/styled-system";
import { X } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

interface HiiveModalProps {
  title: string;
  body: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  confirmText?: string;
  loading?: boolean;
  disableConfirm?: boolean;
  size?: ThemingProps<"Modal">["size"];
  modalVariant?: ThemingProps<"Modal">["variant"];
}

export default function HiiveModal({
  title,
  body,
  isOpen,
  onClose,
  onConfirm,
  loading = false,
  confirmText = `Confirm`,
  disableConfirm = false,
  size = `lg`,
  modalVariant = `base`,
}: HiiveModalProps) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={size} variant={modalVariant}>
      <ModalOverlay />
      <ModalContent borderWidth={0} maxW="2xl">
        <ModalHeader
          as={HStack}
          p={{ base: 4, md: 6 }}
          justifyContent="space-between"
          alignItems="center"
          w="full"
          color="white"
          bg="grey.900"
        >
          <Box textStyle="heading-3xl">{title}</Box>
          <IconButton
            aria-label={t(`close_modal`)}
            onClick={onClose}
            variant="iconClose"
            icon={<X size={32} />}
          />
        </ModalHeader>
        <ModalBody>{body}</ModalBody>
        <ModalFooter bg="grey.25">
          <Button
            isLoading={loading}
            onClick={onConfirm}
            size="lg"
            variant="hiive"
            isDisabled={disableConfirm}
            px={12}
          >
            {confirmText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
