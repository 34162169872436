import { Text } from "@chakra-ui/react";
import { AddEntityInput } from "@/graphql";
import { getCountryName } from "@/modules/Country";
import { useTranslation } from "react-i18next";
import EntityCardBody from "./EntityCardBody";
import EntityCard from "./EntityCard";

type NewEntityCardProps = {
  entity: AddEntityInput;
};

function NewEntityCard({ entity }: NewEntityCardProps) {
  const { t } = useTranslation();
  const country = getCountryName(entity.jurisdictionOfFormationId);

  return (
    <EntityCard borderColor="black" borderStyle="solid" borderWidth={1}>
      <Text fontWeight={400} fontSize={12} lineHeight={3}>
        {t(`new_entity`)}
      </Text>
      <EntityCardBody country={country} entity={entity} />
    </EntityCard>
  );
}

export default NewEntityCard;
