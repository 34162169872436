import { SVGProps } from "react";

function InProgressIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.14734 0.362104L5.11873 0.333496L5.08707 0.365163C4.6644 0.387758 4.33696 0.430167 4.03989 0.509767C2.31443 0.972103 0.966691 2.31984 0.504355 4.0453C0.333984 4.68113 0.333984 5.45611 0.333984 7.00606C0.333984 8.55602 0.333984 9.33099 0.504355 9.96682C0.966691 11.6923 2.31443 13.04 4.03989 13.5024C4.67572 13.6727 5.4507 13.6727 7.00065 13.6727C8.5506 13.6727 9.32558 13.6727 9.96141 13.5024C11.6869 13.04 13.0346 11.6923 13.4969 9.96682C13.6673 9.33099 13.6673 8.55602 13.6673 7.00606C13.6673 5.45611 13.6673 4.68113 13.4969 4.0453C13.0346 2.31984 11.6869 0.972103 9.96141 0.509767C9.32558 0.339396 8.5506 0.339396 7.00065 0.339396C6.20892 0.339396 5.6194 0.339396 5.14734 0.362104ZM4.41142 2.57246C3.63546 2.83105 3.00785 3.40249 2.67521 4.14013L9.86658 11.3315C10.6042 10.9989 11.1757 10.3712 11.4343 9.59529L4.41142 2.57246ZM11.5839 6.79865L7.20807 2.42283C8.72122 2.42436 9.13097 2.44408 9.42221 2.52211C10.4287 2.79181 11.2149 3.57799 11.4846 4.58451C11.5626 4.87574 11.5824 5.28549 11.5839 6.79865ZM4.57909 11.49C4.88322 11.5715 5.31657 11.5894 7.00065 11.5894L7.17813 11.5893L2.41739 6.82859L2.41732 7.00606C2.41732 8.69014 2.43521 9.1235 2.5167 9.42762C2.7864 10.4341 3.57258 11.2203 4.57909 11.49Z"
        fill="#2191B7"
      />
    </svg>
  );
}
export default InProgressIcon;
