import { ConfirmModal } from "@/modules/Modal";
import { Text } from "@chakra-ui/react";
import { sentenceCase } from "change-case";
import { Trans, useTranslation } from "react-i18next";
import {
  useUpdateExecutionWorkflowTemplateStatusMutation,
  WorkflowTemplateStatus,
} from "@/graphql";

import { WorkflowTemplatesTableData } from "./WorkflowTemplatesTable";

export function ConfirmTemplateStatusToggleModal({
  workflowTemplate,
  isOpen,
  onClose,
}: {
  workflowTemplate: WorkflowTemplatesTableData;
  isOpen: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation();

  const [_, updateExecutionWorkflowTemplateStatus] =
    useUpdateExecutionWorkflowTemplateStatusMutation();
  const title =
    workflowTemplate.status === `INACTIVE`
      ? t(`publish_company_template`)
      : t(`make_company_template_inactive`);

  const bodyTextKey =
    workflowTemplate.status === `INACTIVE`
      ? `publish_company_template_warning`
      : `confirm_make_template_inactive`;

  const toggleStatus = () => {
    updateExecutionWorkflowTemplateStatus({
      id: workflowTemplate.id,
      status:
        workflowTemplate.status === WorkflowTemplateStatus.Active
          ? WorkflowTemplateStatus.Inactive
          : WorkflowTemplateStatus.Active,
    });
    onClose();
  };

  return (
    <ConfirmModal
      title={title}
      body={
        <Text>
          <Trans
            i18nKey={bodyTextKey}
            components={{ bold: <strong /> }}
            values={{
              company_name: workflowTemplate?.company?.name,
              transfer_type: sentenceCase(workflowTemplate.transferType || ``),
            }}
          />
        </Text>
      }
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={toggleStatus}
    />
  );
}
