import { match } from "ts-pattern";

import { FeeDiscountType } from "@/graphql";
import { CurrencyDollarSimple, Percent } from "@phosphor-icons/react";

interface FeeDiscountIconProps {
  readonly feeDiscountType: FeeDiscountType;
}

export default function FeeDiscountIcon({
  feeDiscountType,
}: FeeDiscountIconProps) {
  return match(feeDiscountType)
    .with(FeeDiscountType.FlatFee, () => <CurrencyDollarSimple size={16} />)
    .with(FeeDiscountType.PercentageFee, () => <Percent size={16} />)
    .with(FeeDiscountType.OverrideCommission, () => <Percent size={16} />)
    .otherwise(() => <CurrencyDollarSimple size={16} />);
}
