import {
  BoxProps,
  HStack,
  Text,
  ThemingProps,
  useStyleConfig,
} from "@chakra-ui/react";
import { ReactNode } from "react";

interface StatusBadgeProps extends BoxProps, ThemingProps<`Status`> {
  readonly icon?: ReactNode;
  readonly title: string;
}

function StatusBadge({
  size,
  variant,
  title,
  icon,
  ...boxProps
}: StatusBadgeProps) {
  const styles = useStyleConfig(`Status`, { size, variant });

  return (
    <HStack py={0.5} px={1} spacing={1} __css={styles} {...boxProps}>
      {!!icon && icon}
      <Text textStyle="colfax-12-regular">{title}</Text>
    </HStack>
  );
}

export default StatusBadge;
