import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  CommissionInfoValidationError,
  FeeDiscountEntityType,
  FeeDiscountType,
  useFeeDiscountsCardActionsFeeDiscountPreviewQuery,
} from "@/graphql";
import { FeeBreakdown } from "@/modules/FeeBreakdown";
import { FeeDiscountEntity } from "@/modules/FeeDiscountsCard";
import { useFeeDiscountAutoNaming } from "@/modules/LaunchDarkly";
import { parseToInteger } from "@/modules/StringFormat";
import { isValidNumber } from "@/modules/Validation";
import { Text } from "@chakra-ui/react";

interface FeeBreakdownPreviewProps {
  entity: FeeDiscountEntity;
  feeDiscountId?: string | undefined;
  feeDiscountName?: string | undefined;
  feeDiscountType?: FeeDiscountType | undefined;
  feeDiscountValue?: number | undefined;
  onError: (error: CommissionInfoValidationError) => void;
}

export default function FeeBreakdownPreview({
  entity,
  feeDiscountId,
  feeDiscountName,
  feeDiscountType,
  feeDiscountValue,
  onError,
}: FeeBreakdownPreviewProps) {
  const feeDiscountAutoNamingEnabled = useFeeDiscountAutoNaming();
  const { t } = useTranslation();

  const { entityId, entityType } = entity;

  const getVariables = useMemo(() => {
    if (entityType === FeeDiscountEntityType.Listing) {
      return { listingId: entityId };
    }

    if (entityType === FeeDiscountEntityType.Transaction) {
      return {
        transactionId: entityId,
        ...(feeDiscountName &&
        feeDiscountType &&
        isValidNumber(feeDiscountValue)
          ? {
              feeDiscountName,
              feeDiscountType,
              feeDiscountValue,
            }
          : {}),
      };
    }

    return {};
  }, [
    entityId,
    entityType,
    feeDiscountName,
    feeDiscountType,
    feeDiscountValue,
  ]);

  const getVariablesV2 = useMemo(() => {
    if (entityType === FeeDiscountEntityType.Listing) {
      return { listingId: entityId };
    }

    if (entityType === FeeDiscountEntityType.Transaction) {
      return {
        transactionId: entityId,
        ...(feeDiscountType && isValidNumber(feeDiscountValue)
          ? {
              feeDiscountType,
              feeDiscountValue:
                feeDiscountType === FeeDiscountType.FlatFee
                  ? feeDiscountValue
                  : undefined,
              feeDiscountPercentageValue:
                feeDiscountType !== FeeDiscountType.FlatFee
                  ? feeDiscountValue
                  : undefined,
            }
          : {}),
      };
    }

    return {};
  }, [entityId, entityType, feeDiscountType, feeDiscountValue]);

  const [{ data }] = useFeeDiscountsCardActionsFeeDiscountPreviewQuery({
    variables: {
      ...(feeDiscountAutoNamingEnabled ? getVariablesV2 : getVariables),
      ...(feeDiscountId ? { feeDiscountId } : {}),
    },
    requestPolicy: `network-only`,
  });

  const {
    commissionAmount,
    feeDiscountAmount,
    feeDiscountApplications,
    flatFeeAmount,
    netFees,
    validationError,
  } = data?.commissionInfo || {};

  const commission = useMemo(
    () => ({
      commissionAmount: parseToInteger(commissionAmount),
      feeDiscountAmount: parseToInteger(feeDiscountAmount),
      flatFeeAmount: parseToInteger(flatFeeAmount),
      netFees: parseToInteger(netFees),
    }),
    [commissionAmount, feeDiscountAmount, flatFeeAmount, netFees],
  );

  const getNumShares = useMemo(() => {
    if (entityType === FeeDiscountEntityType.Listing) {
      const { listing } = entity;
      return listing?.numSharesAvailable;
    }

    if (entityType === FeeDiscountEntityType.Transaction) {
      const { transaction } = entity;
      const { pendingModification } = transaction;

      return pendingModification
        ? pendingModification.numShares
        : transaction.numShares;
    }

    return 0;
  }, [entity, entityType]);

  const getPricePerShare = useMemo(() => {
    if (entityType === FeeDiscountEntityType.Listing) {
      const { listing } = entity;
      return listing?.pricePerShare;
    }

    if (entityType === FeeDiscountEntityType.Transaction) {
      const { transaction } = entity;
      const { pendingModification } = transaction;

      return pendingModification
        ? pendingModification.pricePerShare
        : transaction.pricePerShare;
    }

    return 0;
  }, [entity, entityType]);

  useEffect(() => {
    if (validationError) onError(validationError);
  }, [onError, validationError]);

  return (
    <>
      <Text color="grey.600" textStyle="colfax-14-medium-uppercased">
        {t(`discount_preview`)}
      </Text>
      <FeeBreakdown
        commission={commission}
        feeDiscountApplications={feeDiscountApplications}
        numSharesActual={getNumShares || 0}
        pricePerShare={getPricePerShare || 0}
      />
    </>
  );
}
