import { Badge, BadgeProps, Box, BoxProps } from "@chakra-ui/react";
import { ReactElement } from "react";

export interface StatusWithIndicatorProps extends BadgeProps {
  text: string | ReactElement;
  indicatorProps?: BoxProps;
}

function StatusIndicator(props: BoxProps) {
  return <Box as="span" w={2} h={2} borderRadius={8} {...props} />;
}

export default function StatusWithIndicator({
  text,
  indicatorProps,
  ...restBadge
}: StatusWithIndicatorProps) {
  return (
    <Badge variant="plain" gap={2} {...restBadge}>
      <StatusIndicator {...indicatorProps} />
      {text}
    </Badge>
  );
}
