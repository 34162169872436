import { useCallback } from "react";

import { useTransactionPageQuery } from "@/graphql";
import { ErrorWrapper } from "@/modules/Error";
import { useTEXASEnabled } from "@/modules/LaunchDarkly";
import { DetailPageLayout } from "@/modules/Layout";

import TransactionOverview from "./TransactionOverview";
import { TransactionOverviewSkeleton } from "./TransactionOverviewSkeleton";
import TransactionPageNavMenu from "./TransactionPageNavMenu";
import { TransactionPageV2 } from "./TransactionPageV2";
import TransactionRightPanel from "./TransactionRightPanel";
import { TransactionRightPanelSkeleton } from "./TransactionRightPanelSkeleton";

export function TransactionPageV1({
  transactionId,
}: {
  readonly transactionId: string;
}) {
  const [{ data, error, fetching }, executeQuery] = useTransactionPageQuery({
    variables: { transactionId },
    requestPolicy: `cache-and-network`,
  });

  const refetch = useCallback(() => {
    executeQuery({ requestPolicy: `network-only` });
  }, [executeQuery]);

  if (fetching) {
    return (
      <DetailPageLayout
        isTwoColumn
        mainPanel={<TransactionOverviewSkeleton />}
        rightPanel={<TransactionRightPanelSkeleton />}
        tabMenu={<TransactionPageNavMenu transactionId={transactionId} />}
      />
    );
  }

  return (
    <ErrorWrapper error={error?.message}>
      {data?.transaction?.id ? (
        <DetailPageLayout
          isTwoColumn
          mainPanel={<TransactionOverview data={data} />}
          rightPanel={<TransactionRightPanel data={data} refetch={refetch} />}
          tabMenu={<TransactionPageNavMenu transactionId={transactionId} />}
        />
      ) : null}
    </ErrorWrapper>
  );
}

export default function TransactionPage({
  transactionId,
}: {
  transactionId: string;
}) {
  const TEXASEnabled = useTEXASEnabled();

  return TEXASEnabled ? (
    <TransactionPageV2 transactionId={transactionId} />
  ) : (
    <TransactionPageV1 transactionId={transactionId} />
  );
}
