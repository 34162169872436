import { Text, Card, CardBody, CardHeader } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

type CompanySecuritySpecialistCardProps = {
  email: string;
};

export default function CompanySecuritySpecialistCard({
  email,
}: CompanySecuritySpecialistCardProps) {
  const { t } = useTranslation();
  return (
    <Card w="full">
      <CardHeader textStyle="colfax-22-medium">
        {t(`security_specialist`)}
      </CardHeader>
      <CardBody>
        <Text textStyle="colfax-14-regular" mt={4}>
          {email}
        </Text>
      </CardBody>
    </Card>
  );
}
