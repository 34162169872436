import {
  Box,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Text,
} from "@chakra-ui/react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { CompanyManagedMarketContext } from "@/features/Company";
import { Status } from "@/modules/Status";
import { CompanyManagedMarketApprovedBuyersContent } from "./CompanyManagedMarketApprovedBuyersContent";
import CompanyManagedMarketApprovedBuyersActions from "./CompanyManagedMarketApprovedBuyersActions";

export default function CompanyManagedMarketApprovedBuyersCard() {
  const { t } = useTranslation();
  const { managedMarketCompany } = useContext(CompanyManagedMarketContext);

  const requiresApprovedBuyers = managedMarketCompany?.requiresApprovedBuyers;

  return (
    <Card w="full">
      <CardHeader as={HStack} gap={4} alignItems="center">
        <Text textStyle="colfax-22-medium">{t(`approved_buyers`)}</Text>
        <Box flexGrow={1} />
        <Status.Indicator
          text={requiresApprovedBuyers ? t(`activated`) : t(`deactivated`)}
          fontWeight={500}
          indicatorProps={{
            bg: requiresApprovedBuyers ? `active` : `archived`,
            w: 2.5,
            h: 2.5,
          }}
        />
        <CompanyManagedMarketApprovedBuyersActions />
      </CardHeader>
      <CardBody>
        <CompanyManagedMarketApprovedBuyersContent />
      </CardBody>
    </Card>
  );
}
