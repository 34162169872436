import { getCountryList } from ".";

export default function useGetCountryName(countryCode?: string) {
  if (!countryCode) {
    return null;
  }

  const { getName } = getCountryList();

  return getName(countryCode);
}
