import { HStack } from "@chakra-ui/react";
import TransactionSignerCard, {
  TransactionSignerCardProps,
} from "./TransactionSignerCard";

function SellerTransactionSignerCard({ signer }: TransactionSignerCardProps) {
  return (
    <TransactionSignerCard.Card>
      <HStack justifyContent="space-between" w="full">
        <TransactionSignerCard.Pill signer={signer} />
      </HStack>
      <TransactionSignerCard.EmailDetails
        email={signer.email}
        name={signer.name}
      />
      <TransactionSignerCard.SignerStatus signedAt={signer.signedAt} />
      <TransactionSignerCard.FullDetails userId={signer.id} />
    </TransactionSignerCard.Card>
  );
}

export default SellerTransactionSignerCard;
