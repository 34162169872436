import {
  CompanyFragment,
  CompanyStatus,
  useCompaniesTableRowActionsUpdateCompanyStatusMutation,
} from "@/graphql";
import {
  Box,
  Link,
  Menu,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  UseToastOptions,
} from "@chakra-ui/react";
import { ArrowSquareOut, Circle } from "@phosphor-icons/react";
import { match } from "ts-pattern";

import { ConfirmModal } from "@/modules/Modal";
import { Table } from "@/modules/Table";
import { useCustomToast } from "@/modules/Toast";
import { useRouter } from "next/router";
import { useCallback, useId, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

const STATUS_ICON_SIZE = 7;

interface CompaniesTableRowActionsProps {
  company: CompanyFragment;
  showActionButtonTitle?: boolean;
}

interface CompanyMenuItemProps {
  companyId: string;
}

interface SuccessToastProps extends UseToastOptions {
  title: string;
}

const useUpdateCompanyStatus = () => {
  const [, updateCompanyStatus] =
    useCompaniesTableRowActionsUpdateCompanyStatusMutation();
  const { errorToast, successToast, successToastLight } = useCustomToast();
  const toastId = useId();

  return useCallback(
    async (
      companyId: string,
      status: Exclude<CompanyStatus, CompanyStatus.Draft>,
      { title, ...opts }: SuccessToastProps,
    ) => {
      const { data } = await updateCompanyStatus({ companyId, status });

      if (data?.updateCompanyStatus?.company?.id) {
        const toastVariant = {
          [CompanyStatus.Delisted]: successToastLight,
          [CompanyStatus.Listed]: successToast,
        }[status];

        toastVariant(title, {
          id: toastId,
          ...opts,
        });
      } else {
        errorToast(null, { id: toastId });
      }
    },
    [errorToast, successToast, successToastLight, toastId, updateCompanyStatus],
  );
};

function DelistCompanyMenuItem({ companyId }: CompanyMenuItemProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const updateStatus = useUpdateCompanyStatus();

  return (
    <>
      <MenuGroup>
        <MenuItem
          icon={
            <Box
              as={Circle}
              size={STATUS_ICON_SIZE}
              color="pending"
              weight="fill"
            />
          }
          onClick={() => setOpen(true)}
        >
          {t(`delist_company`)}
        </MenuItem>
      </MenuGroup>
      <ConfirmModal
        title={t(`confirm_delist_title`)}
        body={
          <Text>
            <Trans
              i18nKey="confirm_delist_company"
              components={{ bold: <strong /> }}
            />
          </Text>
        }
        isOpen={open}
        onClose={() => setOpen(false)}
        onConfirm={async () => {
          setOpen(false);
          await updateStatus(companyId, CompanyStatus.Delisted, {
            title: t(`company_status_delisted`),
            description: t(`company_saved_delisted_body`),
          });
        }}
      />
    </>
  );
}

function ListCompanyMenuItem({ companyId }: CompanyMenuItemProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const updateStatus = useUpdateCompanyStatus();

  return (
    <>
      <MenuGroup>
        <MenuItem
          icon={
            <Box
              as={Circle}
              size={STATUS_ICON_SIZE}
              color="active"
              weight="fill"
            />
          }
          onClick={() => setOpen(true)}
        >
          {t(`list_company`)}
        </MenuItem>
      </MenuGroup>
      <ConfirmModal
        title={t(`confirm_list_title`)}
        body={
          <>
            <Text>
              <Trans
                i18nKey="confirm_list_company"
                components={{ bold: <strong /> }}
              />
            </Text>
            <Text color="archived">{t(`confirm_list_company_extra`)}</Text>
          </>
        }
        isOpen={open}
        onClose={() => setOpen(false)}
        onConfirm={async () => {
          setOpen(false);
          await updateStatus(companyId, CompanyStatus.Listed, {
            title: t(`company_status_listed`),
            description: t(`company_saved_listed_body`),
          });
        }}
      />
    </>
  );
}

export default function CompaniesTableRowActions({
  company,
  showActionButtonTitle,
}: CompaniesTableRowActionsProps) {
  const { push } = useRouter();
  const { t } = useTranslation();
  const { status } = company;

  const listingAction = useMemo(
    () =>
      match(status.toUpperCase() as CompanyStatus)
        .with(CompanyStatus.Delisted, () => (
          <ListCompanyMenuItem companyId={company.id} />
        ))
        .with(CompanyStatus.Draft, () => (
          <ListCompanyMenuItem companyId={company.id} />
        ))
        .with(CompanyStatus.Listed, () => (
          <DelistCompanyMenuItem companyId={company.id} />
        ))
        .exhaustive(),
    [company.id, status],
  );

  return (
    <Menu>
      <Table.ActionButton showTitle={showActionButtonTitle} />
      <MenuList>
        {listingAction}
        <MenuDivider />
        <MenuGroup>
          <MenuItem
            onClick={async () => {
              await push(`/companies/edit/${company.nameSlug}`);
            }}
          >
            {t(`edit_company`)}
          </MenuItem>
        </MenuGroup>
        <MenuDivider />
        <MenuGroup>
          <MenuItem
            as={Link}
            href={`${process.env.NEXT_PUBLIC_HIIVE_URL}/companies/${company.id}`}
            isExternal
            fontWeight={400}
          >
            {t(`open_in_platform`)}
            <Box as={ArrowSquareOut} size={16} ml={1} />
          </MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  );
}
