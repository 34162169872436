import { FeeDiscountPageApplicationsTableDataType } from "@/features/FeeDiscount";
import { FeeDiscountEntityType } from "@/graphql";
import { Row } from "@tanstack/react-table";
import router from "next/router";
import { useCallback } from "react";

type FeeDiscountApplicationTableData = {
  entityId: string;
  entityType: FeeDiscountEntityType;
  nameSlug?: string;
};

const getEntityRedirectUrl = ({
  entityId,
  entityType,
  nameSlug,
}: FeeDiscountApplicationTableData) =>
  ({
    [FeeDiscountEntityType.Company]: `/companies/${nameSlug}`,
    [FeeDiscountEntityType.Listing]: `/listings/${entityId}`,
    [FeeDiscountEntityType.Transaction]: `/transactions/${entityId}`,
    [FeeDiscountEntityType.User]: `/users/${entityId}`,
  })[entityType];

export function useRowClick() {
  const { push } = router;

  return useCallback(
    async (row: Row<Record<string, unknown>>) => {
      const { entityId, entityType, nameSlug } =
        row.original as FeeDiscountPageApplicationsTableDataType;
      const url = getEntityRedirectUrl({ entityId, entityType, nameSlug });
      if (url) {
        await push(url);
      }
    },
    [push],
  );
}
