import {
  ExecutionStepType,
  ExecutionTaskType,
  TransactionPageV2ExecutionTaskFragment,
} from "@/graphql";
import { HStack, VStack } from "@chakra-ui/react";

import React from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import { StatusBadge } from "@/modules/StatusBadge";
import TaskCard from "./TaskCard";
import { taskUserLabel } from "./utils";

function TaskWithDependency({
  task,
  index,
  previousTask,
  stepName,
  stepType,
  preview = false,
}: {
  readonly task: TransactionPageV2ExecutionTaskFragment;
  readonly index: number;
  readonly previousTask?: TransactionPageV2ExecutionTaskFragment;
  readonly stepName: string;
  readonly stepType: ExecutionStepType;
  readonly preview?: boolean;
}) {
  const { t } = useTranslation();

  return (
    <VStack
      key={task.id}
      w="full"
      alignItems="start"
      borderLeft="1.5px solid"
      borderColor="grey.100"
      pl={3}
    >
      <HStack>
        {previousTask &&
          match(previousTask.type)
            .with(ExecutionTaskType.AnvilFillDocument, () => (
              <StatusBadge
                variant="grey"
                // this is temp
                title={`${taskUserLabel(previousTask.actorType)} ${t(
                  `fill_document`,
                )}`}
              />
            ))
            .with(ExecutionTaskType.AnvilSignDocument, () => (
              <StatusBadge
                variant="grey"
                // this is temp
                title={`${taskUserLabel(previousTask.actorType)} ${t(
                  `sign_document`,
                )}`}
              />
            ))
            .otherwise(() => null)}
        <StatusBadge
          variant="sky"
          // this will need more advanced logic when we get steps with more tasks
          title={t(index === 0 ? `do_this` : `then`)}
        />
      </HStack>
      <TaskCard
        task={task}
        stepName={stepName}
        stepType={stepType}
        preview={preview}
      />
    </VStack>
  );
}

export default TaskWithDependency;
