import { useColors } from "@/modules/Theme";
import { Card, HStack, Text } from "@chakra-ui/react";
import { Handle, Position } from "@xyflow/react";

import { ExecutionStepStatus } from "@/graphql";
import { StepIcon } from "@/modules/Step";
import { WorkflowPreviewStepNodeProps } from "./types";

const statusColor: Record<
  ExecutionStepStatus,
  { bg: string; border: string; opacity: number }
> = {
  [ExecutionStepStatus.Pending]: {
    bg: `white`,
    border: `grey.100`,
    opacity: 1,
  },
  [ExecutionStepStatus.InProgress]: {
    bg: `sky.50`,
    border: `sky.500`,
    opacity: 1,
  },
  [ExecutionStepStatus.Completed]: {
    bg: `grey.50`,
    border: `grey.100`,
    opacity: 1,
  },
  [ExecutionStepStatus.Terminated]: {
    bg: `grey.25`,
    border: `grey.100`,
    opacity: 0.65,
  },
};

export function PreviewStepNode({ data }: WorkflowPreviewStepNodeProps) {
  const [grey100] = useColors([`grey.100`]);
  const handleStyle = {
    background: grey100,
    borderRadius: `6px`,
    width: `8px`,
    height: `8px`,
    border: `1px solid white`,
  };

  return (
    <Card
      variant="stepNode"
      w="250px"
      p={2}
      bg={statusColor[data.status].bg}
      borderColor={statusColor[data.status].border}
      opacity={statusColor[data.status].opacity}
      cursor="pointer"
    >
      <Handle type="target" position={Position.Top} style={handleStyle} />
      <HStack>
        <StepIcon stepType={data.type} />
        <Text textStyle="heading-xs">{data.name}</Text>
      </HStack>
      <Handle type="source" position={Position.Bottom} style={handleStyle} />
    </Card>
  );
}
