import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import {
  FeeDiscountApplicationFragment,
  FeeDiscountEntityType,
} from "@/graphql";
import {
  FeeDiscountEntity,
  hasCompanyFeeDiscountApplications,
  hasUserFeeDiscountApplications,
} from "@/modules/FeeDiscountsCard";

export default function useRemoveFeeDiscountWarning(
  entity: FeeDiscountEntity,
  feeDiscountApplications: FeeDiscountApplicationFragment[],
) {
  const [warningMessage, setWarningMessage] = useState<string | null>();
  const { t } = useTranslation();

  useEffect(() => {
    const { entityType } = entity;

    match(entityType)
      .with(FeeDiscountEntityType.User, () => {
        const hasCompanyFeeDiscountApplications = feeDiscountApplications.some(
          (fda) =>
            (fda.company?.feeDiscountApplications?.length &&
              fda.company?.feeDiscountApplications?.length > 0) ??
            false,
        );

        if (hasCompanyFeeDiscountApplications) {
          setWarningMessage(t(`remove_user_fee_discount_warning_message`));
        }
      })
      .with(FeeDiscountEntityType.Listing, () => {
        if (entityType !== FeeDiscountEntityType.Listing) return;

        const { company, listing, seller } = entity;

        const userFeeDiscountApplications = hasUserFeeDiscountApplications(
          listing,
          seller,
        );

        const companyFeeDiscountApplications =
          hasCompanyFeeDiscountApplications(company);

        const warningMessages = [
          {
            message: t(`remove_listing_fee_discount_warning_message`, {
              entityType: `user`,
            }),
            show:
              userFeeDiscountApplications ||
              (userFeeDiscountApplications && companyFeeDiscountApplications),
          },
          {
            message: t(`remove_listing_fee_discount_warning_message`, {
              entityType: `company`,
            }),
            show: companyFeeDiscountApplications,
          },
        ];

        const { message } = warningMessages.find((msg) => msg.show) || {};

        if (message) {
          setWarningMessage(message);
        }
      })
      .otherwise(() => null);
  }, [entity, feeDiscountApplications, t]);

  return warningMessage;
}
