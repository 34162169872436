import { Input } from "@/modules/Form";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AddEntityInput, EntityType } from "@/graphql";

function EntityTypeInput() {
  const { t } = useTranslation();
  const {
    formState: { errors },
    register,
    watch,
  } = useFormContext<Pick<AddEntityInput, "otherType" | "type">>();
  const entityType = watch(`type`);

  return (
    <Input.Generic
      error={errors.otherType}
      label={t(`provide_entity_type`)}
      {...register(`otherType`, {
        required:
          entityType === EntityType.Other
            ? t(`other_entity_type_required`)
            : undefined,
      })}
    />
  );
}

export default EntityTypeInput;
