import { getDefaultTableProviderVariables } from "@/constants";
import {
  CompanyTemplatesPageQuery,
  SortDirection,
  useCompanyTemplatesPageQuery,
  useWorkflowTemplatesPageWorkflowTemplatesQuery,
  WorkflowTemplateSortableField,
  WorkflowTemplatesPageWorkflowTemplatesQuery,
} from "@/graphql";
import { ReactNode, createContext, useCallback } from "react";
import { CombinedError } from "urql";

type CompanyTemplatesContext = {
  error?: CombinedError;
  fetching: boolean;
  data:
    | WorkflowTemplatesPageWorkflowTemplatesQuery["listExecutionWorkflowTemplates"]
    | null;
  company: CompanyTemplatesPageQuery["company"] | null;
  refetch: () => void;
};

const DEFAULT_CONTEXT: CompanyTemplatesContext = {
  error: undefined,
  fetching: false,
  data: null,
  company: null,
  refetch: () => {},
};
const Context = createContext<CompanyTemplatesContext>(DEFAULT_CONTEXT);
const { Provider } = Context;

interface CompanyTemplatesProviderProps {
  children: ReactNode;
  nameSlug: string;
}

export const DEFAULT_VARIABLES = getDefaultTableProviderVariables({
  first: 100,
  sortBy: {
    field: WorkflowTemplateSortableField.UpdatedAt,
    direction: SortDirection.Desc,
  },
  filterBy: {
    searchText: ``,
  },
});

function CompanyTemplatesProvider({
  children,
  nameSlug,
}: CompanyTemplatesProviderProps) {
  const [{ fetching, error, data: response }, executeQuery] =
    useWorkflowTemplatesPageWorkflowTemplatesQuery({
      variables: {
        ...DEFAULT_VARIABLES,
        filterBy: {
          ...DEFAULT_VARIABLES.filterBy,
          nameSlug,
        },
      },
      requestPolicy: `cache-and-network`,
    });

  const refetch = useCallback(() => {
    executeQuery({ requestPolicy: `network-only` });
  }, [executeQuery]);

  const [
    { fetching: companyFetching, error: companyError, data: companyData },
  ] = useCompanyTemplatesPageQuery({
    variables: {
      nameSlug,
    },
    requestPolicy: `cache-and-network`,
  });

  return (
    <Provider
      value={{
        fetching: fetching || companyFetching,
        error: error || companyError,
        refetch,
        data: response?.listExecutionWorkflowTemplates,
        company: companyData?.company,
      }}
    >
      {children}
    </Provider>
  );
}

export { Context, CompanyTemplatesProvider };
