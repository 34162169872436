import React from "react";
import { Box } from "@chakra-ui/react";
import { XCircle, Warning } from "@phosphor-icons/react";
import { useColors } from "@/modules/Theme";
import { match } from "ts-pattern";
import { ExecutionWorkflowCondition } from "@/graphql";

export default function WorkflowConditionStatusIcon({
  condition,
  background = `white`,
}: {
  condition: ExecutionWorkflowCondition;
  background?: string;
}) {
  const [red700, pending] = useColors([`red.700`, `pending`]);

  return match(condition)
    .with(ExecutionWorkflowCondition.IssuerApprovalDeclined, () => (
      <Box background={background} borderRadius="50%">
        <XCircle width="100%" height="100%" color={red700} weight="fill" />
      </Box>
    ))
    .with(ExecutionWorkflowCondition.Rofr, () => (
      <Box background={background} borderRadius="50%">
        <Warning width="100%" height="100%" color={pending} weight="fill" />
      </Box>
    ))
    .otherwise(() => null);
}
