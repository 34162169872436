import { DateTimeFormat, formatDate } from "@/modules/NumeralFormat";
import { Box, Text } from "@chakra-ui/react";
import { Cell } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";
import { CompanyApprovedBuyerType } from "@/graphql";
import { CompanyApprovedBuyersTableData } from "@/features/Company";

type CompanyApprovedBuyersTableCell = {
  cell: Cell<CompanyApprovedBuyersTableData, unknown>;
};

export function CompanyApprovedBuyerNameTableCell({
  cell,
}: CompanyApprovedBuyersTableCell) {
  const companyApprovedBuyer = cell.getContext().row.original;
  const name = match(companyApprovedBuyer.type)
    .with(CompanyApprovedBuyerType.User, () => companyApprovedBuyer?.user?.name)
    .with(
      CompanyApprovedBuyerType.Institution,
      () => companyApprovedBuyer?.institution?.legalName,
    )
    .exhaustive();

  return (
    <Box display="inline-block">
      <Text>{name}</Text>
    </Box>
  );
}

export function CompanyApprovedBuyerTypeTableCell({
  cell,
}: CompanyApprovedBuyersTableCell) {
  const { t } = useTranslation();
  const companyApprovedBuyer = cell.getContext().row.original;
  const type = match(companyApprovedBuyer.type)
    .with(CompanyApprovedBuyerType.User, () => t(`user`))
    .with(CompanyApprovedBuyerType.Institution, () => t(`institution`))
    .exhaustive();

  return (
    <Box display="inline-block">
      <Text>{type}</Text>
    </Box>
  );
}

export function CompanyApprovedBuyerEmailTableCell({
  cell,
}: CompanyApprovedBuyersTableCell) {
  const companyApprovedBuyer = cell.getContext().row.original;
  const email = match(companyApprovedBuyer.type)
    .with(
      CompanyApprovedBuyerType.User,
      () => companyApprovedBuyer?.user?.email,
    )
    .with(
      CompanyApprovedBuyerType.Institution,
      () => companyApprovedBuyer.institution?.registeredBy?.email,
    )
    .exhaustive();

  return (
    <Box display="inline-block">
      <Text>{email || `-`}</Text>
    </Box>
  );
}

export function CompanyApprovedBuyerAppliedByTableCell({
  cell,
}: CompanyApprovedBuyersTableCell) {
  const companyApprovedBuyer = cell.getContext().row.original;
  const appliedBy =
    companyApprovedBuyer?.companyApprovedBuyerAction?.actor?.name;

  return (
    <Box display="inline-block">
      <Text>{appliedBy}</Text>
    </Box>
  );
}

export function CompanyApprovedBuyerDateAppliedTableCell({
  cell,
}: CompanyApprovedBuyersTableCell) {
  const companyApprovedBuyer = cell.getContext().row.original;
  const dateApplied =
    companyApprovedBuyer?.companyApprovedBuyerAction?.insertedAt;

  return (
    <Box display="inline-block">
      <Text>
        {formatDate(dateApplied, DateTimeFormat.fullDateWithMonthShort)}
      </Text>
    </Box>
  );
}
