import { HStack, Text, Tag, TagLabel, BoxProps } from "@chakra-ui/react";
import { taskUserLabel } from "./utils";
import UserTypeAvatar from "./UserTypeAvatar";
import { UserType } from "./types";

interface UserTypeTagProps extends BoxProps {
  readonly assignee: UserType;
}

function UserTypeTag({ assignee, ...boxProps }: UserTypeTagProps) {
  return (
    <Tag borderRadius="full" size="md" variant="grey" {...boxProps}>
      <HStack spacing={1}>
        <UserTypeAvatar assignee={assignee} />
        <TagLabel>
          <Text textStyle="colfax-12-regular">{taskUserLabel(assignee)}</Text>
        </TagLabel>
      </HStack>
    </Tag>
  );
}

export default UserTypeTag;
