import { AddEntityInput, EntityType } from "@/graphql";
import { Input } from "@/modules/Form";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTranslatedTransactingEntityNames } from "@/features/Transactions";

function EntityTypeSelect() {
  const { t } = useTranslation();

  const { getFormattedEntityName } = useTranslatedTransactingEntityNames();

  const {
    formState: { errors },
    register,
  } = useFormContext<Pick<AddEntityInput, "type">>();

  return (
    <Input.Select
      error={errors.type}
      label={t(`entity_type`)}
      options={Object.values(EntityType).map((type) => ({
        label: getFormattedEntityName(type),
        value: type,
      }))}
      placeholder={t(`select_entity`)}
      {...register(`type`, { required: t(`entity_type_required`) })}
    />
  );
}

export default EntityTypeSelect;
