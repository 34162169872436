import i18next from "i18next";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import {
  FeeDiscount,
  FeeDiscountApplicationFragment,
  FeeDiscountEntityType,
} from "@/graphql";
import {
  FeeDiscountEntity,
  useRemoveFeeDiscountWarning,
  useUnapplyFeeDiscount,
} from "@/modules/FeeDiscountsCard";
import { Input, useYupValidationResolver } from "@/modules/Form";
import { ConfirmModal } from "@/modules/Modal";
import { useCustomToast } from "@/modules/Toast";
import { Text, VStack } from "@chakra-ui/react";

const MODAL_CONFIG = {
  [FeeDiscountEntityType.Transaction]: {
    note: i18next.t(`remove_fee_discount_warning_message`),
  },
  [FeeDiscountEntityType.Listing]: {
    note: i18next.t(`remove_fee_discount_warning_message_listing`),
  },
  [FeeDiscountEntityType.Company]: {
    note: i18next.t(`remove_fee_discount_warning_message_listing`),
  },
  [FeeDiscountEntityType.User]: {
    note: i18next.t(`remove_fee_discount_warning_message_listing`),
  },
};

const ValidationSchema = yup.object().shape({
  feeDiscountApplicationId: yup
    .string()
    .required(i18next.t(`fee_discount_select_invalid_option`)),
});

type RemoveFeeDiscountInputs = yup.InferType<typeof ValidationSchema>;

interface RemoveFeeDiscountModalBodyProps {
  entity: FeeDiscountEntity;
  feeDiscountApplications: FeeDiscountApplicationFragment[];
  note?: string;
}

function RemoveFeeDiscountModalBody({
  entity,
  feeDiscountApplications,
  note,
}: RemoveFeeDiscountModalBodyProps) {
  const {
    formState: { errors },
    register,
  } = useFormContext<RemoveFeeDiscountInputs>();

  const { t } = useTranslation();

  const warningMessage = useRemoveFeeDiscountWarning(
    entity,
    feeDiscountApplications,
  );

  if (feeDiscountApplications.some(({ feeDiscount }) => feeDiscount === null)) {
    return null;
  }

  const { entityType } = entity;

  const unapplyOptions = feeDiscountApplications.map(({ id, feeDiscount }) => ({
    label: (feeDiscount as FeeDiscount).name,
    value: id,
  }));

  const [firstFeeDiscountApplication] = feeDiscountApplications;

  const noteColorProp =
    entityType === FeeDiscountEntityType.Transaction
      ? { color: `archived` }
      : {};

  return (
    <VStack gap={4} alignItems="flex-start" w="full">
      {!!warningMessage && <Text color="archived">{warningMessage}</Text>}
      {note && <Text {...noteColorProp}>{note}</Text>}

      {feeDiscountApplications.length > 1 && (
        <Input.Select
          error={errors.feeDiscountApplicationId}
          label={t(`select_fee_discount`)}
          options={unapplyOptions}
          placeholder={t(`select`)}
          {...register(`feeDiscountApplicationId`)}
        />
      )}
      {feeDiscountApplications.length === 1 && (
        <input
          type="hidden"
          value={firstFeeDiscountApplication.id || ``}
          {...register(`feeDiscountApplicationId`)}
        />
      )}
    </VStack>
  );
}

interface RemoveFeeDiscountModalProps {
  readonly entity: FeeDiscountEntity;
  readonly feeDiscountApplications: FeeDiscountApplicationFragment[];
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly onSuccess?: () => void;
}

export function RemoveFeeDiscountModal({
  entity,
  feeDiscountApplications,
  isOpen,
  onClose,
  onSuccess,
}: RemoveFeeDiscountModalProps) {
  const { entityType } = entity;
  const { t } = useTranslation();
  const resolver = useYupValidationResolver(ValidationSchema);

  const methods = useForm<RemoveFeeDiscountInputs>({ resolver });

  const {
    formState: { isSubmitting },
    handleSubmit,
    setError,
  } = methods;

  const { fetching, unapplyFeeDiscount } = useUnapplyFeeDiscount();
  const { successToast } = useCustomToast();

  const onConfirm = (data: RemoveFeeDiscountInputs) =>
    unapplyFeeDiscount({
      data,
      onSuccess: () => {
        successToast(t(`fee_discount_removed`));
        onSuccess?.();
        onClose();
      },
      onFailure: (message) => setError(`feeDiscountApplicationId`, { message }),
    });

  return (
    <ConfirmModal
      title={t(`remove_fee_discount`)}
      body={
        <FormProvider {...methods}>
          <RemoveFeeDiscountModalBody
            entity={entity}
            feeDiscountApplications={feeDiscountApplications}
            {...MODAL_CONFIG[entityType]}
          />
        </FormProvider>
      }
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleSubmit(onConfirm)}
      disableConfirm={isSubmitting || fetching}
    />
  );
}
