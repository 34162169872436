import {
  ForwardedRef,
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { Country } from "@/graphql";
import { Combobox, InputProps } from "@/modules/Form";
import { useColors } from "@/modules/Theme";
import { MagnifyingGlass } from "@phosphor-icons/react";
import { RefCallBack } from "react-hook-form";
import { getCountryList } from "@/modules/Country";

interface CountryComboboxProps extends InputProps {
  countries: Country[];
  value: string | undefined;
  setValue: (value: string) => void;
  isLoading?: boolean;
}

const CountryCombobox = forwardRef<HTMLDivElement, CountryComboboxProps>(
  (
    {
      countries: defaultCountries,
      value,
      setValue,
      isLoading,
      onBlur: _onBlur,
      ...restProps
    }: CountryComboboxProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const { t } = useTranslation();
    const [search, setSearch] = useState(``);
    const [grey200] = useColors([`grey.200`]);
    const countryList = getCountryList();
    const countries = useMemo(
      () =>
        defaultCountries.filter(({ name }) => {
          const countryName = countryList.getName(name)?.toLowerCase();
          return search
            ? !!countryName && countryName.includes(search.toLowerCase())
            : !!countryName;
        }),
      [search, countryList, defaultCountries],
    );
    const selectedCountry = useMemo(() => {
      const country = countries.find(({ id }) => id === value);
      return country ? countryList.getName(country.name) : null;
    }, [countryList, countries, value]);

    const handleSetValue = useCallback(
      (country: Country) => {
        setSearch(countryList.getName(country.name) ?? search);
        setValue?.(country.id);
      },
      [countryList, search, setValue],
    );

    const handleOnBlur = () => {
      const hasSelectedCountry = countries.some(({ id }) => id === value);
      if (hasSelectedCountry) return;

      if (value && !search) {
        const code = countryList.getCode(value);
        const country = countries.find(({ name }) => name === code);
        if (country) {
          setValue(country.id);
          return;
        }
        setValue(``);
      }

      if (search) {
        const code = countryList.getCode(search);
        const country = countries.find(({ name }) => name === code);
        if (country) setValue(country.id);
      }

      setSearch(``);
    };

    useEffect(() => {
      setSearch(selectedCountry as string);
    }, [selectedCountry]);

    return (
      <Combobox
        label={t(`country`)}
        placeholder={t(`search_country`)}
        getItemKey={(country) => country.id}
        getItemLabel={(country) =>
          countryList.getName(country.name) ?? country.name
        }
        getItems={() => countries}
        value={search}
        setValue={handleSetValue}
        leftElement={<MagnifyingGlass size={20} color={grey200} />}
        isLoading={isLoading}
        getSearchValue={(text) => setSearch(text)}
        onBlur={() => Promise.resolve(handleOnBlur())}
        {...restProps}
        ref={ref as RefCallBack & (Ref<HTMLDivElement> | undefined)}
      />
    );
  },
);

export default CountryCombobox;
