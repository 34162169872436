export enum IndicatorColor {
  ACTIVE = `active`,
  ARCHIVED = `archived`,
  DISABLED = `disabled`,
  DISABLED_DARK = `disabled_dark`,
  PENDING = `pending`,
  LISTING_TYPE = `listing_type`,
  TRANSACTION_PENDING = `transaction_pending`,
  TRANSACTION_CLOSED = `transaction_closed`,
  TRANSACTION_DECLINED = `transaction_declined`,
}
