import { useRouter } from "next/router";
import { useCallback } from "react";

const useAppendToUrl = <
  T extends Record<string, string> = Record<string, string>,
>() => {
  const { replace } = useRouter();

  const append = useCallback(
    (fields: T) => {
      replace(
        {
          query: fields,
        },
        undefined,
        { shallow: true },
      );
    },
    [replace],
  );

  return append;
};

export default useAppendToUrl;
