import { SVGProps } from "react";

function AnvilIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 145 109"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M45.1819 36.0123C45.3146 36.0123 45.4418 35.9596 45.5356 35.8658C45.6294 35.772 45.6821 35.6448 45.6821 35.5122V0.500171C45.6821 0.367518 45.6294 0.240297 45.5356 0.146497C45.4418 0.0526965 45.3146 0 45.1819 0H1.16686C1.0342 0 0.906984 0.0526965 0.813184 0.146497C0.719383 0.240297 0.666687 0.367518 0.666687 0.500171V10.0034C0.666687 16.9014 3.4069 23.5169 8.2845 28.3945C13.1622 33.2721 19.7776 36.0123 26.6756 36.0123H45.1819Z"
        fill="#1D1F21"
      />
      <path
        d="M144.215 0H64.1883C64.0556 0 63.9284 0.0526965 63.8346 0.146497C63.7408 0.240297 63.6881 0.367518 63.6881 0.500172V54.0185C63.6881 58.7941 61.791 63.374 58.4142 66.7508C55.0374 70.1274 50.4574 72.0247 45.6819 72.0247H19.1728C19.0402 72.0247 18.913 72.0777 18.8192 72.1709C18.7253 72.2648 18.6727 72.3923 18.6727 72.5251V107.537C18.6727 107.669 18.7253 107.797 18.8192 107.891C18.913 107.984 19.0402 108.037 19.1728 108.037H126.21C126.342 108.037 126.469 107.984 126.563 107.891C126.657 107.797 126.709 107.669 126.709 107.537V72.5251C126.709 72.3923 126.657 72.2648 126.563 72.1709C126.469 72.0777 126.342 72.0247 126.21 72.0247H117.707C112.931 72.0247 108.351 70.1274 104.974 66.7508C101.598 63.374 99.7004 58.7941 99.7004 54.0185C99.7004 49.243 101.598 44.663 104.974 41.2862C108.351 37.9094 112.931 36.0123 117.707 36.0123H144.215C144.348 36.0123 144.476 35.9597 144.569 35.8658C144.663 35.772 144.716 35.6448 144.716 35.5122V0.500172C144.716 0.367518 144.663 0.240297 144.569 0.146497C144.476 0.0526965 144.348 0 144.215 0Z"
        fill="#1D1F21"
      />
    </svg>
  );
}
export default AnvilIcon;
