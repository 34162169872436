import { useTranslation } from "react-i18next";

import { CompanyManagedMarketPageCompanyEligibilityCriterionFragment } from "@/graphql";
import { CardTable } from "@/modules/CardTable";
import { Markdown } from "@/modules/Markdown";
import { formatToLocalTimezone } from "@/modules/NumeralFormat";
import { Card, CardBody, CardHeader, Text, VStack } from "@chakra-ui/react";

import styles from "./seller-eligibility-markdown-style.module.css";

interface CompanyManagedMarketSellerEligibilityContentEligibilityCriteriaProps {
  readonly eligibilityCriteria: string;
}

function CompanyManagedMarketSellerEligibilityContentEligibilityCriteria({
  eligibilityCriteria,
}: CompanyManagedMarketSellerEligibilityContentEligibilityCriteriaProps) {
  const { t } = useTranslation();

  return (
    <Card variant="table">
      <CardHeader>{t(`eligibility_criteria`)}</CardHeader>
      <CardBody>
        <div className={styles[`seller-eligibility-markdown`]}>
          <Markdown markdown={eligibilityCriteria} />
        </div>
      </CardBody>
    </Card>
  );
}

interface CompanyManagedMarketSellerEligibilityContentEligibilityCriteriaAppliedByProps {
  readonly insertedAt: string;
  readonly name: string;
}

function CompanyManagedMarketSellerEligibilityContentEligibilityCriteriaAppliedBy({
  insertedAt,
  name,
}: CompanyManagedMarketSellerEligibilityContentEligibilityCriteriaAppliedByProps) {
  const { t } = useTranslation();

  return (
    <Card variant="table">
      <CardHeader>{t(`applied_by`)}:</CardHeader>
      <CardBody>
        <VStack alignItems="flex-start">
          <Text color="grey.600" textStyle="colfax-14-medium">
            {name}
          </Text>
          <Text>{formatToLocalTimezone(insertedAt)}</Text>
        </VStack>
      </CardBody>
    </Card>
  );
}

interface CompanyManagedMarketSellerEligibilityContentEligibilityCriteriaUpdatedByProps {
  readonly updatedAt: string;
  readonly name: string;
}

function CompanyManagedMarketSellerEligibilityContentEligibilityCriteriaUpdatedBy({
  updatedAt,
  name,
}: CompanyManagedMarketSellerEligibilityContentEligibilityCriteriaUpdatedByProps) {
  const { t } = useTranslation();

  return (
    <Card variant="table">
      <CardHeader>{t(`last_edited_by`)}:</CardHeader>
      <CardBody>
        <VStack alignItems="flex-start">
          <Text color="grey.600" textStyle="colfax-14-medium">
            {name}
          </Text>
          <Text>{formatToLocalTimezone(updatedAt)}</Text>
        </VStack>
      </CardBody>
    </Card>
  );
}

interface CompanyManagedMarketSellerEligibilityContentProps {
  readonly companyEligibilityCriterion: CompanyManagedMarketPageCompanyEligibilityCriterionFragment;
}

export default function CompanyManagedMarketSellerEligibilityContent({
  companyEligibilityCriterion,
}: CompanyManagedMarketSellerEligibilityContentProps) {
  const { t } = useTranslation();

  const { createdBy, insertedAt, text, updatedAt, updatedBy } =
    companyEligibilityCriterion;

  const columns = updatedBy ? 3 : 2;

  return (
    <VStack gap={4} alignItems="flex-start" w="full">
      <Text>{t(`seller_eligibility_main_panel_heading`)}</Text>
      <CardTable w="full" columns={columns}>
        <CompanyManagedMarketSellerEligibilityContentEligibilityCriteria
          eligibilityCriteria={text}
        />
        <CompanyManagedMarketSellerEligibilityContentEligibilityCriteriaAppliedBy
          insertedAt={insertedAt}
          name={createdBy.name}
        />
        {updatedBy && (
          <CompanyManagedMarketSellerEligibilityContentEligibilityCriteriaUpdatedBy
            updatedAt={updatedAt}
            name={updatedBy.name}
          />
        )}
      </CardTable>
    </VStack>
  );
}
