import {
  ExecutionStepType,
  ExecutionTaskActorType,
  ExecutionTaskStatus,
  ExecutionTaskType,
  TransactionPageV2ExecutionTaskFragment,
} from "@/graphql";
import {
  Box,
  HStack,
  Text,
  Card,
  CardBody,
  CardHeader,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { StepIconTask, stepStatusIcon } from "@/modules/Step";
import { DateTimeFormat, formatDate } from "@/modules/NumeralFormat";
import { DotsThreeOutlineVertical } from "@phosphor-icons/react";
import { match } from "ts-pattern";
import { useState } from "react";
import UserTypeTag from "./UserTypeTag";
import { CompleteTaskModal, CompleteTaskTypes } from "./CompleteTaskModal";
import { taskUserLabel } from "./utils";

function TaskCard({
  stepName,
  task,
  stepType,
  preview,
}: {
  readonly stepName: string;
  readonly task: TransactionPageV2ExecutionTaskFragment;
  readonly stepType: ExecutionStepType;
  readonly preview: boolean;
}) {
  const { t } = useTranslation();

  const hasAction =
    task.actorType === ExecutionTaskActorType.Hiive &&
    task.status === ExecutionTaskStatus.InProgress;

  const bgColor =
    task.status === ExecutionTaskStatus.Completed ? `grey.15` : `white`;

  const {
    isOpen: isCompleteTaskModalOpen,
    onClose: onCloseCompleteTaskModal,
    onOpen: onOpenCompleteTaskModal,
  } = useDisclosure();

  const [modalType, setModalType] = useState<CompleteTaskTypes>(`MANUAL`);

  const handleMenuClick = (type: CompleteTaskTypes) => {
    setModalType(type);
    onOpenCompleteTaskModal();
  };

  return (
    <>
      <Card variant="outline" borderColor="grey.100" bg={bgColor} w="full">
        <CardHeader p={4} px={4} py={2}>
          <HStack justifyContent="space-between">
            <HStack>
              <Box>
                {preview ? (
                  <StepIconTask stepType={stepType} />
                ) : (
                  stepStatusIcon[task.status]
                )}
              </Box>
              <Text textStyle="heading-md">
                {match(task.type)
                  .with(
                    ExecutionTaskType.AnvilFillDocument,
                    () =>
                      `${taskUserLabel(task.actorType)} ${t(`fill_document`)}`,
                  )
                  .with(
                    ExecutionTaskType.AnvilSignDocument,
                    () =>
                      `${taskUserLabel(task.actorType)} ${t(`sign_document`)}`,
                  )
                  .otherwise(() => stepName)}
              </Text>
            </HStack>
            {hasAction && (
              <Menu>
                <MenuButton width="24px" height="24px" p={0} m={0}>
                  <DotsThreeOutlineVertical
                    weight="fill"
                    size={14}
                    width="100%"
                  />
                </MenuButton>
                <MenuList>
                  {match(task.type)
                    .with(ExecutionTaskType.ManuallyConfirm, () => (
                      <MenuItem
                        onClick={() => {
                          handleMenuClick(`MANUAL`);
                        }}
                      >
                        <Text>{t(`complete_task`)}</Text>
                      </MenuItem>
                    ))
                    .with(ExecutionTaskType.ConfirmIssuerApproval, () => (
                      <>
                        <MenuItem
                          onClick={() => {
                            handleMenuClick(`ISSUER_APPROVED`);
                          }}
                        >
                          <Text>{t(`issuer_approved`)}</Text>
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleMenuClick(`ISSUER_ROFR`);
                          }}
                        >
                          <Text>{t(`issuer_rofr`)}</Text>
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleMenuClick(`ISSUER_DECLINED`);
                          }}
                          color="archived"
                        >
                          <Text>{t(`issuer_declined`)}</Text>
                        </MenuItem>
                      </>
                    ))
                    .otherwise(() => (
                      <Text>{t(`unknown_step_type`)}</Text>
                    ))}
                </MenuList>
              </Menu>
            )}
          </HStack>
        </CardHeader>
        <CardBody p={4} px={4} py={2}>
          <HStack justifyContent="space-between">
            <UserTypeTag assignee={task.actorType} />
            <Text>
              {formatDate(task.completedAt, DateTimeFormat.shortDateWithTime)}
            </Text>
          </HStack>
        </CardBody>
      </Card>
      <CompleteTaskModal
        task={task}
        stepName={stepName}
        isOpen={isCompleteTaskModalOpen}
        onClose={onCloseCompleteTaskModal}
        type={modalType}
      />
    </>
  );
}

export default TaskCard;
