import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { FeeDiscountDetailsContext } from "@/features/FeeDiscount";
import { CardTable } from "@/modules/CardTable";
import { useFeeDiscountManagement } from "@/modules/FeeDiscountsCard";
import { useFeeDiscountArchiveDiscountEnabledFlag } from "@/modules/LaunchDarkly";
import { Status } from "@/modules/Status";
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FeeDiscountType } from "@/graphql";
import { formatPercentageDecimal } from "@/modules/NumeralFormat";

import FeeDiscountsTableRowActions from "./FeeDiscountsTableRowActions";

export default function FeeDiscountPageOverview() {
  const { feeDiscount } = useContext(FeeDiscountDetailsContext);
  const { t } = useTranslation();
  const { canManageFeeDiscount } = useFeeDiscountManagement();

  const feeDiscountArchiveDiscounEnabled =
    useFeeDiscountArchiveDiscountEnabledFlag();

  const {
    archivedAt,
    name,
    description,
    formatedFeeDiscountType,
    value,
    type,
    percentageValue,
  } = feeDiscount || {};

  const statusText = archivedAt ? t(`archived`) : t(`active`);
  const statusIndicatorColor = archivedAt ? `archived` : `active`;
  const displayValue =
    type === FeeDiscountType.FlatFee
      ? value
      : formatPercentageDecimal(percentageValue);
  return (
    <Card w="full">
      <CardHeader as={HStack} gap={4} alignItems="center">
        <Text textStyle="colfax-22-medium">{name}</Text>
        <Box flexGrow={1} />
        {feeDiscountArchiveDiscounEnabled && (
          <HStack spacing={4}>
            <HStack>
              <Status.Indicator
                text={statusText}
                fontWeight={500}
                indicatorProps={{ bg: statusIndicatorColor }}
              />
              {canManageFeeDiscount && !archivedAt && (
                <FeeDiscountsTableRowActions showActionButtonTitle />
              )}
            </HStack>
          </HStack>
        )}
      </CardHeader>
      <CardBody>
        <VStack alignItems="flex-start" gap={8}>
          {description && <Text>{description}</Text>}
          <CardTable w="full" columns={2}>
            <Card variant="table">
              <CardHeader>{t(`type`)}</CardHeader>
              <CardBody>
                <Text>{formatedFeeDiscountType}</Text>
              </CardBody>
            </Card>
            <Card variant="table">
              <CardHeader>{t(`discount_value`)}</CardHeader>
              <CardBody>
                <Text>{displayValue}</Text>
              </CardBody>
            </Card>
          </CardTable>
        </VStack>
      </CardBody>
    </Card>
  );
}
