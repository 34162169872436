import { useRouter } from "next/router";
import { useCallback, useId } from "react";
import { FieldValues, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  FeeDiscountFormInputs,
  mapToFeeDiscount,
} from "@/features/FeeDiscountForm";
import { useCustomToast } from "@/modules/Toast";

import { useCreateFeeDiscount } from "./useCreateFeeDiscount";

export const useSubmitCreateFeeDiscount = () => {
  const router = useRouter();
  const { setError } = useFormContext<FeeDiscountFormInputs>();
  const { createFeeDiscount, fetching } = useCreateFeeDiscount({ setError });
  const { errorToast, successToast } = useCustomToast();
  const { t } = useTranslation();
  const toastId = useId();

  const submitCreateFeeDiscount = useCallback(
    async (data: FieldValues) => {
      const { id } =
        (await createFeeDiscount(
          mapToFeeDiscount(data as FeeDiscountFormInputs),
        )) || {};

      if (id) {
        router.back();

        successToast(t(`fee_discount_create_success_title`), {
          id: toastId,
        });
      } else {
        errorToast(null, { id: toastId });
      }
      return null;
    },
    [createFeeDiscount, errorToast, router, successToast, t, toastId],
  );

  return { submitCreateFeeDiscount, fetching };
};
