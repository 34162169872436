import TransactionSignerCard, {
  TransactionSignerCardProps,
} from "./TransactionSignerCard";

function RepresentedUserTransactionSignerCard({
  signer,
}: TransactionSignerCardProps) {
  return (
    <TransactionSignerCard.Card justifyContent="flex-start">
      <TransactionSignerCard.Pill signer={signer} isRepresentedUser />
      <TransactionSignerCard.EmailDetails
        email={signer.email}
        name={signer.name}
      />
    </TransactionSignerCard.Card>
  );
}

export default RepresentedUserTransactionSignerCard;
