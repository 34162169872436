import { useUpdateCompanyApprovedBuyersMutation } from "@/graphql";
import { CompanyManagedMarketContext } from "@/features/Company";

import { ConfirmModal } from "@/modules/Modal";
import { useCustomToast } from "@/modules/Toast";
import { useContext } from "react";
import { Trans, useTranslation } from "react-i18next";

interface UpdateCompanyApprovedBuyersModalProps {
  readonly isOpen: boolean;
  readonly onClose: () => void;
}

export function UpdateCompanyApprovedBuyersModal({
  isOpen,
  onClose,
}: UpdateCompanyApprovedBuyersModalProps) {
  const { t } = useTranslation();
  const { successToast, errorToast } = useCustomToast();
  const { managedMarketCompany } = useContext(CompanyManagedMarketContext);
  const [, updateCompanyApprovedBuyers] =
    useUpdateCompanyApprovedBuyersMutation();

  if (!managedMarketCompany) return null;

  const requiresApprovedBuyers = managedMarketCompany?.requiresApprovedBuyers;

  const onConfirm = async () => {
    const { data: response } = await updateCompanyApprovedBuyers({
      companyId: managedMarketCompany?.id,
      requiresApprovedBuyers: !requiresApprovedBuyers,
    });
    if (response?.updateCompanyRequiresApprovedBuyer.company) {
      successToast(
        requiresApprovedBuyers
          ? t(`approved_buyers_deactivated`)
          : t(`approved_buyers_activated`),
      );
    } else {
      errorToast(
        requiresApprovedBuyers
          ? t(`approved_buyers_deactivated_error`)
          : t(`approved_buyers_activated_error`),
      );
    }
    onClose();
  };

  return (
    <ConfirmModal
      title={
        requiresApprovedBuyers
          ? t(`deactivate_approved_buyers`)
          : t(`activate_approved_buyers`)
      }
      body={
        <Trans
          components={{ bold: <strong /> }}
          values={{
            companyName: managedMarketCompany?.name,
          }}
          i18nKey={
            requiresApprovedBuyers
              ? `deactivate_approved_buyers_confirmation`
              : `activate_approved_buyers_confirmation`
          }
        />
      }
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      confirmText={requiresApprovedBuyers ? t(`deactivate`) : t(`activate`)}
    />
  );
}
