import { FeeDiscountFormInputs } from "@/features/FeeDiscountForm";
import { CreateFeeDiscountInput, FeeDiscountType } from "@/graphql";

export const mapToFeeDiscount = ({
  name,
  description,
  type,
  value,
  percentageValue,
}: FeeDiscountFormInputs): CreateFeeDiscountInput => ({
  description: description?.trim(),
  type: type as FeeDiscountType,
  name: name?.trim(),
  value:
    type === FeeDiscountType.FlatFee
      ? value && Math.round(value * 100)
      : undefined,
  percentageValue:
    type !== FeeDiscountType.FlatFee ? percentageValue : undefined,
});
