import { SimpleGrid, SimpleGridProps } from "@chakra-ui/react";
import { ReactNode } from "react";

type CardTableProps = {
  children: ReactNode;
  columns: number;
} & SimpleGridProps;

export default function CardTable({
  children,
  columns,
  ...simpleGridProps
}: CardTableProps) {
  const gridTemplateColumns = `repeat(${columns}, 1fr)`;

  return (
    <SimpleGrid
      gridTemplateColumns={gridTemplateColumns}
      sx={{
        ".chakra-card:first-of-type": {
          borderLeftRadius: `md`,
        },
        ".chakra-card:last-child": {
          borderRightRadius: `md`,
        },
        ".chakra-card:not(div:first-of-type)": {
          borderLeft: `none`,
        },
        ".chakra-card:not(div:last-child)": {
          borderRightColor: `grey.50`,
        },

        ".chakra-card:first-of-type > .chakra-card__header:first-child": {
          borderTopLeftRadius: `md`,
        },
        ".chakra-card:last-of-type > .chakra-card__header": {
          borderTopRightRadius: `md`,
        },

        ".chakra-card > * > .chakra-card__header:first-child": {
          borderTopLeftRadius: `md`,
        },
        ".chakra-card > * > .chakra-card__header:last-child": {
          borderTopRightRadius: `md`,
        },

        ".chakra-card > .chakra-card__header:last-child": {
          borderBottomRadius: `md`,
        },

        ".chakra-card__footer": {
          backgroundColor: `grey.50`,
          padding: `0.5rem 1rem`,
        },
      }}
      {...simpleGridProps}
    >
      {children}
    </SimpleGrid>
  );
}
