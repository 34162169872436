import {
  FeeDiscountApplicationState,
  FeeDiscountEntityType,
  useFeeDiscountsCardActionsApplyFeeDiscountMutation,
} from "@/graphql";
import { handleGqlError } from "@/modules/Error";
import { FeeDiscountEntity } from "@/modules/FeeDiscountsCard";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

type ApplyFeeDiscountPayload = {
  expireAt?: string | null | undefined;
  entityId: string;
  entityType: FeeDiscountEntityType;
  feeDiscountId: string;
  state?: FeeDiscountApplicationState;
};

type FeeDiscountCardActionsApplyFeeDiscountInputs = {
  entityCompanyId?: string;
  feeDiscountId: string;
  expireAt?: Date | null;
  entity: FeeDiscountEntity;
};

type ApplyFeeDiscountProps = {
  data: FeeDiscountCardActionsApplyFeeDiscountInputs;
  onSuccess: () => void;
  onFailure: (message?: string) => void;
};

function getInput({
  entity,
  entityCompanyId,
  expireAt,
  feeDiscountId,
}: FeeDiscountCardActionsApplyFeeDiscountInputs): ApplyFeeDiscountPayload {
  const { entityId, entityType } = entity;

  const hasPendingModification =
    entityType === FeeDiscountEntityType.Transaction &&
    entity.transaction?.pendingModification;

  return {
    entityId,
    entityType,
    feeDiscountId,
    ...(entityCompanyId ? { entityCompanyId } : {}),
    ...(expireAt ? { expireAt: expireAt.toJSON() } : {}),
    ...(hasPendingModification
      ? { state: FeeDiscountApplicationState.Pending }
      : {}),
  };
}

export default function useApplyFeeDiscount() {
  const { t } = useTranslation();
  const [{ fetching }, applyFeeDiscountMutation] =
    useFeeDiscountsCardActionsApplyFeeDiscountMutation();

  const applyFeeDiscount = useCallback(
    async ({ data, onFailure, onSuccess }: ApplyFeeDiscountProps) => {
      if (!data.feeDiscountId) {
        onFailure(t(`select_invalid_option`));
        return;
      }

      const { data: responseData, error } = await applyFeeDiscountMutation({
        input: getInput(data),
      });

      if (error) {
        onFailure(handleGqlError(error));
      }
      if (responseData?.applyFeeDiscount?.feeDiscountApplication) {
        onSuccess();
      }
    },
    [applyFeeDiscountMutation, t],
  );

  return { fetching, applyFeeDiscount };
}
