import {
  Card,
  CardHeader,
  HStack,
  CardBody,
  VStack,
  Box,
} from "@chakra-ui/react";
import { BankAccountCardSkeleton } from "@/features/Entities";
import { Skeleton } from "@/modules/Skeleton";

type EntityCardSkeletonProps = {
  count?: number;
  bankAccoutCount?: number;
};

export default function EntityCardSkeleton({
  count = 1,
  bankAccoutCount = 4,
}: EntityCardSkeletonProps) {
  return [...Array(count).keys()].map((index) => (
    <Card key={index} w="full">
      <CardHeader as={HStack} justifyContent="space-between">
        <HStack>
          <Skeleton h={5} w={100} />
          <Box as={HStack}>
            <Skeleton h={2.5} w={100} />
          </Box>
        </HStack>
        <HStack>
          <Skeleton h={2.5} w={50} />
        </HStack>
      </CardHeader>
      <CardBody>
        <VStack w="full" spacing={4}>
          {[...Array(bankAccoutCount).keys()].map((index) => (
            <BankAccountCardSkeleton key={index} />
          ))}
        </VStack>
      </CardBody>
    </Card>
  ));
}
