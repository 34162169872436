import { ExecutionStepType } from "@/graphql";
import { useColors } from "@/modules/Theme";
import { Box } from "@chakra-ui/react";
import { CheckFat, Diamond, Question } from "@phosphor-icons/react";
import { match } from "ts-pattern";
import { AnvilIcon } from "@/modules/Step";

function StepIcon({ stepType }: { readonly stepType: ExecutionStepType }) {
  const [purple1000] = useColors([`purple.1000`]);
  return match(stepType)
    .with(ExecutionStepType.Manual, () => (
      <Box background="grey.50" padding={1} borderRadius={6}>
        <CheckFat size={16} />
      </Box>
    ))
    .with(ExecutionStepType.IssuerApproval, () => (
      <Box background="grey.50" padding={1} borderRadius={6}>
        <CheckFat size={16} />
      </Box>
    ))
    .with(ExecutionStepType.Anvil, () => (
      <Box
        background="grey.50"
        width={6}
        height={6}
        borderRadius={6}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <AnvilIcon width="14px" />
      </Box>
    ))
    .with(ExecutionStepType.AnvilV2, () => (
      <Box
        background="grey.50"
        width={6}
        height={6}
        borderRadius={6}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <AnvilIcon width="14px" />
      </Box>
    ))
    .with(ExecutionStepType.Milestone, () => (
      <Box
        padding={1}
        borderRadius={6}
        border="1px solid"
        borderColor="grey.50"
      >
        <Diamond color={purple1000} size={16} />
      </Box>
    ))
    .otherwise(() => (
      <Box background="red" padding={2} borderRadius={6}>
        <Question />
      </Box>
    ));
}

export default StepIcon;
