import { ExecutionStepStatus } from "@/graphql";
import { Diamond, XCircle } from "@phosphor-icons/react";
import { match } from "ts-pattern";
import { useColors } from "@/modules/Theme";
import { Box } from "@chakra-ui/react";

export default function MilestoneStatusIcon({
  status,
}: {
  status: ExecutionStepStatus;
}) {
  const [red700] = useColors([`red.700`]);

  return match(status)
    .with(ExecutionStepStatus.Terminated, () => (
      <Box background="white" borderRadius="50%">
        <XCircle width="100%" height="100%" color={red700} weight="fill" />
      </Box>
    ))
    .with(ExecutionStepStatus.Completed, () => (
      <Diamond width="100%" height="100%" weight="fill" />
    ))
    .with(ExecutionStepStatus.Pending, () => (
      <>
        <Diamond width="100%" height="100%" weight="fill" fill="white" />
        <Diamond
          width="100%"
          height="100%"
          weight="bold"
          style={{ position: `absolute`, top: 0, left: 0 }}
        />
      </>
    ))
    .otherwise(() => (
      // in progress
      <>
        <Diamond
          width="100%"
          height="100%"
          weight="fill"
          fill="white"
          style={{ position: `absolute`, top: 0, left: 0 }}
        />
        <Diamond
          width="100%"
          height="100%"
          weight="bold"
          style={{ position: `absolute`, top: 0, left: 0 }}
        />
        <Diamond
          width="45%"
          height="45%"
          weight="fill"
          style={{
            position: `absolute`,
            top: `50%`,
            left: `50%`,
            transform: `auto`,
            translate: `-50% -50%`,
          }}
        />
      </>
    ));
}
