import { CompanyStatus } from "@/graphql";
import { Checkbox } from "@/modules/Form";
import { SimpleTableQueryVariables } from "@/modules/SimpleTable";
import { Status } from "@/modules/Status";
import { MergedColorsType } from "@/modules/Theme";
import { Paths } from "@/types";
import {
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  SimpleGrid,
  GridItem,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { CaretDown } from "@phosphor-icons/react";
import keys from "lodash/keys";
import { useCallback, useEffect, useMemo } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

type CompanyStatusCheckboxOptionKey = "listed" | "delisted" | "draft";
type Inputs = Record<CompanyStatusCheckboxOptionKey, boolean>;

type CheckboxOptions = Record<
  CompanyStatusCheckboxOptionKey,
  {
    label: string;
    indicatorColor: Paths<MergedColorsType>;
  }
>;

const defaultStatuses = {
  listed: false,
  delisted: false,
  draft: false,
};

export default function CompaniesStatusFilterButton({
  variables,
  setFilters,
}: {
  variables: SimpleTableQueryVariables;
  setFilters: (variables: SimpleTableQueryVariables) => void;
}) {
  const { t } = useTranslation();

  const statuses: Inputs = useMemo(() => {
    const statuses = variables.filterBy?.statuses as string[] | undefined;
    if (!statuses) return defaultStatuses;

    return {
      listed: false,
      delisted: false,
      draft: false,
      ...statuses.reduce(
        (prevStatuses, status) => ({
          ...prevStatuses,
          [status.toLowerCase()]: true,
        }),
        {},
      ),
    };
  }, [variables.filterBy?.statuses]);

  const checkboxOptions: CheckboxOptions = {
    listed: {
      label: t(`listed`),
      indicatorColor: `active`,
    },
    delisted: {
      label: t(`delisted`),
      indicatorColor: `pending`,
    },
    draft: {
      label: t(`draft`),
      indicatorColor: `grey.200`,
    },
  };

  const { control, formState, handleSubmit, register, reset } = useForm<Inputs>(
    { defaultValues: statuses },
  );
  const formValues = useWatch({ control });

  const { isOpen, onClose, onToggle } = useDisclosure();

  const onSubmit: SubmitHandler<Inputs> = useCallback(
    (data) => {
      const newStatuses = keys(data)
        .map((key) =>
          data[key as keyof Inputs] ? key.toUpperCase() : undefined,
        )
        .filter((key): key is string => !!key) as CompanyStatus[];

      onToggle();
      setFilters({
        filterBy: {
          ...{ statuses: newStatuses.length > 0 ? newStatuses : undefined },
        },
      });
    },
    [onToggle, setFilters],
  );

  useEffect(() => {
    const statuses = variables.filterBy?.statuses as string[] | undefined;

    if (formState.isSubmitted && !statuses?.length) {
      reset(defaultStatuses);
    }
  }, [formState.isSubmitted, variables.filterBy?.statuses, reset]);

  return (
    <Popover returnFocusOnClose={false} isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button
          variant="outline"
          rightIcon={<CaretDown />}
          w={150}
          onClick={onToggle}
        >
          {t(`listing_status`)}
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverBody as="form" onSubmit={handleSubmit(onSubmit)}>
            <VStack p={2} gap={2} alignItems="flex-start">
              {keys(checkboxOptions).map((key) => (
                <Checkbox
                  label={
                    <Status.Indicator
                      text={
                        checkboxOptions[key as CompanyStatusCheckboxOptionKey]
                          .label
                      }
                      indicatorProps={{
                        bg: checkboxOptions[
                          key as CompanyStatusCheckboxOptionKey
                        ].indicatorColor,
                      }}
                    />
                  }
                  key={key}
                  isChecked={formValues[key as CompanyStatusCheckboxOptionKey]}
                  {...register(key as CompanyStatusCheckboxOptionKey)}
                />
              ))}
            </VStack>

            <SimpleGrid columns={2} columnGap={2} mt={2}>
              <GridItem colSpan={1}>
                <Button variant="outline" size="lg" w="full" onClick={onClose}>
                  {t(`cancel`)}
                </Button>
              </GridItem>
              <GridItem colSpan={1}>
                <Button type="submit" size="lg" w="full">
                  {t(`apply`)}
                </Button>
              </GridItem>
            </SimpleGrid>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}
