import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FeeDiscountFormInputs } from "@/features/FeeDiscountForm";
import { FormLabelPosition, Input, InputWrapper } from "@/modules/Form";
import { useFeeDiscountAutoNaming } from "@/modules/LaunchDarkly";
import {
  Card,
  CardBody,
  CardHeader,
  GridItem,
  HStack,
  SimpleGrid,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";

export default function FeeDiscountFormDetailsCard() {
  const {
    formState: { errors },
    register,
  } = useFormContext<FeeDiscountFormInputs>();
  const { t } = useTranslation();
  const feeDiscountAutoNamingEnabled = useFeeDiscountAutoNaming();

  return (
    <Card w="full">
      <CardHeader>
        <HStack gap={6}>
          <Text textStyle="colfax-18-medium">{t(`fee_discount_details`)}</Text>
        </HStack>
      </CardHeader>
      <CardBody>
        <VStack gap={6} alignItems="flex-start">
          <SimpleGrid
            gridTemplateColumns={{ base: `1fr 200px` }}
            w="full"
            gap={{ base: 6 }}
          >
            <GridItem>
              <HStack gap={{ base: 6 }} alignItems="flex-start">
                {!feeDiscountAutoNamingEnabled && (
                  <VStack gap={4} alignItems="flex-start" w="50%">
                    <Input.Generic
                      formLabelPosition={FormLabelPosition.column}
                      label={t(`fee_discount_name`)}
                      helperText={t(`fee_discount_name_sub_label`)}
                      error={errors.name}
                      {...register(`name`)}
                    />
                  </VStack>
                )}
                <VStack gap={{ base: 6 }} alignItems="flex-start" w="50%">
                  <InputWrapper
                    name="description"
                    formLabelPosition={FormLabelPosition.column}
                    label={t(`fee_discount_description`)}
                    helperText={t(`fee_discount_description_sub_label`)}
                    error={errors.description}
                  >
                    <Textarea maxLength={255} {...register(`description`)} />
                  </InputWrapper>
                </VStack>
              </HStack>
            </GridItem>
          </SimpleGrid>
        </VStack>
      </CardBody>
    </Card>
  );
}
