import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { FeeDiscountEntityType } from "@/graphql";
import {
  FeeDiscountEntity,
  hasCompanyFeeDiscountApplications,
  hasUserFeeDiscountApplications,
} from "@/modules/FeeDiscountsCard";

export default function useApplyFeeDiscountWarning(entity: FeeDiscountEntity) {
  const [warningMessage, setWarningMessage] = useState<string | null>();
  const { t } = useTranslation();

  useEffect(() => {
    const { entityType } = entity;

    // Only listing-level fee discount application has warning message on intial render
    if (entityType !== FeeDiscountEntityType.Listing) return;

    const { company, listing, seller } = entity;

    const userFeeDiscountApplications = hasUserFeeDiscountApplications(
      listing,
      seller,
    );

    const companyFeeDiscountApplications =
      hasCompanyFeeDiscountApplications(company);

    const warningMessages = [
      {
        message: t(`apply_listing_fee_discount_warning_message`, {
          entityType: `user`,
        }),
        show:
          userFeeDiscountApplications ||
          (userFeeDiscountApplications && companyFeeDiscountApplications),
      },
      {
        message: t(`apply_listing_fee_discount_warning_message`, {
          entityType: `company`,
        }),
        show: companyFeeDiscountApplications,
      },
    ];

    const { message } = warningMessages.find((msg) => msg.show) || {};

    if (message) {
      setWarningMessage(message);
    }
  }, [entity, t]);

  return { warningMessage, setWarningMessage };
}
