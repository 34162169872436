import { useContext } from "react";
import { ErrorWrapper } from "@/modules/Error";
import {
  CompanyPageNavMenu,
  CompanyTemplatesContext,
  CompanyTemplatesProvider,
} from "@/features/Company";
import { DetailPageLayout } from "@/modules/Layout";
import CompanyTemplatesTable from "./CompanyTemplatesTable";

interface CompanyTemplatesPageContentProps {
  readonly nameSlug: string;
}

function CompanyTemplatesPageContent({
  nameSlug,
}: CompanyTemplatesPageContentProps) {
  const { error } = useContext(CompanyTemplatesContext);

  return (
    <ErrorWrapper error={error?.message}>
      <DetailPageLayout
        isTwoColumn={false}
        mainPanel={<CompanyTemplatesTable />}
        tabMenu={<CompanyPageNavMenu slug={nameSlug} />}
      />
    </ErrorWrapper>
  );
}

interface CompanyTemplatesPageProps {
  readonly nameSlug: string;
}

export default function CompanyTemplatesPage({
  nameSlug,
}: CompanyTemplatesPageProps) {
  return (
    <CompanyTemplatesProvider nameSlug={nameSlug}>
      <CompanyTemplatesPageContent nameSlug={nameSlug} />
    </CompanyTemplatesProvider>
  );
}
