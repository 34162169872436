import {
  TransactionsTableQuery,
  TransactionsTableQueryVariables,
} from "@/graphql";
import { UseQueryState } from "urql";
import { TransactionsTableDataType } from "@/features/Transactions";

const DEFAULT = { transactions: [], totalCount: 0, pageInfo: undefined };

export const transformTransactionsQueryToData = (
  responseData:
    | UseQueryState<TransactionsTableQuery, TransactionsTableQueryVariables>
    | undefined,
) => {
  if (!responseData?.data?.transactions) {
    return {
      ...DEFAULT,
      error: responseData?.error,
      fetching: responseData?.fetching,
    };
  }

  const { edges } = responseData.data.transactions;
  const transactions: TransactionsTableDataType[] = edges.map((edge) => {
    const {
      bid: { company, numSharesActual, pricePerShare },
    } = edge.node;

    return {
      ...edge.node,
      companyName: company ? company.name : ``,
      sharesPPS: `${numSharesActual} @ ${pricePerShare}`,
    };
  });

  return {
    transactions,
    totalCount: responseData.data.transactions.totalCount,
    pageInfo: responseData.data.transactions.pageInfo,
    error: responseData.error,
    loading: responseData.fetching,
  };
};
