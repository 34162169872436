import { AddEntityInput } from "@/graphql";
import { Input } from "@/modules/Form";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

function LegalNameInput() {
  const { t } = useTranslation();
  const {
    formState: { errors },
    register,
  } = useFormContext<Pick<AddEntityInput, "legalName">>();

  return (
    <Input.Generic
      error={errors.legalName}
      label={t(`legal_entity_name`)}
      {...register(`legalName`, { required: t(`legal_name_required`) })}
    />
  );
}

export default LegalNameInput;
