import { sentenceCase } from "change-case";
import { useCallback } from "react";
import { UseFormSetError } from "react-hook-form";

import { FeeDiscountFormInputs } from "@/features/FeeDiscountForm";
import {
  CreateFeeDiscountInput,
  useFeeDiscountFormCreateFeeDiscountMutation,
} from "@/graphql";
import * as Sentry from "@sentry/nextjs";

interface UseCreateFeeDiscountProps {
  setError: UseFormSetError<FeeDiscountFormInputs>;
}

export const useCreateFeeDiscount = ({
  setError,
}: UseCreateFeeDiscountProps) => {
  const [{ fetching }, createFeeDiscountMutation] =
    useFeeDiscountFormCreateFeeDiscountMutation();

  const createFeeDiscount = useCallback(
    async (input: CreateFeeDiscountInput) => {
      try {
        const { data: { createFeeDiscount: response = {} } = {} } =
          await createFeeDiscountMutation({
            input,
          });
        const id = response?.feeDiscount?.id;
        const errors = response?.errors;

        if (errors) {
          errors.forEach(({ field, message }) =>
            setError(field as keyof FeeDiscountFormInputs, {
              message: sentenceCase(message),
            }),
          );
          return null;
        }

        return { id };
      } catch (error) {
        Sentry.captureException(error);
        return null;
      }
    },
    [createFeeDiscountMutation, setError],
  );
  return { createFeeDiscount, fetching };
};
