import { match } from "ts-pattern";

import StatusWithIcon, { StatusWithIconProps } from "./StatusWithIcon";

interface StatusAccreditationProps extends StatusWithIconProps {
  booleanFlag: boolean | null | undefined;
}

export default function StatusAccreditation({
  booleanFlag,
  ...restProps
}: StatusAccreditationProps) {
  return match(booleanFlag)
    .with(true, () => <StatusWithIcon color="#323232" {...restProps} />)
    .with(false, () => <StatusWithIcon color="#D5D5D5" {...restProps} />)
    .otherwise(() => <StatusWithIcon color="#D5D5D5" {...restProps} />);
}
