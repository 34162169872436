import {
  Card as ChakraCard,
  CardBody,
  VStack,
  Text,
  HStack,
  IconButton,
  Box,
  Button,
  BoxProps,
} from "@chakra-ui/react";
import { CaretRight, CheckCircle, CopySimple } from "@phosphor-icons/react";
import { useCustomToast } from "@/modules/Toast";
import { useTranslation } from "react-i18next";
import { useColors } from "@/modules/Theme";
import { BankAccountVerificationStatus, InvestorType } from "@/graphql";
import { Link } from "@/modules/Navigation";
import { formatToLocalTimezone } from "@/modules/NumeralFormat";
import { ReactNode } from "react";
import { match } from "ts-pattern";

import { getBankAccountStatusByVerificationStatus } from "@/features/Transactions";

type TransactionSigner = {
  readonly id: string;
  readonly name: string;
  readonly email: string;
  readonly investorType?: InvestorType | null;
  readonly institutionId?: string | null;
  readonly isHiiveUser?: boolean;
  readonly isSigner?: boolean;
  readonly signedAt?: string;
};
export type TransactionSignerCardProps = {
  readonly signer: TransactionSigner;
};

function Pill({
  signer,
  isRepresentedUser = false,
}: {
  readonly signer: TransactionSigner;
  readonly isRepresentedUser?: boolean;
}) {
  const { t } = useTranslation();

  function getRoleKey({
    investorType,
    isInstitution,
    isHiiveUser,
    isRepresentedUser,
  }: {
    readonly investorType?: InvestorType | null;
    readonly isInstitution: boolean;
    readonly isHiiveUser: boolean;
    readonly isRepresentedUser: boolean;
  }) {
    if (isHiiveUser) return `hiive`;

    if (isRepresentedUser) return `represented_user`;

    if (isInstitution) return `institutional_user`;

    if (investorType === InvestorType.Broker) return `broker`;

    if (investorType === InvestorType.UnaccreditedSeller)
      return `unaccredited_seller`;

    return `individual_trader`;
  }

  const role = getRoleKey({
    investorType: signer.investorType,
    isInstitution: !!signer.institutionId,
    isHiiveUser: !!signer.isHiiveUser,
    isRepresentedUser,
  });

  const pillBackground = match({
    isRepresentedUser,
    isHiiveUser: !!signer.isHiiveUser,
  })
    .with({ isHiiveUser: true }, () => `sky.200`)
    .with({ isRepresentedUser: true }, () => `grey.25`)
    .otherwise(() => `grey.100`);

  const pillColor = match({
    isRepresentedUser,
  })
    .with({ isRepresentedUser: true }, () => `grey.600`)
    .otherwise(() => `grey.900`);

  return (
    <Text
      px={isRepresentedUser ? 0 : 2}
      py={0.5}
      borderRadius="lg"
      textStyle="colfax-12-regular"
      bg={pillBackground}
      color={pillColor}
    >
      {t(role)}
    </Text>
  );
}

function SignerBankAccountStatus({
  verificationStatus = null,
}: {
  verificationStatus?: BankAccountVerificationStatus | null;
}) {
  return getBankAccountStatusByVerificationStatus(verificationStatus);
}

function Card({
  children,
  ...boxProps
}: BoxProps & { readonly children: ReactNode }) {
  const [grey50] = useColors([`grey.50`]);
  return (
    <ChakraCard bg="grey.25" border={`solid 1px ${grey50}`} minH={188}>
      <CardBody p={0} py={4} px={4}>
        <VStack
          alignItems="flex-start"
          justifyContent="space-between"
          h="full"
          p={0}
          {...boxProps}
        >
          {children}
        </VStack>
      </CardBody>
    </ChakraCard>
  );
}

function EmailDetails({
  email,
  name,
}: {
  readonly email: string;
  readonly name: string;
}) {
  const { t } = useTranslation();
  const { infoToastCondensed } = useCustomToast();
  const [grey100, sky600, grey900] = useColors([
    `grey.100`,
    `sky.600`,
    `grey.900`,
  ]);

  return (
    <VStack alignItems="flex-start">
      <Text textStyle="colfax-16-medium">{name}</Text>
      <HStack pb={1}>
        <Text textStyle="colfax-14-regular" color="grey.600">
          {email}
        </Text>
        <IconButton
          aria-label="copy-email"
          variant="outline"
          minW={0}
          minH={0}
          w={6}
          h={6}
          borderColor={grey100}
          onClick={() => {
            navigator.clipboard.writeText(email);
            infoToastCondensed(t(`copied_to_clipboard`), {
              icon: <CopySimple size={24} weight="fill" color={sky600} />,
            });
          }}
          icon={<CopySimple size={12} color={grey900} />}
        />
      </HStack>
    </VStack>
  );
}

function SignerStatus({ signedAt }: { readonly signedAt?: string }) {
  const [grey900, grey600] = useColors([`grey.900`, `grey.600`]);

  return (
    <HStack gap={0}>
      <CheckCircle
        weight="fill"
        size={16}
        color={!!signedAt ? grey900 : grey600}
      />
      <Text textStyle="colfax-16-regular" pl={1}>
        {formatToLocalTimezone(signedAt)}
      </Text>
    </HStack>
  );
}

function FullDetails({ userId }: { readonly userId: string }) {
  const { t } = useTranslation();

  return (
    <Box pt={1}>
      <Link href={`/users/${userId}`}>
        <Button variant="outline" size="md" rightIcon={<CaretRight />}>
          {t(`full_details`)}
        </Button>
      </Link>
    </Box>
  );
}

const TransactionSignerCard = {
  Card,
  EmailDetails,
  FullDetails,
  SignerStatus,
  Pill,
  BankAccountStatus: SignerBankAccountStatus,
};

export default TransactionSignerCard;
