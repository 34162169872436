import {
  SimpleTable,
  SimpleTableQueryVariables,
  TableContext,
} from "@/modules/SimpleTable";
import { Box, Button, useDisclosure } from "@chakra-ui/react";
import { useContext, useMemo, useCallback } from "react";
import { WorkflowTemplatesTableContext } from "@/features/WorkflowTemplates";
import { TableColumns } from "@/modules/Table";
import { Trans, useTranslation } from "react-i18next";
import {
  WorkflowTemplatesPageWorkflowTemplatesQuery,
  WorkflowTemplatesPageWorkflowTemplatesQueryVariables,
  WorkflowTemplatesPageWorkflowTemplateNodeFragment,
} from "@/graphql";
import { UseQueryState } from "urql";
import { useRouter } from "next/router";
import { Row } from "@tanstack/react-table";

import {
  CompanyCell,
  StatusCell,
  LastSavedCell,
} from "./WorkflowTemplatesTableCells";
import WorkflowTemplatesFilters from "./WorkflowTemplatesFilters";
import CreateCompanyTemplateModal from "./CreateCompanyTemplateModal";
import RowActions from "./TemplatesRowActions";

type WorkflowTemplatesTableColumn = {
  readonly companyName?: string;
  readonly company?: {
    readonly name: string;
    readonly logoUrl?: string | null;
  } | null;
  readonly status?: string;
  readonly lastSavedAt?: string;
};

export type WorkflowTemplatesTableData = WorkflowTemplatesTableColumn &
  Pick<
    WorkflowTemplatesPageWorkflowTemplateNodeFragment,
    "id" | "lastSavedAt" | "status" | "transferType"
  >;

const columns: TableColumns<
  Omit<WorkflowTemplatesTableColumn, "company">,
  WorkflowTemplatesTableData
> = {
  companyName: {
    header: () => <Trans i18nKey="company" />,
    cell: (props) => <CompanyCell {...props} />,
    enableSorting: false,
  },
  status: {
    header: () => <Trans i18nKey="status" />,
    cell: (props) => <StatusCell {...props} />,
    enableSorting: false,
  },
  lastSavedAt: {
    header: () => <Trans i18nKey="last_saved" />,
    cell: (props) => <LastSavedCell {...props} />,
  },
};

const toTableData = (
  response:
    | UseQueryState<
        WorkflowTemplatesPageWorkflowTemplatesQuery,
        WorkflowTemplatesPageWorkflowTemplatesQueryVariables
      >
    | undefined,
) => {
  if (!response?.data?.listExecutionWorkflowTemplates) {
    return {
      executionWorkflowTemplates: [],
      totalCount: 0,
      pageInfo: undefined,
    };
  }

  const { edges } = response.data.listExecutionWorkflowTemplates;

  const executionWorkflowTemplates = edges.map((edge) => ({
    ...edge.node,
    companyName: edge.node.company?.name,
  }));

  return {
    executionWorkflowTemplates,
    totalCount: response.data.listExecutionWorkflowTemplates.totalCount,
    pageInfo: response.data.listExecutionWorkflowTemplates.pageInfo,
  };
};

function WorkflowTemplatesTable() {
  const { t } = useTranslation();
  const {
    isOpen: createCompanyTemplateIsOpen,
    onOpen: onOpenCreateCompanyTemplate,
    onClose: onCloseCreateCompanyTemplate,
  } = useDisclosure();
  const { response } = useContext(WorkflowTemplatesTableContext);
  const context = useContext(TableContext);
  const { push } = useRouter();

  const { executionWorkflowTemplates, pageInfo, totalCount } = useMemo(
    () => toTableData(response),
    [response],
  );

  const onRowClick = useCallback(
    (row: Row<Record<string, unknown>>) => {
      const { id } =
        row.original as WorkflowTemplatesPageWorkflowTemplateNodeFragment;
      push(`/workflow-templates/${id}`);
    },
    [push],
  );

  if (!response || !response.data) return null;

  const renderRowActions = (row: Row<Record<string, unknown>>) => (
    <RowActions
      workflowTemplate={
        row.original as WorkflowTemplatesPageWorkflowTemplateNodeFragment
      }
    />
  );

  return (
    <>
      <Box
        w="full"
        bg="white"
        p={4}
        borderTopRadius={8}
        justifyContent="space-between"
        display="flex"
      >
        <WorkflowTemplatesFilters />
        <Button onClick={onOpenCreateCompanyTemplate}>
          {t(`create_template`)}
        </Button>
      </Box>
      <SimpleTable<
        SimpleTableQueryVariables,
        Omit<WorkflowTemplatesTableColumn, "company">[],
        WorkflowTemplatesTableData[]
      >
        {...context}
        tableData={executionWorkflowTemplates}
        pageInfo={pageInfo}
        totalCount={totalCount}
        loading={response.fetching}
        renderRowActions={renderRowActions}
        error={response.error}
        onRowClick={onRowClick}
        columns={columns}
      />
      <CreateCompanyTemplateModal
        createCompanyTemplateIsOpen={createCompanyTemplateIsOpen}
        onCloseCreateCompanyTemplate={onCloseCreateCompanyTemplate}
      />
    </>
  );
}

export default WorkflowTemplatesTable;
