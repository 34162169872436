import { SVGProps } from "react";

function PendingIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.506308 4.03842C0.335938 4.67426 0.335938 5.44923 0.335938 6.99919C0.335938 8.54914 0.335938 9.32411 0.506308 9.95995C0.968644 11.6854 2.31638 13.0331 4.04184 13.4955C4.67767 13.6659 5.45265 13.6659 7.0026 13.6659C8.55256 13.6659 9.32753 13.6659 9.96337 13.4955C11.6888 13.0331 13.0366 11.6854 13.4989 9.95995C13.6693 9.32411 13.6693 8.54914 13.6693 6.99919C13.6693 5.44923 13.6693 4.67426 13.4989 4.03842C13.0366 2.31296 11.6888 0.965226 9.96337 0.50289C9.32753 0.33252 8.55256 0.33252 7.0026 0.33252C5.45265 0.33252 4.67767 0.33252 4.04184 0.50289C2.31638 0.965226 0.968644 2.31296 0.506308 4.03842ZM4.58105 11.4831C4.88517 11.5646 5.31852 11.5825 7.0026 11.5825C8.68668 11.5825 9.12004 11.5646 9.42416 11.4831C10.4307 11.2134 11.2169 10.4273 11.4866 9.42074C11.568 9.11662 11.5859 8.68327 11.5859 6.99919C11.5859 5.31511 11.568 4.88175 11.4866 4.57763C11.2169 3.57111 10.4307 2.78493 9.42416 2.51524C9.12004 2.43375 8.68668 2.41585 7.0026 2.41585C5.31852 2.41585 4.88517 2.43375 4.58105 2.51524C3.57453 2.78493 2.78835 3.57111 2.51865 4.57763C2.43716 4.88175 2.41927 5.31511 2.41927 6.99919C2.41927 8.68327 2.43716 9.11662 2.51865 9.42074C2.78835 10.4273 3.57453 11.2134 4.58105 11.4831Z"
        // eslint-disable-next-line react/destructuring-assignment
        fill={props.fill ?? `#6C6C6C`}
      />
    </svg>
  );
}
export default PendingIcon;
