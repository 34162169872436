import { DateTime } from "luxon";

export enum DateTimeFormat {
  monthShort = `MMM`,
  monthYear = `MMMM yyyy`,
  fullDateWithMonthShort = `MMM. dd, yyyy`,
  MMddyyyy = `MM/dd/yyyy`,
  MMddyy = `MM/dd/yy`,
  shortDateWithTime = `dd MMM (h:mm a ZZZZ)`,
  yyyyMMdd = `yyyy-MM-dd`,
  timeWithTimezone = `h:mm a ZZZZ`,
  fullDateTime = `MMMM dd yyyy, HH:mm:ss`,
  fullDateTimeWithMonthShort = `MMM. dd, yyyy (h:mm a ZZZZ)`,
}

export function formatDate(
  date?: string | null,
  format = DateTimeFormat.yyyyMMdd,
) {
  if (!date) {
    return ``;
  }
  const value = DateTime.fromISO(date);
  return value.isValid ? value.toFormat(format) : ``;
}

export function formatToLocalTimezone(
  date?: string | null,
  format = DateTimeFormat.fullDateWithMonthShort,
) {
  if (!date) {
    return ``;
  }
  const isoDate = DateTime.fromISO(date).toLocal();
  return `${isoDate.toFormat(format)} (${isoDate.toFormat(
    DateTimeFormat.timeWithTimezone,
  )})`;
}
