import { useCallback } from "react";

import { useCompanyPageQuery } from "@/graphql";
import { ErrorWrapper } from "@/modules/Error";
import { useManagedMarkets } from "@/modules/LaunchDarkly";
import { DetailPageLayout } from "@/modules/Layout";

import {
  CompanyCard,
  CompanyPageNavMenu,
  CompanyPageSkeleton,
  CompanyRightPanel,
} from "./components";

interface CompanyPageProps {
  readonly nameSlug: string;
}

export default function CompanyPage({ nameSlug }: CompanyPageProps) {
  const [{ data, error, fetching }, executeQuery] = useCompanyPageQuery({
    variables: {
      nameSlug,
    },
    requestPolicy: `cache-and-network`,
  });
  const managedMarketsEnabled = useManagedMarkets();

  const refetch = useCallback(() => {
    executeQuery({ requestPolicy: `network-only` });
  }, [executeQuery]);

  const { company } = data || {};

  if (nameSlug && !fetching && !company) {
    return null;
  }

  return (
    <ErrorWrapper error={error?.message}>
      {company ? (
        <DetailPageLayout
          isTwoColumn
          mainPanel={<CompanyCard company={company} />}
          rightPanel={<CompanyRightPanel company={company} refetch={refetch} />}
          tabMenu={
            managedMarketsEnabled && <CompanyPageNavMenu slug={nameSlug} />
          }
        />
      ) : (
        <CompanyPageSkeleton />
      )}
    </ErrorWrapper>
  );
}
