import { Box, Tag } from "@chakra-ui/react";
import { CaretDown } from "@phosphor-icons/react";
import {
  Children,
  ReactElement,
  ReactNode,
  cloneElement,
  isValidElement,
} from "react";

function OverlappingTags({
  children,
  editable,
  skipWrap = false,
}: {
  children: ReactNode;
  editable?: boolean;
  skipWrap?: boolean;
}) {
  const childArray = Children.toArray(children).filter(
    (child): child is ReactElement => isValidElement(child),
  );
  const count = childArray.length;
  const overlap = 20;
  const compensation = (count - 1) * (overlap - 4);

  return (
    <Box display="flex" w={`calc(100% + ${compensation}px)`}>
      {childArray.map((child, index) => {
        const marginLeft = index === 0 ? 0 : `-${overlap - 4}px`;
        const maxW = skipWrap
          ? // limiting width only for the sake of ellipsis on long tags
            undefined
          : // accounting for dropdown if editable
            `${100 / count + (editable ? 10 : 0)}%`;
        const paddingRight =
          index !== count - 1 || editable ? `${overlap}px` : undefined;

        return (
          <Box
            // eslint-disable-next-line react/no-array-index-key
            key={`tag-${index}`}
            marginLeft={marginLeft}
            zIndex={index}
            maxW={maxW}
          >
            {cloneElement(child, {
              style: {
                ...child.props.style,
                paddingRight,
                border: `1px solid white`,
              },
            })}
          </Box>
        );
      })}
      {editable && (
        <Tag
          borderRightRadius="full"
          size="md"
          variant="grey"
          maxW="100%"
          ml={-3}
          zIndex={2}
          border="1px solid"
          borderColor="white"
        >
          <CaretDown size={10} />
        </Tag>
      )}
    </Box>
  );
}

export default OverlappingTags;
