import {
  BankAccountRequestStatus,
  BankAccountVerificationStatus,
  ListingShareSeriesMakeup,
  Maybe,
  ShareSeriesType,
  TransactionBidBrokerFragment,
  TransactionListingBrokerFragment,
  TransactionSignerFragment,
  TransactionsTableDocumentSignerFragment,
  TransactionState,
  TransactionTableUserFragment,
  TransactionTransferMethod,
  TransactionUserFragment,
} from "@/graphql";
import { match } from "ts-pattern";
import { StatusBadge } from "@/modules/StatusBadge";
import {
  CheckCircle,
  Circle,
  WarningCircle,
  XCircle,
} from "@phosphor-icons/react";
import { ReactElement } from "react";
import i18next from "i18next";

export const getSignedSigner = (
  signers: TransactionsTableDocumentSignerFragment[],
  user?: TransactionUserFragment | TransactionTableUserFragment,
  broker?: TransactionBidBrokerFragment | TransactionListingBrokerFragment,
):
  | TransactionsTableDocumentSignerFragment
  | TransactionSignerFragment
  | undefined =>
  signers.find(
    (signer) =>
      signer.userId === user?.id ||
      (signer.institutionId !== null &&
        signer.institutionId === user?.institutionId) ||
      signer.userId === broker?.id,
  );

type TransactionTransferMethodi18nKeys =
  | `direct`
  | `forward`
  | `spv`
  | `unknown_transfer_method`;

export const transactionTransferMethodToi18nKeys = (
  transferMethod: TransactionTransferMethod,
): TransactionTransferMethodi18nKeys =>
  match(transferMethod)
    .with(TransactionTransferMethod.Direct, () => `direct`)
    .with(TransactionTransferMethod.ForwardContract, () => `forward`)
    .with(TransactionTransferMethod.SpvLister, () => `spv`)
    .with(TransactionTransferMethod.SpvThirdparty, () => `spv`)
    .otherwise(
      () => `unknown_transfer_method`,
    ) as TransactionTransferMethodi18nKeys;

export const TerminalTransactionStates = [
  TransactionState.Cancelled,
  TransactionState.ClosedFeePaid,
  TransactionState.Expired,
  TransactionState.IssuerApprovalDeclined,
];

const ShareSeriesLongString = {
  [ShareSeriesType.A]: `Preferred A`,
  [ShareSeriesType.B]: `Preferred B`,
  [ShareSeriesType.C]: `Preferred C`,
  [ShareSeriesType.D]: `Preferred D`,
  [ShareSeriesType.E]: `Preferred E`,
  [ShareSeriesType.F]: `Preferred F`,
  [ShareSeriesType.G]: `Preferred G`,
  [ShareSeriesType.H]: `Preferred H`,
  [ShareSeriesType.I]: `Preferred I`,
  [ShareSeriesType.J]: `Preferred J`,
  [ShareSeriesType.K]: `Preferred K`,
  [ShareSeriesType.L]: `Preferred L`,
  [ShareSeriesType.Common]: `Common Stock`,
  [ShareSeriesType.CommonOptions]: `Common Options`,
  [ShareSeriesType.Options]: `Unexercised Options`,
  [ShareSeriesType.RestrictedStockUnits]: `Restricted Stock Units`,
  [ShareSeriesType.Seed]: `Preferred Seed`,
  [`Mixed`]: `Mixed Share Series`,
};

export const getBankAccountStatusByVerificationStatus = (
  verificationStatus: BankAccountVerificationStatus | null | undefined,
): ReactElement =>
  match(verificationStatus)
    .with(BankAccountVerificationStatus.Failed, () => (
      <StatusBadge
        variant="red-bordered"
        icon={<XCircle weight="fill" width={12} height={12} />}
        title={i18next.t(`verification_failed`)}
      />
    ))
    .with(BankAccountVerificationStatus.Cancelled, () => (
      <StatusBadge
        variant="red-bordered"
        icon={<XCircle weight="fill" width={12} height={12} />}
        title={i18next.t(`verification_cancelled`)}
      />
    ))
    .with(BankAccountVerificationStatus.Verified, () => (
      <StatusBadge
        variant="green-bordered"
        icon={<CheckCircle weight="fill" width={12} height={12} />}
        title={i18next.t(`verification_verified`)}
      />
    ))
    .with(BankAccountVerificationStatus.Manual, () => (
      <StatusBadge
        variant="yellow-bordered"
        icon={<WarningCircle weight="fill" width={12} height={12} />}
        title={i18next.t(`verification_manual`)}
      />
    ))
    .with(BankAccountVerificationStatus.PendingVerification, () => (
      <StatusBadge
        variant="sky-bordered"
        icon={<Circle width={12} height={12} />}
        title={i18next.t(`verification_pending`)}
      />
    ))
    .otherwise(() => (
      <StatusBadge variant="outline" title={i18next.t(`no_account`)} />
    ));

type TransactionBankAccountRequestStatusI18nKeys =
  | `requested`
  | `not_requested`
  | `cancelled`
  | `unknown_request_status`;

export const transactionBankAccountRequestStatusToi18nKeys = (
  requestStatus: BankAccountRequestStatus,
): TransactionBankAccountRequestStatusI18nKeys =>
  match(requestStatus)
    .with(BankAccountRequestStatus.Requested, () => `requested`)
    .with(BankAccountRequestStatus.NotRequested, () => `not_requested`)
    .with(BankAccountRequestStatus.Cancelled, () => `cancelled`)
    .otherwise(
      () => `unknown_request_status`,
    ) as TransactionBankAccountRequestStatusI18nKeys;

export const getShareSeriesLongString = (
  shareSeriesMakeup?: Maybe<Array<Maybe<ListingShareSeriesMakeup>>>,
) =>
  shareSeriesMakeup?.length === 1 &&
  shareSeriesMakeup[0] &&
  shareSeriesMakeup[0].shareSeries
    ? ShareSeriesLongString[shareSeriesMakeup[0].shareSeries]
    : ShareSeriesLongString.Mixed;
