import {
  AdminRole,
  CompanyStatus,
  FeeDiscountEntityType,
  InvestorType,
  ListingState,
  UserStatus,
} from "@/graphql";

import { TerminalTransactionStates } from "@/features/Transactions";
import { FeeDiscountEntity } from "@/modules/FeeDiscountsCard";
import { useSession } from "@/modules/Session";

const TERMINAL_LISTING_STATES = [
  ListingState.Closed,
  ListingState.ConditionallySold,
  ListingState.Expired,
  ListingState.Rejected,
  ListingState.Withdrawn,
  ListingState.InReview,
];

const INVALID_COMPANY_STATES = [CompanyStatus.Delisted, CompanyStatus.Draft];

const INVALID_USER_STATES = [
  UserStatus.Deactivated,
  UserStatus.Ignored,
  UserStatus.Incomplete,
];

function checkInvestorType(entity: FeeDiscountEntity) {
  if (entity.entityType !== FeeDiscountEntityType.User) return false;

  const { holdings, investorType } = entity;

  const isBroker = investorType === InvestorType.Broker;
  const isU16R = investorType === InvestorType.UnaccreditedSeller;
  const hasHoldings = holdings && holdings.length > 0;

  return !isBroker && (!isU16R || (isU16R && hasHoldings));
}

function checkTerminalState<T>(
  terminalStates: T[],
  state?: FeeDiscountEntity["state"],
): boolean {
  return !!state && !terminalStates.includes(state as T);
}

const checkFeeDiscountActionsAllowedByEntityType = {
  [FeeDiscountEntityType.Transaction]: (entity: FeeDiscountEntity) =>
    checkTerminalState(TerminalTransactionStates, entity.state),
  [FeeDiscountEntityType.Company]: (entity: FeeDiscountEntity) =>
    checkTerminalState(INVALID_COMPANY_STATES, entity.state),
  [FeeDiscountEntityType.Listing]: (entity: FeeDiscountEntity) =>
    checkTerminalState(TERMINAL_LISTING_STATES, entity.state),
  [FeeDiscountEntityType.User]: (entity: FeeDiscountEntity) =>
    checkInvestorType(entity) &&
    checkTerminalState(INVALID_USER_STATES, entity.state),
};

export default function useFeeDiscountManagement(entity?: FeeDiscountEntity) {
  const { user } = useSession();

  const canManageFeeDiscount = user?.adminRole === AdminRole.FeeDiscountAdmin;

  const isFeeDiscountActionAllowedForEntity =
    entity?.entityType &&
    checkFeeDiscountActionsAllowedByEntityType[entity.entityType]?.(entity);

  return { canManageFeeDiscount, isFeeDiscountActionAllowedForEntity };
}
