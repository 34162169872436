import { InputWrapper, useYupValidationResolver } from "@/modules/Form";
import { HStack, Input, ModalProps, Text, VStack } from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { BankAccountNumber } from "@/features/Entities";
import {
  BankAccountCardBankAccountFragment,
  useBankAccountCardUpdateBankAccountVerificationMutation,
} from "@/graphql";
import { useCustomToast } from "@/modules/Toast";
import * as yup from "yup";
import { NumericFormat } from "react-number-format";
import isNil from "lodash/isNil";
import { ConfirmModal } from "@/modules/Modal";
import { t } from "i18next";
import ModernTreasuryLogo from "./ModernTreasury";

const MIN_MICRO_DEPOSIT = 0.01;

const MICRODEPOSIT_VALIDATION_SCHEMA = yup.object().shape({
  deposit1Value: yup
    .number()
    .lessThan(100, t(`must_be_less_than_one`))
    .min(
      MIN_MICRO_DEPOSIT,
      t(`must_be_atleast`, {
        min_value: MIN_MICRO_DEPOSIT,
      }),
    )
    .nullable()
    .required(`Required`),

  deposit2Value: yup
    .number()
    .lessThan(100, t(`must_be_less_than_one`))
    .min(
      MIN_MICRO_DEPOSIT,
      t(`must_be_atleast`, {
        min_value: MIN_MICRO_DEPOSIT,
      }),
    )
    .nullable()
    .required(`Required`),
});

type MicrodepositFormValues = yup.InferType<
  typeof MICRODEPOSIT_VALIDATION_SCHEMA
>;

type MicrodepositVerificationModalProps = Omit<ModalProps, "children"> & {
  bankAccount: BankAccountCardBankAccountFragment;
};

type MicrodepositInputProps = {
  onChange: (value: number | null) => void;
  onBlur: () => void;
};

function MicrodepositInput({ onBlur, onChange }: MicrodepositInputProps) {
  return (
    <NumericFormat
      customInput={Input}
      decimalScale={2}
      min={0}
      onBlur={onBlur}
      onValueChange={(values) => {
        onChange(
          !isNil(values.floatValue)
            ? Math.round(values.floatValue * 100)
            : null,
        );
      }}
      placeholder="$0.00"
      prefix="$"
      thousandSeparator
      valueIsNumericString
    />
  );
}

export default function MicrodepositVerificationModal({
  bankAccount,
  onClose,
  ...props
}: MicrodepositVerificationModalProps) {
  const { t } = useTranslation();
  const { errorToast, successToast } = useCustomToast();

  const [, updateBankAccountVerification] =
    useBankAccountCardUpdateBankAccountVerificationMutation();

  const resolver = useYupValidationResolver(MICRODEPOSIT_VALIDATION_SCHEMA);

  const methods = useForm<MicrodepositFormValues>({
    resolver,
    defaultValues: {
      deposit1Value: undefined,
      deposit2Value: undefined,
    },
    mode: `onChange`,
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
    reset,
  } = methods;

  const closeAndResetModal = () => {
    onClose();
    reset();
  };

  const onSubmit = async (values: MicrodepositFormValues) => {
    const { data } = await updateBankAccountVerification({
      bankAccountId: bankAccount.id,
      deposit1Value: values.deposit1Value,
      deposit2Value: values.deposit2Value,
    });

    if (!data?.updateBankAccountVerification.externalAccount?.id) {
      errorToast();
      return;
    }

    successToast();
    closeAndResetModal();
  };
  return (
    <ConfirmModal
      {...props}
      body={
        <VStack gap={3} alignItems="flex-start">
          <HStack>
            <Text fontSize={22} fontWeight={500}>
              {t(`manually_verify_account`)}
            </Text>
            <BankAccountNumber
              bankAccount={bankAccount}
              fontSize={22}
              fontWeight={500}
            />
          </HStack>
          <VStack spacing={6} alignItems="flex-start">
            <Text textStyle="colfax-14-regular">
              {t(`manually_enter_microdeposits_explainer`)}
            </Text>
            <Controller
              name="deposit1Value"
              control={control}
              render={({ field }) => (
                <InputWrapper
                  error={errors.deposit1Value}
                  name="deposit1Value"
                  label="Deposit 1 Value"
                >
                  <MicrodepositInput {...field} />
                </InputWrapper>
              )}
            />
            <Controller
              name="deposit2Value"
              control={control}
              render={({ field }) => (
                <InputWrapper
                  error={errors.deposit2Value}
                  name="deposit2Value"
                  label="Deposit 2 Value"
                >
                  <MicrodepositInput {...field} />
                </InputWrapper>
              )}
            />
            <ModernTreasuryLogo />
          </VStack>
        </VStack>
      }
      confirmText={t(`submit`)}
      disableConfirm={!isValid}
      loading={isSubmitting}
      onConfirm={handleSubmit(onSubmit)}
      onClose={closeAndResetModal}
      title=""
    />
  );
}
