import {
  UnarchiveEntityInput,
  UserEntityFragment,
  useUnarchiveEntityMutation,
} from "@/graphql";
import { ConfirmModal } from "@/modules/Modal";
import { useCustomToast } from "@/modules/Toast";
import { MenuItem, Text, useDisclosure } from "@chakra-ui/react";
import { SubmitHandler } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";

type UnarchiveEntityMenuItemProps = {
  entity: UserEntityFragment;
  isInstitution?: boolean;
};

export default function UnarchiveEntityMenuItem({
  entity,
  isInstitution = false,
}: UnarchiveEntityMenuItemProps) {
  const { t } = useTranslation();
  const [, unarchiveEntity] = useUnarchiveEntityMutation();

  const { successToast, errorToast } = useCustomToast();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const onSubmit: SubmitHandler<UnarchiveEntityInput> = async (input) => {
    const data = {
      input,
    };
    const { error } = await unarchiveEntity(data, {
      additionalTypenames: [isInstitution ? `Institution` : `User`],
    });

    if (error) {
      errorToast(t(`could_not_unarchive_entity`));
    } else {
      successToast(t(`entity_unarchived_successfully`));
      onClose();
    }
  };

  return (
    <>
      <MenuItem onClick={() => onOpen()}>
        <Text>{t(`unarchive_entity`)}</Text>
      </MenuItem>
      <ConfirmModal
        body={
          <Trans
            components={{ bold: <strong /> }}
            values={{
              entity: entity.legalName,
            }}
            i18nKey="confirm_unarchive_message"
          />
        }
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={() =>
          onSubmit({
            entityId: entity.id,
          })
        }
        title={t(`confirm_unarchive`)}
      />
    </>
  );
}
