import { useCallback } from "react";

import { FeeDiscountFormInputs } from "@/features/FeeDiscountForm";
import {
  FeeDiscountApplicationState,
  FeeDiscountEntityType,
  FeeDiscountType,
  useFeeDiscountsCardActionsApplyOneTimeFeeDiscountMutation,
} from "@/graphql";
import { handleGqlError } from "@/modules/Error";
import { FeeDiscountEntity } from "@/modules/FeeDiscountsCard";

type FeeDiscountCardActionsApplyOneTimeFeeDiscountInputs =
  FeeDiscountFormInputs & {
    entity: FeeDiscountEntity;
  };

function getInput({
  description,
  entity,
  name,
  type,
  value,
}: FeeDiscountCardActionsApplyOneTimeFeeDiscountInputs) {
  const { entityId: transactionId, entityType } = entity;

  const hasPendingModification =
    entityType === FeeDiscountEntityType.Transaction &&
    entity.transaction?.pendingModification;

  return {
    description: description?.trim(),
    name: name?.trim(),
    transactionId,
    type: type as FeeDiscountType,
    value:
      type === FeeDiscountType.FlatFee
        ? value && Math.round(value * 100)
        : undefined,
    percentageValue: type !== FeeDiscountType.FlatFee ? value : undefined,
    ...(hasPendingModification
      ? { state: FeeDiscountApplicationState.Pending }
      : {}),
  };
}

interface ApplyOneTimeFeeDiscountProps {
  data: FeeDiscountCardActionsApplyOneTimeFeeDiscountInputs;
  onSuccess: () => void;
  onFailure: (message?: string) => void;
}

export default function useApplyOneTimeFeeDiscount() {
  const [{ fetching }, applyOneTimeFeeDiscountMutation] =
    useFeeDiscountsCardActionsApplyOneTimeFeeDiscountMutation();

  const applyOneTimeFeeDiscount = useCallback(
    async ({ data, onFailure, onSuccess }: ApplyOneTimeFeeDiscountProps) => {
      const { data: responseData, error } =
        await applyOneTimeFeeDiscountMutation({
          input: getInput(data),
        });

      if (error) {
        onFailure(handleGqlError(error));
      }
      if (responseData?.applyOneTimeFeeDiscount?.feeDiscountApplication) {
        onSuccess();
      }
    },
    [applyOneTimeFeeDiscountMutation],
  );

  return { fetching, applyOneTimeFeeDiscount };
}
