import { Card, CardHeader, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export default function NoBankAccountsCard() {
  const { t } = useTranslation();
  return (
    <Card w="full" variant="grey-footer">
      <CardHeader>
        <Text textStyle="heading-md">{t(`no_account`)}</Text>
      </CardHeader>
    </Card>
  );
}
