import { Maybe } from "@/graphql";
import formatCurrencyCents from "./formatCurrencyCents";
import formatValuation from "./formatValuation";

const formatLastRoundValue = ({
  lastRoundPostValuation,
  lastRoundPricePerShare,
}: {
  readonly lastRoundPostValuation: Maybe<number> | undefined;
  readonly lastRoundPricePerShare: Maybe<number> | undefined;
}) => {
  const lastRoundPostValuationText =
    lastRoundPostValuation && `${formatValuation(lastRoundPostValuation)} post`;

  const lastRoundPricePerShareText =
    lastRoundPricePerShare &&
    `${formatCurrencyCents(lastRoundPricePerShare)}/sh`;

  return [lastRoundPostValuationText, lastRoundPricePerShareText]
    .filter(Boolean)
    .join(` at `);
};

export default formatLastRoundValue;
