import { useColors } from "@/modules/Theme";
import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import { sentenceCase } from "change-case";
import { Handle, Position, useNodes } from "@xyflow/react";
import { WorkflowStepNodeProps } from "@/modules/Workflow";
import { MilestoneStatusIcon } from "@/modules/Step";
import { ExecutionStepStatus } from "@/graphql";

export function MilestoneStepNode({ data }: WorkflowStepNodeProps) {
  const [grey100] = useColors([`grey.100`]);
  const handleStyle = {
    background: grey100,
    borderRadius: `6px`,
    width: `8px`,
    height: `8px`,
    border: `1px solid white`,
  };

  const nodes = useNodes();
  const otherSelected = nodes.some(
    (node) => node.selected && node.id !== data.id,
  );

  const status = data?.status ?? ExecutionStepStatus.Pending;

  if (data.config.__typename !== `ExecutionMilestoneConfig`) return null;
  return (
    <Flex
      w="250px"
      justifyContent="center"
      py={0.5}
      height="initial"
      opacity={otherSelected ? 0.5 : 1}
    >
      <Handle type="target" position={Position.Top} style={handleStyle} />
      {data.config.milestone && (
        <HStack
          py={0.5}
          px={2}
          spacing={1}
          borderRadius={9}
          boxShadow="0px 2px 2px rgba(0, 0, 0, 0.15)"
          width="fit-content"
          background="purple.100"
          color="purple.1000"
        >
          <Box width={3} h={3} position="relative">
            <MilestoneStatusIcon status={status} />
          </Box>
          <Text textStyle="colfax-12-regular">
            {sentenceCase(data.config.milestone)}
          </Text>
        </HStack>
      )}
      <Handle type="source" position={Position.Bottom} style={handleStyle} />
    </Flex>
  );
}
