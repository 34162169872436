import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { CompanyManagedMarketContext } from "@/features/Company";
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Text,
} from "@chakra-ui/react";

import CompanyManagedMarketSellerEligibilityContent from "./CompanyManagedMarketSellerEligibilityContent";
import CompanyManagedMarketSellerEligibilityActions from "./CompanyManagedMarketSellerEligibilityActions";

interface CompanyManagedMarketSellerEligibilityCardEmptyStateProps {
  readonly name: string;
}

function CompanyManagedMarketSellerEligibilityCardEmptyState({
  name,
}: CompanyManagedMarketSellerEligibilityCardEmptyStateProps) {
  const { t } = useTranslation();

  return (
    <Text>{t(`seller_eligibility_empty_state_description`, { name })}</Text>
  );
}

export default function CompanyManagedMarketSellerEligibilityCard() {
  const { t } = useTranslation();
  const { managedMarketCompany } = useContext(CompanyManagedMarketContext);

  if (!managedMarketCompany) return null;

  const { companyEligibilityCriterion, name } = managedMarketCompany;

  return (
    <Card w="full">
      <CardHeader as={HStack} gap={4} alignItems="center">
        <Text textStyle="colfax-22-medium">{t(`seller_eligibility`)}</Text>
        <Box flexGrow={1} />
        <CompanyManagedMarketSellerEligibilityActions
          companyEligibilityCriterion={companyEligibilityCriterion}
          showActionButtonTitle
        />
      </CardHeader>
      <CardBody>
        {companyEligibilityCriterion ? (
          <CompanyManagedMarketSellerEligibilityContent
            companyEligibilityCriterion={companyEligibilityCriterion}
          />
        ) : (
          <CompanyManagedMarketSellerEligibilityCardEmptyState name={name} />
        )}
      </CardBody>
    </Card>
  );
}
