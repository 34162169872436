import { useCallback, useRef, useEffect } from "react";

/**
 * useDebounce to debounce a function call.
 *
 * @param delay - in milliseconds
 *
 * @returns debounced version of the callback function
 *
 * Usage:
 * const debouncedFunction = useDebounce(500);
 */

export const useDebounce = (delay: number) => {
  const ref = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(
    () => () => {
      if (ref.current !== null) {
        clearTimeout(ref.current);
      }
    },
    [],
  );

  const debounce = useCallback(
    (callback: () => void) => {
      if (ref.current !== null) {
        clearTimeout(ref.current);
      }

      // eslint-disable-next-line functional/immutable-data
      ref.current = setTimeout(() => {
        callback();
      }, delay);
    },
    [delay],
  );

  const cancel = useCallback(() => {
    if (ref.current !== null) {
      clearTimeout(ref.current);
      // eslint-disable-next-line functional/immutable-data
      ref.current = null;
    }
  }, []);

  return { debounce, cancel };
};
