import { useTranslation } from "react-i18next";

import { FeeDiscountApplicationFragment } from "@/graphql";
import {
  FeeDiscountEntity,
  useFeeDiscountManagement,
} from "@/modules/FeeDiscountsCard";
import { Box, CardHeader, HStack, Text } from "@chakra-ui/react";

import FeeDiscountCardActions from "./FeeDiscountsCardActions";

interface FeeDiscountCardHeaderProps {
  entity: FeeDiscountEntity;
  feeDiscountApplications: FeeDiscountApplicationFragment[];
  onSuccess?: () => void;
}

export default function FeeDiscountsCardHeader({
  entity,
  feeDiscountApplications,
  onSuccess,
}: FeeDiscountCardHeaderProps) {
  const { t } = useTranslation();
  const { canManageFeeDiscount, isFeeDiscountActionAllowedForEntity } =
    useFeeDiscountManagement(entity);

  const showCardActions =
    canManageFeeDiscount && isFeeDiscountActionAllowedForEntity;

  return (
    <CardHeader as={HStack} gap={4} alignItems="center">
      <Text textStyle="colfax-22-medium">{t(`fee_discounts`)}</Text>
      <Box flexGrow={1} />
      {showCardActions && (
        <FeeDiscountCardActions
          entity={entity}
          feeDiscountApplications={feeDiscountApplications}
          onSuccess={onSuccess}
        />
      )}
    </CardHeader>
  );
}
