import { Stack } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { AddEntityInput, EntityType } from "@/graphql";
import { LegalNameInput } from "./LegalNameInput";
import { EntityTypeSelect } from "./EntityTypeSelect";
import { EntityTypeInput } from "./EntityTypeInput";
import { JurisdictionOfFormationInput } from "./JurisdictionOfFormationInput";

function EntityFormFields() {
  const { watch, setValue } = useFormContext<AddEntityInput>();
  const entityType = watch(`type`);
  const showEntityTypeInput = entityType === EntityType.Other;

  useEffect(() => {
    if (!showEntityTypeInput) {
      setValue(`otherType`, undefined);
    }
  }, [showEntityTypeInput, setValue]);

  return (
    <Stack gap={4}>
      <EntityTypeSelect />
      {showEntityTypeInput && <EntityTypeInput />}
      <LegalNameInput />
      <JurisdictionOfFormationInput />
    </Stack>
  );
}

export default EntityFormFields;
