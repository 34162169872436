import { Card, CardBody, HStack, Text, VStack } from "@chakra-ui/react";
import {
  TransactionDetailFragment,
  TransactionEntityFragment,
} from "@/graphql";
import { useTranslation } from "react-i18next";
import { EntityDetailsActionsButton } from "./EntityDetailsActionsButton";
import EntityInfo from "./EntityInfo";

type EntityDetailsCardProps = {
  entity?: TransactionEntityFragment | null;
  id: string;
  isInstitutionalUser?: boolean;
  isBuyer: boolean;
  transaction: TransactionDetailFragment;
};

function EntityDetailsCard({
  entity,
  isBuyer,
  transaction,
  ...others
}: EntityDetailsCardProps) {
  const { t } = useTranslation();
  const showActionsButton = !!entity;

  return (
    <Card bg="grey.25" border="solid 1px" borderColor="grey.50">
      <CardBody p={4}>
        <VStack alignItems="start" width="full" gap="8px" textStyle="text-xs">
          <HStack width="full" justifyContent="space-between">
            <Text textStyle="colfax-16-medium">{t(`entity`)}</Text>
            {showActionsButton && (
              <EntityDetailsActionsButton
                isBuyer={isBuyer}
                entity={entity}
                transaction={transaction}
                {...others}
              />
            )}
          </HStack>
          {entity ? (
            <EntityInfo
              entity={entity}
              isBuyer={isBuyer}
              transaction={transaction}
            />
          ) : (
            <Text>{t(`none`)}</Text>
          )}
        </VStack>
      </CardBody>
    </Card>
  );
}

export default EntityDetailsCard;
