import { match } from "ts-pattern";

import { InvestorType } from "@/graphql";

import StatusWithIcon, { StatusWithIconProps } from "./StatusWithIcon";

interface StatusMembershipAgreementProps extends StatusWithIconProps {
  membershipAgreementSigned: boolean | null | undefined;
  investorType: InvestorType | null | undefined;
}

export default function StatusMembershipAgreement({
  membershipAgreementSigned,
  investorType,
  ...restProps
}: StatusMembershipAgreementProps) {
  return match({ membershipAgreementSigned, investorType })
    .with({ membershipAgreementSigned: true }, () => (
      <StatusWithIcon color="#323232" {...restProps} />
    ))
    .with({ membershipAgreementSigned: false }, () => (
      <StatusWithIcon color="#D5D5D5" {...restProps} />
    ))
    .otherwise(() => <StatusWithIcon color="#D5D5D5" {...restProps} />);
}
