import { Skeleton } from "@/modules/Skeleton";
import { Card, HStack, CardBody, VStack } from "@chakra-ui/react";

export default function BankAccountCardSkeleton() {
  return (
    <Card w="full" variant="grey-footer">
      <CardBody>
        <HStack justifyContent="space-between" w="full">
          <HStack gap={6}>
            <Skeleton h={2.5} w={50} />
            <VStack alignItems="start">
              <HStack>
                <Skeleton h={2.5} w={50} />
                <Skeleton h={2.5} w={50} />
              </HStack>
              <Skeleton h={2.5} w={109} />
            </VStack>
          </HStack>
          <HStack>
            <Skeleton h={2.5} w={100} />
          </HStack>
        </HStack>
      </CardBody>
    </Card>
  );
}
