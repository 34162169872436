import { match } from "ts-pattern";

import { InvestorType } from "@/graphql";

import StatusEmpty from "./StatusEmpty";
import StatusWithIcon, { StatusWithIconProps } from "./StatusWithIcon";

interface StatusIdentityVerificationProps extends StatusWithIconProps {
  identityVerified: boolean | null | undefined;
  investorType: InvestorType | null | undefined;
  isInstitutionUser: boolean;
}

export default function StatusIdentityVerification({
  identityVerified,
  investorType,
  isInstitutionUser,
  ...restProps
}: StatusIdentityVerificationProps) {
  return match({ identityVerified, investorType, isInstitutionUser })
    .with({ isInstitutionUser: true }, () => <StatusEmpty {...restProps} />)
    .with({ investorType: InvestorType.Broker }, () => (
      <StatusEmpty {...restProps} />
    ))
    .with({ identityVerified: true }, () => (
      <StatusWithIcon color="#323232" {...restProps} />
    ))
    .with({ identityVerified: false }, () => (
      <StatusWithIcon color="#D5D5D5" {...restProps} />
    ))
    .otherwise(() => <StatusWithIcon color="#D5D5D5" {...restProps} />);
}
