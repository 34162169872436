import isNil from "lodash/isNil";
import keys from "lodash/keys";
import {
  Dispatch,
  MouseEvent,
  ReactNode,
  SetStateAction,
  useCallback,
  useMemo,
  useRef,
} from "react";

import { SortDirection } from "@/graphql";
import { Status } from "@/modules/Status";
import {
  Box,
  BoxProps,
  Table as ChakraTable,
  HStack,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { CaretDown, CaretUp } from "@phosphor-icons/react";
import {
  AccessorFn,
  CellContext,
  Column,
  DeepKeys,
  HeaderContext,
  Row,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  formatColumnIdToSortField,
  useTableResizeObserver,
} from "@/modules/Table";

type TableColumn<
  TColumnChild extends Record<string, unknown>,
  TDataChild extends Record<string, unknown> = TColumnChild,
> = {
  accessorFn?: AccessorFn<TColumnChild, TColumnChild[keyof TColumnChild]>;
  header?: (
    header: HeaderContext<TColumnChild, TColumnChild[keyof TColumnChild]>,
  ) => ReactNode;
  cell?: (
    cell: CellContext<TDataChild, TDataChild[keyof TDataChild]>,
  ) => ReactNode;
  enableSorting?: boolean;
  isVisible?: boolean;
};

export type TableColumns<
  TColumn extends Record<string, unknown> = Record<string, unknown>,
  TData extends Record<string, unknown> = TColumn,
> = Record<keyof TColumn, TableColumn<TColumn, TData>>;

type TableContentProps<
  TColumn extends Record<string, unknown>[],
  TData extends Record<string, unknown>[] = TColumn,
> = {
  data: TData;
  columns: TableColumns<TColumn[number], TData[number]>;
  onRowClick?: (
    row: Row<TData[number]>,
    event: MouseEvent<HTMLTableCellElement, globalThis.MouseEvent>,
  ) => void;
  renderRowActions?: (row: Row<TData[number]>) => ReactNode;
  onSort?: (columnId: string) => SortDirection;
  sorting?: TableSortState;
  setSorting?: Dispatch<SetStateAction<TableSortState | undefined>>;
} & BoxProps;

type TableProps<
  TColumn extends Record<string, unknown>[],
  TData extends Record<string, unknown>[] = TColumn,
> = TableContentProps<TColumn, TData> & {
  tabs?: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
  pagination?: ReactNode;
  onRowClick?: (
    row: Row<TData[number]>,
    event: MouseEvent<HTMLTableCellElement, globalThis.MouseEvent>,
  ) => void;
  renderRowActions?: (row: Row<TData[number]>) => ReactNode;
  onSort?: (columnId: string) => SortDirection;
  sorting?: TableSortState;
  setSorting?: Dispatch<SetStateAction<TableSortState | undefined>>;
} & BoxProps;

export type TableSortState = {
  direction: SortDirection;
  field: string;
};

function TableSortButton({
  state,
}: {
  state?: SortDirection.Asc | SortDirection.Desc;
}) {
  return (
    <VStack gap={0} position="relative">
      <Box
        as={CaretUp}
        size={11}
        weight="fill"
        color={state === SortDirection.Asc ? `grey.900` : `grey.200`}
      />
      <Box
        as={CaretDown}
        size={11}
        mt={-1}
        weight="fill"
        color={state === SortDirection.Desc ? `grey.900` : `grey.200`}
      />
    </VStack>
  );
}

const STICKY_COLUMN_WIDTH = 48;

function TableContent<
  TColumn extends Record<string, unknown>[],
  TData extends Record<string, unknown>[] = TColumn,
>({
  data,
  columns: defaultColumns,
  onRowClick,
  renderRowActions,
  onSort,
  sorting,
  setSorting,
  ...tableStyleProps
}: TableContentProps<TColumn, TData>) {
  const columnHelper = createColumnHelper<TData[number]>();
  const stickyColumnContainerRef = useRef<HTMLDivElement>(null);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const tableBodyRef = useRef<HTMLTableSectionElement>(null);
  const { onTableScroll } = useTableResizeObserver(
    tableContainerRef,
    stickyColumnContainerRef,
    STICKY_COLUMN_WIDTH,
  );

  const handleSort = useCallback(
    (column: Column<TData[number], unknown>, enableSorting: boolean) => {
      if (!isNil(onSort) && enableSorting) {
        const direction = onSort(column.id);
        setSorting?.({
          direction,
          field: formatColumnIdToSortField(column.id),
        });
      }
    },
    [onSort, setSorting],
  );

  const columns = useMemo(
    () => [
      // The cast to DeepKeys is required here since "keys" does not keep
      // the original type signature of "defaultColumns"
      ...(keys(defaultColumns) as DeepKeys<TData[number]>[])
        .filter(
          (key) =>
            // Filter out columns where isVisible is explicitly set to false
            defaultColumns[key].isVisible !== false,
        )
        .map((key) => {
          const column = defaultColumns[key];

          const {
            cell,
            header,
            accessorFn,
            enableSorting,
            isVisible = true,
          } = column;

          const canSort = enableSorting ?? true;

          return columnHelper.accessor(accessorFn ?? key, {
            id: key.toString(),
            enableSorting,
            isVisible,
            header: (props) => (
              <HStack
                alignItems="center"
                p={1}
                gap={1.5}
                h={10}
                onClick={() => handleSort(props.column, canSort)}
              >
                <Text
                  textStyle="colfax-14-medium"
                  textTransform="capitalize"
                  color="grey.900"
                  display="flex"
                  lineHeight={0}
                  as="div"
                >
                  {header ? header?.(props) : key.toString()}
                </Text>
                {canSort && (
                  <TableSortButton
                    state={
                      sorting?.field ===
                      formatColumnIdToSortField(props.column.id)
                        ? sorting.direction
                        : undefined
                    }
                  />
                )}
              </HStack>
            ),
            cell:
              cell ??
              ((cell) => {
                const value = cell.getValue();
                return value || value === 0 ? value : <Status.Empty />;
              }),
          });
        }),
      columnHelper.display({
        id: `row-actions`,
        header: () => <HStack p={1} gap={1.5} h={10} />,
        enablePinning: true,
        cell: ({ row }) => {
          if (isNil(renderRowActions)) {
            return null;
          }
          return renderRowActions(row);
        },
      }),
    ],
    [defaultColumns, columnHelper, sorting, handleSort, renderRowActions],
  );

  const table = useReactTable({
    data,
    columns,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      columnPinning: {
        right: [`row-actions`],
      },
    },
  });

  const clientHeight =
    (tableBodyRef?.current &&
      tableBodyRef?.current?.children?.length > 0 &&
      tableBodyRef?.current?.children[0]?.getBoundingClientRect().height) ||
    0;

  const optionalCellProps =
    clientHeight < 63 && !!renderRowActions
      ? {
          "padding-vertical": 1,
        }
      : {};

  const stickyColumnWidth = `${STICKY_COLUMN_WIDTH}px`;

  return (
    <HStack
      justifyContent="center"
      alignItems="start"
      spacing={0}
      margin={0}
      {...tableStyleProps}
    >
      <TableContainer
        ref={tableContainerRef}
        width="100%"
        onScroll={onTableScroll}
      >
        <ChakraTable>
          <Thead>
            {table.getCenterHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th key={header.id}>
                    {header.isPlaceholder ? null : (
                      <Box
                        cursor={
                          header.column.getCanSort() ? `pointer` : `default`
                        }
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                      </Box>
                    )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody ref={tableBodyRef}>
            {table.getRowModel().rows.map((row, index) => (
              <Tr
                key={row.id}
                bg={index % 2 ? `grey.25` : `white`}
                _hover={
                  onRowClick
                    ? {
                        boxShadow: `lg`,
                        bg: `grey.50`,
                      }
                    : { bg: `grey.50` }
                }
              >
                {row.getCenterVisibleCells().map((cell) => (
                  <Td
                    key={cell.id}
                    onClick={(event) => {
                      if (cell.column.id === `row-actions`) return;
                      onRowClick?.(row, event);
                    }}
                    cursor={onRowClick ? `pointer` : `default`}
                    bg={index % 2 ? `grey.25` : `white`}
                  >
                    <Box
                      display="inline-block"
                      textStyle="colfax-16-regular"
                      {...optionalCellProps}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </Box>
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </ChakraTable>
      </TableContainer>
      {!!renderRowActions && (
        <Box zIndex={2}>
          <ChakraTable width={stickyColumnWidth}>
            <Thead>
              {table.getRightHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Th key={header.id} px={0} width={stickyColumnWidth}>
                      {header.isPlaceholder ? null : (
                        <Box
                          cursor={
                            header.column.getCanSort() ? `pointer` : `default`
                          }
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </Box>
                      )}
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Box ref={stickyColumnContainerRef}>
              <Tbody>
                {table.getRowModel().rows.map((row, index) => (
                  <Tr
                    key={row.id}
                    bg={index % 2 ? `grey.25` : `white`}
                    height={`${
                      tableBodyRef.current?.children[
                        index
                      ]?.getBoundingClientRect().height ||
                      0 - (index === 0 ? 0.5 : 0)
                    }px`}
                  >
                    {row.getRightVisibleCells().map((cell) => (
                      <Td
                        key={cell.id}
                        cursor={onRowClick ? `pointer` : `default`}
                        bg={index % 2 ? `grey.25` : `white`}
                        px={2}
                        width={stickyColumnWidth}
                      >
                        <HStack
                          textStyle="colfax-16-regular"
                          justifyContent="center"
                          alignItems="center"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </HStack>
                      </Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
            </Box>
          </ChakraTable>
        </Box>
      )}
    </HStack>
  );
}

function TableHeader({
  tabs,
  children,
}: {
  tabs?: ReactNode;
  children?: ReactNode;
}) {
  return (
    <>
      {tabs}
      {children && (
        <Box
          w="full"
          bg="white"
          p={4}
          borderTopRightRadius={8}
          borderTopLeftRadius={tabs ? 0 : 8}
        >
          {children}
        </Box>
      )}
    </>
  );
}

function TableFooter({
  pagination,
  children,
}: {
  pagination?: ReactNode;
  children?: ReactNode;
}) {
  return (
    <>
      {pagination}
      {children}
    </>
  );
}

function Table<
  TColumn extends Record<string, unknown>[],
  TData extends Record<string, unknown>[] = TColumn,
>({
  data,
  columns,
  tabs,
  header,
  footer,
  pagination,
  onRowClick,
  renderRowActions,
  onSort,
  sorting,
  setSorting,
  ...tableStyleProps
}: TableProps<TColumn, TData>) {
  return (
    <Box w="full">
      <TableHeader tabs={tabs}>{header}</TableHeader>
      <TableContent
        data={data}
        columns={columns}
        onRowClick={onRowClick}
        renderRowActions={renderRowActions}
        onSort={onSort}
        sorting={sorting}
        setSorting={setSorting}
        {...tableStyleProps}
      />
      <TableFooter pagination={pagination}>{footer}</TableFooter>
    </Box>
  );
}

export default Table;
