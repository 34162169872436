import { transactionTransferMethodToi18nKeys } from "@/features/Transactions";
import { useCallback } from "react";
import {
  ProposedTransactionModificationCardTransactionFragment,
  ProposedTransactionModificationCardTransactionModificationAcknowledgmentFragment,
  ProposedTransactionModificationCardTransactionModificationFragment,
  TransactionDetailFragment,
  TransactionModificationAcknowledgmentStatus,
  useCancelPendingTransactionModificationMutation,
  useManuallyApprovePendingTransactionModificationMutation,
} from "@/graphql";
import { FeeBreakdown } from "@/modules/FeeBreakdown";
import { ConfirmModal } from "@/modules/Modal";
import {
  DateTimeFormat,
  formatCurrencyCents,
  formatDate,
  formatShares,
} from "@/modules/NumeralFormat";
import { useColors } from "@/modules/Theme";
import { useCustomToast } from "@/modules/Toast";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Text,
  VStack,
  useDisclosure,
  Divider,
} from "@chakra-ui/react";
import { CheckCircle, WarningCircle } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import MuteNotificationWithReasonSection, {
  muteNotificationValidationSchema,
  MuteNotificationReasonType,
} from "./MuteNotificationWithReasonSection";

function CancelTransactionModificationModal({
  isOpen,
  onClose,
  onSuccess,
  transaction,
}: {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly onSuccess?: () => void;
  readonly transaction: ProposedTransactionModificationCardTransactionFragment;
}) {
  const {
    transferMethod,
    pendingModification,
    id: transactionId,
    bid: { displayId, company },
  } = transaction;
  const { t } = useTranslation();
  const [, cancelPendingTransactionModification] =
    useCancelPendingTransactionModificationMutation();

  const { successToast, errorToast } = useCustomToast();
  const {
    handleSubmit,
    register,
    resetField,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(muteNotificationValidationSchema),
    defaultValues: {
      muteNotificationReason: null,
    },
  });

  const handleConfirm: SubmitHandler<MuteNotificationReasonType> = useCallback(
    async (data) => {
      const response = await cancelPendingTransactionModification({
        transactionId,
        muteNotificationReason: data.muteNotificationReason,
      });

      if (response.error) {
        errorToast();
        return;
      }

      onSuccess?.();
      successToast(t(`transaction_modification_cancelled`));
    },
    [
      transactionId,
      cancelPendingTransactionModification,
      errorToast,
      successToast,
      onSuccess,
      t,
    ],
  );

  if (!pendingModification) {
    onClose();
    return null;
  }

  const {
    numShares: pendingNumberShares,
    pricePerShare: pendingPricePerShare,
  } = pendingModification;

  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={onClose}
      title={t(`cancel_transaction_modification_modal_title`)}
      onConfirm={handleSubmit(handleConfirm)}
      body={
        <VStack spacing={6}>
          <Text>
            {t(`cancel_transaction_modification_modal_description_line_1`, {
              displayId,
            })}
          </Text>
          <Text>
            {t(`cancel_transaction_modification_modal_description_line_2`, {
              numShares: formatShares(pendingNumberShares),
              companyName: company?.name || ``,
              pricePerShare: formatCurrencyCents(pendingPricePerShare),
              transferMethod: t(
                transactionTransferMethodToi18nKeys(transferMethod),
              ),
            })}
          </Text>
          <Divider borderColor="grey.200" borderWidth="1" />
          <MuteNotificationWithReasonSection
            name="muteNotificationReason"
            setValue={setValue}
            error={errors.muteNotificationReason}
            register={register}
            resetField={resetField}
          />
        </VStack>
      }
    />
  );
}

function ManuallyApproveTransactionModificationModal({
  isOpen,
  onClose,
  onSuccess,
  transaction,
}: {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly onSuccess?: () => void;
  readonly transaction: TransactionDetailFragment;
}) {
  const [, manuallyApprovePendingTransactionModification] =
    useManuallyApprovePendingTransactionModificationMutation();
  const { t } = useTranslation();
  const { successToast, errorToast } = useCustomToast();

  const {
    handleSubmit,
    register,
    resetField,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(muteNotificationValidationSchema),
    defaultValues: {
      muteNotificationReason: null,
    },
  });

  const {
    bid: { displayId, company },
    pendingModification,
    id: transactionId,
    transferMethod,
  } = transaction;

  const onConfirm: SubmitHandler<MuteNotificationReasonType> = useCallback(
    async (data) => {
      const response = await manuallyApprovePendingTransactionModification({
        transactionId,
        input: {
          muteNotificationReason: data.muteNotificationReason,
        },
      });

      if (response.error) {
        errorToast();
        return;
      }

      onSuccess?.();
      successToast(t(`transaction_modification_approved_success_toast`));
    },
    [
      transactionId,
      manuallyApprovePendingTransactionModification,
      errorToast,
      successToast,
      onSuccess,
      t,
    ],
  );

  if (!pendingModification) {
    onClose();
    return null;
  }

  const {
    numShares: pendingNumberShares,
    pricePerShare: pendingPricePerShare,
  } = pendingModification;

  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={onClose}
      title={t(`manually_approve_modification_modal_title`)}
      onConfirm={handleSubmit(onConfirm)}
      body={
        <VStack spacing={6}>
          <Text>
            {t(`manually_approve_modification_modal_description_line_1`, {
              displayId,
            })}
          </Text>
          <Text>
            {t(`manually_approve_modification_modal_description_line_2`, {
              numShares: formatShares(pendingNumberShares),
              companyName: company?.name || ``,
              pricePerShare: formatCurrencyCents(pendingPricePerShare),
              transferMethod: t(
                transactionTransferMethodToi18nKeys(transferMethod),
              ),
            })}
          </Text>
          <Divider borderColor="grey.200" borderWidth="1" />
          <MuteNotificationWithReasonSection
            name="muteNotificationReason"
            setValue={setValue}
            error={errors.muteNotificationReason}
            register={register}
            resetField={resetField}
          />
        </VStack>
      }
    />
  );
}

function ProposedTransactionModificationCardAcknowledgmentStatus({
  transaction,
  acknowledgment,
}: {
  readonly transaction: ProposedTransactionModificationCardTransactionFragment;
  readonly acknowledgment: ProposedTransactionModificationCardTransactionModificationAcknowledgmentFragment;
}) {
  const { t } = useTranslation();

  const [grey200, grey800] = useColors([`grey.200`, `grey.800`]);

  const partyName = match(acknowledgment.userId)
    .with(transaction.buyerId, () => t(`buyer`))
    .with(transaction.sellerId, () => t(`seller`))
    .with(transaction.buyerBrokerId || ``, () => t(`buyer`))
    .with(transaction.sellerBrokerId || ``, () => t(`seller`))
    .otherwise(() => {
      throw new Error(
        `Unknown party in Transaction Modification Acknowledgment ${acknowledgment}`,
      );
    });

  const formattedUpdatedAt = formatDate(
    acknowledgment.updatedAt,
    DateTimeFormat.shortDateWithTime,
  );

  const statusText = match(acknowledgment.status)
    .with(
      TransactionModificationAcknowledgmentStatus.Approved,
      () => formattedUpdatedAt,
    )
    .with(TransactionModificationAcknowledgmentStatus.Rejected, () =>
      t(`rejected`),
    )
    .with(TransactionModificationAcknowledgmentStatus.Pending, () =>
      t(`pending`),
    )
    .otherwise(() => {
      throw new Error(
        `Unknown status in Transaction Modification Acknowledgment ${acknowledgment}`,
      );
    });

  const statusIconColor = match(acknowledgment.status)
    .with(TransactionModificationAcknowledgmentStatus.Approved, () => grey800)
    .otherwise(() => grey200);

  const statusTextColor = match(acknowledgment.status)
    .with(
      TransactionModificationAcknowledgmentStatus.Approved,
      () => `grey.800`,
    )
    .otherwise(() => `grey.500`);

  return (
    <HStack w="full" justifyContent="space-between">
      <HStack>
        <CheckCircle weight="fill" color={statusIconColor} />
        <Text textStyle="heading-xs" color={statusTextColor}>
          {t(`party_approval`, {
            partyName,
          })}
        </Text>
      </HStack>
      <Text textStyle="text-xs">{statusText}</Text>
    </HStack>
  );
}

export default function ProposedTransactionModificationCard({
  transaction,
  modification,
  onSuccess,
}: {
  readonly transaction: TransactionDetailFragment;
  readonly modification: ProposedTransactionModificationCardTransactionModificationFragment;
  readonly onSuccess?: () => void;
}) {
  const {
    numShares,
    pricePerShare,
    feeDiscountApplications,
    commission,
    reason,
    acknowledgments,
  } = modification;

  const { t } = useTranslation();

  const [red600] = useColors([`red.600`]);

  const {
    isOpen: isCancelTransactionModificationModalOpen,
    onOpen: onOpenCancelTransactionModificationModal,
    onClose: onCloseCancelTransactionModificationModal,
  } = useDisclosure();

  const {
    isOpen: isManuallyApproveTransactionModificationModalOpen,
    onOpen: onOpenManuallyApproveTransactionModificationModal,
    onClose: onCloseManuallyApproveTransactionModificationModal,
  } = useDisclosure();

  const handleCancel = () => {
    onOpenCancelTransactionModificationModal();
  };

  const handleManuallyApprove = () => {
    onOpenManuallyApproveTransactionModificationModal();
  };

  return (
    <>
      <Card w="full">
        <CardHeader>
          <HStack>
            <WarningCircle size={20} weight="fill" color={red600} />
            <Text textStyle="colfax-22-medium">
              {t(`proposed_modification`)}
            </Text>
          </HStack>
        </CardHeader>
        <CardBody>
          <FeeBreakdown
            numSharesActual={numShares}
            pricePerShare={pricePerShare}
            feeDiscountApplications={feeDiscountApplications}
            commission={commission}
            variant="teal"
          />
        </CardBody>
        <CardBody>
          {!!reason && (
            <Text textStyle="colfax-14-regular" mb={4}>
              {reason}
            </Text>
          )}
          <Card variant="grey">
            <CardBody>
              <VStack>
                {acknowledgments.map((acknowledgment) => (
                  <ProposedTransactionModificationCardAcknowledgmentStatus
                    key={acknowledgment.id}
                    transaction={transaction}
                    acknowledgment={acknowledgment}
                  />
                ))}
              </VStack>
            </CardBody>
          </Card>
        </CardBody>
        <CardBody>
          <HStack>
            <Button
              onClick={handleCancel}
              flex={1}
              variant="destructive"
              size="lg"
            >
              {t(`cancel`)}
            </Button>
            <Button
              onClick={handleManuallyApprove}
              flex={1}
              variant="solid"
              size="lg"
            >
              {t(`manually_approve`)}
            </Button>
          </HStack>
        </CardBody>
      </Card>
      <CancelTransactionModificationModal
        isOpen={isCancelTransactionModificationModalOpen}
        onClose={onCloseCancelTransactionModificationModal}
        onSuccess={onSuccess}
        transaction={transaction}
      />
      <ManuallyApproveTransactionModificationModal
        isOpen={isManuallyApproveTransactionModificationModalOpen}
        onClose={onCloseManuallyApproveTransactionModificationModal}
        onSuccess={onSuccess}
        transaction={transaction}
      />
    </>
  );
}
