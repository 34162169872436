import { useTranslation } from "react-i18next";

import { FeeDiscountPageFragment } from "@/graphql";
import { useFeeDiscountArchiveDiscountEnabledFlag } from "@/modules/LaunchDarkly";
import { formatToLocalTimezone } from "@/modules/NumeralFormat";
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Text,
  VStack,
} from "@chakra-ui/react";

interface FeeDiscountPageRightPanelProps {
  readonly archivedAt?: FeeDiscountPageFragment["archivedAt"];
  readonly archivedBy: FeeDiscountPageFragment["archivedBy"];
  readonly createdBy: NonNullable<FeeDiscountPageFragment["createdBy"]>;
  readonly insertedAt: FeeDiscountPageFragment["insertedAt"];
}

export default function FeeDiscountPageRightPanel({
  archivedAt,
  archivedBy,
  createdBy,
  insertedAt,
}: FeeDiscountPageRightPanelProps) {
  const { t } = useTranslation();

  const feeDiscountArchiveDiscounEnabled =
    useFeeDiscountArchiveDiscountEnabledFlag();

  const showArchivedBy = feeDiscountArchiveDiscounEnabled && !!archivedAt;

  return (
    <Card w="full">
      <CardHeader textStyle="colfax-22-medium">{t(`details`)}</CardHeader>
      <CardBody>
        <VStack alignItems="flex-start">
          <Box>
            <Text fontSize={14} textTransform="uppercase" fontWeight={500}>
              {t(`created`)}
            </Text>
            <Text textStyle="colfax-16-regular">{`${
              createdBy.name
            } ${formatToLocalTimezone(insertedAt)}`}</Text>
          </Box>
          {showArchivedBy && (
            <Box>
              <Text fontSize={14} textTransform="uppercase" fontWeight={500}>
                {t(`archived`)}
              </Text>
              <Text textStyle="colfax-16-regular">{`${
                !!archivedBy ? `${archivedBy.name} ` : ``
              }${formatToLocalTimezone(archivedAt)}`}</Text>
            </Box>
          )}
        </VStack>
      </CardBody>
    </Card>
  );
}
