import { useTranslation } from "react-i18next";

import { Input } from "@/modules/Form";
import { DetailPageLayout } from "@/modules/Layout";
import { Skeleton } from "@/modules/Skeleton";
import { Table } from "@/modules/Table";
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Menu,
  Text,
} from "@chakra-ui/react";

import { CompanyPageNavMenuSkeleton } from "@/features/Company";

export default function CompanyManagedMarketPageSkeleton() {
  const { t } = useTranslation();

  return (
    <DetailPageLayout
      isTwoColumn
      mainPanel={
        <Card w="full">
          <CardHeader as={HStack} gap={4} alignItems="center">
            <Text textStyle="colfax-22-medium">{t(`seller_eligibility`)}</Text>
            <Box flexGrow={1} />
            <Menu>
              <Table.ActionButton showTitle />
            </Menu>
          </CardHeader>
          <CardBody>
            <Skeleton h={5} w="full" />
          </CardBody>
        </Card>
      }
      rightPanel={
        <Card w="full">
          <CardHeader>
            <Text textStyle="colfax-22-medium">{t(`issuer_disclosure`)}</Text>
          </CardHeader>
          <CardBody>
            <Input.Switch
              formControlProps={{ display: `flex` }}
              formLabelProps={{ color: `grey.900`, textTransform: `none` }}
              label={t(`issuer_disclosure_switch_label`)}
              name="issuerDisclosure"
              onChange={() => {}}
            />
          </CardBody>
        </Card>
      }
      tabMenu={<CompanyPageNavMenuSkeleton />}
    />
  );
}
