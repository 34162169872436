import { match } from "ts-pattern";

import { InvestorType } from "@/graphql";

import StatusEmpty from "./StatusEmpty";
import StatusWithIcon, { StatusWithIconProps } from "./StatusWithIcon";

interface StatusSuitabilityProps extends StatusWithIconProps {
  isSuitable: boolean | null | undefined;
  investorType: InvestorType | null | undefined;
}

export default function StatusSuitability({
  isSuitable,
  investorType,
  ...restProps
}: StatusSuitabilityProps) {
  return match({ isSuitable, investorType })
    .with({ investorType: InvestorType.UnaccreditedSeller }, () => (
      <StatusEmpty {...restProps} />
    ))
    .with({ investorType: InvestorType.Broker }, () => (
      <StatusEmpty {...restProps} />
    ))
    .with({ isSuitable: true }, () => (
      <StatusWithIcon color="#323232" {...restProps} />
    ))
    .with({ isSuitable: false }, () => (
      <StatusWithIcon color="#D5D5D5" {...restProps} />
    ))
    .otherwise(() => <StatusWithIcon color="#D5D5D5" {...restProps} />);
}
