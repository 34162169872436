import { useTranslation } from "react-i18next";

import {
  FeeDiscountApplicationFragment,
  TransactionDetailFragment,
} from "@/graphql";
import { Card, CardBody, CardHeader, HStack, Text } from "@chakra-ui/react";

import { FeeBreakdown } from "@/modules/FeeBreakdown";

interface TransactionsProceedsInfoProps {
  displayId?: string;
  feeDiscountApplications: FeeDiscountApplicationFragment[];
  transaction?: TransactionDetailFragment;
}

export function TransactionsProceedsInfo({
  displayId,
  feeDiscountApplications,
  transaction,
}: TransactionsProceedsInfoProps) {
  const { t } = useTranslation();

  if (!transaction) {
    return null;
  }

  const { numShares, pricePerShare, commission } = transaction;

  return (
    <Card w="full">
      <CardHeader as={HStack} gap={4} alignItems="center">
        <Text textStyle="colfax-22-medium">
          {`${t(`proceeds_for_transaction`)} ${displayId}`}
        </Text>
      </CardHeader>

      <CardBody>
        <FeeBreakdown
          numSharesActual={numShares}
          pricePerShare={pricePerShare}
          feeDiscountApplications={feeDiscountApplications}
          commission={commission}
        />
      </CardBody>
    </Card>
  );
}
