import { HStack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export default function ModernTreasuryLogo() {
  const { t } = useTranslation();

  return (
    <HStack w="full" justifyContent="center">
      <Text color="grey.400" fontSize={10}>
        {t(`powered_by`)}
      </Text>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100"
        height="15"
        viewBox="0 0 229 20"
        fill="none"
      >
        <path d="M10 6.40625L20 3.125V0H0V3.125L10 6.40625Z" fill="#00835A" />
        <path d="M0 6.09375V20H4.6875V7.65625L0 6.09375Z" fill="#00835A" />
        <path d="M20 6.09375V20H15.3125V7.65625L20 6.09375Z" fill="#00835A" />
        <path
          d="M12.3438 8.59375L11.2649 8.95336C10.7956 9.1098 10.5609 9.18802 10.3209 9.21909C10.1078 9.24666 9.89216 9.24666 9.67914 9.21909C9.43907 9.18802 9.20441 9.1098 8.73509 8.95336L7.65625 8.59375V20H12.3438V8.59375Z"
          fill="#00835A"
        />
        <path
          d="M80.4814 17.2567H89.6334V14.4419H83.4924V11.3069H88.4022V8.49453H83.4924V5.58787H89.6334V2.77801H80.4814V17.2567Z"
          fill="#00835A"
        />
        <path
          d="M151.664 17.2567H160.816V14.4419H154.675V11.3069H159.585V8.49453H154.675V5.58787H160.816V2.77801H151.664V17.2567Z"
          fill="#00835A"
        />
        <path
          d="M42.5087 13.0544L38.9095 2.78544H35V17.2543H37.8421V7.46691L41.3172 17.2592H43.6828L47.0611 7.49918V17.2543H49.9404V2.78049H46.1501L42.5087 13.0544Z"
          fill="#00835A"
        />
        <path
          d="M103.236 7.72754V7.10698C103.236 4.2301 101.325 2.77552 98.768 2.77552H92.5625V17.2468H95.566V12.2476H97.7652L100.247 17.2567H103.581L100.677 11.7016C102.524 11.0562 103.236 9.66862 103.236 7.72754ZM100.228 7.68781C100.228 8.94381 99.4829 9.42536 98.5124 9.42536H95.5337V5.58787H98.5124C99.4829 5.58787 100.228 6.08431 100.228 7.39491V7.68781Z"
          fill="#00835A"
        />
        <path
          d="M148.931 7.72754V7.10698C148.931 4.2301 147.017 2.77552 144.463 2.77552H138.257V17.2468H141.261V12.2476H143.458L145.94 17.2567H149.283L146.387 11.7016C148.219 11.0562 148.931 9.66862 148.931 7.72754ZM145.922 7.68781C145.922 8.94381 145.178 9.42536 144.207 9.42536H141.229V5.58787H144.207C145.178 5.58787 145.922 6.08431 145.922 7.39491V7.68781Z"
          fill="#00835A"
        />
        <path
          d="M58.4369 2.57695C55.1356 2.57695 52.7104 5.00952 52.7104 8.6261V11.3938C52.7104 15.0128 55.1058 17.4702 58.4369 17.4702C61.768 17.4702 64.2229 15.0128 64.2229 11.3938V8.6261C64.2229 4.99959 61.7358 2.57695 58.4369 2.57695ZM61.2095 11.4236C61.2095 13.5285 59.9386 14.6504 58.4295 14.6504C56.9203 14.6504 55.7239 13.5533 55.7239 11.4236V8.67823C55.7239 6.54353 56.9079 5.41412 58.4295 5.41412C59.9511 5.41412 61.2095 6.54849 61.2095 8.67823V11.4236Z"
          fill="#00835A"
        />
        <path
          d="M71.9253 2.73828H66.7474V17.2567H71.9253C75.254 17.2567 77.7461 14.7993 77.7461 11.1803V8.78991C77.7461 5.16589 75.2838 2.73828 71.9253 2.73828ZM74.6682 11.1356C74.6682 13.2405 73.3998 14.3625 71.8757 14.3625H69.7261V5.57793H71.8831C73.4072 5.57793 74.6756 6.70982 74.6756 8.80481L74.6682 11.1356Z"
          fill="#00835A"
        />
        <path
          d="M214.592 7.72754V7.10698C214.592 4.2301 212.681 2.77552 210.124 2.77552H203.919V17.2468H206.917V12.2476H209.114L211.596 17.2567H214.94L212.04 11.7016C213.867 11.0562 214.592 9.66862 214.592 7.72754ZM211.581 7.68781C211.581 8.94381 210.837 9.42536 209.869 9.42536H206.89V5.58787H209.869C210.842 5.58787 211.581 6.08431 211.581 7.39491V7.68781Z"
          fill="#00835A"
        />
        <path
          d="M197.942 11.6196C197.942 13.7543 196.924 14.6678 195.447 14.6678C193.97 14.6678 192.992 13.7593 192.992 11.6196V2.78298H189.989V11.6196C189.989 15.2387 192.19 17.4603 195.449 17.4603C198.709 17.4603 200.96 15.2213 200.96 11.6196V2.78298H197.942V11.6196Z"
          fill="#00835A"
        />
        <path
          d="M167.564 2.75319L162.416 17.2567H165.422L166.393 14.3749H171.752L172.712 17.2195H175.837L170.796 2.74574L167.564 2.75319ZM167.316 11.6668L169.163 6.25062L170.9 11.6792L167.316 11.6668Z"
          fill="#00835A"
        />
        <path
          d="M225.519 2.78545L222.193 9.04559L218.966 2.78545H215.657L220.649 11.8679V17.2617H223.652V11.9249L228.808 2.78545H225.519Z"
          fill="#00835A"
        />
        <path
          d="M114.577 11.8902L109.526 2.77801H106.138V17.2567H109.077V7.39988L114.644 17.2543H117.506V2.77801H114.577V11.8902Z"
          fill="#00835A"
        />
        <path
          d="M124.893 5.5829H128.974V17.2493H131.98V5.5829H136.051V2.78049H124.888L124.893 5.5829Z"
          fill="#00835A"
        />
        <path
          d="M182.259 17.5025C178.99 17.5025 176.736 16.0802 176.736 12.9749V12.5505H179.673V12.9029C179.673 14.5089 180.914 14.916 182.185 14.916C183.535 14.916 184.57 14.5089 184.57 13.3075C184.57 12.1062 183.019 11.6296 181.095 11.0463C179.442 10.5498 177.009 9.71827 177.009 6.74955C177.009 3.8677 179.208 2.5 182.239 2.5C184.721 2.5 187.283 3.60955 187.283 6.71976V7.03501H184.404V6.83146C184.404 5.74177 183.552 5.09391 182.259 5.09391C181.077 5.09391 180.097 5.42652 180.097 6.4616C180.097 7.86653 181.877 8.11724 183.522 8.63354C185.168 9.14984 187.618 10.0261 187.618 12.8732C187.618 16.2117 185.322 17.5025 182.259 17.5025Z"
          fill="#00835A"
        />
      </svg>
    </HStack>
  );
}
