import { CardTable } from "@/modules/CardTable";
import { useManagedMarkets } from "@/modules/LaunchDarkly";
import { DetailPageLayout } from "@/modules/Layout";
import { Skeleton } from "@/modules/Skeleton";
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";

import CompanyPageNavMenuSkeleton from "./CompanyPageNavMenuSkeleton";

export default function CompanyPageSkeleton() {
  const managedMarketsEnabled = useManagedMarkets();

  return (
    <DetailPageLayout
      isTwoColumn
      mainPanel={
        <Card w="full">
          <CardHeader as={HStack} gap={4} alignItems="center">
            <Skeleton w={7} h={7} />
            <Skeleton w={240} h="22px" />
            <Box flexGrow={1} />
            <HStack>
              <>
                <Skeleton w={90} h={3} />
                <Text color="grey.300" mx={0.5}>
                  |
                </Text>
              </>
              <Skeleton w={90} h={3} />
              <Skeleton w={24} h={9} ml={2} />
            </HStack>
          </CardHeader>
          <CardBody>
            <Skeleton w={220} h={4} />
            <Skeleton w="80%" h="16px" mt={2} />
            <VStack rowGap={6} my={6} alignItems="baseline" mt={10}>
              <CardTable w="full" columns={3}>
                <Card variant="table">
                  <CardHeader>
                    <Skeleton h={3} my={2} w={200} />
                  </CardHeader>
                  <CardBody>
                    <Skeleton w={100} h={3} />
                  </CardBody>
                </Card>
                <Card variant="table">
                  <CardHeader>
                    <Skeleton h={3} my={2} w={200} />
                  </CardHeader>
                  <CardBody>
                    <Skeleton w={100} h={3} />
                  </CardBody>
                </Card>
                <Card variant="table">
                  <CardHeader>
                    <Skeleton h={3} my={2} w={200} />
                  </CardHeader>
                  <CardBody>
                    <Skeleton w={100} h={3} />
                  </CardBody>
                </Card>
              </CardTable>
              <CardTable w="full" columns={3}>
                <Card variant="table">
                  <CardHeader>
                    <Skeleton h={3} my={2} w={200} />
                  </CardHeader>
                  <CardBody>
                    <Skeleton w={100} h={3} />
                  </CardBody>
                </Card>
                <Card variant="table">
                  <CardHeader>
                    <Skeleton h={3} my={2} w={200} />
                  </CardHeader>
                  <CardBody>
                    <Skeleton w={100} h={3} />
                  </CardBody>
                </Card>

                <Card variant="table">
                  <CardHeader>
                    <Skeleton h={3} my={2} w={200} />
                  </CardHeader>
                  <CardBody>
                    <Skeleton w={100} h={3} />
                  </CardBody>
                </Card>
              </CardTable>
            </VStack>
          </CardBody>
        </Card>
      }
      rightPanel={
        <Card w="full">
          <CardHeader>
            <Skeleton h={8} w={20} />
          </CardHeader>
          <CardBody>
            <VStack alignItems="flex-start">
              <Skeleton h={3} w={80} />
            </VStack>
          </CardBody>
        </Card>
      }
      tabMenu={managedMarketsEnabled && <CompanyPageNavMenuSkeleton />}
    />
  );
}
