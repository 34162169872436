import { useCallback, useEffect, useState } from "react";
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import { formatFeeDiscountType } from "@/features/FeeDiscount";
import {
  FeeDiscountAutoNamingValidationSchema,
  FeeDiscountFormInputs,
  FeeDiscountIcon,
  FeeDiscountPreviewAndAutoNamingValidationSchema,
  FeeDiscountPreviewValidationSchema,
  ValidationSchema,
} from "@/features/FeeDiscountForm";
import { CommissionInfoValidationError, FeeDiscountType } from "@/graphql";
import { useDebounce } from "@/modules/Debounce";
import { FeeBreakdownPreview } from "@/modules/FeeBreakdown";
import {
  FeeDiscountEntity,
  useApplyOneTimeFeeDiscount,
} from "@/modules/FeeDiscountsCard";
import {
  FormLabelPosition,
  Input,
  InputWrapper,
  useYupValidationResolver,
} from "@/modules/Form";
import {
  useFeeDiscountAutoNaming,
  useFeeDiscountPreview,
} from "@/modules/LaunchDarkly";
import { ConfirmModal } from "@/modules/Modal";
import { formatStringToNumber } from "@/modules/NumeralFormat";
import { useCustomToast } from "@/modules/Toast";
import { Text, Textarea, VStack } from "@chakra-ui/react";

function mapFeeDiscountValue(value: string) {
  const numericValue = formatStringToNumber(value);
  return Math.round(numericValue * 100);
}

interface ApplyOneTimeFeeDiscountModalBodyProps {
  readonly entity: FeeDiscountEntity;
}

function ApplyOneTimeFeeDiscountModalBody({
  entity,
}: ApplyOneTimeFeeDiscountModalBodyProps) {
  const {
    clearErrors,
    control,
    formState: { errors },
    register,
    setError,
    setValue,
  } = useFormContext<FeeDiscountFormInputs>();
  const { t } = useTranslation();
  const feeDiscountPreviewEnabled = useFeeDiscountPreview();
  const feeDiscountAutoNamingEnabled = useFeeDiscountAutoNaming();
  const { name, type, value } = useWatch({ control });
  const { debounce, cancel } = useDebounce(500);
  const [debouncedValue, setDebouncedValue] = useState<number | undefined>();

  const feeDiscountTypes = Object.values(FeeDiscountType).map((type) => ({
    label: formatFeeDiscountType(type),
    value: type,
  }));

  const handleError = useCallback(
    (error: CommissionInfoValidationError) => {
      const { message } = error;
      setError(`value`, { message });
    },
    [setError],
  );

  useEffect(() => {
    debounce(() => {
      if (typeof value === `string`) {
        const numericValue =
          type === FeeDiscountType.FlatFee
            ? mapFeeDiscountValue(value)
            : formatStringToNumber(value);
        setDebouncedValue(numericValue);
      }
    });
    return cancel;
  }, [cancel, debounce, value, type]);

  useEffect(() => {
    clearErrors();
  }, [clearErrors]);

  const typeRegister = register(`type`);

  const decimalScale = type === FeeDiscountType.FlatFee ? 2 : 9;

  return (
    <VStack gap={4} alignItems="flex-start" w="full">
      <Text>{t(`create_one_time_fee_discount_description`)}</Text>
      {!feeDiscountAutoNamingEnabled && (
        <Input.Generic
          error={errors.name}
          formLabelPosition={FormLabelPosition.column}
          label={t(`fee_discount_name`)}
          helperText={t(`fee_discount_name_sub_label`)}
          {...register(`name`)}
        />
      )}
      <Input.Select
        label={t(`fee_discount_type`)}
        options={feeDiscountTypes}
        {...typeRegister}
        onChange={(e) => {
          setDebouncedValue(undefined);
          setValue(`value`, `` as unknown as number);
          return typeRegister.onChange(e);
        }}
      />
      <Controller
        name="value"
        control={control}
        render={({ field }) => (
          <Input.DecimalWithSeparator
            decimalScale={decimalScale}
            error={errors.value}
            field={field}
            iconLeft={
              <FeeDiscountIcon feeDiscountType={type as FeeDiscountType} />
            }
            label={t(`value`)}
            name="value"
          />
        )}
      />
      <InputWrapper
        name="description"
        formLabelPosition={FormLabelPosition.column}
        label={t(`fee_discount_description`)}
        helperText={t(`fee_discount_description_sub_label`)}
        error={errors.description}
      >
        <Textarea maxLength={255} {...register(`description`)} />
      </InputWrapper>
      {feeDiscountPreviewEnabled && (
        <FeeBreakdownPreview
          entity={entity}
          feeDiscountName={name}
          feeDiscountType={type as FeeDiscountType}
          feeDiscountValue={debouncedValue}
          onError={handleError}
        />
      )}
    </VStack>
  );
}

interface ApplyFeeDiscountModalProps {
  readonly entity: FeeDiscountEntity;
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly onSuccess?: () => void;
}

export function ApplyOneTimeFeeDiscountModal({
  entity,
  isOpen,
  onClose,
  onSuccess,
}: ApplyFeeDiscountModalProps) {
  const { t } = useTranslation();
  const feeDiscountPreviewEnabled = useFeeDiscountPreview();
  const feeDiscountAutoNamingEnabled = useFeeDiscountAutoNaming();

  const schema = match([
    feeDiscountAutoNamingEnabled,
    feeDiscountPreviewEnabled,
  ])
    .with([true, true], () => FeeDiscountPreviewAndAutoNamingValidationSchema)
    .with([true, false], () => FeeDiscountAutoNamingValidationSchema)
    .with([false, true], () => FeeDiscountPreviewValidationSchema)
    .otherwise(() => ValidationSchema);

  const resolver = useYupValidationResolver(schema);

  const methods = useForm<FeeDiscountFormInputs>({
    resolver,
    mode: `onChange`,
  });

  const {
    formState: { isSubmitting },
    handleSubmit,
    setError,
  } = methods;

  const { fetching, applyOneTimeFeeDiscount } = useApplyOneTimeFeeDiscount();
  const { successToast } = useCustomToast();

  const onConfirm = (data: FeeDiscountFormInputs) =>
    applyOneTimeFeeDiscount({
      data: { ...data, entity },
      onSuccess: () => {
        successToast(t(`fee_discount_created_and_applied`));
        onSuccess?.();
        onClose();
      },
      onFailure: (message) => setError(`value`, { message }),
    });

  return (
    <ConfirmModal
      title={t(`create_fee_discount`)}
      body={
        <FormProvider {...methods}>
          <ApplyOneTimeFeeDiscountModalBody entity={entity} />
        </FormProvider>
      }
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleSubmit(onConfirm)}
      disableConfirm={isSubmitting || fetching}
      {...(feeDiscountPreviewEnabled && { size: `2xl` })}
    />
  );
}
