import { Button } from "@chakra-ui/react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export default function ClearFiltersButton({
  resetFilters,
}: {
  resetFilters: () => void;
}) {
  const { t } = useTranslation();

  const onClearFilters = useCallback(() => {
    resetFilters();
  }, [resetFilters]);

  return (
    <Button variant="link" onClick={onClearFilters}>
      {t(`clear_filters`)}
    </Button>
  );
}
