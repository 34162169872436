import { Table, TableColumns } from "@/modules/Table";
import { ReactNode, useCallback, useContext, useMemo } from "react";
import {
  CompanyApprovedBuyersTableData,
  CompanyManagedMarketContext,
} from "@/features/Company";
import { CompanyApprovedBuyerType } from "@/graphql";
import i18next from "i18next";
import { Row } from "@tanstack/react-table";
import { useRouter } from "next/router";
import { match } from "ts-pattern";
import {
  CompanyApprovedBuyerAppliedByTableCell,
  CompanyApprovedBuyerDateAppliedTableCell,
  CompanyApprovedBuyerEmailTableCell,
  CompanyApprovedBuyerNameTableCell,
  CompanyApprovedBuyerTypeTableCell,
} from "./CompanyApprovedBuyersTableCells";
import { CompanyApprovedBuyersTableRowActions } from "./CompanyApprovedBuyersTableRowActions";

type CompanyApprovedBuyersTableColumn = Record<
  "name" | "type" | "email" | "appliedBy" | "dateApplied",
  ReactNode
>;

const columns: TableColumns<
  CompanyApprovedBuyersTableColumn,
  CompanyApprovedBuyersTableData
> = {
  name: {
    header: () => i18next.t(`name`),
    cell: ({ cell }) => <CompanyApprovedBuyerNameTableCell cell={cell} />,
    enableSorting: false,
  },
  type: {
    header: () => i18next.t(`type`),
    cell: ({ cell }) => <CompanyApprovedBuyerTypeTableCell cell={cell} />,
    enableSorting: false,
  },
  email: {
    header: () => i18next.t(`email`),
    cell: ({ cell }) => <CompanyApprovedBuyerEmailTableCell cell={cell} />,
    enableSorting: false,
  },
  appliedBy: {
    header: () => i18next.t(`applied_by`),
    cell: ({ cell }) => <CompanyApprovedBuyerAppliedByTableCell cell={cell} />,
    enableSorting: false,
  },
  dateApplied: {
    header: () => i18next.t(`date_applied`),
    cell: ({ cell }) => (
      <CompanyApprovedBuyerDateAppliedTableCell cell={cell} />
    ),
    enableSorting: false,
  },
};

export function CompanyApprovedBuyersTable() {
  const { push } = useRouter();
  const { managedMarketCompany } = useContext(CompanyManagedMarketContext);

  const companyApprovedBuyersTableData = useMemo(
    () =>
      (managedMarketCompany?.companyApprovedBuyers ?? [])
        .map((companyApprovedBuyer) => {
          const { user, institution } = companyApprovedBuyer;
          if (user) {
            return {
              ...companyApprovedBuyer,
              type: CompanyApprovedBuyerType.User,
            };
          }
          if (institution) {
            return {
              ...companyApprovedBuyer,
              type: CompanyApprovedBuyerType.Institution,
            };
          }
          return null;
        })
        .filter((companyApprovedBuyer) => companyApprovedBuyer != null),
    [managedMarketCompany],
  );

  const onRowClick = useCallback(
    (tableRow: Row<CompanyApprovedBuyersTableData>) => {
      const { type, user, institution } = tableRow.original;
      return match(type)
        .with(CompanyApprovedBuyerType.User, () => push(`/users/${user?.id}`))
        .with(CompanyApprovedBuyerType.Institution, () =>
          push(`/institutions/${institution?.id}`),
        )
        .exhaustive();
    },
    [push],
  );

  const renderRowActions = useCallback(
    (row: Row<CompanyApprovedBuyersTableData>) => (
      <CompanyApprovedBuyersTableRowActions
        companyApprovedBuyer={row.original}
      />
    ),
    [],
  );

  return (
    <Table<CompanyApprovedBuyersTableColumn[], CompanyApprovedBuyersTableData[]>
      columns={columns}
      data={companyApprovedBuyersTableData}
      borderColor="grey.100"
      borderStyle="solid"
      borderBottomWidth={1}
      borderLeftWidth={1}
      borderRightWidth={1}
      onRowClick={onRowClick}
      renderRowActions={renderRowActions}
    />
  );
}
