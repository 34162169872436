import { Table } from "@/modules/Table";
import {
  Menu,
  MenuList,
  MenuGroup,
  MenuItem,
  useDisclosure,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { CompanyApprovedBuyersTableData } from "@/features/Company";
import { RemoveApprovedBuyerModal } from "./CompanyApprovedBuyersModals/RemoveApprovedBuyerModal";

interface CompanyApprovedBuyersTableRowActionsProps {
  companyApprovedBuyer: CompanyApprovedBuyersTableData;
}

export function CompanyApprovedBuyersTableRowActions({
  companyApprovedBuyer,
}: CompanyApprovedBuyersTableRowActionsProps) {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Menu>
        <Table.ActionButton />
        <MenuList>
          <MenuGroup>
            <MenuItem textColor="archived" onClick={onOpen}>
              {t(`remove_approved_buyer`)}
            </MenuItem>
          </MenuGroup>
        </MenuList>
      </Menu>
      {isOpen && (
        <RemoveApprovedBuyerModal
          companyApprovedBuyer={companyApprovedBuyer}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </>
  );
}
