import { Text, VStack } from "@chakra-ui/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  CompanySearchFragment,
  ExecutionWorkflowTemplateTransferType,
  useCloneExecutionWorkflowTemplateMutation,
  useWorkflowTemplatesPageGlobalTemplatesQuery,
} from "@/graphql";

import { ConfirmModal } from "@/modules/Modal";
import { CompanySearchCombobox } from "@/modules/Company";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useRouter } from "next/router";
import { Input } from "@/modules/Form";

type CreateCompanyTemplateFormInputs = {
  readonly companyId: string;
};

type CompanyProps = Pick<CompanySearchFragment, "id" | "name">;

function CreateCompanyTemplateForm({ company }: { company?: CompanyProps }) {
  const { t } = useTranslation();

  const context = useFormContext<CreateCompanyTemplateFormInputs>();
  const {
    formState: { errors },
    register,
    watch,
    setValue,
    trigger,
  } = context;

  const companyId = watch(`companyId`);

  useEffect(() => {
    if (company) {
      setValue(`companyId`, company.id, { shouldValidate: true });
    }
  }, [company, setValue]);

  useEffect(() => {
    if (companyId) {
      // combining combobox with hook form needs manual validation trigger
      trigger(`companyId`);
    }
  }, [companyId, trigger]);

  const handleCompanySelect = (value: CompanySearchFragment) => {
    setValue(`companyId`, value.id);
  };

  if (company)
    return (
      <Input.Generic
        label={t(`company`)}
        value={company.name}
        isDisabled
        {...register(`companyId`)}
      />
    );

  return (
    <CompanySearchCombobox
      value={companyId}
      setValueCallback={handleCompanySelect}
      error={errors.companyId}
      {...register(`companyId`, { required: t(`company_required`) })}
    />
  );
}

export default function CreateCompanyTemplateModal({
  createCompanyTemplateIsOpen,
  onCloseCreateCompanyTemplate,
  company,
  refetch,
}: {
  createCompanyTemplateIsOpen: boolean;
  onCloseCreateCompanyTemplate: () => void;
  company?: CompanyProps;
  refetch?: () => void;
}) {
  const { t } = useTranslation();
  const { push } = useRouter();

  const methods = useForm<CreateCompanyTemplateFormInputs>({
    defaultValues: {
      companyId: company ? company.id : ``,
    },
  });

  const {
    formState: { isSubmitting },
    handleSubmit,
    setError,
    reset,
  } = methods;

  const [, cloneExecutionWorkflowTemplate] =
    useCloneExecutionWorkflowTemplateMutation();

  const [{ fetching, data: globalTemplatesData }] =
    useWorkflowTemplatesPageGlobalTemplatesQuery();

  if (fetching || !globalTemplatesData) return null;

  const globalTemplates =
    globalTemplatesData.listExecutionWorkflowTemplates?.edges.map(
      (edge) => edge.node,
    );

  const directTemplateId = globalTemplates?.find(
    (template) =>
      template.transferType === ExecutionWorkflowTemplateTransferType.Direct,
  )?.id;

  if (!directTemplateId) return null;

  const handleClose = () => {
    reset();
    onCloseCreateCompanyTemplate();
  };

  const onConfirm = async (data: CreateCompanyTemplateFormInputs) => {
    const { data: response } = await cloneExecutionWorkflowTemplate({
      companyId: data.companyId,
      workflowId: directTemplateId,
    });

    if (response?.cloneExecutionWorkflowTemplate.errors) {
      response.cloneExecutionWorkflowTemplate.errors.forEach(
        ({ field, message }) =>
          setError(field as keyof CreateCompanyTemplateFormInputs, { message }),
      );
      return null;
    }

    if (response?.cloneExecutionWorkflowTemplate.workflowTemplate) {
      handleClose();
      push(
        `/workflow-templates/${response?.cloneExecutionWorkflowTemplate.workflowTemplate.id}`,
      );
    }

    if (refetch) {
      refetch();
    }

    return response;
  };

  return (
    <ConfirmModal
      title={t(`create_company_template`)}
      isOpen={createCompanyTemplateIsOpen}
      onClose={handleClose}
      onConfirm={handleSubmit(onConfirm)}
      disableConfirm={isSubmitting}
      body={
        <VStack gap={4}>
          <Text>{t(`create_company_template_explainer`)}</Text>
          <FormProvider {...methods}>
            <CreateCompanyTemplateForm company={company} />
          </FormProvider>
        </VStack>
      }
    />
  );
}
