import { useTranslation } from "react-i18next";

import { CountryCombobox } from "@/modules/Country";
import {
  AddEntityInput,
  useCompanyFormOverviewCompanyCountriesQuery,
} from "@/graphql";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";

function JurisdictionOfFormationInput() {
  const { t } = useTranslation();
  const [{ data: countriesData }] =
    useCompanyFormOverviewCompanyCountriesQuery();

  const countries = useMemo(() => {
    if (countriesData && countriesData.countries) {
      const { countries: countriesList } = countriesData;
      return countriesList;
    }
    return [];
  }, [countriesData]);

  const {
    formState: { errors },
    register,
    setValue,
    watch,
  } = useFormContext<Pick<AddEntityInput, "jurisdictionOfFormationId">>();

  const { onChange, ...otherControls } = register(`jurisdictionOfFormationId`, {
    required: t(`jurisdiction_of_formation_required`),
  });

  return (
    <CountryCombobox
      error={errors.jurisdictionOfFormationId}
      onSelect={onChange}
      onChange={onChange}
      countries={countries}
      label={t(`jurisdiction_of_formation`)}
      placeholder={t(`search_country`)}
      setValue={(value) => setValue(`jurisdictionOfFormationId`, value)}
      value={watch(`jurisdictionOfFormationId`)}
      {...otherControls}
    />
  );
}

export default JurisdictionOfFormationInput;
