import {
  ExecutionTaskActorType,
  ExecutionTaskStatus,
  ExecutionTaskType,
  TransactionPageV2ExecutionTaskFragment,
  WorkflowStepConfigFragment,
} from "@/graphql";
import { t } from "i18next";
import { HiiveFullLogo } from "@/modules/Assets";
import {
  CurrencyCircleDollar,
  ShoppingBag,
  Buildings,
  Circle,
} from "@phosphor-icons/react";
import { v4 as uuidv4 } from "uuid";
import { match } from "ts-pattern";

import { UserType } from "./types";

export const taskUserLabel = (actorType: UserType) =>
  match(actorType)
    .with(ExecutionTaskActorType.Hiive, () => t(`hiive`))
    .with(ExecutionTaskActorType.Buyer, () => t(`buyer`))
    .with(ExecutionTaskActorType.Seller, () => t(`seller`))
    .with(ExecutionTaskActorType.Issuer, () => t(`issuer`))
    .otherwise(() => t(`unknown`));

export const taskUserTypeAvatarColor = (actorType: UserType) =>
  match(actorType)
    .with(ExecutionTaskActorType.Hiive, () => `white`)
    .with(ExecutionTaskActorType.Buyer, () => `purple.300`)
    .with(ExecutionTaskActorType.Seller, () => `teal.900`)
    .with(ExecutionTaskActorType.Issuer, () => `orange.400`)
    .otherwise(() => `gray.300`);

export const taskUserTypeIcon = (actorType: UserType) =>
  match(actorType)
    .with(ExecutionTaskActorType.Hiive, () => (
      <HiiveFullLogo.DarkGreyIcon width="12px" height="12px" />
    ))
    .with(ExecutionTaskActorType.Buyer, () => <ShoppingBag size="11px" />)
    .with(ExecutionTaskActorType.Seller, () => (
      <CurrencyCircleDollar size="11px" />
    ))
    .with(ExecutionTaskActorType.Issuer, () => <Buildings size="11px" />)
    .otherwise(() => <Circle size="11px" />);

const DEFAULT_TASK = {
  actorType: ExecutionTaskActorType.Hiive,
  status: ExecutionTaskStatus.Pending,
  type: ExecutionTaskType.ManuallyConfirm,
};

export function getFutureTasks(config: WorkflowStepConfigFragment) {
  if (config.__typename === `ExecutionAnvilConfig`) {
    return config.taskOrder.map(
      (task) =>
        ({
          id: uuidv4(),
          actorType: task.actorType,
          status: ExecutionTaskStatus.Pending,
          type: task.type,
        }) as TransactionPageV2ExecutionTaskFragment,
    );
  }

  if (config.__typename === `ExecutionAnvilV2Config`) {
    return [
      ...config.requiredFills.map((requiredFill) => ({
        id: uuidv4(),
        actorType: requiredFill.actorType,
        status: ExecutionTaskStatus.Pending,
        type: ExecutionTaskType.AnvilFillDocument,
      })),
      ...config.requiredSignatures.map((actorType) => ({
        id: uuidv4(),
        actorType,
        status: ExecutionTaskStatus.Pending,
        type: ExecutionTaskType.AnvilSignDocument,
      })),
    ];
  }

  if (config.__typename === `ExecutionIssuerApprovalConfig`) {
    return [
      {
        id: uuidv4(),
        ...DEFAULT_TASK,
        type: ExecutionTaskType.ConfirmIssuerApproval,
      },
    ];
  }

  return [{ id: uuidv4(), ...DEFAULT_TASK }];
}
