import currency from "currency.js";
import isNil from "lodash/isNil";

export const CurrencyFormat = {
  precision2: {
    separator: `,`,
    precision: 2,
    symbol: `$`,
  },
  noSymbolPrecision0: {
    separator: `,`,
    precision: 0,
    symbol: ``,
  },
} as const;

const formatCurrency: (
  number?: number | null,
  opts?: currency.Options,
) => string = (number, opts) =>
  !isNil(number) ? currency(number, opts).format() : formatCurrency(0, opts);

export default formatCurrency;
