import debounce from "lodash/debounce";
import {
  ForwardedRef,
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { RefCallBack } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  CompanySearchFragment,
  CompanySortField,
  CompanyStatus,
  SortDirection,
  useCompanySearchComboboxQuery,
} from "@/graphql";
import { Combobox, InputProps } from "@/modules/Form";
import { DEFAULT_FETCH_LIMIT } from "@/constants";
import { Box, Image } from "@chakra-ui/react";
import { MagnifyingGlass } from "@phosphor-icons/react";
import { useColors } from "@/modules/Theme";

interface CompanySearchComboboxProps extends InputProps {
  setValueCallback: (value: CompanySearchFragment) => void;
  value: string;
  label?: string;
  rejectIds?: string[];
}

const CompanySearchCombobox = forwardRef<
  HTMLDivElement,
  CompanySearchComboboxProps
>(
  (
    {
      setValueCallback,
      value,
      label,
      onBlur: _onBlur,
      rejectIds = [],
      ...restProps
    }: CompanySearchComboboxProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const [variables, setVariables] = useState({
      first: DEFAULT_FETCH_LIMIT,
      sortBy: { field: CompanySortField.Name, direction: SortDirection.Asc },
      filterBy: { searchText: ``, statuses: [CompanyStatus.Listed] },
    });
    const { t } = useTranslation();
    const [grey200] = useColors([`grey.200`]);

    const [{ data, fetching }] = useCompanySearchComboboxQuery({
      variables,
    });

    const [search, setSearch] = useState(``);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearch = useCallback(
      debounce((search: string) => {
        setVariables((currentVariables) => ({
          ...currentVariables,
          filterBy: {
            searchText: search,
            statuses: currentVariables.filterBy.statuses,
          },
        }));
      }, 500),
      [],
    );

    useEffect(() => {
      debouncedSearch(search);

      return () => debouncedSearch.cancel();
    }, [search, debouncedSearch]);

    const companies = useMemo(() => {
      if (!data || !data.companies || !data.companies.edges) return [];

      return data.companies.edges
        ?.map((edge) => edge.node)
        .filter((node) => !rejectIds.includes(node.id));
    }, [data, rejectIds]);

    const handleOnBlur = () => {
      const hasSelectedCompany = companies.some(({ id }) => id === value);
      if (hasSelectedCompany) return;

      const match = search || value;
      const company = companies.find(({ name }) => name === match);

      setValueCallback(
        company || { id: ``, name: ``, feeDiscountApplications: [] },
      );

      setSearch(company?.name ?? ``);
    };

    const handleSetValue = (company: CompanySearchFragment) => {
      setSearch(company.name ?? search);
      setValueCallback(company);
    };

    return (
      <Combobox
        label={label || t(`company`)}
        placeholder={t(`search_companies`)}
        getItemKey={(company) => company.id}
        getItemLabel={(company) => company.name}
        getItemLogo={(company) =>
          company.logoUrl ? (
            <Image
              h={6}
              w={6}
              objectFit="contain"
              src={company.logoUrl}
              alt={company.name}
            />
          ) : (
            <Box h={6} w={6} />
          )
        }
        getItems={() => companies}
        value={search}
        getSearchValue={(text) => setSearch(text)}
        setValue={handleSetValue}
        isLoading={fetching}
        leftElement={<MagnifyingGlass size={20} color={grey200} />}
        onBlur={() => Promise.resolve(handleOnBlur())}
        {...restProps}
        ref={ref as RefCallBack & (Ref<HTMLDivElement> | undefined)}
      />
    );
  },
);

export default CompanySearchCombobox;
