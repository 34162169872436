import {
  CompanyManagedMarketPageFragment,
  CompanyManagedMarketPageQuery,
} from "@/graphql";

export function mapManagedMarketCompany(
  data?: CompanyManagedMarketPageQuery,
): CompanyManagedMarketPageFragment | null {
  if (!data?.company) {
    return null;
  }

  return data.company;
}
