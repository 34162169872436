import { Cell } from "@tanstack/react-table";
import { HStack, Image, Text } from "@chakra-ui/react";
import { StatusBadge } from "@/modules/StatusBadge";
import { formatDate, DateTimeFormat } from "@/modules/NumeralFormat";
import { match } from "ts-pattern";
import { useTranslation } from "react-i18next";

import { WorkflowTemplateStatus } from "@/graphql";
import { WorkflowTemplatesTableData } from "./WorkflowTemplatesTable";

interface TableCell {
  readonly cell: Cell<WorkflowTemplatesTableData, unknown>;
}

export function CompanyCell({ cell }: TableCell) {
  const row = cell.getContext().row.original;
  if (!row.company) {
    return null;
  }
  const {
    company: { name, logoUrl },
  } = row;

  return (
    <HStack>
      {!!logoUrl && <Image w={6} h={6} src={logoUrl} objectFit="contain" />}
      <Text>{name}</Text>
    </HStack>
  );
}

export function StatusCell({ cell }: TableCell) {
  const { status } = cell.getContext().row.original;

  const { t } = useTranslation();

  return match(status)
    .with(WorkflowTemplateStatus.Active, () => (
      <StatusBadge variant="green" border="none" title={t(`active`)} />
    ))
    .with(WorkflowTemplateStatus.Inactive, () => (
      <StatusBadge variant="outline" border="none" title={t(`inactive`)} />
    ))

    .otherwise(() => {
      throw new Error(`Unknown status in workflows table <StatusCell/>`);
    });
}

export function LastSavedCell({ cell }: TableCell) {
  const { lastSavedAt } = cell.getContext().row.original;

  return (
    <Text>
      {formatDate(lastSavedAt, DateTimeFormat.fullDateWithMonthShort)}
    </Text>
  );
}
