import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { FeeDiscountDetailsContext } from "@/features/FeeDiscount";
import { useFeeDiscountsTableRowActionsArchiveFeeDiscountMutation } from "@/graphql";
import { ConfirmModal } from "@/modules/Modal";
import { Table } from "@/modules/Table";
import { useCustomToast } from "@/modules/Toast";
import { Menu, MenuGroup, MenuItem, MenuList } from "@chakra-ui/react";

interface FeeDiscountsTableRowActionsProps {
  readonly showActionButtonTitle?: boolean;
}

export default function FeeDiscountsTableRowActions({
  showActionButtonTitle,
}: FeeDiscountsTableRowActionsProps) {
  const { feeDiscount } = useContext(FeeDiscountDetailsContext);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [, archiveFeeDiscount] =
    useFeeDiscountsTableRowActionsArchiveFeeDiscountMutation();
  const { errorToast, successToast } = useCustomToast();

  const { id, name } = feeDiscount || {};

  const handleArchive = async () => {
    if (!id) return;

    setOpen(false);
    const { data } = await archiveFeeDiscount({ id });
    if (data?.archiveFeeDiscount?.feeDiscount?.archivedAt) {
      successToast();
    } else {
      errorToast();
    }
  };

  return (
    <>
      <Menu>
        <Table.ActionButton showTitle={showActionButtonTitle} />
        <MenuList>
          <MenuGroup>
            <MenuItem onClick={() => setOpen(true)}>
              {t(`archive_fee_discount`)}
            </MenuItem>
          </MenuGroup>
        </MenuList>
      </Menu>
      <ConfirmModal
        title={t(`archive_fee_discount`)}
        body={t(`confirm_archive_fee_discount`, { name })}
        isOpen={open}
        onClose={() => setOpen(false)}
        onConfirm={handleArchive}
      />
    </>
  );
}
