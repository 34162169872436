import { useTranslation } from "react-i18next";

import { Skeleton } from "@/modules/Skeleton";
import { useColors } from "@/modules/Theme";
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Menu,
  MenuButton,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  ArrowSquareOut,
  DotsThreeOutlineVertical,
} from "@phosphor-icons/react";

export default function FeeDiscountsCardSkeleton() {
  const { t } = useTranslation();
  const [grey600] = useColors([`grey.600`]);

  return (
    <Card w="full">
      <CardHeader as={HStack} gap={4} alignItems="center">
        <Text textStyle="colfax-22-medium">{t(`fee_discounts`)}</Text>
        <Box flexGrow={1} />
        <Menu>
          <MenuButton>
            <HStack p={1}>
              <DotsThreeOutlineVertical weight="fill" size={16} />
              <Text textStyle="colfax-12-medium">{t(`actions`)}</Text>
            </HStack>
          </MenuButton>
        </Menu>
      </CardHeader>
      <CardBody>
        <VStack alignItems="flex-start" mb={4}>
          <HStack alignItems="center">
            <Skeleton h={5} w={40} />
            <ArrowSquareOut size={18} color={grey600} />
          </HStack>
          <VStack
            alignItems="flex-start"
            whiteSpace="nowrap"
            spacing="1"
            textStyle="colfax-14-regular"
            width="full"
          >
            <Skeleton h={5} w="full" />
            <Skeleton h={5} w="full" />
            <Skeleton h={5} w="full" />
          </VStack>
        </VStack>
      </CardBody>
    </Card>
  );
}
