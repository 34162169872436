import { SortDirection } from "@/graphql";
import {
  Table,
  TableSortState,
  formatColumnIdToSortField,
} from "@/modules/Table";
import { DEFAULT_FETCH_LIMIT, RESET_PAGINATION_PARAMS } from "@/constants";
import { BoxProps } from "@chakra-ui/react";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { useDebounce } from "@/modules/Debounce";
import isNil from "lodash/isNil";
import {
  SimpleTableProps,
  SimpleTableQueryVariables,
  TableData,
} from "./types";

export default function SimpleTable<
  TVariables extends SimpleTableQueryVariables = SimpleTableQueryVariables,
  TColumn extends TableData = TableData,
  TData extends TableData = TColumn,
>({
  setVariables,
  columns,
  pageInfo,
  totalCount,
  page,
  perPage = DEFAULT_FETCH_LIMIT,
  sort,
  setSort,
  searchText,
  onRowClick,
  renderRowActions,
  error,
  loading,
  tableData,
  hidePagination = false, // TODO:: remove on pagination separation
  tableStyleProps,
}: SimpleTableProps<TVariables, TColumn, TData> & {
  page: number;
  perPage?: number;
  sort: TableSortState | undefined;
  searchText?: string;
  setSort: Dispatch<SetStateAction<TableSortState | undefined>>;
  hidePagination?: boolean;
  tableStyleProps?: BoxProps;
}) {
  const startCursorRef = useRef<string | null | undefined>(null);

  const onPreviousPage = useCallback(() => {
    if (!pageInfo) {
      return;
    }
    const { startCursor } = pageInfo;
    setVariables((prevVariables) => ({
      ...prevVariables,
      first: undefined,
      last: perPage,
      after: undefined,
      before: startCursor,
      page: prevVariables.page ? prevVariables.page - 1 : undefined,
    }));
  }, [pageInfo, setVariables, perPage]);

  const onNextPage = useCallback(() => {
    if (!pageInfo) {
      return;
    }

    const { endCursor } = pageInfo;

    setVariables((prevVariables) => ({
      ...prevVariables,
      first: perPage,
      last: undefined,
      before: undefined,
      after: endCursor,
      page: prevVariables.page ? prevVariables.page + 1 : undefined,
    }));
  }, [pageInfo, setVariables, perPage]);

  useEffect(() => {
    if (!pageInfo) {
      return;
    }
    const { startCursor, endCursor, hasPreviousPage } = pageInfo;

    // if no start or end cursor then no items on page,
    // navigate back if previous page available
    if (isNil(startCursor) && isNil(endCursor) && hasPreviousPage) {
      setVariables((prevVariables) => ({
        ...prevVariables,
        first: undefined,
        last: perPage,
        after: undefined,
        before: startCursorRef.current,
        page: prevVariables.page ? prevVariables.page - 1 : undefined,
      }));
    }

    // eslint-disable-next-line functional/immutable-data
    startCursorRef.current = startCursor;
  }, [pageInfo, setVariables, perPage]);

  // When a user select the sort field
  const onSort = useCallback(
    (columnId: string): SortDirection => {
      const field = formatColumnIdToSortField(columnId);

      const newDirection =
        sort?.field === field && sort?.direction === SortDirection.Asc
          ? SortDirection.Desc
          : SortDirection.Asc;

      setVariables((prevVariables) => ({
        ...prevVariables,
        sortBy: {
          field,
          direction: newDirection,
        },
      }));

      return newDirection;
    },
    [setVariables, sort?.direction, sort?.field],
  );

  // When user searches, debounce
  const { debounce, cancel } = useDebounce(500);

  useEffect(() => {
    const isNewSearchTerm = (search: string, prevVariables: TVariables) => {
      const previousSearch = prevVariables?.filterBy?.searchText ?? ``;
      return search !== previousSearch;
    };

    const resetKeysOnNewSearch = (search: string, prevVariables: TVariables) =>
      isNewSearchTerm(search, prevVariables)
        ? {
            ...prevVariables,
            ...RESET_PAGINATION_PARAMS,
            first: perPage,
          }
        : prevVariables;

    debounce(() => {
      setVariables((prevVariables) => ({
        ...resetKeysOnNewSearch(searchText as string, prevVariables),
        filterBy: {
          ...prevVariables.filterBy,
          searchText,
        },
      }));
    });

    return cancel;
  }, [searchText, setVariables, debounce, cancel, perPage]);

  if (error) {
    return <Table.Error message={error.message} />;
  }

  // shown on initial load only
  if (loading && !pageInfo) {
    return <Table.Loading />;
  }

  return (
    <Table
      data={tableData}
      columns={columns}
      sorting={sort}
      setSorting={setSort}
      onSort={onSort}
      onRowClick={onRowClick}
      renderRowActions={renderRowActions}
      // FIXME: (Optional) Candidate for breaking the footer into its own component outside of this component
      pagination={
        hidePagination ? null : (
          <Table.Pagination
            onPreviousPage={onPreviousPage}
            onNextPage={onNextPage}
            page={page}
            showPreviousPage={page > 1}
            perPage={perPage}
            showNextPage={!!(totalCount && page * perPage < totalCount)}
            totalCount={totalCount ?? 0}
            loading={loading}
          />
        )
      }
      {...tableStyleProps}
    />
  );
}
