import { BankAccount } from "@/graphql";
import { HStack, Flex, Text, TextProps } from "@chakra-ui/react";

type BankAccountNumberProps = TextProps & {
  readonly bankAccount: Pick<BankAccount, "last4">;
};

export default function BankAccountNumber({
  bankAccount: { last4 },
  ...props
}: BankAccountNumberProps) {
  return (
    <Text
      as={HStack}
      fontSize={16}
      fontWeight={400}
      gap={1}
      color="gray.800"
      {...props}
    >
      <Flex gap={0.5} justifyContent="end">
        {[...Array(4).keys()].map((key) => (
          <svg
            fill="none"
            height="4"
            key={key}
            viewBox="0 0 4 4"
            width="4"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="2" cy="2" r="2" fill="#424242" />
          </svg>
        ))}
      </Flex>
      <span>{last4}</span>
    </Text>
  );
}
