import { Maybe } from "@/graphql";
import formatValuation from "./formatValuation";

const formatCapitalRaised = ({
  lastRoundRaised,
  totalRaised,
}: {
  readonly lastRoundRaised: Maybe<number> | undefined;
  readonly totalRaised: Maybe<number> | undefined;
}) => {
  const lastRoundText = lastRoundRaised && formatValuation(lastRoundRaised);
  const totalText = totalRaised && `${formatValuation(totalRaised)} total`;

  return [lastRoundText, totalText].filter(Boolean).join(` of `);
};

export default formatCapitalRaised;
