import { Skeleton } from "@/modules/Skeleton";
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

export default function TableLoading() {
  const data = new Array(15).fill(undefined);
  const columns = new Array(6).fill(undefined);
  return (
    <TableContainer w="full">
      <Table size="sm">
        <Thead>
          {[undefined].map((_, index) => (
            <Tr
              key={`skeleton-table-thead-tr-${index.toString()}`}
              borderColor="grey.100 !important"
              borderY="1px"
            >
              {columns.map((_, index) => (
                <Th
                  key={`skeleton-table-thead-th-${index.toString()}`}
                  borderColor="grey.100"
                  bg="grey.25"
                >
                  <Skeleton h={5} m={2} w="full" />
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {data.map((_, index) => (
            <Tr
              key={`skeleton-table-tbody-tr-${index.toString()}`}
              bg={index % 2 ? `grey.25` : `white`}
              border="none"
            >
              {columns.map((_, index) =>
                index === 0 ? (
                  <Td
                    key={`skeleton-table-tbody-td-${index.toString()}`}
                    border="none"
                    w={5}
                  >
                    <Skeleton w={5} h={5} m={2} borderRadius="full" />
                  </Td>
                ) : (
                  <Td
                    key={`skeleton-table-tbody-td-${index.toString()}`}
                    border="none"
                  >
                    <Skeleton h={5} m={2} w="full" />
                  </Td>
                ),
              )}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
