import { Badge, Box, HStack, Image, Text } from "@chakra-ui/react";

function BankAccountTransactionBadge({
  logoUrl,
  displayId,
}: {
  readonly logoUrl: string;
  readonly displayId: string;
}) {
  return (
    <Badge
      as={HStack}
      variant="outline"
      shadow={0}
      bg="white"
      borderColor="grey.75"
      borderWidth="1px"
      p={1}
      px={1.5}
    >
      {logoUrl && (
        <Box>
          <Image h="4" w="4" objectFit="contain" src={logoUrl} />
        </Box>
      )}
      <Text textStyle="colfax-12-regular" color="grey.900">
        {displayId}
      </Text>
    </Badge>
  );
}

export default BankAccountTransactionBadge;
