import { TransactionEntityFragment } from "@/graphql";
import { Text } from "@chakra-ui/react";
import { getCountryName } from "@/modules/Country";
import { useTranslation } from "react-i18next";
import EntityCardBody from "./EntityCardBody";
import EntityCard from "./EntityCard";

type CurrentEntityCardProps = {
  entity: TransactionEntityFragment;
};

function CurrentEntityCard({ entity }: CurrentEntityCardProps) {
  const { t } = useTranslation();
  const country = getCountryName(entity.jurisdictionOfFormation?.id);

  return (
    <EntityCard backgroundColor="grey.50">
      <Text fontWeight={400} fontSize={12} lineHeight={3}>
        {t(`current_entity`)}
      </Text>
      <EntityCardBody country={country} entity={entity} />
    </EntityCard>
  );
}

export default CurrentEntityCard;
