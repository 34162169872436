import { useForm } from "react-hook-form";
import { useEffect, useMemo, useState } from "react";

import { TableSortState } from "@/modules/Table";
import {
  SimpleTableQueryVariables,
  SimpleTableUrlParams,
  mergeSimpleTableVariables,
} from "@/modules/SimpleTable";
import { FeeDiscountType } from "@/graphql";

interface UseTableProps {
  defaultVariables: SimpleTableQueryVariables;
  query: SimpleTableUrlParams & {
    status?: string;
  };
  sortFields: string[];
  fromUrl: (
    vars: SimpleTableUrlParams,
    sortFields: string[],
  ) => SimpleTableQueryVariables;
}

export const useTable = ({
  defaultVariables,
  query,
  sortFields,
  fromUrl,
}: UseTableProps) => {
  const urlQuery = useMemo(
    () => fromUrl(query, sortFields),
    [fromUrl, query, sortFields],
  );

  const [variables, setVariables] = useState(
    mergeSimpleTableVariables<SimpleTableQueryVariables>(
      defaultVariables,
      urlQuery,
    ),
  );

  const { register, watch, resetField, handleSubmit, control } = useForm<{
    searchText: string;
    securitySpecialistCheckbox?: Record<string, boolean>;
    feeDiscountCheckbox?: Record<FeeDiscountType, boolean>;
  }>({
    defaultValues: {
      searchText: variables.filterBy?.searchText ?? ``,
    },
  });
  const [sort, setSort] = useState<TableSortState | undefined>(
    variables.sortBy,
  );

  useEffect(() => {
    // if URL query changes, update query variables
    setVariables(
      mergeSimpleTableVariables<SimpleTableQueryVariables>(
        defaultVariables,
        fromUrl(query, sortFields),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return {
    page: variables.page ?? 1,
    searchText: watch(`searchText`),
    sort,
    variables,
    register,
    resetField,
    setSort,
    setVariables,
    handleSubmit,
    control,
  };
};
