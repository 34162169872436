import { ReactNode, createContext, useContext } from "react";
import { UseQueryState } from "urql";

import {
  SortDirection,
  TransactionFilter,
  TransactionSort,
  TransactionSortField,
  TransactionsTableQuery,
  TransactionsTableQueryVariables,
  useTransactionsTableQuery,
} from "@/graphql";

import { getDefaultTableProviderVariables } from "@/constants";
import {
  SimpleTableUrlParams,
  TableContext,
  TableProvider,
} from "@/modules/SimpleTable";

interface TransactionsTableProviderProps {
  children: ReactNode;
  query: SimpleTableUrlParams & {
    states?: [string];
    transfer_methods?: [string];
  };
}

interface ChildProps {
  children: ReactNode;
}

const Context = createContext<{
  data:
    | UseQueryState<TransactionsTableQuery, TransactionsTableQueryVariables>
    | undefined;
}>({ data: undefined });
const { Provider } = Context;

const DEFAULT_VARIABLES = getDefaultTableProviderVariables({
  sortBy: {
    field: TransactionSortField.UpdatedAt,
    direction: SortDirection.Desc,
  },
  filterBy: {
    searchText: ``,
    securitySpecialistUserIds: [],
  },
});

function Child({ children }: ChildProps) {
  const { variables } = useContext(TableContext);

  const [data] = useTransactionsTableQuery({
    variables: {
      ...variables,
      sortBy: variables.sortBy as TransactionSort,
      filterBy: variables.filterBy as TransactionFilter,
    },
    requestPolicy: `cache-and-network`,
  });

  return <Provider value={{ data }}>{children}</Provider>;
}

function TransactionsTableProvider({
  children,
  query,
}: TransactionsTableProviderProps) {
  return (
    <TableProvider
      query={query}
      defaultVariables={DEFAULT_VARIABLES}
      sortFields={Object.values(TransactionSortField)}
    >
      <Child>{children}</Child>
    </TableProvider>
  );
}

export { Context, TransactionsTableProvider };
