import {
  CompanyApprovedBuyerType,
  useInstitutionsTableQuery,
  InstitutionSortField,
  SortDirection,
  InstitutionsTableEdgeFragment,
  useAddCompanyApprovedBuyerMutation,
  useUsersTableQuery,
  UserSortField,
  UsersTableEdgeFragment,
} from "@/graphql";
import { useCustomToast } from "@/modules/Toast";
import { CompanyManagedMarketContext } from "@/features/Company";
import { useDebounce } from "@/modules/Debounce";
import { InferType } from "yup";
import { ConfirmModal } from "@/modules/Modal";
import { useContext, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ComboboxPatch, Input, useYupValidationResolver } from "@/modules/Form";
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import * as yup from "yup";
import { VStack } from "@chakra-ui/react";
import { MagnifyingGlass } from "@phosphor-icons/react";
import { useColors } from "@/modules/Theme";
import i18next from "i18next";

const AddApprovedBuyerValidationSchema = yup.object({
  entityName: yup.string().required(),
  buyerType: yup
    .mixed<CompanyApprovedBuyerType>()
    .oneOf(Object.values(CompanyApprovedBuyerType))
    .required(),
  entityId: yup
    .string()
    .required()
    .when(`buyerType`, ([buyerType], schema) => {
      if (buyerType === CompanyApprovedBuyerType.User) {
        return schema.required(i18next.t(`user_email_required`));
      }
      if (buyerType === CompanyApprovedBuyerType.Institution) {
        return schema.required(i18next.t(`institution_name_required`));
      }
      return schema.required(i18next.t(`required`));
    }),
});

type AddApprovedBuyerModalInputs = InferType<
  typeof AddApprovedBuyerValidationSchema
>;

const DEFAULT_LIMIT = 100;

function InstitutionCombobox() {
  const { t } = useTranslation();
  const { managedMarketCompany } = useContext(CompanyManagedMarketContext);
  const [searchText, setSearchText] = useState(``);
  const { setValue, control, clearErrors } =
    useFormContext<AddApprovedBuyerModalInputs>();
  const { debounce } = useDebounce(500);
  const [grey200] = useColors([`grey.200`]);

  const [{ data, fetching }] = useInstitutionsTableQuery({
    variables: {
      first: DEFAULT_LIMIT, // Arbitrary limit so we don't load too many institutions but still enough for user to scroll if needed
      sortBy: {
        field: InstitutionSortField.LegalName,
        direction: SortDirection.Asc,
      },
      filterBy: {
        searchText,
      },
    },
    requestPolicy: `cache-and-network`,
  });

  const comboboxItems = useMemo(() => {
    const approvedBuyerInstitutionIds = new Set(
      managedMarketCompany?.companyApprovedBuyers?.map(
        (buyer) => buyer.institution?.id,
      ),
    );

    return (data?.institutions?.edges ?? []).filter(
      (institution) => !approvedBuyerInstitutionIds.has(institution.node.id),
    );
  }, [data, managedMarketCompany]);

  const onChangeSearch = (searchText: string) => {
    const entity = data?.institutions?.edges?.find(
      (edge) => edge.node.legalName === searchText,
    );
    if (entity) {
      setValue(`entityId`, entity.node.id);
      setValue(`entityName`, entity.node.legalName);
      clearErrors(`entityId`);
    }
    if (!entity) {
      setValue(`entityId`, ``);
      setValue(`entityName`, ``);
    }

    debounce(() => setSearchText(searchText));
  };

  const setSelectedItem = (value: InstitutionsTableEdgeFragment | null) => {
    setValue(`entityId`, value?.node.id || ``);
    setValue(`entityName`, value?.node.legalName || ``);
  };

  return (
    <ComboboxPatch<InstitutionsTableEdgeFragment>
      getItems={() => comboboxItems}
      itemToString={(item) => item.node?.legalName || ``}
      getItemKey={(item) => item.node?.id || ``}
      isLoading={fetching}
      label={t(`institution_name`)}
      placeholder={t(`search_name_registrant`)}
      setSelectedItem={setSelectedItem}
      control={{
        name: `entityId`,
        ...control,
      }}
      leftElement={<MagnifyingGlass size={20} color={grey200} />}
      onChangeSearch={onChangeSearch}
    />
  );
}

const getUserDisplayString = (user: UsersTableEdgeFragment) =>
  user.node?.email ? `${user.node.email} (${user.node.name})` : ``;

function UserCombobox() {
  const { t } = useTranslation();
  const { managedMarketCompany } = useContext(CompanyManagedMarketContext);
  const [searchText, setSearchText] = useState(``);
  const { setValue, control, clearErrors } =
    useFormContext<AddApprovedBuyerModalInputs>();
  const { debounce } = useDebounce(500);
  const [grey200] = useColors([`grey.200`]);
  const [{ data, fetching }] = useUsersTableQuery({
    variables: {
      first: 100, // Arbitrary limit so we don't load too many institutions but still enough for user to scroll if needed
      sortBy: {
        field: UserSortField.Email,
        direction: SortDirection.Asc,
      },
      filterBy: {
        searchText,
      },
    },
    requestPolicy: `cache-and-network`,
  });

  const comboboxItems = useMemo(() => {
    const approvedBuyerUserIds = new Set(
      managedMarketCompany?.companyApprovedBuyers?.map(
        (buyer) => buyer.user?.id,
      ),
    );

    return (data?.users?.edges ?? []).filter(
      (user) => !approvedBuyerUserIds.has(user.node.id),
    );
  }, [data, managedMarketCompany]);

  const onChangeSearch = (searchText: string) => {
    const entity = data?.users?.edges?.find(
      (edge) => getUserDisplayString(edge) === searchText,
    );
    if (entity) {
      setValue(`entityId`, entity.node.id);
      setValue(`entityName`, getUserDisplayString(entity));
      clearErrors(`entityId`);
    }
    if (!entity) {
      setValue(`entityId`, ``);
      setValue(`entityName`, ``);
    }

    debounce(() => setSearchText(searchText));
  };

  const setSelectedItem = (value: UsersTableEdgeFragment | null) => {
    setValue(`entityId`, value?.node.id || ``);
    setValue(`entityName`, value ? getUserDisplayString(value) : ``);
  };

  return (
    <ComboboxPatch<UsersTableEdgeFragment>
      getItems={() => comboboxItems}
      itemToString={getUserDisplayString}
      getItemKey={(user) => user.node?.id || ``}
      isLoading={fetching}
      label={t(`user`)}
      placeholder={t(`search_email_name`)}
      setSelectedItem={setSelectedItem}
      control={{
        name: `entityId`,
        ...control,
      }}
      leftElement={<MagnifyingGlass size={20} color={grey200} />}
      onChangeSearch={onChangeSearch}
    />
  );
}

const useBuyerType = () => {
  const { control, resetField } = useFormContext();

  const buyerType = useWatch({
    control,
    name: `buyerType`,
  });

  useEffect(() => {
    resetField(`entityId`);
    resetField(`entityName`);
  }, [buyerType, resetField]);

  return buyerType;
};

function AddApprovedBuyerModalBody() {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useFormContext<AddApprovedBuyerModalInputs>();

  const buyerType = useBuyerType();

  return (
    <VStack spacing={8}>
      <Input.Select
        label={t(`buyer_type`)}
        options={[
          {
            label: t(`individual`),
            value: CompanyApprovedBuyerType.User,
          },
          {
            label: t(`institution`),
            value: CompanyApprovedBuyerType.Institution,
          },
        ]}
        error={errors.buyerType}
        {...register(`buyerType`)}
      />
      {buyerType === CompanyApprovedBuyerType.Institution && (
        <InstitutionCombobox />
      )}
      {buyerType === CompanyApprovedBuyerType.User && <UserCombobox />}
    </VStack>
  );
}

interface AddApprovedBuyerModalProps {
  readonly isOpen: boolean;
  readonly onClose: () => void;
}

export function AddApprovedBuyerModal({
  isOpen,
  onClose,
}: AddApprovedBuyerModalProps) {
  const { t } = useTranslation();
  const { successToast, errorToast } = useCustomToast();
  const [confirmationModal, setShowConfirmationModal] = useState(false);
  const { managedMarketCompany } = useContext(CompanyManagedMarketContext);
  const [, addCompanyApprovedBuyer] = useAddCompanyApprovedBuyerMutation();

  const methods = useForm<AddApprovedBuyerModalInputs>({
    resolver: useYupValidationResolver(AddApprovedBuyerValidationSchema),
    defaultValues: { buyerType: CompanyApprovedBuyerType.Institution },
  });

  if (!managedMarketCompany) {
    return null;
  }

  const onConfirmConfirmationModal = async () => {
    const { entityId, buyerType } = methods.getValues();
    const { data: response } = await addCompanyApprovedBuyer({
      companyId: managedMarketCompany.id,
      entityId,
      type: buyerType,
    });
    if (response?.addCompanyApprovedBuyer.companyApprovedBuyer?.id) {
      successToast(t(`approved_buyer_added`));
    } else {
      errorToast(t(`approved_buyer_added_error`));
    }
    onClose();
  };

  const onConfirmEntitySelectionModal = () => {
    setShowConfirmationModal(true);
  };

  const handleCancel = () => {
    methods.resetField(`entityId`);
    methods.resetField(`entityName`);
    setShowConfirmationModal(false);
  };

  return (
    <FormProvider {...methods}>
      {!confirmationModal ? (
        <ConfirmModal
          title={t(`add_approved_buyer_to_company`, {
            companyName: managedMarketCompany?.name,
          })}
          body={<AddApprovedBuyerModalBody />}
          isOpen={isOpen}
          onClose={onClose}
          onConfirm={methods.handleSubmit(onConfirmEntitySelectionModal)}
          confirmText={t(`add_approved_buyer`)}
        />
      ) : (
        <ConfirmModal
          title={t(`confirm_approved_buyer`)}
          body={
            <Trans
              i18nKey="are_you_sure_confirm_approved_buyer"
              components={{ bold: <strong /> }}
              values={{
                companyName: managedMarketCompany?.name,
                entityName: methods.watch(`entityName`),
              }}
            />
          }
          isOpen={isOpen}
          onClose={onClose}
          onConfirm={methods.handleSubmit(onConfirmConfirmationModal)}
          onCancel={handleCancel}
          cancelText={t(`back`)}
        />
      )}
    </FormProvider>
  );
}
